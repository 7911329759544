import {URL} from '../../../config';
import {
  GET_SUBFORM_LIST_BEGIN,
  GET_SUBFORM_LIST_FAILURE,
  GET_SUBFORM_LIST_RESET,
  GET_SUBFORM_LIST_SUCCESS, 
  ACTIVATE_SUBFORM_BEGIN,
  ACTIVATE_SUBFORM_FAILURE,
  ACTIVATE_SUBFORM_SUCCESS,
  SWAP_SUBFORM_BEGIN,
  SWAP_SUBFORM_FAILURE,
  SWAP_SUBFORM_SUCCESS,
  SET_FILLING_UP_STATUS, 
  SET_NEXT_SUBFORM_ID,
  PREVIOUS_SUBFORM_LIST
} from "../../../reducers/questionnaire/form/subFormList";

const OPERATION = {
  GET : 'get',
  ACTIVATE : 'activate',
  SWAP: 'swap',
}

function getSubFormList(localeDicId, formId, modeAdmin) {
  return dispatch => {
    dispatch(setBegin(OPERATION.GET));
    return getFormListStart(localeDicId, formId, modeAdmin)
      .then((data) => {
        if (modeAdmin !== true) initFillingUpStatus(dispatch, data);
        dispatch(setSuccess(OPERATION.GET, data));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.GET))
      });
  };
}

function getFormListStart(localeDicId, formId, modeAdmin) {
  return new Promise((resolve, reject) => {
    fetch(
      modeAdmin !== true ?
      URL + `/secured/questinnaire/subform/` + formId + '?localeDicId=' + localeDicId
      :
      URL + `/secured/questinnaire/subform/dic/list?localeDicId=${localeDicId}&formDicId=${formId}`, {
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
       },
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data)
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}


function activateSubform(id, active) {
  return dispatch => {
    dispatch(setBegin(OPERATION.ACTIVATE));
    return activateSubformStart(id, active)
      .then(() => {
        dispatch(setSuccess(OPERATION.ACTIVATE));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.ACTIVATE))
      });
  };
}

function activateSubformStart(id, active) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/questinnaire/subform/dic/setIsActive?subFormDicId=${id}&isActive=${active}`, {
      method: 'PUT',
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
       },
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

function swapSubform(idFirst, idSecond) {
  return dispatch => {
    dispatch(setBegin(OPERATION.SWAP));
    return swapSubformStart(idFirst, idSecond)
      .then(() => {
        dispatch(setSuccess(OPERATION.SWAP));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.SWAP))
      });
  };
}

function swapSubformStart(idFirst, idSecond) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/questinnaire/subform/dic/swap-order?firstId=${idFirst}&secondId=${idSecond}`, {
      method: 'PUT',
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
       },
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setBegin = (operation) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_SUBFORM_LIST_BEGIN
      }
    case OPERATION.ACTIVATE:
      return {
        type: ACTIVATE_SUBFORM_BEGIN
      }
    case OPERATION.SWAP:
      return {
        type: SWAP_SUBFORM_BEGIN
      }
    default: return null;
  }

};

const setSuccess = (operation, data) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_SUBFORM_LIST_SUCCESS,
        data: data
      }
    case OPERATION.ACTIVATE:
      return {
        type: ACTIVATE_SUBFORM_SUCCESS
      }
    case OPERATION.SWAP:
      return {
        type: SWAP_SUBFORM_SUCCESS
      }
    default: return null;
  }
};

const setFailure = (operation) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_SUBFORM_LIST_FAILURE,
      }
    case OPERATION.ACTIVATE:
      return {
        type: ACTIVATE_SUBFORM_FAILURE
      }
    case OPERATION.SWAP:
      return {
        type: SWAP_SUBFORM_FAILURE
      }
    default: return null;
  }
};


const setFillingUpStatus = (fillingUpStatus) => ({
  type: SET_FILLING_UP_STATUS,
  fillingUpStatus : fillingUpStatus
});

const resetState = () => ({
  type: GET_SUBFORM_LIST_RESET
});

const initFillingUpStatus = (dispatch, data) => {
  const initFillingUpStatus = getInitFillingUpStatus(data);
  dispatch(setFillingUpStatus(initFillingUpStatus));
}

const getInitFillingUpStatus = (data) => {
  const fillingUpStatus = [];
  data.forEach(subForm => {
    const questionGroupList = [];
    fillingUpStatus.push({
      subFormId : subForm.id,
      questionGroupList : questionGroupList
    })
    const newQuestionGroupList = subForm.questionGroupList;
    initQuestionGroupList(newQuestionGroupList, questionGroupList);
  });
  return fillingUpStatus;
}

const updateFillingUpStatus = (oldFillingUpStatus, questionList, subFormId, wihoutData) => {
  const fillingUpStatus = JSON.parse(JSON.stringify(oldFillingUpStatus));
  const questionGroupList = fillingUpStatus.find(el => el.subFormId === subFormId).questionGroupList;
  const newQuestionGroupList = wihoutData ? questionList : questionList.data;
  questionGroupList.length = 0;
  initQuestionGroupList(newQuestionGroupList, questionGroupList);
  return {
    type: SET_FILLING_UP_STATUS,
    fillingUpStatus: fillingUpStatus
  };
}

const getSkipQuestions = (question) => {
  let skipQuestions = 0;
  question.answerList.forEach(el => {
    if (Number.isInteger(el.skipQuestions) && el.skipQuestions > skipQuestions) {
      const answer = question.answers.find(i => {
        return i.answerListId === el.id;
      })
      if (answer !== undefined) {
        skipQuestions = el.skipQuestions;
      }
    }
  })
  return skipQuestions;
}

const initQuestionGroupList = (newQuestionGroupList, questionGroupList) => {
  newQuestionGroupList.forEach(group => {
    const disabledIndex = [];
    const questionGroup = {
      id: group.id,
      qlNumber: group.qlNumber,
      questionList : []
    };

    group.questionList.forEach((question, key) => {
      const skipQuestions = getSkipQuestions(question);
      if (skipQuestions > 0) {
        for (let i = 0; i < skipQuestions; i++) {
          disabledIndex.push(key + i + 1);
        }
      }

      // for question number 1069
      if (question.questionId===1069 && (question.answers[0] ? ! parseFloat(question.answers[0].answer) : true)){
        for (let i = key; i < group.questionList.length; i++) {
          disabledIndex.push(i+1);
        }
      }

      const newQuestionList = {
        id: question.id,
        isComplete : isQuestionListComplete(question, disabledIndex, key)
      };
      questionGroup.questionList.push(newQuestionList);
    })
    questionGroupList.push(questionGroup);
  });
}

const isQuestionListComplete = (question, disabledIndex, key) => {
  let isComplete = false;
  if (disabledIndex.includes(key)){
      isComplete = true;
  }
  else{
    if (question.answers.length !== 0){
        if (question.answers[0].text || question.answers[0].answerListId || question.answers[0].answer){
          isComplete = true;
        }
    }
  }
  return isComplete;
}


const setNextSubFormId = (id) => ({
  type: SET_NEXT_SUBFORM_ID,
  nextSubFormId : id
});

const setPrevSubformList = (prevSubformList) => ({
  type: PREVIOUS_SUBFORM_LIST,
  prevSubformList : prevSubformList
});




const index = {
  getSubFormList,
  setFillingUpStatus,
  setNextSubFormId,
  updateFillingUpStatus,
  resetState,
  setPrevSubformList,
  activateSubform,
  swapSubform,
}

export default index;
