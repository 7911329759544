import React from "react";
import {withStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import QuestionTemplate from "./Template";
import {translations} from "../../../../dictionary/translations";
import QuestionTemplateContainer from "../../../../containers/questionnaire/form/question/Template";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "inline-block",
    marginRight: '1%'
  },
});

class TextQuestion extends React.Component {

  componentDidMount(){
    const { modeAdmin, question, answerLastPen, setAnswers, persistAnswersOnTimeout } =  this.props;
    if (modeAdmin!==true){
      if (question.answers[0]){
        this.setState({value: question.answers[0].answer });
      }
      else if (answerLastPen){
        setAnswers([{
          answerListId: null,
          answer: answerLastPen
        }]);
        persistAnswersOnTimeout();
        this.setState({value: answerLastPen });
      }
    }
  }

  componentDidUpdate(prevProps){
    const {answerLastPen, setAnswers, persistAnswersOnTimeout } =  this.props;
    const {value} = this.state;
    if (answerLastPen !== prevProps.answerLastPen && !value){
        setAnswers([{
          answerListId: null,
          answer: answerLastPen
        }]);
        persistAnswersOnTimeout();
        this.setState({value: answerLastPen });
    }
  }

  state = {
    value: ''
  }

  handleChange = (event) => {
    const { setAnswers, persistAnswersOnTimeout } = this.props;
    this.setState({value: event.target.value});
    setAnswers([{
      answerListId: null,
      answer: event.target.value
    }]);
    persistAnswersOnTimeout();
  };

  render() {
    const { locale, questionNumber, question, isSideListOpen, persistAnswersOnBlur, answersPersistStatus, isAnswerReadOnly, isQuestionReadOnly, modeAdmin, activateQuestion } = this.props;
    const {value} = this.state;
    return (
      <QuestionTemplateContainer 
          Layout={QuestionTemplate} 
          modeAdmin={modeAdmin} 
          activateQuestion={activateQuestion} 
          questionNumber={questionNumber} 
          question={question} 
          answersPersistStatus={answersPersistStatus} 
          isQuestionReadOnly={isQuestionReadOnly} 
          isAnswerReadOnly={isAnswerReadOnly}
          isSideListOpen={isSideListOpen}
      >
        <FormControl component="fieldset">
          <TextField
            name="value"
            label={translations.WRITE_ANSWER[locale.localeName]}
            onChange={modeAdmin!==true ? this.handleChange : null}
            //onBlur={modeAdmin!==true ? persistAnswersOnBlur: null}
            multiline
            rows="3"
            value={modeAdmin!==true ? value : ''}
            inputProps={{
              maxLength: '1000'
            }}
            />
        </FormControl>
      </QuestionTemplateContainer>
    )
  }
}

export default withStyles(styles)(TextQuestion);
