import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class FarmEditContainer extends React.Component {

  render() {

    const {
      Layout,
      locale,
      editFarm,
      editFarmStatus,
      resetEditFarm,
      farmId
    } = this.props;

    return (
      <Layout
        locale={locale}
        editFarm={editFarm}
        editFarmStatus={editFarmStatus}
        resetEditFarm={resetEditFarm}
        farmId={farmId}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  editFarmStatus: state.farm.edit,
  farmId: state.farm.farm.id
});

const mapDispatchToProps = {
  editFarm: actions.farm.edit.editFarm,
  resetEditFarm : actions.farm.edit.resetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmEditContainer);
