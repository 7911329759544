import React from "react";
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import QuestionTemplate from "./Template";
import {translations} from "../../../../dictionary/translations";
import QuestionTemplateContainer from "../../../../containers/questionnaire/form/question/Template";
import Input from "@material-ui/core/Input";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import {style} from "../../../../styles/style.js";
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import {URL} from "../../../../config";
import FormHelperText from '@material-ui/core/FormHelperText';



const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {main: style.rose_dark},
  }
});

const styles = theme => ({
  label: {
    paddingTop: 12,
    paddingBottom: 12,
    color: `${style.fiolet} !important`,
  },
  helper : {
    color: 'red',
    fontSize: 15,
  },
});

class AnswerType13 extends React.Component {

  state = {
    questionContent : null,
    localQuestion : {
      answerList : []
    },
    errorStatus: [],
    errorText: [],
    inputValue: [],
    inputValueList: [],
  }

  getQuestionContent = (questionnaireId) => {
    fetch(URL + `/secured/question/answers-from-tab7c/` + questionnaireId, {
      method: 'GET',
      headers: {
       "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())
      .then(json => {
        this.setState({
          questionContent: json
        })
      })
  }

  componentDidMount() {
    const { questionnaireId, question, modeAdmin} = this.props;
    const { questionContent } = this.state;
    if (modeAdmin !==true) {
      this.getQuestionContent(questionnaireId);
    }

    this.setState({localQuestion : question});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { question } = this.props;
    const { questionContent } = this.state;

    if ((this.state.questionContent !== prevState.questionContent && this.state.questionContent !== null) || (question !== prevProps.question)) {
      const newQuestion = JSON.parse(JSON.stringify(question));
      questionContent.forEach(el => {
        let isExist = newQuestion.answerList.find(item => {
          return item.id === el.id;
        });
        if (isExist === undefined) {
          newQuestion.answerList.push({
            id : el.id,
            text : el.answer
          });
        }
      });

      question.answerList.forEach(el => {
        let content = questionContent.find(item => {
          return item.id === el.id;
        });
        if (content === undefined) {
          newQuestion.answerList.splice(questionContent.indexOf(content), 1);
        }
      });

      const valuesFromAnswers = [];
      const valuesFromAnswersList = [];
      newQuestion.answerList.map((el,key) => {
        const founded = question.answers.filter(elem => elem.answerListId===el.id );
        if (founded[0]){
          valuesFromAnswers.push(founded[0].answer);
          valuesFromAnswersList.push(founded[0].answerListId);
        }
        else{
          valuesFromAnswers.push('');
          valuesFromAnswersList.push(null);
        }
      })
      const valuesOfTexts = Array(valuesFromAnswers.length).fill('');
      const valuesOfErrors = Array(valuesFromAnswers.length).fill(false);
      this.setState({inputValue: valuesFromAnswers, inputValueList: valuesFromAnswersList, errorText: valuesOfTexts, errorStatus: valuesOfErrors});

      this.setState({
        localQuestion : newQuestion
      })
    }
  }


  removeById = (array, id) => {
    const item = this.findAnswer(array, id);
    const index = array.indexOf(item);
    if(index !== -1){
      array.splice(index, 1);
    }
  }

  handleChangeCheckBox = (event, key) => {
    const { setAnswers, persistAnswersOnTimeout, locale } = this.props;
    const {inputValue, inputValueList, errorStatus, errorText, localQuestion } = this.state;
    const id = Number.parseInt(event.target.value);
    const newInputValueList = [...inputValueList];
    const newInputValue = [...inputValue];
    newInputValue[key] = '';
    this.setState({inputValue: newInputValue});
    const newAnswers = JSON.parse(JSON.stringify(localQuestion.answers));
    const newAnswersWithoutDate = newAnswers.map((el) => {
      el.date='';
      return el
    });
    if (event.target.checked === true) {
      newAnswersWithoutDate.push({
        answerListId: id
      });
      newInputValueList[key] = id;
      const newErrorStatus = [...errorStatus];
      const newErrorText = [...errorText];
      newErrorStatus[key] = true;
      newErrorText[key] = translations.FIELD_IS_REQUIRED[locale.localeName];
      this.setState({ errorStatus: newErrorStatus, errorText: newErrorText, inputValueList : newInputValueList})
    } else {
      this.removeById(newAnswersWithoutDate, id);
      newInputValueList[key] = null;
      this.setState({inputValueList : newInputValueList});
      setAnswers(newAnswersWithoutDate);
      persistAnswersOnTimeout();
    }
  }

  handleChangeInput = async (event, key) => {
    const { setAnswers, persistAnswersOnTimeout, locale } = this.props;
    const { localQuestion, inputValue, errorStatus, errorText, inputValueList } = this.state;
    const newValue = Number.parseInt(event.target.value);
    const answerListId = Number.parseInt(event.currentTarget.attributes.answerlistid.value);
    const newAnswers = JSON.parse(JSON.stringify(localQuestion.answers));
    const newAnswersWithoutDate = newAnswers.map((el) => {
      el.date='';
      return el
    });
    const newInputValue = [...inputValue];
    const newErrorStatus = [...errorStatus];
    const newErrorText = [...errorText];
    const newInputValueList = [...inputValueList];
    newInputValue[key] = newValue;

    if(newValue){
      const answer = this.findAnswer(newAnswers, answerListId);
      if (answer){
        answer.answer = newValue;
      }
      else{
        newAnswersWithoutDate.push({
          date: '',
          answer: newValue,
          answerListId: inputValueList[key]
        })
      }
      await setAnswers(newAnswersWithoutDate);
      await persistAnswersOnTimeout();
      newErrorStatus[key] = false;
      newErrorText[key] = '';
      this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
    }
    else{
      this.removeById(newAnswersWithoutDate, answerListId);
      await setAnswers(newAnswersWithoutDate);
      await persistAnswersOnTimeout();
      newInputValueList[key] = null;
      newErrorStatus[key] = false;
      newErrorText[key] = '';
      this.setState({inputValue: newInputValue, errorStatus: newErrorStatus, errorText: newErrorText, inputValueList : newInputValueList})
    }
  }

  isChecked = id => {
    /*
    const { localQuestion } = this.state;
    const answers = localQuestion.answers;
    const answer = this.findAnswer(answers, id);
    return answer !== undefined ? true : false;
    */
    const {inputValueList} = this.state;
    const answer = inputValueList.find(item => {
      if (item === id) {
        return true;
      } else {
        return false;
      }
    })
    if (answer !== undefined) {
      return true
    } else {
      return false;
    }
  }

  findAnswer = (answers, id) => {
    return answers.find(item => {
      return item.answerListId === id ? true : false;
    });
  }

  isHidden = (answers, id) => {
    /*
    const answer = this.findAnswer(answers, id);
    if (answer === undefined) {
      return {visibility: 'hidden'};
    } else {
      return {};
    }
    */
    const {inputValueList} = this.state;
    const answer = inputValueList.find(item => {
      if (item === id) {
        return true;
      } else {
        return false;
      }
    })
    if (!answer) {
      return {visibility: 'hidden'};
    } 
    else {
      return {};
    }
  }

  choiceList = () => {
    const { locale, persistAnswersOnBlur, classes } = this.props;
    const { localQuestion, errorStatus, errorText, inputValueList } = this.state;

    return localQuestion['answerList'].filter(el => el.text == null || el.text == '' ? false : true).map((el, key) => (
      <div key={el.id}>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.isChecked(el.id)}
              onChange={event => this.handleChangeCheckBox(event, key)}
              value={el.id.toString()}/>
          }
          key={el.id}
          label={el.text}
        />
        <FormControl required style={this.isHidden(localQuestion.answers, el.id)}>
          <Input
            name="value"
            value={this.findAnswer(localQuestion.answers, el.id) ? this.findAnswer(localQuestion.answers, el.id).answer ? this.findAnswer(localQuestion.answers, el.id).answer.toString() : '' : ''}
            onChange={event => this.handleChangeInput(event, key)} placeholder={translations.WRITE_ANSWER[locale.localeName]}
            onBlur={persistAnswersOnBlur}
            inputProps={{answerlistid: el.id}}/>
            {errorStatus[key] && <FormHelperText className={classes.helper}>{errorText[key]}</FormHelperText>}
        </FormControl>
      </div>
    ))
  }

  render() {
    const { locale, questionNumber, answersPersistStatus, isAnswerReadOnly, isQuestionReadOnly, modeAdmin, isSideListOpen } = this.props;
    const { localQuestion } = this.state;
      return (
        <QuestionTemplateContainer 
            Layout={QuestionTemplate} 
            modeAdmin={modeAdmin} 
            questionNumber={questionNumber} 
            question={localQuestion} 
            answersPersistStatus={answersPersistStatus} 
            isQuestionReadOnly={isQuestionReadOnly} 
            isAnswerReadOnly={isAnswerReadOnly}
            isSideListOpen={isSideListOpen}
        >
          <MuiThemeProvider theme={theme}>
          <FormControl component="fieldset">
            <FormLabel filled={false} focused={false}>{translations.CHOOSE_ANSWER[locale.localeName]}:</FormLabel>
            <FormGroup>
              {modeAdmin!==true ? this.choiceList() : translations.ANSWERS_TYPE_13_ADMIN[locale.localeName]}
            </FormGroup>
          </FormControl>
          </MuiThemeProvider>
        </QuestionTemplateContainer>
      )
  }
}

export default withStyles(styles)(AnswerType13);
