import React from "react";
import ChoiceQuestion from "./Choice";
import ChoicePlusQuestion from "./ChoicePlus";
import MultiChoiceQuestion from "./MultiChoice";
import MultiChoicePlusQuestion from "./MultiChoicePlus";
import LickertQuestion from "./Lickert";
import BooleanQuestion from "./Boolean";
import DecimalNumberQuestion from "./DecimalNumber";
import FloatNumberQuestion from "./FloatNumber";
import TextQuestion from "./Text";
import DateQuestion from "./Date";
import {ANSWER_TYPE_DIC_ID} from "../../../../util";
import QuestionContainer from "../../../../containers/questionnaire/form/question/Question";
import {withStyles} from "@material-ui/core";
import AnswerType13 from "./AnswerType13";
import clsx from 'clsx';

const styles = {
  popover: {
    pointerEvents: 'none',
    maxWidth: '60vw'
  }
};

class QuestionList extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot){
    const {modeAdmin, questionListId, getQuestionsAdmin, afterQuestionListAdding, afterQuestionListEditing, answersForLastPen, setAnswersForLastPen} = this.props;
    const {answersFromLastPen} = this.state;
    if (modeAdmin === true){
      if (questionListId != prevProps.questionListId && afterQuestionListAdding!==true && afterQuestionListEditing!==true){
          this.setState({areQuestionsForAdmin : false});
          const download = async () => {
            await getQuestionsAdmin(questionListId);
          }
          download().then(() => this.setState({areQuestionsForAdmin : true}));
      }
      
      
      if (afterQuestionListAdding !== prevProps.afterQuestionListAdding){
        this.setState({areQuestionsForAdmin : false});
        const download = async () => {
          await getQuestionsAdmin(questionListId);
        }
        download().then(() => this.setState({areQuestionsForAdmin : true}));
      }
    }
    else {
          if (answersForLastPen){
              if (questionListId === answersForLastPen.id && answersFromLastPen.length===0){
                const answersFromLastPen = answersForLastPen.questionList[answersForLastPen.questionList.length-2].questionList.map(({answers}) => answers.length !== 0 ? answers : []);
                this.setState({answersFromLastPen: answersFromLastPen});
              }
              else if (questionListId !== answersForLastPen.id && answersFromLastPen.length!==0) {
                this.setState({answersFromLastPen: []});
              }
          }
    }
  }

  state = {
    areQuestionsForAdmin : true,
    answersFromLastPen: [],
  }

  getSkipQuestions = (question) => {
    let skipQuestions = 0;
    question.answerList.forEach(el => {
      if (Number.isInteger(el.skipQuestions) && el.skipQuestions > skipQuestions) {
        const answer = question.answers.find(i => {
          return i.answerListId === el.id;
        })
        if (answer !== undefined) {
          skipQuestions = el.skipQuestions;
        }
      }
    })
    return skipQuestions;
  }

  render() {
    const { questionList, prevQuestionList, questionListId, modeAdmin, questionsAdmin, answersForLastPen, isSideListOpen } = this.props;
    const { areQuestionsForAdmin, answersFromLastPen } = this.state;
    let questionGroup = [];
            if (modeAdmin !== true) questionGroup = questionList ? questionList.find((item) => { return item.id === questionListId }) : prevQuestionList.find((item) => { return item.id === questionListId });
            let counter = 0;
            if (questionGroup !== undefined) {
              let theQuestionList;
              if (modeAdmin !== true) theQuestionList = questionList ? questionList.find((item) => { return item.id === questionListId; }).questionList : prevQuestionList.find((item) => { return item.id === questionListId; }).questionList ;
              if (modeAdmin === true) {
                if (areQuestionsForAdmin){
                  theQuestionList = questionsAdmin ? questionsAdmin : null;
                }
                else{
                  theQuestionList=[];
                }
              }
              const disabledIndex = [];
  
              return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  {
                    theQuestionList ? theQuestionList.map((question, index) => {
                      counter = counter + 1;

                      if (modeAdmin !== true){
                          const skipQuestions = this.getSkipQuestions(question);
                          if (skipQuestions > 0) {
                            for (let i = 0; i < skipQuestions; i++) {
                              disabledIndex.push(index + i + 1);
                            }
                          }
                          // for question number 1069
                          if (question.questionId===1069 && (question.answers[0] ? ! parseFloat(question.answers[0].answer) : true)){
                            for (let i = index; i < theQuestionList.length; i++) {
                              disabledIndex.push(i+1);
                            }
                          }
                      }
                      let isQuestionReadOnly = (disabledIndex.indexOf(index) !== -1) ? true : false;
                      let result;
                      let answerLastPen = [{answer: null, answerListId: null}];
                      if (answersForLastPen && answersFromLastPen.length!== 0){
                        if (questionListId === answersForLastPen.id){
                            answerLastPen = answersFromLastPen[index].length!==0 ? answersFromLastPen[index] : answerLastPen;
                        }
                      }
                      switch(question.answerTypeDicId) {
                        case ANSWER_TYPE_DIC_ID.LISTA_JEDNOKROTNEGO_WYBORU_SKALA_LIKERTA :
                          result = <QuestionContainer modeAdmin={modeAdmin} isSideListOpen={isSideListOpen} Layout={LickertQuestion} answerLastPen={answerLastPen[0].answer} questionId={question.id} questionNumber={counter} isQuestionReadOnly={isQuestionReadOnly}/>
                          break;
                        case ANSWER_TYPE_DIC_ID.TRUE_FALSE :
                          result = <QuestionContainer modeAdmin={modeAdmin} isSideListOpen={isSideListOpen} Layout={BooleanQuestion} answerLastPen={answerLastPen[0].answer} questionId={question.id} questionNumber={counter} isQuestionReadOnly={isQuestionReadOnly}/>
                          break;
                        case ANSWER_TYPE_DIC_ID.LISTA_JEDNOKROTNEGO_WYBORU_COMBO_BOX :
                          result = <QuestionContainer modeAdmin={modeAdmin} isSideListOpen={isSideListOpen} Layout={ChoiceQuestion} answerLastPen={answerLastPen[0].answerListId} questionId={question.id} questionNumber={counter} isQuestionReadOnly={isQuestionReadOnly}/>
                          break;
                        case ANSWER_TYPE_DIC_ID.LISTA_JEDNOKROTNEGO_WYBORU_Z_WARTOSCIA :
                          result = <QuestionContainer questions={theQuestionList} modeAdmin={modeAdmin} Layout={ChoicePlusQuestion} answerLastPen={answerLastPen[0]} questionId={question.id} questionNumber={counter} isQuestionReadOnly={isQuestionReadOnly}/>
                          break;
                        case ANSWER_TYPE_DIC_ID.LISTA_WIELOKROTNEGO_WYBORU :
                          result = <QuestionContainer modeAdmin={modeAdmin} isSideListOpen={isSideListOpen} Layout={MultiChoiceQuestion} answerLastPen={answerLastPen} questionId={question.id} questionNumber={counter} isQuestionReadOnly={isQuestionReadOnly}/>
                          break;
                        case ANSWER_TYPE_DIC_ID.LISTA_WIELOKROTNEGO_WYBORU_Z_WARTOSCIA :
                          result = <QuestionContainer questions={theQuestionList} isSideListOpen={isSideListOpen} modeAdmin={modeAdmin} Layout={MultiChoicePlusQuestion} answerLastPen={answerLastPen} questionId={question.id} questionNumber={counter} isQuestionReadOnly={isQuestionReadOnly}/>
                          break;
                        case ANSWER_TYPE_DIC_ID.LICZBA_CALKOWITA :
                          result = <QuestionContainer questions={theQuestionList} isSideListOpen={isSideListOpen} modeAdmin={modeAdmin} Layout={DecimalNumberQuestion} answerLastPen={answerLastPen[0].answer} questionId={question.id} questionNumber={counter} isQuestionReadOnly={isQuestionReadOnly}/>
                          break;
                        case ANSWER_TYPE_DIC_ID.LICZBA_ZMIENNOPRZECINKOWA :
                          result = <QuestionContainer questions={theQuestionList} isSideListOpen={isSideListOpen} modeAdmin={modeAdmin} Layout={FloatNumberQuestion} answerLastPen={answerLastPen[0].answer} questionId={question.id} questionNumber={counter} isQuestionReadOnly={isQuestionReadOnly}/>
                          break;
                        case ANSWER_TYPE_DIC_ID.TEKST_1000_ZNAKOW :
                          result = <QuestionContainer modeAdmin={modeAdmin} isSideListOpen={isSideListOpen} Layout={TextQuestion} answerLastPen={answerLastPen[0].answer} questionId={question.id} questionNumber={counter} isQuestionReadOnly={isQuestionReadOnly}/>
                          break;
                        case ANSWER_TYPE_DIC_ID.DATE :
                          result = <QuestionContainer modeAdmin={modeAdmin} isSideListOpen={isSideListOpen} Layout={DateQuestion} answerLastPen={answerLastPen[0].answer} questionId={question.id} questionNumber={counter} isQuestionReadOnly={isQuestionReadOnly}/>
                          break;
                        case ANSWER_TYPE_DIC_ID.ANSWER_TYPE_13 :
                          result = <QuestionContainer modeAdmin={modeAdmin} isSideListOpen={isSideListOpen} Layout={AnswerType13} questionId={question.id} questionNumber={counter} isQuestionReadOnly={isQuestionReadOnly}/>
                          break;
                        default:
                          counter = counter - 1;
                          return (<div key={question.id}/>)
                      }
                      return (
                        <div key={question.id} style={{flexGrow: 1}}>
                          {result}
                        </div>
                      )
                    }) : null
                  }
                </div>
              )
            }
            return <div/>



        }     
}

export default withStyles(styles)(QuestionList);
