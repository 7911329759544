export const SET_QUESTIONNAIRE_ID = 'SET_QUESTIONNAIRE_ID';
export const SET_QUESTIONNAIRE_DIC_ID = 'SET_QUESTIONNAIRE_DIC_ID';
export const SET_FORM_ID = 'SET_FORM_ID';
export const SET_SUBFORM_ID = 'SET_SUBFORM_ID';
export const RESET_SUBFORM_ID = 'RESET_SUBFORM_ID';
export const SET_QUESTION_LIST_ID = 'SET_QUESTION_LIST_ID';
export const SET_QUESTIONNAIRE_READONLY = 'SET_QUESTIONNAIRE_READONLY';
export const RESET_QUESTION_LIST_ID = 'RESET_QUESTION_LIST_ID';
export const SET_IS_QUESTION_GROUP_INITIAL = 'SET_IS_QUESTION_GROUP_INITIAL';
export const QUESTIONNAIRE_FORM_RESET = 'QUESTIONNAIRE_FORM_RESET';
export const QUESTION_1069_HAS_VALUE = 'QUESTION_1069_HAS_VALUE';
export const AFTER_QUESTION_LIST_ADDING = 'AFTER_QUESTION_LIST_ADDING';
export const AFTER_QUESTION_LIST_EDITING = 'AFTER_QUESTION_LIST_EDITING';
export const AFTER_SUBFORM_ADDING = 'AFTER_SUBFORM_ADDING';
export const AFTER_SUBFORM_EDITING = 'AFTER_SUBFORM_EDITING';
export const SET_CHOOSEN_FARM_CODE = 'SET_CHOOSEN_FARM_CODE';
export const SET_CHOOSEN_FARM_TYPE = 'SET_CHOOSEN_FARM_TYPE';

export const initialState = {
  questionnaireId: '',
  questionnaireDicId: '',
  formId: '',
  subFormId: '',
  questionListId: '',
  prevSubFormId: '',
  isQuestionGroupInitial: false,
  isAnswerReadOnly: '',
  question1069hasValue: false,
  afterQuestionListAdding: false,
  afterSubformAdding: false,
  afterSubformEditing: false,
  afterQuestionListEditing: false,
  choosenFarmCode: '',
  choosenFarmType: ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_QUESTIONNAIRE_DIC_ID: {
      return {
        ...state,
        questionnaireDicId : action.id,
      };
    }
    case SET_QUESTIONNAIRE_ID: {
      return {
        ...state,
        questionnaireId : action.id,
      };
    }
    case SET_FORM_ID: {
      return {
        ...state,
        formId : action.id,
      };
    }
    case SET_SUBFORM_ID: {
      let prevSubFormId;
      if (Number.isInteger(state.subFormId) && Number.isInteger(action.id) && state.subFormId !== action.id) {
        prevSubFormId = state.subFormId;
      } else {
        prevSubFormId = state.prevSubFormId;
      }
      if (!state.prevSubFormId) {
        prevSubFormId = action.id
      }
      return {
        ...state,
        prevSubFormId : prevSubFormId,
        subFormId : action.id
      };
    }
    case RESET_SUBFORM_ID: {
      return {
        ...state,
        subFormId : '',
      };
    }
    case SET_QUESTION_LIST_ID: {
      return {
        ...state,
        questionListId : action.id
      };
    }
    case SET_QUESTIONNAIRE_READONLY: {
      return {
        ...state,
        isAnswerReadOnly : action.isAnswerReadOnly,
      };
    }
    case SET_IS_QUESTION_GROUP_INITIAL: {
      return {
        ...state,
        isQuestionGroupInitial : action.isQuestionGroupInitial,
      };
    }
    case QUESTION_1069_HAS_VALUE: {
      return {
        ...state,
        question1069hasValue : action.question1069hasValue,
      };
    }
    case RESET_QUESTION_LIST_ID: {
      return {
        ...state,
        questionListId : ''
      };
    }
    case AFTER_QUESTION_LIST_ADDING: {
      return {
        ...state,
        afterQuestionListAdding : action.afterQuestionListAdding,
      };
    }
    case AFTER_QUESTION_LIST_EDITING: {
      return {
        ...state,
        afterQuestionListEditing : action.afterQuestionListEditing,
      };
    }
    case AFTER_SUBFORM_ADDING: {
      return {
        ...state,
        afterSubformAdding : action.afterSubformAdding,
      };
    }
    case AFTER_SUBFORM_EDITING: {
      return {
        ...state,
        afterSubformEditing : action.afterSubformEditing,
      };
    }
    case QUESTIONNAIRE_FORM_RESET: {
      return initialState;
    }
    case SET_CHOOSEN_FARM_CODE: {
      return {
        ...state,
        choosenFarmCode : action.code,
      };
    }
    case SET_CHOOSEN_FARM_TYPE: {
      return {
        ...state,
        choosenFarmType : action.code,
      };
    }
    default:
      return state;
  }
}
