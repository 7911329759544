import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {translations} from '../../../dictionary/translations';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {ERROR_MODE, ANSWER_TYPE_DIC_ID} from "../../../util";
import ErrorContainer from "../../../containers/Error";
import Loading from "../../Loading";
import Error from "../../Error";
import {style} from "../../../styles/style.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = {
  dialogTitle: {
    width: 500,
    '@media only screen and (max-width: 768px)' : {
      width: 300,
    },
  },
  form: {
    width: '90%',
    marginTop: 10,
    position: 'relative',
    left: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  section: {
    width: '100%',
    marginTop: 10,
    display: 'flex',
    alignItems: 'center'
  },
  label: {
      color: `${style.violet} !important`,
  },
  input : {
      width: '100%',
      color: `${style.violet}`,
      '&:before' : {
        borderBottom: `2px solid ${style.rose_light} !important`,
      },
      '&:after' : {
        borderBottom: `2px solid ${style.rose_dark}`,
      },
  },
  backButton : {
    transition: 'background-color ease 0.5s',
    backgroundColor: style.gray,
    marginRight: '10%',
    color: 'white',
    outline: 'none',
    '&:hover' : {
      backgroundColor: style.gray_hover,
    },
  },
  submitButton : {
      transition: 'background-color ease 0.5s',
      outline: 'none',
      color: 'white',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
  select : {
    '&:before' : {
      borderBottom: `2px solid ${style.rose_light} !important`,
    },

    '&:after' : {
      borderBottom: `2px solid ${style.rose_dark}`,
    }
  },
  helper : {
    color: 'red',
  }
};


export class AnswerTemplate extends React.Component {

  componentDidMount() {
    const {localeList, mode, questionId, getAnswer} = this.props;
    this.setState({trans: new Array(localeList.items.length).fill(-1)})
    if (mode === 'edit') getAnswer(questionId);
  }

  state = {
    firstClickingError: true,
    textId: null,
    trans: [],
    transRequiredError: false,
    isMaxNumberError: false,
    isMinNumberError: false,
    isSkipQuestionsNumberError: false,
    min: '',
    max: '',
    skipQuestions: '',
    isActive: '',
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {items, selectedAnswer} = this.props;
    const {trans} = this.state;
    if (items !== prevProps.items){
      const item = items.filter(el => {return el.id === selectedAnswer})[0]
      let arr = [...trans];
      const  tr = item.text.translations.map((el, key) => {
        arr[key] = el.text;
      })
      this.setState({
          textId: item.text.id,
          trans: arr,
          min: item.min ? item.min : '',
          max: item.max ? item.max : '',
          skipQuestions: item.skipQuestions ? item.skipQuestions : '',
          isActive: item.isActive,
      })
      
    }

  }

  answerPrepare = (event) => {
    const { questionId, addAnswer, mode, editAnswer, selectedAnswer} = this.props;
    const { trans, min, max, textId, skipQuestions, isActive } = this.state;
    if (event) event.preventDefault() ;
    if (!trans.every(el => {return el !== -1})) {
      this.setState({ transRequiredError: true });
    }
    this.setState({ firstClickingError: false });
    if((!this.state.transRequiredError)&&
       (!this.state.firstClickingError)&&
       (!this.state.isMaxNumberError)&&
       (!this.state.isMinNumberError) ){
      const  tr = trans.map((item, key) => {
        return {
          localeDicId: key+1,
          text: item
         }
      });
     const body = {
       questionId : questionId,
       id : selectedAnswer,
       text: {
         id: textId,
         translations: tr,
       },
       min: min,
       max: max,
       skipQuestions : skipQuestions,
       maxAnswerQuestionId: '',
       isActive: mode==='add' ? true : isActive,
     };
     mode==='add' ? addAnswer(JSON.stringify(body)) : editAnswer(selectedAnswer, JSON.stringify(body));
   }
  }

  answerSuccess = () => {
    const { close } = this.props;
    close();
  }

  handleChangeName = event => {
      const {trans} = this.state;
      let arrTrans = [...trans];
      arrTrans[event.target.name.substr(6) - 1] = event.target.value;
      this.setState({trans: arrTrans}, () => {
        if (arrTrans.every(el => {return el !== -1})) {
          this.setState({ transRequiredError: false });
        }
        if (arrTrans.every(el => {return el !== -1})) this.setState({firstClickingError: false});
      });
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      const {trans, min, max, skipQuestions} = this.state;
      if (trans.every(el => {return el !== -1})) {
        this.setState({ transRequiredError: false });
      }
      const regex = new RegExp("^(?:[1-9]\\d*|0)?$");
      if (regex.test(min)){
        this.setState({ isMinNumberError: false });
      }
      else{
        this.setState({ isMinNumberError: true });
      }
      if (regex.test(max)){
        this.setState({ isMaxNumberError: false });
      }
      else{
        this.setState({ isMaxNumberError: true });
      }
      if (regex.test(skipQuestions)){
        this.setState({ isSkipQuestionsNumberError: false });
      }
      else{
        this.setState({ isSkipQuestionsNumberError: true });
      }
      if (trans.every(el => {return el !== -1}) && regex.test(min) && regex.test(max) && regex.test(skipQuestions) ) this.setState({firstClickingError: false});
    });
  };


  render() {
    const { classes, mode, locale, close, addStatus, localeList, getStatus, editStatus, isMultiple, withInput } = this.props;
    const {trans, transRequiredError, min, max, skipQuestions, isMaxNumberError, isMinNumberError, isSkipQuestionsNumberError} = this.state;
    if (localeList.error === true || getStatus.error === true) {
      return (
        <ErrorContainer Layout={Error}
                content={translations.ERROR_WHILE_DOWNLOADING_DATA}
                mode={ERROR_MODE.GO_BACK}/>
      )
    }

    else if (addStatus.error === true || editStatus.error === true) {
      return (
        <ErrorContainer Layout={Error}
                content={translations.ERROR_WHILE_UPLOADING_DATA}
                mode={ERROR_MODE.GO_BACK}/>
      )
    }

    else if (addStatus.loading===true || localeList.loading===true || getStatus.loading===true || editStatus.loading===true) {
      return (
        //<Loading/>
        null
      )
    }

    else if (addStatus.success === true || editStatus.success === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={mode==='add' ? translations.ANSWER_ADDED_SUCCESSFULLY : translations.ANSWER_EDITED_SUCCESSFULLY}
                        callback={this.answerSuccess}/>
      )
    }
    
    return (
      <Dialog open={true} scroll={'paper'} >
        <DialogTitle id="scroll-dialog-title" className={classes.dialogTitle} >{mode === 'add' ? translations.ADD_ANSWER[locale.localeName] : translations.EDIT_ANSWER[locale.localeName]}</DialogTitle>
        <DialogContent dividers={true} >
              <form className={classes.form}>
                {localeList.items.map((item) => (
                    <section key={item.localeName} className={classes.section} >
                          <Avatar src={"./images/flags/" + item.localeName + ".jpg"} style={{width: 30, height: 30, marginRight: 20,}} />
                          <FormControl margin="normal" required fullWidth style={{marginTop: 8}}>
                            <InputLabel className={classes.label} htmlFor={`answer${item.id}`}>{translations.ANSWER[locale.localeName]}</InputLabel>
                            <Input className={classes.input} value={trans[item.id-1]!==-1 ? trans[item.id-1] : ''} id={`answer${item.id}`} name={`answer${item.id}`}  onChange={this.handleChangeName} />
                            {transRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
                          </FormControl>
                    </section>

                ))}
                <FormControl margin="normal" fullWidth style={{marginTop: 8, display: withInput ? 'block' : 'none'}}>
                            <InputLabel className={classes.label} htmlFor={`min`}>Min</InputLabel>
                            <Input className={classes.input} value={min} id={`min`} name={`min`}  onChange={this.handleChange} />
                            {isMinNumberError && <FormHelperText className={classes.helper}>{translations.VALUE_SHOULD_BE_NUMBER[locale.localeName]}</FormHelperText>}
                </FormControl>
                <FormControl margin="normal" fullWidth style={{marginTop: 8, display: withInput ? 'block' : 'none'}}>
                            <InputLabel className={classes.label} htmlFor={`max`}>Max</InputLabel>
                            <Input className={classes.input} value={max} id={`max`} name={`max`}  onChange={this.handleChange} />
                            {isMaxNumberError && <FormHelperText className={classes.helper}>{translations.VALUE_SHOULD_BE_NUMBER[locale.localeName]}</FormHelperText>}
                </FormControl>
                <FormControl margin="normal" fullWidth style={{marginTop: 8, display: isMultiple ? 'none' : 'block'}}>
                            <InputLabel className={classes.label} htmlFor={`skipQuestions`}>{translations.NUMBER_OF_SKIPPED_QUESTIONS[locale.localeName]}</InputLabel>
                            <Input className={classes.input} value={skipQuestions} id={`skipQuestions`} name={`skipQuestions`}  onChange={this.handleChange} />
                            {isSkipQuestionsNumberError && <FormHelperText className={classes.helper}>{translations.VALUE_SHOULD_BE_NUMBER[locale.localeName]}</FormHelperText>}
                </FormControl>
              </form>
          </DialogContent>
          <DialogActions>
                  <Button
                    fullWidth
                    size="large"
                    className={classes.backButton}
                    onClick={() => {close()}}
                  >
                      {translations.GO_BACK[locale.localeName]}
                  </Button>
                  <Button
                    fullWidth
                    size="large"
                    className={classes.submitButton}
                    onClick={(event) => {this.answerPrepare(event)}}
                  >
                    {translations.SUBMIT[locale.localeName]}
                  </Button>
          </DialogActions>
      </Dialog>
    )

  }
}

export default withStyles(styles)(AnswerTemplate);
