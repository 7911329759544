import React from "react";
import {Checkbox, DialogActions, withStyles} from "@material-ui/core";
import {style} from "../../../styles/style.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import {translations} from "../../../dictionary/translations";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import Error from "../../Error";
import ErrorContainer from "../../../containers/Error";
import Button from "@material-ui/core/Button";

const styles = {
  button : {
    transition: 'background-color ease 0.5s',
    color: 'white',
    outline: 'none',
    backgroundColor: style.rose_dark,
    '&:hover' : {
      backgroundColor: style.rose_dark_hover,
    },
  }
};

class OptionalQuestionList extends React.Component {

  componentDidMount() {
    const { locale, getOptionalQuestionList, subFormId } = this.props;
    getOptionalQuestionList(locale.id, subFormId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { locale, setIsOpen, subFormId, setNextSubFormId, setNextQuestionListId, getSubFormList, questionListId, formId, optionalQuestionList, setQuestionListId } = this.props;
    if (optionalQuestionList.getStatus.success === true && optionalQuestionList.data !== prevProps.optionalQuestionList.data) {
      this.initQuestionGroupIdList();
    }
    if (optionalQuestionList.saveStatus.success === true && prevProps.optionalQuestionList.saveStatus.loading === true) {
      const theQuestionList = optionalQuestionList.data.find(el => {
        if (el.id === questionListId && el.added === true) {
          return true;
        }
      });
      if (theQuestionList) {
        setNextQuestionListId(questionListId);
      } else {
        setNextQuestionListId(null);
        setQuestionListId(null);
      }

      setNextSubFormId(subFormId);
      setIsOpen(false, false);
    }

  }

  initQuestionGroupIdList = () => {
    const { optionalQuestionList } = this.props;
    const questionGroupIdList = [];
    optionalQuestionList.data.forEach(el => {
      if (el.added === true) {
        questionGroupIdList.push(el.id);
      }
    });
    this.setState({
      beforeQuestionGroupIdList : questionGroupIdList,
      afterQuestionGroupIdList : questionGroupIdList,
      optionalQuestionListLocal : JSON.parse(JSON.stringify(optionalQuestionList.data))
    });
  }

  handleSave = () => {
    const { subFormId, optionalQuestionList, saveQuestionList } = this.props;
    const { beforeQuestionGroupIdList, afterQuestionGroupIdList } = this.state;
    let config = {
      added: [],
      deleted: []
    };
    optionalQuestionList.data.forEach(el => {
      if (beforeQuestionGroupIdList.indexOf(el.id) === -1
      && afterQuestionGroupIdList.indexOf(el.id) !== -1) {
        config.added.push({
          filledSubformId: subFormId,
          questionListDicId: el.id
        })
      }
      if (beforeQuestionGroupIdList.indexOf(el.id) !== -1
        && afterQuestionGroupIdList.indexOf(el.id) === -1) {
        config.deleted.push({
          id: el.filledQuestionListId
        })
      }
    })
    saveQuestionList(config);
  }

  state = {
    beforeQuestionGroupIdList: [],
    afterQuestionGroupIdList: [],
    optionalQuestionListLocal: ''
  }

  handleChange = (event, checked) => {
    const { afterQuestionGroupIdList, optionalQuestionListLocal } = this.state;
    let newOptionalQuestionListLocal = JSON.parse(JSON.stringify(optionalQuestionListLocal));
    const id = Number.parseInt(event.currentTarget.attributes.id.value);
    const newAfterQuestionGroupIdList = afterQuestionGroupIdList.slice(0);
    if (checked === true) {
      newAfterQuestionGroupIdList.push(id);
      newOptionalQuestionListLocal.find(el => {
        if (el.id === id) {
          el.added = true;
        }
      })
    } else {
      const index = afterQuestionGroupIdList.indexOf(id);
      if(index !== -1){
        newAfterQuestionGroupIdList.splice(index, 1);
      }
      newOptionalQuestionListLocal.find(el => {
        if (el.id === id) {
          el.added = false;
        }
      })
    }
    this.setState({
      optionalQuestionListLocal: newOptionalQuestionListLocal,
      afterQuestionGroupIdList: newAfterQuestionGroupIdList
    })
  }

  render() {
    const { classes, locale, setIsOpen, optionalQuestionList, resetGetQuestionList, resetSaveQuestionList, setPreviousSubForm } = this.props;
    const { optionalQuestionListLocal } = this.state;
    if (optionalQuestionList.getStatus.error === true) {
      return <ErrorContainer Layout={Error} content={translations.ERROR_WHILE_DOWNLOADING_DATA} callback={() => {resetGetQuestionList()}}/>
    }
    else if (optionalQuestionList.saveStatus.error === true) {
      return <ErrorContainer Layout={Error} content={translations.ERROR_WHILE_UPLOADING_DATA} callback={() => {resetSaveQuestionList()}}/>
    }
    else if (optionalQuestionList.getStatus.loading === true || optionalQuestionList.saveStatus.loading === true || optionalQuestionListLocal === '') {
      return (
        //<Loading/>
        <div/>
      )
    }
    else if (optionalQuestionList.getStatus.success === true && optionalQuestionList.data.length === 0) {
      return (
        <ErrorContainer Layout={Error}
                        content={translations.QUESTION_GROUP_LIST_IS_EMPTY}
                        callback={() => { setPreviousSubForm(); }}/>
      )
    }

    else if (optionalQuestionList.getStatus.success === true && optionalQuestionList.data.length !== 0) {
      return (
        <Dialog open={true}>
          <DialogTitle>
            {optionalQuestionListLocal[0].groupText + ':'}
          </DialogTitle>
          <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            {optionalQuestionListLocal.map(item => (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={this.handleChange}
                    checked={item.added === true ? true : false}
                    inputProps={{
                      id: item.id
                    }}
                  />
                }
                key={item.id}
                label={item.text}
              />
            ))}
          </DialogContent>
          <DialogActions style={{display: 'flex', justifyContent: 'space-between', margin: 10}}>
            <Button size="large" className={classes.button} onClick={() => setIsOpen(false, true)}>
              {translations.CANCEL[locale.localeName]}
            </Button>
            <Button size="large" className={classes.button} onClick={this.handleSave}>
              {translations.OK[locale.localeName]}
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
    return <div/>;

  }
}

export default withStyles(styles)(OptionalQuestionList);
