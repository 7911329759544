import React from 'react';
import {connect} from "react-redux";
import actions from "../../../actions";
import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SAVE_STATUS, SUCCESS_STATUS} from "../../../util";
import {URL} from "../../../config";

export class SubformTemplateContainer extends React.Component {

  state = {
    swapStatus : INITIAL_STATUS,
    addStatus : INITIAL_STATUS,
    editStatus : INITIAL_STATUS,
    getStatus : INITIAL_STATUS,
    items: null,
  }

  OPERATION = {
    SWAP_SUBFORM : 'swapSubform',
    ADD_SUBFORMN : 'addSubform',
    EDIT_SUBFORM: 'editSubform',
    GET_SUBFORM: 'getSubform',
  }

  setBegin = (operation) => {
    switch (operation) {
      case this.OPERATION.SWAP_SUBFORM:
        this.setState({
          swapStatus : BEGIN_STATUS,
        })
        break;
      case this.OPERATION.ADD_SUBFORM:
        this.setState({
          addStatus : BEGIN_STATUS
        })
        break;
      case this.OPERATION.EDIT_SUBFORM:
        this.setState({
          editStatus : BEGIN_STATUS
        })
        break;
      case this.OPERATION.GET_SUBFORM:
        this.setState({
          getStatus : BEGIN_STATUS
        })
        break;
      default: return null;
    }
  };

  setSuccess = (operation, data) => {
    switch (operation) {
      case this.OPERATION.SWAP_SUBFORM:
        this.setState({
          swapStatus : SUCCESS_STATUS,
        })
        break;
      case this.OPERATION.ADD_SUBFORM:
        this.setState({
          addStatus : SUCCESS_STATUS
        })
        break;
      case this.OPERATION.EDIT_SUBFORM:
        this.setState({
          editStatus : SUCCESS_STATUS,
        })
        break;
      case this.OPERATION.GET_SUBFORM:
        this.setState({
          getStatus : SUCCESS_STATUS,
          items: data
        })
        break;
      default: return null;
    }
  };

  setFailure = (errorType, operation) => {
    switch (operation) {
      case this.OPERATION.SWAP_SUBFORM:
        this.setState({
          swapStatus : FAILURE_STATUS,
        })
        break;
      case this.OPERATION.ADD_SUBFORMN:
        this.setState({
          addStatus : FAILURE_STATUS,
        })
        break;
      case this.OPERATION.EDIT_SUBFORM:
        this.setState({
          editStatus : FAILURE_STATUS,
        })
        break;
      case this.OPERATION.GET_SUBFORM:
        this.setState({
          getStatus : FAILURE_STATUS,
        })
        break;
      default: return null;
    }
  };

  
  swapSubform = () => {
    this.setBegin(this.OPERATION.SWAP_SUBFORM);
    this.swapSubformStart()
      .then(() => {
        this.setSuccess(this.OPERATION.SWAP_SUBFORM);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.SWAP_SUBFORM);
      });
  }

  swapSubformStart = () => {
    const { questionId, locale } = this.props;
    return new Promise((resolve, reject) => {
      fetch(URL + `/farm/farmTypeList`
      )
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve()
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  addSubform = (body) => {
    this.setBegin(this.OPERATION.ADD_SUBFORM);
    this.addSubformStart(body)
      .then(() => {
        this.setSuccess(this.OPERATION.ADD_SUBFORM);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.ADD_SUBFORM);
      });
  }

  addSubformStart = (body) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/questinnaire/subform/dic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        },
        body: body
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve()
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  getSubform = (id) => {
    this.setBegin(this.OPERATION.GET_SUBFORM);
    this.getSubformStart(id)
      .then((data) => {
        this.setSuccess(this.OPERATION.GET_SUBFORM, data);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.GET_SUBFORM);
      });
  }

  getSubformStart = (id) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/questinnaire/subform/dic/${id}`, {
        method: 'GET',
        headers: {
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        },
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve(json.data)
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  editSubform = (body) => {
    this.setBegin(this.OPERATION.EDIT_SUBFORM);
    this.editSubformStart(body)
      .then(() => {
        this.setSuccess(this.OPERATION.EDIT_SUBFORM);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.EDIT_SUBFORM);
      });
  }

  editSubformStart = (body) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/questinnaire/subform/dic`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        },
        body: body
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve()
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  render() {
    const { 
      Layout,
      mode,
      locale,
      close,
      subformId,
      getFarmTypeList,
      farmTypeList,
      formId,
      localeList
    } = this.props;

    const {
      addStatus,
      getStatus,
      items,
      editStatus
    } = this.state;

    return (
      <Layout
          mode={mode}
          locale={locale}
          close={close}
          subformId={subformId}
          farmTypeList={farmTypeList}
          getFarmTypeList={getFarmTypeList}
          formId={formId}
          addStatus={addStatus}
          addSubform={this.addSubform}
          localeList={localeList}
          getSubform={this.getSubform}
          getStatus={getStatus}
          items={items}
          editSubform={this.editSubform}
          editStatus={editStatus}
      />
    )
  }
}

const mapStateToProps = state => ({
  farmTypeList: {
    loading: state.dictionary.farmTypeList.loading,
    error: state.dictionary.farmTypeList.error,
    success: state.dictionary.farmTypeList.success,
    items: state.dictionary.farmTypeList.items,
  },
  localeList: {
    loading: state.dictionary.localeList.loading,
    error: state.dictionary.localeList.error,
    success: state.dictionary.localeList.success,
    items: state.dictionary.localeList.items,
  },

});

const mapDispatchToProps = {
  getFarmTypeList: actions.dictionary.farmTypeList.getFarmTypeList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SubformTemplateContainer);
