export const trimDate = (date) => {
  if (date && date.length > 0) {
    return date.substr(0, date.search(' '))
  } else {
    return '';
  }
}

export const ERROR_MODE = {
  REFRESH: 'REFRESH',
  GO_BACK: 'GO_BACK',
  GO_HOME_PAGE: 'GO_HOME_PAGE'
}

export const INITIAL_STATUS = {
  loading: false,
  error: false,
  success: false
}

export const FAILURE_STATUS = {
  loading: false,
  error: true,
  success: false
}

export const BEGIN_STATUS = {
  loading: true,
  error: false,
  success: false
}

export const SUCCESS_STATUS = {
  loading: false,
  error: false,
  success: true
}

export const ANSWER_TYPE_DIC_ID = {
  LICZBA_CALKOWITA : 1,
  LICZBA_ZMIENNOPRZECINKOWA : 2,
  TEKST_1000_ZNAKOW : 3,
  LISTA_JEDNOKROTNEGO_WYBORU_SKALA_LIKERTA : 4,
  LISTA_JEDNOKROTNEGO_WYBORU_COMBO_BOX : 5,
  LISTA_WIELOKROTNEGO_WYBORU : 6,
  LISTA_WIELOKROTNEGO_WYBORU_Z_WARTOSCIA : 7,
  LISTA_JEDNOKROTNEGO_WYBORU_Z_WARTOSCIA : 8,
  TRUE_FALSE : 9,
  DATE : 10,
  ANSWER_TYPE_13 : 13
}

export const USER_ROLE_DIC_ID = {
  ADMINISTRATOR : 1,
  EKSPERT_NAUKOWY : 2,
  EKSPERT_TERENOWY : 3,
  HODOWCA : 4
}

export const SAVE_STATUS = {
  LOADING : 'loading',
  ERROR : 'error',
  SUCCESS : 'success',
  EMPTY : 'empty',
}

export const SUPPORTED_LOCALE = ['en', 'pl', 'de', 'fi', 'it', 'nl'];
