import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';

// Routes
import {translations} from '../dictionary/translations';
import {ERROR_MODE} from "../util";
import UserLoginTemplateContainer from "../containers/user/login/Template";
import UserLoginContainer from "../containers/user/login/Login";
import UserRegistrationContainer from "../containers/user/Registration";
import UserChangePasswordContainer from "../containers/user/ChangePassword";
import ForgotPasswordContainer from "../containers/user/ForgotPassword";
import UserRegistration from "../components/user/Registration";
import ForgotPassword from "../components/user/ForgotPassword";
import UserCheckContainer from "../containers/user/Check";
import UserTemplateContainer from "../containers/user/Template";
import MainNavigationContainer from "../containers/mainNavigation/MainNavigation";
import UserTemplate from "../components/user/Template";
import UserCheck from "../components/user/Check";
import MainNavigation from "../components/mainNavigation/MainNavigation";
import UserFormTemplate from "../components/user/FormTemplate";
import FarmAdd from "../components/farm/Add";
import ChangePassword from "../components/user/ChangePassword";
import FarmAddContainer from "../containers/farm/Add";
import QuestionnaireAdd from "../components/questionnaire/Add";
import QuestionnaireAddContainer from "../containers/questionnaire/Add";
import NothingTemplate from "../components/NothingTemplate";
import QuestionnaireForm from "../components/questionnaire/form/Form";
import FormQuestionnaireContainer from "../containers/questionnaire/form/Form";
import ErrorContainer from "../containers/Error";
import Error from "../components/Error";
import {DictionaryContext} from "../components/Dictionary";
import UserLogin from "../components/user/login/Login";
import UserLoginTemplate from "../components/user/login/Template";
import FarmEdit from "../components/farm/Edit";
import FarmEditContainer from "../containers/farm/Edit";
import ResetPasswordContainer from "../containers/user/ResetPassword";
import ResetPassword from "../components/user/ResetPassword";
import ActivationContainer from "../containers/user/Activation";
import Activation from "../components/user/Activation";
import ThemeContainer from "../containers/reports/Theme";
import Theme from "../components/reports/Theme";

export class Index extends React.Component {

  static contextType = DictionaryContext;

  state = {
    emailValidator : /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ,
    passwordValidator : /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/ ,
  }



  render() {
    const {locale, dictionary} = this.context;
    const { emailValidator, passwordValidator } = this.state;

    return (
      <Switch>
        <Route
          exact
          path="/"
          render={props => (
            <UserLoginTemplateContainer Layout={UserLoginTemplate} pageTitle={translations.LOGIN[locale.localeName]} dictionary={dictionary} >
              <UserLoginContainer Layout={UserLogin} dictionary={dictionary} {...props} />
            </UserLoginTemplateContainer>
          )}
        />
        <Route
          exact
          path="/registration"
          render={props => (
            <UserLoginTemplateContainer Layout={UserLoginTemplate} pageTitle={translations.REGISTRATION[locale.localeName]} dictionary={dictionary} >
              <UserRegistrationContainer emailValidator={emailValidator} passwordValidator={passwordValidator} Layout={UserRegistration} dictionary={dictionary} {...props} />
            </UserLoginTemplateContainer>
          )}
        />
        <Route
          exact
          path="/add-farm"
          render={props => (
            <UserCheckContainer Layout={UserCheck}>
              <UserTemplateContainer Layout={UserFormTemplate} pageTitle={translations.ADD_FARM[locale.localeName]} {...props} >
                <FarmAddContainer emailValidator={emailValidator} passwordValidator={passwordValidator}  Layout={FarmAdd} dictionary={dictionary} {...props} />
              </UserTemplateContainer>
            </UserCheckContainer>
          )}
        />
        <Route
          exact
          path="/edit-farm"
          render={props => (
            <UserCheckContainer Layout={UserCheck}>
              <UserTemplateContainer Layout={UserFormTemplate} pageTitle={translations.EDIT_FARM[locale.localeName]} {...props} >
                <FarmEditContainer Layout={FarmEdit} {...props} />
              </UserTemplateContainer>
            </UserCheckContainer>
          )}
        />
        <Route
          exact
          path="/add-questionnaire"
          render={props => (
            <UserCheckContainer Layout={UserCheck}>
              <UserTemplateContainer Layout={UserFormTemplate} pageTitle={translations.ADD_QUESTIONNAIRE[locale.localeName]} {...props} >
                <QuestionnaireAddContainer Layout={QuestionnaireAdd} modeAdmin={false} dictionary={dictionary} {...props} />
              </UserTemplateContainer>
            </UserCheckContainer>
          )}
        />
        <Route
          exact
          path="/edit-questionnaire"
          render={props => (
            <UserCheckContainer Layout={UserCheck}>
              <UserTemplateContainer Layout={UserFormTemplate} pageTitle={translations.EDIT_QUESTIONNAIRE[locale.localeName]} {...props} >
                <QuestionnaireAddContainer Layout={QuestionnaireAdd} modeAdmin={true} dictionary={dictionary} {...props} />
              </UserTemplateContainer>
            </UserCheckContainer>
          )}
        />
        <Route
          exact
          path="/edit-questionnaire-structure"
          render={props => (
            <UserCheckContainer Layout={UserCheck}>
              <NothingTemplate>
                <FormQuestionnaireContainer Layout={QuestionnaireForm} modeAdmin={true} dictionary={dictionary} {...props} />
              </NothingTemplate>
            </UserCheckContainer>
          )}
        />
        <Route
          exact
          path="/fill-questionnaire"
          render={props => (
            <UserCheckContainer Layout={UserCheck}>
              <NothingTemplate>
                <FormQuestionnaireContainer Layout={QuestionnaireForm} dictionary={dictionary} {...props} />
              </NothingTemplate>
            </UserCheckContainer>
          )}
        />
        <Route
          exact
          path="/change-password"
          render={props => (
            <UserCheckContainer Layout={UserCheck}>
              <UserTemplateContainer Layout={UserFormTemplate} pageTitle={translations.CHANGE_PASSWORD[locale.localeName]} {...props} >
                <UserChangePasswordContainer passwordValidator={passwordValidator}  Layout={ChangePassword} {...props} />
              </UserTemplateContainer>
            </UserCheckContainer>
          )}
        />
        <Route
          exact
          path="/administration"
          render={props => (
            <UserCheckContainer Layout={UserCheck} >
              <UserTemplateContainer Layout={UserTemplate} pageTitle={translations.USER_LIST[locale.localeName]} {...props} >
                  <MainNavigationContainer Layout={MainNavigation} dictionary={dictionary} modeAdmin={true} {...props} />
              </UserTemplateContainer>
            </UserCheckContainer>
          )}
        />
        <Route
          exact
          path="/main-navigation"
          render={props => (
            <UserCheckContainer Layout={UserCheck}>
              <UserTemplateContainer Layout={UserTemplate} pageTitle={translations.FARM_LIST[locale.localeName]} {...props} >
                <MainNavigationContainer Layout={MainNavigation} modeAdmin={false} {...props} />
              </UserTemplateContainer>
            </UserCheckContainer>
          )}
        />
        <Route
            exact
            path="/forgotPassword"
            render={props => (
              <UserLoginTemplateContainer Layout={UserLoginTemplate} pageTitle={translations.FORGOT_PASSWORD[locale.localeName]} dictionary={dictionary} >
                <ForgotPasswordContainer emailValidator={emailValidator} Layout={ForgotPassword} dictionary={dictionary} {...props} />
              </UserLoginTemplateContainer>
            )}
        />
        <Route
            path="/user-reset-password/:token/:localeId"
            render={props => (
              <UserLoginTemplateContainer Layout={UserLoginTemplate} >
                <ResetPasswordContainer passwordValidator={passwordValidator} Layout={ResetPassword} {...props} />
              </UserLoginTemplateContainer>
            )}
        />
        <Route
            path="/user-account-confirmation/:token/:localeId/:roleId"
            render={props => (
              <UserLoginTemplateContainer Layout={UserLoginTemplate} >
                <ActivationContainer Layout={Activation} {...props} />
              </UserLoginTemplateContainer>
            )}
        />
         <Route
          exact
          path="/reports/theme"
          render={props => (    
                <ThemeContainer Layout={Theme} {...props} />     
          )}
        />
        <Route
          render={() => (
            <NothingTemplate>
              <ErrorContainer Layout={Error} mode = {ERROR_MODE.GO_BACK} content={translations.ERROR_404}/>
            </NothingTemplate>
          )}
        />
      </Switch>
    )

  }
};

export default withRouter(Index);
