import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {translations} from '../../dictionary/translations';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ErrorContainer from "../../containers/Error";
import {withRouter} from "react-router-dom";
import Loading from "../Loading";
import Error from "../Error";
import LogoImage from '../../img/logo.jpg'
import {style} from "../../styles/style.js";
import Image from 'material-ui-image'
import FormHelperText from '@material-ui/core/FormHelperText';


const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: 400,
    height: 'auto',
    left: 'calc(50vw - 200px)',
    top: 'calc(50vh - 200px)',
    border: `3px solid ${style.gray}`,
    borderRadius: 20,
    paddingBottom: 20,
    '@media only screen and (max-width: 768px)' : {
      width: '70vw',
      left: '15vw',
    },
  },
  logo : {
    position: 'absolute',
    width: 120,
    height:120,
    left: 'calc(50vw - 60px)',
    top: 'calc(50vh - 260px)',
    zIndex: 1,
    '-webkit-box-shadow': '0px 0px 22px 0px rgba(79,74,79,1)',
    '-moz-box-shadow': '0px 0px 22px 0px rgba(79,74,79,1)',
    'box-shadow' : '0px 0px 22px 0px rgba(79,74,79,1)',
    '@media only screen and (max-width: 768px)' : {
      width: '22vw',
      left: '39vw',
      height: '22vw',
      top: 'calc(50vh - 200px - 11vw)',
    },
  },
  form: {
    width: '90%',
    marginTop: 55,
  },
  label: {
      color: `${style.violet} !important`,
  },
  input : {
    color: `${style.violet}`,
    '&:before' : {
      borderBottom: `2px solid ${style.rose_light} !important`,
    },
    '&:after' : {
      borderBottom: `2px solid ${style.rose_dark} !important`,
    },
    
  },
  submitButton : {
      transition: 'background-color ease 0.5s',
      outline: 'none',
      color: 'white',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
  backButton : {
    transition: 'background-color ease 0.5s',
    backgroundColor: style.gray,
    marginRight: '10%',
    color: 'white',
    outline: 'none',
    '&:hover' : {
      backgroundColor: style.gray_hover,
    },
  },
  link : {
      marginTop: 20,
      width: '90%',
  },
  otherButton : {
      transition: 'background-color ease 0.5s',
      outline: 'none',
      backgroundColor: style.rose_light,
      color: style.violet,
      '&:hover' : {
        backgroundColor: style.rose_light_hover,
      },
    },
  helper : {
    color: 'red',
  }
};



export class ForgotPassword extends React.Component {

  state = {
    email : '',
    emailRequiredError: false,
    emailValidationError: false,
    firstClickError: true,
  }

  handleChange = event => {
    const {emailValidator} = this.props;
    this.setState({ [event.target.name]: event.target.value}, () => {
      const { email, firstClickError} = this.state;
      if (email) {
          this.setState({ emailRequiredError: false });
          if (email.match(emailValidator) != null){
            this.setState({ emailValidationError: false });
          }
          else{
            this.setState({ emailValidationError: true });
          }
      }
      else if (firstClickError==false){
        this.setState({ emailRequiredError: true, emailValidationError: false });
      }
      else{
        this.setState({emailValidationError: false});
      }
      if (email) this.setState({firstClickError: false});
    });
  };

  sendRequest = (event) => {
    const {email} = this.state;
    const {locale, emailValidator} = this.props;
    event.preventDefault();
    if (!email) {
      this.setState({ emailRequiredError: true });
    }
    else if (email.match(emailValidator) == null) {
      this.setState({ emailValidationError: true });
    }
    this.setState({ firstClickError: false });

    if((!this.state.emailRequiredError)&&
       (!this.state.firstClickError)&&
       (!this.state.emailValidationError) ){
      const { forgotPassword } = this.props;
      forgotPassword(email, locale.localeName);
    }
    
  }

  handleSuccess = () => {
    const { resetForgotPassword, history} = this.props;
    resetForgotPassword();
    history.push('')
  }


  render() {
    const { classes, history, locale, error, loading, success, resetForgotPassword, errorType } = this.props;
    if (error === true) {
      return (
        <ErrorContainer Layout={Error} content={errorType} callback={resetForgotPassword}/>
      )
    }
    if (loading === true) {
      return (
        <Loading/>
      )
    }
    if (success === true) {
      return (<ErrorContainer Layout={Error}
                              content={translations.FORGOT_PASSWORD_SEND_EMAIL}
                              callback={this.handleSuccess}/>)
    }
    return (
      <div>
        <Paper className ={classes.logo}>
            <Image disableTransition={true} src={LogoImage} className={classes.logoImage} alt='logo SusPigSys' />
        </Paper>
        <Paper className={classes.paper}>
        <form className={classes.form} >
            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="email">{translations.E_MAIL_ADDRESS[locale.localeName]}</InputLabel>
              <Input className={classes.input} id="email" name="email"  onChange={this.handleChange} />
              {this.state.emailRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
              {this.state.emailValidationError && <FormHelperText className={classes.helper}>{translations.INVALID_EMAIL[locale.localeName]}</FormHelperText>}
            </FormControl>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20, outline: 'none'}}>
              <Button
                fullWidth
                size="large"
                className={classes.backButton}
                onClick={() => {history.goBack()}}
              >
                {translations.GO_BACK[locale.localeName]}
              </Button>
              <Button
                type='submit'
                fullWidth
                size="large"
                className={classes.submitButton}
                onClick={(event) => {this.sendRequest(event)}}
              >
                {translations.SUBMIT[locale.localeName]}
              </Button>
            </div>
            </form>
        </Paper>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(ForgotPassword));
