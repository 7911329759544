import {URL} from '../../../config';
import {
  GET_QUESTION_BEGIN,
  GET_QUESTION_FAILURE,
  GET_QUESTION_RESET,
  GET_QUESTION_SUCCESS, 
  SAVE_QUESTION_BEGIN,
  SAVE_QUESTION_FAILURE,
  SAVE_QUESTION_RESET,
  SAVE_QUESTION_SUCCESS,
  GET_QUESTION_1069_BEGIN,
  GET_QUESTION_1069_FAILURE,
  GET_QUESTION_1069_RESET,
  GET_QUESTION_1069_SUCCESS, 
  ADD_FILLED_QUESTION,
  REMOVE_FILLED_QUESTION
} from "../../../reducers/questionnaire/form/question";

const OPERATION = {
  GET : 'get',
  SAVE : 'save',
  GET_QUESTION_1069: 'get1069',
}

function saveAndGetQuestion(localeDicId, question) {
  return dispatch => {
    return dispatch(saveQuestion(question))
      .then(() => {
        dispatch(getQuestion(localeDicId, JSON.parse(question).id, JSON.parse(question).filledQuestionListId))
      });
  };
}


function getQuestion(localeDicId, questionId, filledQuestionListId) {
  return dispatch => {
    dispatch(setBegin(OPERATION.GET, questionId, filledQuestionListId, null));
    return getQuestionStart(localeDicId, questionId)
      .then((data) => {
        dispatch(setSuccess(OPERATION.GET, questionId, filledQuestionListId, data));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.GET, questionId, filledQuestionListId))
      });
  };
}

function getQuestionStart(localeDicId, questionId) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/question/` + questionId + '?localeDicId=' + localeDicId, {
      headers: {
      "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.id > 0) {
          resolve(json)
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

function saveQuestion(question) {
  return dispatch => {
    dispatch(setBegin(OPERATION.SAVE, JSON.parse(question).id, JSON.parse(question).filledQuestionListId, null));
    return saveQuestionStart(question)
      .then(() => {
        dispatch(setSuccess(OPERATION.SAVE, JSON.parse(question).id, JSON.parse(question).filledQuestionListId));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.SAVE, JSON.parse(question).id, JSON.parse(question).filledQuestionListId))
      });
  };
}

function saveQuestionStart(question) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/question/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
      body: question
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

function getQuestion1069(filledFormId) {
  return dispatch => {
    dispatch(setBegin(OPERATION.GET_QUESTION_1069, null, null, filledFormId));
    return getQuestion1069Start(filledFormId)
      .then((data) => {
        dispatch(setSuccess(OPERATION.GET_QUESTION_1069, null, null, data));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.GET_QUESTION_1069))
      });
  };
}

function getQuestion1069Start(filledFormId) {
  return new Promise((resolve, reject) => {
    fetch(`${URL}/secured/question/answerByQuestionDicId/${filledFormId}?questionDicId=1069`, {
      headers: {
      "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data)
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setBegin = (operation, questionId, questionListId, filledFormId) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_QUESTION_BEGIN,
        questionId: questionId,
        questionListId: questionListId
      }
    case OPERATION.SAVE:
      return {
        type: SAVE_QUESTION_BEGIN,
        questionId: questionId,
        questionListId: questionListId
      }
    case OPERATION.GET_QUESTION_1069:
      return {
        type: GET_QUESTION_1069_BEGIN,
      }
    default: return null;
  }
};

const setSuccess = (operation, questionId, questionListId, data) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_QUESTION_SUCCESS,
        questionId: questionId,
        questionListId: questionListId,
        data: data
      }
    case OPERATION.SAVE:
      return {
        type: SAVE_QUESTION_SUCCESS,
        questionId: questionId,
        questionListId: questionListId
      }
    case OPERATION.GET_QUESTION_1069:
      return {
        type: GET_QUESTION_1069_SUCCESS,
        data: data
      }
    default: return null;
  }
};

const setFailure = (operation, questionId, questionListId) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_QUESTION_FAILURE,
        questionId: questionId,
        questionListId: questionListId
      }
    case OPERATION.SAVE:
      return {
        type: SAVE_QUESTION_FAILURE,
        questionId: questionId,
        questionListId: questionListId
      }
    case OPERATION.GET_QUESTION_1069:
      return {
        type: GET_QUESTION_1069_FAILURE,
      }
    default: return null;
  }
};

const resetGetQuestionState = (questionId, questionListId) => {
  return {
    type: GET_QUESTION_RESET,
    questionId: questionId,
    questionListId: questionListId
  }
};

const resetSaveQuestionState = (questionId, questionListId) => {
  return {
    type: SAVE_QUESTION_RESET,
    questionId: questionId,
    questionListId: questionListId
  }
};

const setQuestionFilledAll = (questionId) => {
  return {
    type: ADD_FILLED_QUESTION,
    id: questionId
  }
};

const removeQuestionFilledAll = (questionId) => {
  return {
    type: REMOVE_FILLED_QUESTION,
    id: questionId
  }
};

const index = {
  getQuestion,
  saveQuestion,
  saveAndGetQuestion,
  resetGetQuestionState,
  resetSaveQuestionState,
  getQuestion1069,
  setQuestionFilledAll,
  removeQuestionFilledAll
}

export default index;
