import {URL} from '../../config';

function fetchUserList(start, limit, searchText, orderBy, order) {
  return dispatch => {
    dispatch(fetchUserListBegin());
    return fetchUserListStart(start, limit, searchText, orderBy, order)
      .then(json => {
        dispatch(fetchUserListSuccess(json.data, json.total));
      })
      .catch(() => {
        dispatch(fetchUserListFailure())
      });
  };
}

const fetchUserListBegin = () => ({
  type: 'FETCH_USER_LIST_BEGIN'
});

function fetchUserListStart(start, limit, searchText, orderBy, order) {
  return new Promise((resolve, reject) => {
    fetch(`${URL}/secured/user/list?start=${start}&limit=${limit}&searchText=${searchText}&orderBy=${orderBy}&order=${order}`, {
        method: 'GET',
        headers: {
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        }
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json)
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const fetchUserListSuccess = (userList, total) => ({
  type: 'FETCH_USER_LIST_SUCCESS',
  items: userList,
  total: total
});

const fetchUserListFailure = () => ({
  type: 'FETCH_USER_LIST_FAILURE',
  error: true
});

const resetState = () => ({
  type: 'FETCH_USER_LIST_RESET'
});

const index = {
  fetchUserList,
  resetState
}

export default index;
