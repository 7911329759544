import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SUCCESS_STATUS} from "../../../util";

export const GET_QUESTION_LIST_BEGIN = 'GET_QUESTION_LIST_BEGIN';
export const GET_QUESTION_LIST_SUCCESS = 'GET_QUESTION_LIST_SUCCESS';
export const GET_QUESTION_LIST_FAILURE = 'GET_QUESTION_LIST_FAILURE';
export const SET_QUESTION_LIST_DATA = 'SET_QUESTION_LIST_DATA';

export const ADD_QUESTION_LIST_BEGIN = 'ADD_QUESTION_LIST_BEGIN';
export const ADD_QUESTION_LIST_SUCCESS = 'ADD_QUESTION_LIST_SUCCESS';
export const ADD_QUESTION_LIST_FAILURE = 'ADD_QUESTION_LIST_FAILURE';

export const DELETE_QUESTION_LIST_BEGIN = 'DELETE_QUESTION_LIST_BEGIN';
export const DELETE_QUESTION_LIST_SUCCESS = 'DELETE_QUESTION_LIST_SUCCESS';
export const DELETE_QUESTION_LIST_FAILURE = 'DELETE_QUESTION_LIST_FAILURE';

export const ACTIVATE_QUESTION_LIST_BEGIN = 'ACTIVATE_QUESTION_LIST_BEGIN';
export const ACTIVATE_QUESTION_LIST_SUCCESS = 'ACTIVATE_QUESTION_LIST_SUCCESS';
export const ACTIVATE_QUESTION_LIST_FAILURE = 'ACTIVATE_QUESTION_LIST_FAILURE';

export const SET_NEXT_QUESTION_LIST_ID = 'SET_NEXT_QUESTION_LIST_ID';
export const PREVIOUS_QUESTION_LIST = 'PREVIOUS_QUESTION_LIST'

export const SET_ANSWERS_FOR_LAST_PEN = 'SET_ANSWERS_FOR_LAST_PEN'

export const initialState = {
  getStatus: INITIAL_STATUS,
  addStatus: INITIAL_STATUS,
  deleteStatus: INITIAL_STATUS,
  activateStatus: INITIAL_STATUS,
  data : null,
  nextQuestionListId : null,
  prevQuestionList: null,
  answersForLastPen: null,
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case GET_QUESTION_LIST_BEGIN: {
      return {
        ...state,
        getStatus: BEGIN_STATUS,
        data: null
      };
    }
    case GET_QUESTION_LIST_SUCCESS: {
      return {
        ...state,
        getStatus: SUCCESS_STATUS,
        data: action.data
      };
    }
    case GET_QUESTION_LIST_FAILURE: {
      return {
        ...state,
        getStatus: FAILURE_STATUS,
        data: null
      };
    }
    case SET_QUESTION_LIST_DATA: {
      return {
        ...state,
        data: action.data
      };
    }


    case ADD_QUESTION_LIST_BEGIN: {
      return {
        ...state,
        addStatus: BEGIN_STATUS
      };
    }
    case ADD_QUESTION_LIST_SUCCESS: {
      return {
        ...state,
        addStatus: SUCCESS_STATUS
      };
    }
    case ADD_QUESTION_LIST_FAILURE: {
      return {
        ...state,
        addStatus: FAILURE_STATUS
      };
    }

    case DELETE_QUESTION_LIST_BEGIN: {
      return {
        ...state,
        deleteStatus: BEGIN_STATUS
      };
    }
    case DELETE_QUESTION_LIST_SUCCESS: {
      return {
        ...state,
        deleteStatus: SUCCESS_STATUS
      };
    }
    case DELETE_QUESTION_LIST_FAILURE: {
      return {
        ...state,
        deleteStatus: FAILURE_STATUS
      };
    }
    case SET_NEXT_QUESTION_LIST_ID: {
      return {
        ...state,
        nextQuestionListId : action.nextQuestionListId
      };
    }
    case PREVIOUS_QUESTION_LIST: {
      return {
        ...state,
        prevQuestionList : action.prevQuestionList
      };
    }

    case ACTIVATE_QUESTION_LIST_BEGIN: {
      return {
        ...state,
        activateStatus: BEGIN_STATUS
      };
    }
    case ACTIVATE_QUESTION_LIST_SUCCESS: {
      return {
        ...state,
        activateStatus: SUCCESS_STATUS
      };
    }
    case ACTIVATE_QUESTION_LIST_FAILURE: {
      return {
        ...state,
        activateStatus: FAILURE_STATUS
      };
    }

    case SET_ANSWERS_FOR_LAST_PEN: {
      return {
        ...state,
        answersForLastPen: action.answersForLastPen
      };
    }


    default:
      return state;
  }
}
