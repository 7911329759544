import {URL} from '../../../config';
import {
  ADD_QUESTION_LIST_BEGIN,
  ADD_QUESTION_LIST_FAILURE,
  ADD_QUESTION_LIST_SUCCESS,
  DELETE_QUESTION_LIST_BEGIN,
  DELETE_QUESTION_LIST_FAILURE,
  DELETE_QUESTION_LIST_SUCCESS,
  GET_QUESTION_LIST_BEGIN,
  GET_QUESTION_LIST_FAILURE,
  GET_QUESTION_LIST_SUCCESS,
  SET_NEXT_QUESTION_LIST_ID,
  SET_QUESTION_LIST_DATA,
  ACTIVATE_QUESTION_LIST_BEGIN,
  ACTIVATE_QUESTION_LIST_FAILURE,
  ACTIVATE_QUESTION_LIST_SUCCESS,
  SET_ANSWERS_FOR_LAST_PEN
} from "../../../reducers/questionnaire/form/questionList";

const OPERATION = {
  GET : 'get',
  ADD : 'add',
  DELETE : 'delete',
  ACTIVATE : 'activate'
}

function getQuestionList(localeDicId, subFormId, modeAdmin) {
  return dispatch => {
    dispatch(setBegin(OPERATION.GET));
    return getQuestionListStart(localeDicId, subFormId, modeAdmin)
      .then((data) => {
        dispatch(setSuccess(OPERATION.GET, data));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.GET))
      });
  };
}

function getQuestionListStart(localeDicId, subFormId, modeAdmin) {
  return new Promise((resolve, reject) => {
    fetch(
      modeAdmin !== true ? 
      URL + `/secured/questionlist/` + subFormId + '?localeDicId=' + localeDicId
      :
      URL + `/secured/questionlist/group/dic/list?localeDicId=${localeDicId}&subFormDicId=${subFormId}` , {
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
       },
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data)
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

function addQuestionList(body) {
  return dispatch => {
    dispatch(setBegin(OPERATION.ADD));
    return addQuestionListStart(body)
      .then(() => {
        dispatch(setSuccess(OPERATION.ADD));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.ADD))
      });
  };
}

function addQuestionListStart(body) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/questionlist/addFilledQuestionList`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

function deleteQuestionList(questionListId) {
  return dispatch => {
    dispatch(setBegin(OPERATION.DELETE));
    return deleteQuestionListStart(questionListId)
      .then(() => {
        dispatch(setSuccess(OPERATION.DELETE));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.DELETE))
      });
  };
}

function deleteQuestionListStart(questionListId) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/questionlist/deleteFilledQuestionList/` + questionListId, {
      method: 'POST',
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
       },
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

function activateQuestionList(id, active) {
  return dispatch => {
    dispatch(setBegin(OPERATION.ACTIVATE));
    return activateQuestionListStart(id, active)
      .then(() => {
        dispatch(setSuccess(OPERATION.ACTIVATE));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.ACTIVATE))
      });
  };
}

function activateQuestionListStart(id, active) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/questionlist/dic/setIsActive?questionGroupDicId=${id}&isActive=${active}`, {
      method: 'PUT',
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
       },
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setBegin = (operation) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_QUESTION_LIST_BEGIN
      }
    case OPERATION.ADD:
      return {
        type: ADD_QUESTION_LIST_BEGIN
      }
    case OPERATION.DELETE:
      return {
        type: DELETE_QUESTION_LIST_BEGIN
      }
    case OPERATION.ACTIVATE:
      return {
        type: ACTIVATE_QUESTION_LIST_BEGIN
      }
    default: return null;
  }
};

const setSuccess = (operation, data) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_QUESTION_LIST_SUCCESS,
        data: data
      }
    case OPERATION.ADD:
      return {
        type: ADD_QUESTION_LIST_SUCCESS
      }
    case OPERATION.DELETE:
      return {
        type: DELETE_QUESTION_LIST_SUCCESS
      }
    case OPERATION.ACTIVATE:
      return {
        type: ACTIVATE_QUESTION_LIST_SUCCESS
      }
    default: return null;
  }
};

const setFailure = (operation) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_QUESTION_LIST_FAILURE
      }
    case OPERATION.ADD:
      return {
        type: ADD_QUESTION_LIST_FAILURE
      }
    case OPERATION.DELETE:
      return {
        type: DELETE_QUESTION_LIST_FAILURE
      }
    case OPERATION.ACTIVATE:
      return {
        type: ACTIVATE_QUESTION_LIST_FAILURE
      }
    default: return null;
  }
};

const setQuestionListData = (data) => {
  return {
    type: SET_QUESTION_LIST_DATA,
    data: data
  }
};

const setNextQuestionListId = (id) => ({
  type: SET_NEXT_QUESTION_LIST_ID,
  nextQuestionListId : id
});

const setPrevQuestionList = (prevQuestionList) => ({
  type: 'PREVIOUS_QUESTION_LIST',
  prevQuestionList : prevQuestionList
});

const setAnswersForLastPen = (obj) => ({
  type: SET_ANSWERS_FOR_LAST_PEN,
  answersForLastPen : obj,
});




const index = {
  getQuestionList,
  addQuestionList,
  deleteQuestionList,
  setQuestionListData,
  setNextQuestionListId,
  setPrevQuestionList,
  activateQuestionList,
  setAnswersForLastPen
}

export default index;
