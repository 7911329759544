import React from 'react';
import {translations} from "../../dictionary/translations";
import {withStyles} from "@material-ui/core";
import {style} from "../../styles/style";
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import {createMuiTheme} from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Charts from './Charts';
import Pdf from '../../instruction/SusPigSys_recommendations_economy.pdf';

const theme = createMuiTheme({
});

const styles = {
    appBar: {
      width: '80%',
      position: 'relative',
      marginTop: 40,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      backgroundColor: 'transparent',
      zIndex: 1,
      '@media only screen and (max-width: 768px)' : {
        width: '95%',
      },
      '@media only screen and (max-width: 400px)' : {
        width: '95%',
        marginTop: 90,
      },
    },
    tabs : {
      width: 'calc(100% + 90px)',
      '@media only screen and (max-width: 768px)' : {
          width: 'calc(100% + 30px)',
    },
    },
    tab : {
      border: `3px solid ${style.gray}`,
      borderBottom: 'none',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      position: 'relative',
      '-webkit-box-shadow': '-10px 0px 5px -5px rgba(0,0,0,0.75)',
      '-moz-box-shadow': '-10px 0px 5px -5px rgba(0,0,0,0.75)',
      'box-shadow': '-10px 0px 5px -5px rgba(0,0,0,0.75)',
      backgroundColor: '#cccccc',
      opacity: 1,
      paddingLeft: '3vw',
      paddingRight: '3vw',
      '@media only screen and (max-width: 768px)' : {
          paddingLeft: '1vw',
          paddingRight: '1vw',
          fontSize: 10,
      },
    },
    tab2 : {
      left: -30,
      '@media only screen and (max-width: 768px)' : {
        left: -10,
      },
    },
    tab3 : {
      left: -60,
      '@media only screen and (max-width: 768px)' : {
        left: -20,
      },
    },
    tab4 : {
      left: -90,
      '@media only screen and (max-width: 768px)' : {
        left: -30,
      },
    },
    tab5 : {
      left: -120,
      '@media only screen and (max-width: 768px)' : {
        left: -40,
      },
    },
    active: {
      zIndex: 1,
      backgroundColor: 'white',
    },
    indicator : {
      backgroundColor: 'white',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      position: 'absolute',
      top: 10,
      right: 20,
      left: 'auto',
    },
    button : {
      marginRight: 10,
      transition: 'background-color ease 0.5s, color ease 0.5s',
      backgroundColor: 'white',
      fontSize: 11,
      padding: 10,
      '&:hover' : {
        backgroundColor: style.rose_light_hover,
        color: 'white',
      },
      '@media only screen and (max-width: 768px)' : {
        fontSize: 8,
        marginRight: 5,
      },
    },
};

export class Themes extends React.Component {

    state = {
      ready: false,
    }

    componentDidMount() {
      const {locale, questionnaireId, getFormList, setNextFormId, history} = this.props;
        if (!Number.isInteger(questionnaireId)) {
          history.push('/');
        }
          setNextFormId(null);
          getFormList(locale.id, questionnaireId, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const { setFormId, formList } = this.props;
      if (formList.success === true && prevProps.formList.success === false) {
        const initialForm = formList.data.find(({status}) => status===true);
        const initFormId = initialForm.formDicId;
        setFormId(initFormId);
        this.setState({ready: true});
      }
    }

    handleChange = (event, value) => {
      const {setFormId} = this.props;
      setFormId(value);
    };
  
    render() {
      const { classes, formId, formList, locale, choosenFarmType, questionnaireId, history, choosenFarmCode } = this.props;
      const { ready } = this.state;
      let tabClass;

      const selectedFormName = formList.data?.find(({formDicId}) => formDicId===formId)?.formName;

      if (formList.data){

            const choosenId = formId-1;
        
            return (
              <>
                  <div className={classes.buttons}>
                      <Button
                        className={classes.button}
                      >
                          <a href = {Pdf} target = "_blank">{translations.RECOMMENDATION[locale.localeName]}</a>
                      </Button>   
                      <Button
                        className={classes.button}
                        onClick={() => {history.goBack()}}
                      >
                            {translations.CLOSE[locale.localeName]}
                      </Button>           
                  </div>
                <AppBar position="static" color="default" className={classes.appBar}>
                  <Tabs
                    value={formId}
                    variant="fullWidth"
                    classes={{indicator : classes.indicator}}
                    onChange={this.handleChange}
                    className = {classes.tabs}
                  >
                    { formList.data.map((item) => {
                      tabClass = [classes.tab];
        
                      if ((item.formDicId === 1 || item.id === 1) && choosenId === 0) {
                        tabClass.push(classes.active);
                      }
                      if (item.formDicId === 2 || item.id === 2) {
                        if (choosenId === 1) {
                          tabClass.push(classes.tab2, classes.active);
                        } else {
                          tabClass.push(classes.tab2);
                        }
                      }
                      if (item.formDicId === 3 || item.id === 3) {
                        if (choosenId === 2) {
                          tabClass.push(classes.tab3, classes.active);
                        } else {
                          tabClass.push(classes.tab3);
                        }
                      }
                      if (item.formDicId === 4 || item.id === 4) {
                        if (choosenId === 3) {
                          tabClass.push(classes.tab4, classes.active);
                        } else {
                          tabClass.push(classes.tab4);
                        }
                      }
                      if (item.formDicId === 5 || item.id === 5) {
                        if (choosenId === 4) {
                          tabClass.push(classes.tab5, classes.active);
                        } else {
                          tabClass.push(classes.tab5);
                        }
                      }
                      return <Tab label={item.formName} disabled={item.status!==true ? true : false } style={item.status!==true ? {backgroundColor: style.gray, opacity: 1} : {} } key={item.id} value={item.formDicId} className={tabClass.join(' ')}/>
                      
       
                    })}
        
                  </Tabs>
                  </AppBar>
                   
                  {formId && ready ? <Charts formDicId={formId} selectedFormName={selectedFormName} choosenFarmCode={choosenFarmCode} questionnaireId={questionnaireId} locale={locale} choosenFarmType={choosenFarmType} /> : null}
                  
              </>
            )
      }
      else{
        return null
      }
    }
}

export default withRouter(withStyles(styles)(Themes));
