import React from 'react';
import {connect} from "react-redux";
import actions from "../../../../actions";

export class SubFormBarContainer extends React.Component {

  render() {

    const {
      Layout,
      setNextSubFormId,
      subFormId,
      subFormListData,
      questionList,
      fillingUpStatus,
      prevSubformList,
      modeAdmin,
      activateSubform,
      formId,
      locale,
      getSubFormList,
      swapSubform,
      setAfterSubformAdding,
      afterSubformAdding,
      setSubFormId,
      setAfterSubformEditing,
      getQuestionList,
      updateFillingUpStatus
    } = this.props;

    return (
      <Layout
        locale={locale}
        setNextSubFormId={setNextSubFormId}
        subFormId={subFormId}
        subFormListData={subFormListData}
        questionList={questionList}
        fillingUpStatus={fillingUpStatus}
        prevSubformList={prevSubformList}
        modeAdmin={modeAdmin}
        activateSubform={activateSubform}
        formId={formId}
        getSubFormList={getSubFormList}
        swapSubform={swapSubform}
        setAfterSubformAdding={setAfterSubformAdding}
        afterSubformAdding={afterSubformAdding}
        setSubFormId={setSubFormId}
        setAfterSubformEditing={setAfterSubformEditing}
        getQuestionList={getQuestionList}
        updateFillingUpStatus={updateFillingUpStatus}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  subFormListData : state.questionnaire.form.subFormList.data,
  fillingUpStatus : state.questionnaire.form.subFormList.fillingUpStatus,
  subFormId : state.questionnaire.form.form.subFormId,
  questionList : state.questionnaire.form.questionList.data,
  prevSubformList: state.questionnaire.form.subFormList.prevSubformList,
  formId : state.questionnaire.form.form.formId,
  afterSubformAdding : state.questionnaire.form.form.afterSubformAdding,
});

const mapDispatchToProps = {
  setNextSubFormId : actions.questionnaire.form.subFormList.setNextSubFormId,
  activateSubform : actions.questionnaire.form.subFormList.activateSubform,
  swapSubform : actions.questionnaire.form.subFormList.swapSubform,
  getSubFormList : actions.questionnaire.form.subFormList.getSubFormList,
  updateFillingUpStatus : actions.questionnaire.form.subFormList.updateFillingUpStatus,
  setAfterSubformAdding : actions.questionnaire.form.form.setAfterSubformAdding,
  setAfterSubformEditing : actions.questionnaire.form.form.setAfterSubformEditing,
  setSubFormId : actions.questionnaire.form.form.setSubFormId,
  getQuestionList : actions.questionnaire.form.questionList.getQuestionList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SubFormBarContainer);
