import {SET_MAIN_NAVIGATION_NAV_BAR_VALUE} from "../../reducers/mainNavigation/mainNavigation";

const setMainNavigationNavBarValue = (val) => ({
  type: SET_MAIN_NAVIGATION_NAV_BAR_VALUE,
  mainNavigationNavBarValue: val
});

const index = {
  setMainNavigationNavBarValue
}

export default index;
