import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {translations} from '../../../dictionary/translations';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {ERROR_MODE, ANSWER_TYPE_DIC_ID} from "../../../util";
import ErrorContainer from "../../../containers/Error";
import Error from "../../Error";
import {style} from "../../../styles/style.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import Avatar from "@material-ui/core/Avatar";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = {
  dialogTitle: {
    width: 500,
    '@media only screen and (max-width: 768px)' : {
      width: 300,
    },
  },
  form: {
    width: '90%',
    marginTop: 10,
    position: 'relative',
    left: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sections: {
    width: '100%',
  },
  section: {
    marginTop: 10,
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  label: {
      color: `${style.violet} !important`,
  },
  input : {
      color: `${style.violet}`,
      width: '100%',
      '&:before' : {
        borderBottom: `2px solid ${style.rose_light} !important`,
      },
      '&:after' : {
        borderBottom: `2px solid ${style.rose_dark}`,
      },
  },
  backButton : {
    transition: 'background-color ease 0.5s',
    backgroundColor: style.gray,
    marginRight: '10%',
    color: 'white',
    outline: 'none',
    '&:hover' : {
      backgroundColor: style.gray_hover,
    },
  },
  submitButton : {
      transition: 'background-color ease 0.5s',
      outline: 'none',
      color: 'white',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
  select : {
    '&:before' : {
      borderBottom: `2px solid ${style.rose_light} !important`,
    },

    '&:after' : {
      borderBottom: `2px solid ${style.rose_dark}`,
    }
  },
  helper : {
    color: 'red',
  }
};


export class QuestionTemplate extends React.Component {

  componentDidMount() {
    const {localeList, mode, questionId, getQuestion} = this.props;
    this.setState({trans: new Array(localeList.items.length).fill(-1), tipTrans: new Array(localeList.items.length).fill(-1), groupTrans: new Array(localeList.items.length).fill(-1)})
    if (mode === 'edit') getQuestion(questionId);
  }

  state = {
    firstClickingError: true,
    answerTypeDicId: '',
    answerTypeRequiredError: false,
    textId: null,
    trans: [],
    transRequiredError: false,
    groupId: null,
    groupTextId: null,
    groupTrans: [],
    tipId: null,
    tipTextId: null,
    tipTrans: [],
    isMaxNumberError: false,
    isMinNumberError: false,
    min: '',
    max: '',
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {items} = this.props;
    const {trans, tipTrans, groupTrans} = this.state;
    if(items !== prevProps.items){
      let arr = [...trans];
      let arrGroup = [...groupTrans];
      let arrTip = [...tipTrans];
      const  tr = items.text.translations.map((item, key) => {
        arr[key] = item.text;
      })
      const  trGroup = items.questionGroup.text.translations.map((item, key) => {
        arrGroup[key] = item.text;
      })
      const  trTip = items.questionTip.text.translations.map((item, key) => {
        arrTip[key] = item.text;
      })

       this.setState({
          answerTypeDicId: items.answerTypeDicId,
          textId: items.text.id,
          trans: arr,
          groupId: items.questionGroup.id,
          groupTextId: items.questionGroup.text.id,
          groupTrans: arrGroup,
          tipId: items.questionTip.id,
          tipTextId: items.questionTip.text.id,
          tipTrans: arrTip,
          min: items.min ? items.min : '',
          max: items.max ? items.max : '',
       })
    }
  }

  questionPrepare = (event) => {
    const { questionListId, addQuestion, mode, editQuestion, questionId, addNewQuestion} = this.props;
    const { answerTypeDicId, trans, groupTrans, tipTrans, min, max, textId, groupId, tipId, tipTextId, groupTextId} = this.state;
    if (event) event.preventDefault() ;
    if (!answerTypeDicId) {
      this.setState({ answerTypeRequiredError: true });
    }
    if (!trans.every(el => {return el !== -1})) {
      this.setState({ transRequiredError: true });
    }
    this.setState({ firstClickingError: false });
    if((!this.state.answerTypeRequiredError)&&
    (!this.state.transRequiredError)&&
    (!this.state.firstClickingError)&&
    (!this.state.isMaxNumberError)&&
    (!this.state.isMinNumberError) ){
      const  tr = trans.map((item, key) => {
        return {
          localeDicId: key+1,
          text: item
         }
      });
      const  trTip = tipTrans.map((item, key) => {
       return {
         localeDicId: key+1,
         text: item !== -1 ? item : '',
        }
     });
     const  trGroup = groupTrans.map((item, key) => {
       return {
         localeDicId: key+1,
         text: item !== -1 ? item : '',
        }
     });
     const body = {
      questionListDicId : questionListId,
       answerTypeDicId: answerTypeDicId,
       text: {
         id: textId,
         translations: tr,
       },
       questionGroup: {
         id: groupId,
         text : {
           id: groupTextId,
           translations: trGroup,
         }
       },
       questionTip: {
         id: tipId,
         text : {
           id: tipTextId,
           translations: trTip,
         }
       },
       min: min,
       max: max,
       maxAnswerQuestionId: '',
       isActive: true,
     };
     mode==='add' ? addQuestion(JSON.stringify(body)) : editQuestion(JSON.stringify(body));
   }
  }

  questionSuccess = () => {
    const { close, questionListId} = this.props;
    close(false, questionListId);
  }

  handleChangeName = event => {
      const {answerTypeDicId, trans, tipTrans, groupTrans} = this.state;
      let arrTrans = [...trans];
      arrTrans[event.target.name.substr(8) - 1] = event.target.value;
      let arrTipTrans = [...tipTrans];
      arrTipTrans[event.target.name.substr(3) - 1] = event.target.value;
      let arrGroupTrans = [...groupTrans];
      arrGroupTrans[event.target.name.substr(5) - 1] = event.target.value;
      this.setState({trans: arrTrans, tipTrans: arrTipTrans, groupTrans: arrGroupTrans}, () => {
        if (answerTypeDicId) {
          this.setState({ answerTypeRequiredError: false });
        }
        if (arrTrans.every(el => {return el !== -1})) {
          this.setState({ transRequiredError: false });
        }
        if (answerTypeDicId && arrTrans.every(el => {return el !== -1})) this.setState({firstClickingError: false});
      });
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      const {answerTypeDicId, trans, min, max} = this.state;
      const regex = new RegExp("^(?:[1-9]\\d*|0)?$");
      if (regex.test(min)){
        this.setState({ isMinNumberError: false });
      }
      else{
        this.setState({ isMinNumberError: true });
      }
      if (regex.test(max)){
        this.setState({ isMaxNumberError: false });
      }
      else{
        this.setState({ isMaxNumberError: true });
      }
      if (answerTypeDicId) {
        this.setState({ answerTypeRequiredError: false });
      }

      if (trans.every(el => {return el !== -1})) {
        this.setState({ transRequiredError: false });
      }
      if (answerTypeDicId && trans.every(el => {return el !== -1}) && regex.test(min) && regex.test(max)) this.setState({firstClickingError: false});
    });
  };


  render() {
    const { classes, mode, locale, close, addStatus, localeList, getStatus, editStatus, questionListId} = this.props;
    const {answerTypeDicId, answerTypeRequiredError, trans, transRequiredError, groupTrans, tipTrans, min, max, isMaxNumberError, isMinNumberError} = this.state;
    if (localeList.error === true || getStatus.error === true) {
      return (
        <ErrorContainer Layout={Error}
                content={translations.ERROR_WHILE_DOWNLOADING_DATA}
                mode={ERROR_MODE.GO_BACK}/>
      )
    }

    else if (addStatus.error === true || editStatus.error === true) {
      return (
        <ErrorContainer Layout={Error}
                content={translations.ERROR_WHILE_UPLOADING_DATA}
                mode={ERROR_MODE.GO_BACK}/>
      )
    }

    else if (addStatus.loading===true || localeList.loading===true || getStatus.loading===true || editStatus.loading===true) {
      return (
        //<Loading/>
        null
      )
    }

    else if (addStatus.success === true || editStatus.success === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={mode==='add' ? translations.QUESTION_ADDED_SUCCESSFULLY : translations.QUESTION_EDITED_SUCCESSFULLY}
                        callback={this.questionSuccess}/>
      )
    }
    
    return (
      <Dialog open={true} scroll={'paper'} >
        <DialogTitle id="scroll-dialog-title" className={classes.dialogTitle} >{mode === 'add' ? translations.ADD_QUESTION[locale.localeName] : translations.EDIT_QUESTION[locale.localeName]}</DialogTitle>
        <DialogContent dividers={true} >
              <form className={classes.form}>
                <FormControl margin="normal" required disabled={mode==='edit' ? true : false} fullWidth>
                    <InputLabel className={classes.label} htmlFor="answerTypeDicId">{translations.TYPE[locale.localeName]}</InputLabel>
                    <Select
                        value={answerTypeDicId}
                        onChange={this.handleChange}
                        className={classes.select}
                        inputProps={{
                          name: 'answerTypeDicId',
                          id: 'answerTypeDicId'
                        }}
                    >
                          <MenuItem key={ANSWER_TYPE_DIC_ID.LICZBA_CALKOWITA} value={ANSWER_TYPE_DIC_ID.LICZBA_CALKOWITA}>{translations.LICZBA_CALKOWITA[locale.localeName]}</MenuItem>
                          <MenuItem key={ANSWER_TYPE_DIC_ID.LICZBA_ZMIENNOPRZECINKOWA} value={ANSWER_TYPE_DIC_ID.LICZBA_ZMIENNOPRZECINKOWA}>{translations.LICZBA_ZMIENNOPRZECINKOWA[locale.localeName]}</MenuItem>
                          <MenuItem key={ANSWER_TYPE_DIC_ID.TEKST_1000_ZNAKOW} value={ANSWER_TYPE_DIC_ID.TEKST_1000_ZNAKOW}>{translations.TEKST_1000_ZNAKOW[locale.localeName]}</MenuItem>
                          <MenuItem key={ANSWER_TYPE_DIC_ID.LISTA_JEDNOKROTNEGO_WYBORU_SKALA_LIKERTA} value={ANSWER_TYPE_DIC_ID.LISTA_JEDNOKROTNEGO_WYBORU_SKALA_LIKERTA}>{translations.LISTA_JEDNOKROTNEGO_WYBORU_SKALA_LIKERTA[locale.localeName]}</MenuItem>
                          <MenuItem key={ANSWER_TYPE_DIC_ID.LISTA_JEDNOKROTNEGO_WYBORU_COMBO_BOX} value={ANSWER_TYPE_DIC_ID.LISTA_JEDNOKROTNEGO_WYBORU_COMBO_BOX}>{translations.LISTA_JEDNOKROTNEGO_WYBORU_COMBO_BOX[locale.localeName]}</MenuItem>
                          <MenuItem key={ANSWER_TYPE_DIC_ID.LISTA_WIELOKROTNEGO_WYBORU} value={ANSWER_TYPE_DIC_ID.LISTA_WIELOKROTNEGO_WYBORU}>{translations.LISTA_WIELOKROTNEGO_WYBORU[locale.localeName]}</MenuItem>
                          <MenuItem key={ANSWER_TYPE_DIC_ID.LISTA_WIELOKROTNEGO_WYBORU_Z_WARTOSCIA} value={ANSWER_TYPE_DIC_ID.LISTA_WIELOKROTNEGO_WYBORU_Z_WARTOSCIA}>{translations.LISTA_WIELOKROTNEGO_WYBORU_Z_WARTOSCIA[locale.localeName]}</MenuItem>
                          <MenuItem key={ANSWER_TYPE_DIC_ID.LISTA_JEDNOKROTNEGO_WYBORU_Z_WARTOSCIA} value={ANSWER_TYPE_DIC_ID.LISTA_JEDNOKROTNEGO_WYBORU_Z_WARTOSCIA}>{translations.LISTA_JEDNOKROTNEGO_WYBORU_Z_WARTOSCIA[locale.localeName]}</MenuItem>
                          <MenuItem key={ANSWER_TYPE_DIC_ID.TRUE_FALSE} value={ANSWER_TYPE_DIC_ID.TRUE_FALSE}>{translations.TRUE_FALSE[locale.localeName]}</MenuItem>
                          <MenuItem key={ANSWER_TYPE_DIC_ID.DATE} value={ANSWER_TYPE_DIC_ID.DATE}>{translations.DATE[locale.localeName]}</MenuItem>
                          
  
                    </Select>
                  {answerTypeRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
                </FormControl>
                {localeList.items.map((item) => (
                  <div key={`section${item.localeName}`} className={classes.sections}>
                       <section  className={classes.section} >
                          <Avatar src={"./images/flags/" + item.localeName + ".jpg"} style={{width: 30, height: 30, marginRight: 20,}} />
                          <FormControl margin="normal" required fullWidth style={{marginTop: 8}}>
                            <InputLabel className={classes.label} htmlFor={`question${item.id}`}>{translations.QUESTION[locale.localeName]}</InputLabel>
                            <Input className={classes.input} value={trans[item.id-1]!==-1 ? trans[item.id-1] : ''} id={`question${item.id}`} name={`question${item.id}`}  onChange={this.handleChangeName} />
                            {transRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
                          </FormControl>
                        </section>

                        <section  className={classes.section} >
                          <Avatar src={"./images/flags/" + item.localeName + ".jpg"} style={{width: 30, height: 30, marginRight: 20,}} />
                          <FormControl margin="normal" fullWidth style={{marginTop: 8}}>
                            <InputLabel className={classes.label} htmlFor={`group${item.id}`}>{translations.GROUP[locale.localeName]}</InputLabel>
                            <Input className={classes.input} value={groupTrans[item.id-1]!==-1 ? groupTrans[item.id-1] : ''} id={`group${item.id}`} name={`group${item.id}`}  onChange={this.handleChangeName} />
                          </FormControl>
                        </section>

                        <section className={classes.section} >
                          <Avatar src={"./images/flags/" + item.localeName + ".jpg"} style={{width: 30, height: 30, marginRight: 20,}} />
                          <FormControl margin="normal" fullWidth style={{marginTop: 8}}>
                            <InputLabel className={classes.label} htmlFor={`tip${item.id}`}>{translations.TIP[locale.localeName]}</InputLabel>
                            <Input className={classes.input} value={tipTrans[item.id-1]!==-1 ? tipTrans[item.id-1] : ''} id={`tip${item.id}`} name={`tip${item.id}`}  onChange={this.handleChangeName} />
                          </FormControl>
                        </section>
                    </div>

                ))}
                <FormControl margin="normal" fullWidth style={{marginTop: 8, display: answerTypeDicId===1 || answerTypeDicId===2 ? 'block' : 'none'}}>
                            <InputLabel className={classes.label} htmlFor={`min`}>Min</InputLabel>
                            <Input className={classes.input} value={min} id={`min`} name={`min`}  onChange={this.handleChange} />
                            {isMinNumberError && <FormHelperText className={classes.helper}>{translations.VALUE_SHOULD_BE_NUMBER[locale.localeName]}</FormHelperText>}
                </FormControl>
                <FormControl margin="normal" fullWidth style={{marginTop: 8, display: answerTypeDicId===1 || answerTypeDicId===2 ? 'block' : 'none'}}>
                            <InputLabel className={classes.label} htmlFor={`max`}>Max</InputLabel>
                            <Input className={classes.input} value={max} id={`max`} name={`max`}  onChange={this.handleChange} />
                            {isMaxNumberError && <FormHelperText className={classes.helper}>{translations.VALUE_SHOULD_BE_NUMBER[locale.localeName]}</FormHelperText>}
                </FormControl>
              </form>
          </DialogContent>
          <DialogActions>
                  <Button
                    fullWidth
                    size="large"
                    className={classes.backButton}
                    onClick={() => {close(true)}}
                  >
                      {translations.GO_BACK[locale.localeName]}
                  </Button>
                  <Button
                    fullWidth
                    size="large"
                    className={classes.submitButton}
                    onClick={(event) => {this.questionPrepare(event)}}
                  >
                    {translations.SUBMIT[locale.localeName]}
                  </Button>
          </DialogActions>
      </Dialog>
    )

  }
}

export default withStyles(styles)(QuestionTemplate);
