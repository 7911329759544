import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {translations} from '../../dictionary/translations';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {ERROR_MODE} from "../../util";
import ErrorContainer from "../../containers/Error";
import {withRouter} from "react-router-dom";
import Loading from "../Loading";
import Error from "../Error";
import {style} from "../../styles/style.js";


const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: 400,
    height: 'auto',
    left: 'calc(50vw - 200px)',
    top: 'calc(50vh - 200px)',
    border: `3px solid ${style.gray}`,
    borderRadius: 20,
    paddingBottom: 20,
    '@media only screen and (max-width: 768px)' : {
          width: '70vw',
          left: '15vw',
    },
  },
  form: {
    width: '90%',
    marginTop: 10,
  },
  label: {
      color: `${style.violet} !important`,
  },
  input : {
      color: `${style.violet}`,
      '&:before' : {
        borderBottom: `2px solid ${style.rose_light} !important`,
      },
      '&:after' : {
        borderBottom: `2px solid ${style.rose_dark}`,
      },
  },
  backButton : {
    transition: 'background-color ease 0.5s',
    backgroundColor: style.gray,
    marginRight: '10%',
    color: 'white',
    outline: 'none',
    '&:hover' : {
      backgroundColor: style.gray_hover,
    },
  },
  submitButton : {
      transition: 'background-color ease 0.5s',
      outline: 'none',
      color: 'white',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
  select : {
    '&:before' : {
      borderBottom: `2px solid ${style.rose_light} !important`,
    },

    '&:after' : {
      borderBottom: `2px solid ${style.rose_dark}`,
    }
  },
};


export class FarmEdit extends React.Component {

  componentDidMount() {
    const { description } = this.props.location;
    this.setState({
      description: description
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.editFarmStatus.success === true && prevProps.editFarmStatus.loading === true) {
      this.handleEditFarmSuccess();
    }
  }

  editFarmPrepare = () => {
    const { editFarm, farmId } = this.props;
    const { description } = this.state;
    const body = {
      description : description
    }
    editFarm(JSON.stringify(body), farmId);
  }

  handleEditFarmSuccess = () => {
    const {resetEditFarm, history} = this.props;
    resetEditFarm();
    history.push('main-navigation');

  }

  handleChange = (event) => {
    this.setState({
      description: event.currentTarget.value
    })
  }

  state = {
    description: ''
  }

  render() {
    const { classes, history, locale, editFarmStatus, resetEditFarm } = this.props;
    const { description } = this.state;

    if (editFarmStatus.error === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={translations.ERROR_WHILE_UPLOADING_DATA}
                        callback={() => resetEditFarm()}/>
      )
    }

    if (editFarmStatus.loading === true) {
      return (
        <Loading/>
      )
    }

    return (
      <Paper className={classes.paper}>
        <form className={classes.form}>
          <FormControl margin="normal" required fullWidth>
            <TextField
              id="description"
              label={translations.DESCRIPTION[locale.localeName]}
              className={classes.input}
              onChange={this.handleChange}
              value={description}
              name="description"
              multiline
              rows="3"
            />
          </FormControl>
          <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20, outline: 'none'}}>
            <Button
              fullWidth
              size="large"
              className={classes.backButton}
              onClick={() => {history.goBack()}}
            >
              {translations.GO_BACK[locale.localeName]}
            </Button>
            <Button
              type="submit"
              fullWidth
              size="large"
              className={classes.submitButton}
              onClick={() => {this.editFarmPrepare()}}
            >
              {translations.SUBMIT[locale.localeName]}
            </Button>
          </div>
        </form>
      </Paper>
    )

  }
}

export default withRouter(withStyles(styles)(FarmEdit));
