import React from 'react';
import {connect} from "react-redux";
import actions from "../../../../actions";

export class QuestionGroupBarContainer extends React.Component {

  render() {
    const {
      Layout,
      locale,
      formId,
      questionList,
      setQuestionListId,
      getQuestionList,
      getSubFormList,
      setNextSubFormId,
      subFormId,
      questionListId,
      isReadOnly,
      addQuestionList,
      deleteQuestionList,
      setNextQuestionListId,
      isQuestionGroupInitial,
      prevQuestionList,
      modeAdmin,
      activateQuestionList,
      setAfterQuestionListAdding,
      afterQuestionListAdding,
      setAfterQuestionListEditing,
      getQuestionsAdmin,
      setAnswersForLastPen,
      answersForLastPen,
      choosenFarmCode,
      isSideListOpen
    } = this.props;

    return (
      <Layout
        locale={locale}
        formId={formId}
        questionList={questionList}
        setQuestionListId={setQuestionListId}
        getQuestionList={getQuestionList}
        getSubFormList={getSubFormList}
        setNextSubFormId={setNextSubFormId}
        subFormId={subFormId}
        questionListId={questionListId}
        isReadOnly={isReadOnly}
        addQuestionList={addQuestionList}
        deleteQuestionList={deleteQuestionList}
        setNextQuestionListId={setNextQuestionListId}
        isQuestionGroupInitial={isQuestionGroupInitial}
        prevQuestionList={prevQuestionList}
        modeAdmin={modeAdmin}
        activateQuestionList={activateQuestionList}
        setAfterQuestionListAdding={setAfterQuestionListAdding}
        afterQuestionListAdding={afterQuestionListAdding}
        setAfterQuestionListEditing={setAfterQuestionListEditing}
        getQuestionsAdmin={getQuestionsAdmin}
        setAnswersForLastPen={setAnswersForLastPen}
        answersForLastPen={answersForLastPen}
        choosenFarmCode={choosenFarmCode}
        isSideListOpen={isSideListOpen}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  formId : state.questionnaire.form.form.formId,
  subFormId : state.questionnaire.form.form.subFormId,
  questionListId : state.questionnaire.form.form.questionListId,
  choosenFarmCode : state.questionnaire.form.form.choosenFarmCode,
  questionList : state.questionnaire.form.questionList,
  isReadOnly : state.questionnaire.form.form.isAnswerReadOnly,
  isQuestionGroupInitial : state.questionnaire.form.form.isQuestionGroupInitial,
  prevQuestionList: state.questionnaire.form.questionList.prevQuestionList,
  afterQuestionListAdding : state.questionnaire.form.form.afterQuestionListAdding,
  answersForLastPen : state.questionnaire.form.questionList.answersForLastPen,
});

const mapDispatchToProps = {
  setQuestionListId: actions.questionnaire.form.form.setQuestionListId,
  setFarmId : actions.farm.farm.setFarmId,
  getQuestionList : actions.questionnaire.form.questionList.getQuestionList,
  getSubFormList : actions.questionnaire.form.subFormList.getSubFormList,
  setNextSubFormId : actions.questionnaire.form.subFormList.setNextSubFormId,
  setNextQuestionListId : actions.questionnaire.form.questionList.setNextQuestionListId,
  addQuestionList : actions.questionnaire.form.questionList.addQuestionList,
  deleteQuestionList : actions.questionnaire.form.questionList.deleteQuestionList,
  activateQuestionList : actions.questionnaire.form.questionList.activateQuestionList,
  setAfterQuestionListAdding : actions.questionnaire.form.form.setAfterQuestionListAdding,
  setAfterQuestionListEditing : actions.questionnaire.form.form.setAfterQuestionListEditing,
  getQuestionsAdmin : actions.questionnaire.form.questionsAdmin.getQuestions,
  setAnswersForLastPen : actions.questionnaire.form.questionList.setAnswersForLastPen,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionGroupBarContainer);
