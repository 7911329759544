export const texts = [
    {
        en: {
            title: "outdoor run",
            definition: "Pigs are housed in a building and can access an area with concrete (slatted) floor or permeable (= e.g. soil, gravel; liquid drains into ground) which has outdoor climatic conditions, has at least 1 completely open side (so that sun and wind can reach pigs). May be covered 100% by a roof.",
        }
    },{
        en: {
            title: "slatted, fully",
            definition: "The complete area of a pen is covered by perforated concrete or plastic flooring",
        }
    },{
        en: {
            title: "slatted, partly",
            definition: ">30 % of the pen area is covered by solid flooring (water tight surface), the other part by perforated flooring. Flooring with >5% of openings (e.g. drainage holes) = perforated.",
        }
    },{
        en: {
            title: "average, mean",
            definition: "statistical mean",
        }
    },{
        en: {
            title: "complete renewal",
            definition: "Substantial (= more than equipment inside) changes of buildings, or similar for solios etc.",
        }
    },{
        en: {
            title: "costs for maintenance of buildings or equipment",
            definition: "Will not be collected. Instead we will calculate 1.5% on the value of buildings or equipment, respectively.",
        }
    },{
        en: {
            title: "Exchange rate, local currency per 1 Euro",
            definition: "Based on 1 year mean January to December. http://sdw.ecb.europa.eu/",
        }
    },{
        en: {
            title: "Historical investment",
            definition: "Economic value of the first investment of a building. In the case of renovation please replace the original investment with the latest investment amount for each part of the renovated buildings.",
        }
    },{
        en: {
            title: "local taxes",
            definition: "Local taxes include taxes on land, on water distribution or protection bodies, municipality taxes. They do NOT include income taxes.",
        }
    },{
        en: {
            title: "social contributions",
            definition: "Social contributions are payments by the employer for each employee (hired or family) to the national pension service, outside the gross salaries. Key points: It's for people currently working on the farms and it's money which goes to the nation's social fund.",
        }
    },{
        en: {
            title: "Biogas produced in an anaerobic digester",
            definition: "An anaerobic digester (which is within a biogas plant) uses biodegradable material (e.g. a waste or maize silage, etc.), which is degraded by microorganisms, to produce bio-energy (and the co-product of a digestate).",
        }
    },{
        en: {
            title: "arable land with zero/ reduced tillage",
            definition: "Reduced or no tillage of arable land leads to a better soil environment (less soil disturbance). It prevents erosion and thereby reduces phosphorus and nitrogen loss. GHG emissions are decreased and reduced tillage diminishes the use of fuel per ha.",
        }
    },{
        en: {
            title: "direct seeding",
            definition: "Direct seeding, like no-till, is a cropping system which aims to improve soil and soil moisture conservation. Direct seeding allows some tillage to solve immediate weed problems and to deal with high moisture and heavy clay soil conditions. Direct seeding is to conserve soil moisture in the seedbed. Most of the crop residue remains on the surface with at least half the stubble remaining upright and anchored to trap as much snow as possible. The crop residues are needed for further soil fertility improvement.",
        }
    },{
        en: {
            title: "drained agricultural land on moorland",
            definition: "Drained peatlands are by far the biggest source of GHG emissions from crop land (nitrous oxide, carbon). Peatlands are habitats for very specialised plant and wildlife species and they have an important influence on local water balance.",
        }
    },{
        en: {
            title: "Ecological compensation areas",
            definition: "Ecological compensation areas create habitats for plants and wildlife. Examples are: extensively managed grassland, wildflower strips, crop-rotation fallow land, crop margins, wild herb borders, etc.",
        }
    },{
        en: {
            title: "GMO crops",
            definition: "Member States decide whether to prohibit or restrict the cultivation of authorized GMOs. At present only GMO-maize (MON810) is commercially cultivated in the EU, mainly in Spain, small areas in Portugal and Czech Republic, Slovakia. Worldwide cultivated GMOs are soya, maize, oilseed rape and cotton.",
        }
    },{
        en: {
            title: "Management of riparian (buffer) stripes",
            definition: "The management of riparian (buffer) stripes regarding cultivation (grassland, arable land etc.), use of pesticides and fertilisation is decisive to avoid erosion and the pollution of water with pesticides and fertilizers. Therefore legal obligations exist in EU states regarding the management of riparian stripes within a certain width (often 3 meters).",
        }
    },{
        en: {
            title: "manure",
            definition: "(farmyard) manure = solid faeces, with bedding where applicableoverall manure = any excrements, with bedding where applicableslurry = liquid manure",
        }
    },{
        en: {
            title: "Number of active substances used in insecticides/herbicides/fungicides",
            definition: "Plant protection products (e.g. insecticides, herbicides, fungicides etc.) contain at least one active substance. An active substance is any chemical, plant extract, pheromone or micro-organism that acts against plants or 'pests'. Active substances must be approved by the EC.",
        }
    },{
        en: {
            title: "permanent grasslands, leguminous grasslands in crop rotations and leguminous crops",
            definition: "The cultivation of leguminous grassland or leguminous crops enhances soil fertility through biological N-fixation. Leguminous crops improve crop rotation diversity and are an important source of protein for animal production.",
        }
    },{
        en: {
            title: "Promote on-farm biodiversity",
            definition: "On-farm measures to promote biodiversity can be: nesting boxes, insect hotels, piles of stones, dead wood, natural water bodies, etc.",
        }
    },{
        en: {
            title: "Rare / endangered crops",
            definition: "Problem for a list of rare and endangered crops: no European list! --> search at national level!",
        }
    },{
        en: {
            title: "SAFA",
            definition: "Sustainability Assessment of Food and Agriculture systems. Universal framework provided by the Food and Agriculture Organization of the United Nations (FAO)",
        }
    },{
        en: {
            title: "SMART",
            definition: "Sustainability Monitoring and Assessment RouTine. A tool for farms and food companies to assess and compare their sustainability based on SAFA Guidelines.",
        }
    },{
        en: {
            title: "Usage of mineral P- and K-fertilisers",
            definition: "Mineral P- and K-fertilizers can contain heavy metals (e.g. lead, cadmium, nickel, mercury, arsenic and uranium). Intensive fertilization can lead to accumulation in soil. Via plant uptake they end up in the food chain or pollute groundwater.",
        }
    },{
        en: {
            title: "building, room, pen",
            definition: "Building = largest contructional unit which separates pigs from the outside world (like a house)Room = sections within the building which are separated by floor-to-ceiling walls (air does not mix between rooms)Pen = sections within a room separated by grids or 'walls' which prevent pigs mixing between pens but allow free air flow across pens",
        }
    },{
        en: {
            title: "certification",
            definition: "Certified organic = complies with European organic standards. May include other organic standards. Conventional certified = labels / assurance schemes with quality criteria on top of national basic legislation.",
        }
    },{
        en: {
            title: "farm: breeder farms, finisher farms, combined farms",
            definition: "breeder farms: piglets are born on the farm; finisher farms: pigs are sold for slaughter from the farm; combined farms: all stages on one farm (piglets from birth to loading for slaughter)",
        }
    },{
        en: {
            title: "land",
            definition: "farm land = overall land area utilised by the farm, including agricultural or forest land, semi-natural structures such as hedgerows, water bodies within a farm's land, pile of stones and timber yards,...agricultural land = grasslands, pastures and arable land land used for production of food, feed, bio-energy and fibresarable land = land, which is ploughed at least every few years; (the land may have a specific status regarding legal possibilities and subsidies (see EU-related agricultural subsidies: Integrated Administration and Control System)",
        }
    },{
        en: {
            title: "Pasture",
            definition: "Pasture = grassland without permanent buildings (shelters ok). Also included that pigs spend only part of their time on fields.",
        }
    },{
        en: {
            title: "rare / endangered livestock breeds",
            definition: "Rare/endangered: everything else than hybrid sows, land race, large white and their crosses. For more information on rare breeds see: ELBARN = European Livestock Breeds Ark and Rescue Net; www.elbarn.net (with national contacts to institutions / lists for rare / endangered livestock breeds)",
        }
    },{
        en: {
            title: "slurry aeration",
            definition: "slurry aeration leads to (substantially) reduced CH4 emissions but to higher NH3 and N2O emissions; those aerators were introduced mainly in the 1990ies to improve the slurry quality, also about odour emissions; in general greenhouse gas emissions could be reduced by using aeration techniques (see e.g. http://www.boku.ac.at/fileadmin/data/H03000/H93000/H93100/AmonPublikationen/emissions_during_storage_slurry.pdf)",
        }
    },{
        en: {
            title: "total utilised agricultural area",
            definition: "Agricultural area of the entire farm utilised in 2017. Includes owned and rented land, pastures, arable crops and forests.",
        }
    },{
        en: {
            title: "group",
            definition: "Animals kept together in one pen or one paddock at one time. (Over time, several groups will be kept in the same pen.)",
        }
    },{
        en: {
            title: "litter (of suckling piglets)",
            definition: "Narrow sense: all piglets born by the same sow on the same day. Practical definition: all suckling piglets kept with one sow or weaned from one sow.",
        }
    },{
        en: {
            title: "Suitable sick pen",
            definition: "Suitable sick pen requires: soft lying area (rubber or bedding), lying area not wet or soiled, free access to water and feed, animals not shivering from cold. Available = free or occupied by sick pigs.",
        }
    },{
        en: {
            title: "pigs: (replacement) gilt",
            definition: "Female pig intended for breeding: until 1st insemination. Usually we mean this type of gilt when we only write gilt.",
        }
    },{
        en: {
            title: "pigs: gestating sow",
            definition: "Female after 1st insemination, pregnant or to be inseminated",
        }
    },{
        en: {
            title: "pigs: growing-finishing pig",
            definition: "Pigs raised for slaughter: includes growing pigs: ~ 25 - 50 kg, finishing pigs: 50 to 110 kg as well as heavy pigs: >110 kg",
        }
    },{
        en: {
            title: "pigs: lactating sows",
            definition: "Sows providing milk to their own or fostered suckling piglets",
        }
    },{
        en: {
            title: "pigs: sow",
            definition: "Breeding female from the very first insemination in her life, until death on the farm or sale for slaughter",
        }
    },{
        en: {
            title: "pigs: suckling piglets",
            definition: "Piglets living of milk (from mother, nurse sow or artificial nursing system), usually until ~ 7 kg (conventional) or ~ 15 kg (organic)",
        }
    },{
        en: {
            title: "pigs: weaner",
            definition: "First stage after weaning until moving into fattening unit (rearing period, 'weaned piglet'); conventional: ~ 7 to 30 kg (4-10 weeks); organic: ~ 15 to 30 kg",
        }
    },{
        en: {
            title: "suckling period (lactation)",
            definition: "Sow and piglet kept together",
        }
    },{
        en: {
            title: "weaning",
            definition: "sow and piglets are separated; conventional: ~ 7 kg, ~ 3 to 4 weeks old; organic: ~ 15 kg, ~ 6 weeks old",
        }
    },{
        en: {
            title: "Mortality (%)",
            definition: "Deaths as a % of the number of pigs entering the system (=number of pigs entering the system - number of pigs leaving the system / number of pigs entering the system). Relates to pigs on farms, and therefore excludes post-farm gate mortality, e.g. mortality in transport to abattoir, and discarded pigs at the abattoir.",
        }
    },{
        en: {
            title: "Litters/sow/year",
            definition: "The actual number of litters per sow in a 365-day period. This will therefore include “empty” or waste-feeding days. Based on mean present sows.",
        }
    },{
        en: {
            title: "Pigs born alive per litter",
            definition: "Excludes pigs born dead.",
        }
    },{
        en: {
            title: "Pigs born dead per litter",
            definition: "Pigs born dead per litter, excludes mummified pigs (approx. 0,3 per litter)",
        }
    },{
        en: {
            title: "Pigs reared per sow/year",
            definition: "Based on mean present sow. =Pigs weaned per sow/year * (1 - Rearing Mortality (%))",
        }
    },{
        en: {
            title: "Pigs sold per sow/year",
            definition: "Based on mean present sow. =Pigs weaned per sow/year * (1 - Rearing Mortality (%)) * (1 - Finishing Mortality (%))",
        }
    },{
        en: {
            title: "Pigs weaned per litter",
            definition: "Based on: Total wean for a period/ Total number of farrowings = excluding nurse sows. =IF (Pigs born alive per litter > 0;Pigs born alive per litter * (1 - Pre Weaning Mortality (%))",
        }
    },{
        en: {
            title: "Pigs weaned per sow/year",
            definition: "Based on mean present sow. =Pigs born alive per litter * (1 - Pre Weaning Mortality (%)) * Litters/sow/year",
        }
    },{
        en: {
            title: "Pre Weaning Mortality (%)",
            definition: "Deaths as a % of the number of pigs born alive, not the number leaving it.",
        }
    },{
        en: {
            title: "Rearing Mortality (%)",
            definition: "Deaths as a % of the number of pigs entering the system, not the number leaving it.",
        }
    },{
        en: {
            title: "Sow mortality (%)",
            definition: "Sows that die on the farm, and where no payment is received. As a % of mean present sows.",
        }
    },{
        en: {
            title: "Sow replacement rate (%)",
            definition: "Sow deaths plus sow culling during the year, as a % of mean present sows.",
        }
    },{
        en: {
            title: "Hormones",
            definition: "synchronise oestrus/birth: Hormones are used after weaning to induce oestrus with the aim to synchronise farrowingtrigger/induce birth: Hormones are used few days before expected farrowing date to plan farrowing or for sows which are overdue to farrowoxytocin during birth (not after): Hormones are used to support weak contractions during labour",
        }
    },{
        en: {
            title: "Antiparasitic treatments",
            definition: "includes treatments against endoparasites/worms and/or ectoparasites (e.g. lice, mange). Do NOT include complementary medicines (e.g. phytotherapy) here",
        }
    },{
        en: {
            title: "Antibiotic treatments",
            definition: `Giving antibiotics (incl. coccidiostatics) to one or several pigs (injection or oral application). General rule: If one animal is treated repeatedly ("course of treatment") for e.g. 3 consecutive days = one treatment; if more than 7 days between two doses= new treatment.Includes combinations with other drugs (e.g. antiinflammatory) when given at same time. How to assess? Optimally use medicine records of previous year (or half year):count all treatments per animal category for disease; calculate treatment incidence = (number of treatments/number of average number of pigs during last calender year)* 100If not possible, estimation this figure: "Remember the previous year- imagine typical batch – estimate percentage of treated animals for e.g. weaning diarrhoea…”allocate to appropriate category: 0, <10 <25 <75, >=75 and <100; 100`,
        }
    },{
        en: {
            title: "Slaughter remarks",
            definition: "Slaughter remarks are data (on e.g. pneumonia, abscess, arthritis, liver) sent by slaughterhouse on batch level usually in combination with data on weight/lean meat content..; How to assess? Use records from previous year (or alternatively half a year); 1) count number of pigs assessed at abattoir during this period; 2) count number of animals affected per slaughter remark (e.g. pneumonia, abscess, arthritis, liver)",
        }
    }
];