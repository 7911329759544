import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {translations} from '../../dictionary/translations';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {withRouter} from "react-router-dom";
import {ERROR_MODE, USER_ROLE_DIC_ID} from "../../util";
import ErrorContainer from "../../containers/Error";
import Error from "../Error";
import Loading from "../Loading";
import {style} from "../../styles/style.js";


const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: 400,
    height: 'auto',
    left: 'calc(50vw - 200px)',
    top: 'calc(50vh - 200px)',
    border: `3px solid ${style.gray}`,
    borderRadius: 20,
    paddingBottom: 20,
    '@media only screen and (max-width: 768px)' : {
          width: '70vw',
          left: '15vw',
    },
  },
  form: {
    width: '90%',
    marginTop: 10,
  },
  label: {
      color: `${style.violet} !important`,
  },
  submitButton : {
      transition: 'background-color ease 0.5s',
      color: 'white',
      outline: 'none',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
  backButton : {
    transition: 'background-color ease 0.5s',
    backgroundColor: style.gray,
    marginRight: '10%',
    color: 'white',
    outline: 'none',
    '&:hover' : {
      backgroundColor: style.gray_hover,
    },

    '@media only screen and (max-width: 600px)':{
      fontSize: style.mobile_font_size,
    }
  },
  select : {
    '&:before' : {
      borderBottom: `2px solid ${style.rose_light} !important`,
    },

    '&:after' : {
      borderBottom: `2px solid ${style.rose_dark}`,
    }
  },
  textField : {
  }
};

export class QuestionnaireAdd extends React.Component {

  componentDidMount() {
    const {getQuestionnaireDicList, locale, farmId, history, modeAdmin} = this.props;
    if (modeAdmin === false){
      if (!Number.isInteger(farmId)) {
        history.push('/');
      }
    }
    getQuestionnaireDicList(locale.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { history, questionnaireAdd, resetQuestionnaireAdding, resetFarm, setQuestionnaireId, setQuestionnaireDicId, questionnaireDicId, setQuestionnaireReadOnly, modeAdmin } = this.props;
    if (modeAdmin === false){
        if (questionnaireAdd.success === true && Number.isInteger(questionnaireAdd.data.id)) {
          resetQuestionnaireAdding();
          resetFarm();
          setQuestionnaireId(questionnaireAdd.data.id);
          setQuestionnaireDicId(questionnaireDicId);
          setQuestionnaireReadOnly(false);
          history.push('fill-questionnaire');
        }
    }
  }

  showQuestionnaireDicList = () => {
    const {locale, questionnaireDicList, user} = this.props;
    if (questionnaireDicList && questionnaireDicList.items && questionnaireDicList.items.length > 0 && locale) {
      return (questionnaireDicList.items.map(questionnaireDic => (
          <MenuItem style={questionnaireDic.name==='Long questionnaire' && (user.roleDicId !== USER_ROLE_DIC_ID.EKSPERT_NAUKOWY && user.roleDicId !== USER_ROLE_DIC_ID.ADMINISTRATOR) ? {display: 'none'} : {}} key={questionnaireDic.id} value={questionnaireDic.id}>{questionnaireDic.name}</MenuItem>
      )))
    } else {
      return (<MenuItem/>)
    }
  }

  addQuestionnairePrepare = () => {
    const { history, farmId, questionnaireDicId, user, addQuestionnaire, modeAdmin, setQuestionnaireDicId} = this.props;
    if (modeAdmin === false){
        const idUsers = user.id;
        const body = {
          farmId,
          questionnaireDicId,
          idUsers
        }
        if (Number.isInteger(questionnaireDicId) && Number.isInteger(farmId) && Number.isInteger(idUsers)) {
          addQuestionnaire(JSON.stringify(body));
        }
    }
    else if (modeAdmin === true){
        if (Number.isInteger(questionnaireDicId)){
            setQuestionnaireDicId(questionnaireDicId);
            history.push('edit-questionnaire-structure');
        }
    }
  }

  handleAddQuestionnaireError = () => {
    const {resetQuestionnaireAdding} = this.props;
    resetQuestionnaireAdding();
  }

  render() {
    const { classes, history, locale, questionnaireDicList, questionnaireAdd, questionnaireDicId, handleChange} = this.props;

    if (questionnaireDicList.loading === true || questionnaireAdd.loading === true) {
      return (
        <Loading/>
      )
    } else if (questionnaireDicList.error === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={translations.ERROR_WHILE_DOWNLOADING_DATA}
                        mode={ERROR_MODE.REFRESH}/>
      )
    } else if (questionnaireAdd.error === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={translations.ERROR_WHILE_UPLOADING_DATA}
                        callback={this.handleAddQuestionnaireError}/>
      )
    } else if (questionnaireDicList.success === true) {
      return (
        <Paper className={classes.paper}>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="questionnaire">{translations.QUESTIONNAIRE[locale.localeName]}</InputLabel>
              <Select
                className={classes.select}
                value={questionnaireDicId}
                onChange={handleChange}
                inputProps={{
                  name: 'questionnaireDicId',
                  id: 'questionnaire',
                }}
              >
                {this.showQuestionnaireDicList()}
              </Select>
            </FormControl>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20, outline: 'none'}}>
              <Button
                fullWidth
                size="large"
                className={classes.backButton}
                onClick={() => {history.goBack()}}
              >
                {translations.GO_BACK[locale.localeName]}
              </Button>
              <Button
                fullWidth
                size="large"
                className={classes.submitButton}
                onClick={() => {this.addQuestionnairePrepare()}}
              >
                {translations.SUBMIT[locale.localeName]}
              </Button>
            </div>
              </form>
        </Paper>
      )
    }
    return (<div/>)

  }
}

export default withStyles(styles)(
  withRouter(QuestionnaireAdd)
);
