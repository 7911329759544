import add from './add';
import remove from './remove';
import list from './list';
import {combineReducers} from "redux";
import form from "./form";

const index = {
  add,
  remove,
  list,
  form : combineReducers(form)
}

export default index;
