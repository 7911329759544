import {URL} from '../../config';
import {ADD_QUESTIONNAIRE_BEGIN} from "../../reducers/questionnaire/add";
import {
  REMOVE_QUESTIONNAIRE_BEGIN,
  REMOVE_QUESTIONNAIRE_FAILURE,
  REMOVE_QUESTIONNAIRE_RESET,
  REMOVE_QUESTIONNAIRE_SUCCESS
} from "../../reducers/questionnaire/remove";
import {
  REMOVE_FARM_BEGIN,
  REMOVE_FARM_FAILURE,
  REMOVE_FARM_RESET,
  REMOVE_FARM_SUCCESS
} from "../../reducers/farm/remove";

function removeFarm(id) {
  return dispatch => {
    dispatch(removeFarmBegin());
    return removeFarmStart(id)
      .then(() => {
        dispatch(removeFarmSuccess());
      })
      .catch(() => {
        dispatch(removeFarmFailure())
      });
  };
}


function removeFarmStart(id) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/farm/` + id, {
      method: 'DELETE',
      headers : {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
    }).then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json)
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const removeFarmBegin = () => ({
  type: REMOVE_FARM_BEGIN
});

const removeFarmSuccess = () => ({
  type: REMOVE_FARM_SUCCESS
});

const removeFarmFailure = () => ({
  type: REMOVE_FARM_FAILURE
});

const resetState = () => ({
  type: REMOVE_FARM_RESET
});

const index = {
  removeFarm,
  resetState
}

export default index;
