import {URL} from '../../../config';
import {
  GET_FORM_LIST_BEGIN,
  GET_FORM_LIST_FAILURE,
  GET_FORM_LIST_RESET,
  GET_FORM_LIST_SUCCESS, 
  SET_NEXT_FORM_ID,
  GET_FORM_LIST_BEGIN_DIC,
  GET_FORM_LIST_FAILURE_DIC,
  GET_FORM_LIST_RESET_DIC,
  GET_FORM_LIST_SUCCESS_DIC, 
  SET_NEXT_FORM_ID_DIC
} from "../../../reducers/questionnaire/form/list";

// COMMON USER

function getFormList(localeDicId, questionnaireId, modeAdmin) {
  return dispatch => {
    dispatch(setBegin());
    return getFormListStart(localeDicId, questionnaireId, modeAdmin)
      .then((data) => {
        dispatch(setSuccess(data));
      })
      .catch(() => {
        dispatch(setFailure())
      });
  };
}

const setBegin = () => ({
  type: GET_FORM_LIST_BEGIN
});

function getFormListStart(localeDicId, questionnaireId, modeAdmin) {
  return new Promise((resolve, reject) => {
    fetch(
      modeAdmin !== true ? 
      URL + `/secured/questinnaire/form/` + questionnaireId + '?localeDicId=' + localeDicId
      : 
      URL + `/secured/form/list?questionnaireDicId=${questionnaireId}` , {
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
       },
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data)
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      })
  });
}

const setSuccess = (data) => ({
  type: GET_FORM_LIST_SUCCESS,
  data: data
});

const setFailure = () => ({
  type: GET_FORM_LIST_FAILURE
});

const resetState = () => ({
  type: GET_FORM_LIST_RESET
});

const setNextFormId = (id) => ({
  type: SET_NEXT_FORM_ID,
  nextFormId : id
});

// ADMIN

function getFormList_DIC(localeDicId, questionnaireId) {
  return dispatch => {
    dispatch(setBegin_DIC());
    return getFormListStart_DIC(localeDicId, questionnaireId)
      .then((data) => {
        dispatch(setSuccess_DIC(data));
      })
      .catch(() => {
        dispatch(setFailure_DIC())
      });
  };
}

const setBegin_DIC = () => ({
  type: GET_FORM_LIST_BEGIN_DIC
});

function getFormListStart_DIC(localeDicId) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/form/list?localeDicId=${localeDicId}`, {
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
       },
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data)
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      })
  });
}

const setSuccess_DIC = (data) => ({
  type: GET_FORM_LIST_SUCCESS_DIC,
  data_DIC: data
});

const setFailure_DIC = () => ({
  type: GET_FORM_LIST_FAILURE_DIC
});

const resetState_DIC = () => ({
  type: GET_FORM_LIST_RESET_DIC
});

const setNextFormId_DIC = (id) => ({
  type: SET_NEXT_FORM_ID_DIC,
  nextFormId_DIC : id
});

const index = {
  getFormList,
  setNextFormId,
  resetState,
  getFormList_DIC,
  setNextFormId_DIC,
  resetState_DIC,
}

export default index;
