import React from 'react';
import {connect} from "react-redux";
import actions from "../../../actions";
import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SAVE_STATUS, SUCCESS_STATUS} from "../../../util";
import {URL} from "../../../config";

export class QuestionListTemplateContainer extends React.Component {

  state = {
    swapStatus : INITIAL_STATUS,
    addStatus : INITIAL_STATUS,
    editStatus : INITIAL_STATUS,
    getStatus : INITIAL_STATUS,
    items: null,
  }

  OPERATION = {
    SWAP_QUESTION_LIST : 'swapQuestionList',
    ADD_QUESTION_LIST : 'addQuestionList',
    EDIT_QUESTION_LIST: 'editQuestionList',
    GET_QUESTION_LIST: 'getQuestionList',
  }

  setBegin = (operation) => {
    switch (operation) {
      case this.OPERATION.SWAP_QUESTION_LIST:
        this.setState({
          swapStatus : BEGIN_STATUS,
        })
        break;
      case this.OPERATION.ADD_QUESTION_LIST:
        this.setState({
          addStatus : BEGIN_STATUS
        })
        break;
      case this.OPERATION.EDIT_QUESTION_LIST:
        this.setState({
          editStatus : BEGIN_STATUS
        })
        break;
      case this.OPERATION.GET_QUESTION_LIST:
        this.setState({
          getStatus : BEGIN_STATUS
        })
        break;
      default: return null;
    }
  };

  setSuccess = (operation, data) => {
    switch (operation) {
      case this.OPERATION.SWAP_QUESTION_LIST:
        this.setState({
          swapStatus : SUCCESS_STATUS,
        })
        break;
      case this.OPERATION.ADD_QUESTION_LIST:
        this.setState({
          addStatus : SUCCESS_STATUS
        })
        break;
      case this.OPERATION.EDIT_QUESTION_LIST:
        this.setState({
          editStatus : SUCCESS_STATUS,
        })
        break;
      case this.OPERATION.GET_QUESTION_LIST:
        this.setState({
          getStatus : SUCCESS_STATUS,
          items: data
        })
        break;
      default: return null;
    }
  };

  setFailure = (errorType, operation) => {
    switch (operation) {
      case this.OPERATION.SWAP_QUESTION_LIST:
        this.setState({
          swapStatus : FAILURE_STATUS,
        })
        break;
      case this.OPERATION.ADD_QUESTION_LISTN:
        this.setState({
          addStatus : FAILURE_STATUS,
        })
        break;
      case this.OPERATION.EDIT_QUESTION_LIST:
        this.setState({
          editStatus : FAILURE_STATUS,
        })
        break;
      case this.OPERATION.GET_QUESTION_LIST:
        this.setState({
          getStatus : FAILURE_STATUS,
        })
        break;
      default: return null;
    }
  };

  
  swapQuestionList = () => {
    this.setBegin(this.OPERATION.SWAP_QUESTION_LIST);
    this.swapQuestionListStart()
      .then(() => {
        this.setSuccess(this.OPERATION.SWAP_QUESTION_LIST);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.SWAP_QUESTION_LIST);
      });
  }

  swapQuestionListStart = () => {
    const { questionId, locale } = this.props;
    return new Promise((resolve, reject) => {
      fetch(URL + `/farm/farmTypeList`
      )
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve()
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  addQuestionList = (body) => {
    this.setBegin(this.OPERATION.ADD_QUESTION_LIST);
    this.addQuestionListStart(body)
      .then(() => {
        this.setSuccess(this.OPERATION.ADD_QUESTION_LIST);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.ADD_QUESTION_LIST);
      });
  }

  addQuestionListStart = (body) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/questionlist/dic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        },
        body: body
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve()
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  getQuestionList = (id) => {
    this.setBegin(this.OPERATION.GET_QUESTION_LIST);
    this.getQuestionListStart(id)
      .then((data) => {
        this.setSuccess(this.OPERATION.GET_QUESTION_LIST, data);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.GET_QUESTION_LIST);
      });
  }

  getQuestionListStart = (id) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/questionlist/dic/${id}`, {
        method: 'GET',
        headers: {
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        },
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve(json.data)
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  editQuestionList = (body) => {
    this.setBegin(this.OPERATION.EDIT_QUESTION_LIST);
    this.editQuestionListStart(body)
      .then(() => {
        this.setSuccess(this.OPERATION.EDIT_QUESTION_LIST);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.EDIT_QUESTION_LIST);
      });
  }

  editQuestionListStart = (body) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/questionlist/dic`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        },
        body: body
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve()
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  render() {
    const { 
      Layout,
      mode,
      locale,
      close,
      selectedQuestionList,
      getFarmTypeList,
      farmTypeList,
      subFormId,
      localeList,
      addOrderKey,
      qlNumberRigid
    } = this.props;

    const {
      addStatus,
      getStatus,
      items,
      editStatus
    } = this.state;

    return (
      <Layout
          mode={mode}
          locale={locale}
          close={close}
          questionListId={selectedQuestionList}
          farmTypeList={farmTypeList}
          getFarmTypeList={getFarmTypeList}
          subFormId={subFormId}
          addStatus={addStatus}
          addQuestionList={this.addQuestionList}
          localeList={localeList}
          getQuestionList={this.getQuestionList}
          getStatus={getStatus}
          items={items}
          editQuestionList={this.editQuestionList}
          editStatus={editStatus}
          addOrderKey={addOrderKey}
          qlNumberRigid={qlNumberRigid}
      />
    )
  }
}

const mapStateToProps = state => ({
  farmTypeList: {
    loading: state.dictionary.farmTypeList.loading,
    error: state.dictionary.farmTypeList.error,
    success: state.dictionary.farmTypeList.success,
    items: state.dictionary.farmTypeList.items,
  },
  localeList: {
    loading: state.dictionary.localeList.loading,
    error: state.dictionary.localeList.error,
    success: state.dictionary.localeList.success,
    items: state.dictionary.localeList.items,
  },

});

const mapDispatchToProps = {
  getFarmTypeList: actions.dictionary.farmTypeList.getFarmTypeList,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionListTemplateContainer);
