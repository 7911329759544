import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {translations} from "../../dictionary/translations";
import {withRouter} from "react-router-dom";
import LogoImage from '../../img/logo.jpg'
import {style} from "../../styles/style.js";
import Image from 'material-ui-image'
import {Link} from "react-router-dom";
import DescriptionIcon from '@material-ui/icons/Description';
import {createMuiTheme} from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Dictionary from './Dictionary';
import Instruction from './Instruction';
import PDF_instruction from '../../instruction/Instruction_simplified.pdf';

const theme = createMuiTheme({
  typography: {
      fontSize: 11,
  },
});

const styles = {
  root:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  buttonInstruction:{
    marginRight: 20,
    backgroundColor: 'white',
    paddingRight: 3,
    '@media only screen and (max-width: 768px)' : {
      fontSize: 11,
      marginBottom: 10,
    },
  },
  icon: {
    '@media only screen and (max-width: 768px)' : {
      width: 20,
      marginRight: 5,
    },
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 500,
    height: 60,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    '@media only screen and (max-width: 768px)' : {
      width: 300,
    },
    '@media only screen and (max-width: 500px)' : {
      width: '90%',
    },
  },
  email: {
      color: style.violet,
      textAlign: 'center',
      paddingRight: 3,
      paddingLeft: 10,
      '@media only screen and (max-width: 768px)' : {
        fontSize: 11,
      },
  },
  panelButtons: {
    '@media only screen and (max-width: 768px)' : {
      display: 'none',
     },
  },
  panelButtonsMobile: {
    display: 'none',
    '@media only screen and (max-width: 768px)' : {
      display: 'block',
     },
  },
  button : {
    fontSize: 11,
    flexGrow: 1,
    padding: 5,
    marginRight: 2,
    marginLeft: 2,
    border: `1px solid ${style.rose_dark}`,
    color: style.violet,
    '&:hover' : {
      backgroundColor: style.rose_light,
    },
  },
  logo : {
    marginLeft: 20,
    width: 40,
    '@media only screen and (max-width: 768px)' : {
      display: 'none',
     },
  },
};

export class UserPanel extends React.Component {

  handleLogOut = () => {
    const { history, resetUser, resetLocale } = this.props;
    resetUser();
    resetLocale();
    history.push('/');
  }

  state={
    anchorEl: null,
    isDictionaryOpen: false,
    isInstructinOpen: false,
  };


  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCloseInstructionOrDictionary = () => {
    this.setState({isInstructionOpen : false, isDictionaryOpen: false})
  }


  render() {
    const { locale, classes, user } = this.props;
    const { anchorEl, isInstructionOpen, isDictionaryOpen } = this.state;
    if (locale && classes && user) {
      return (
        <div className={classes.root}>
            {isDictionaryOpen ?
            <Dictionary isOpen={isDictionaryOpen} closeFunction={this.handleCloseInstructionOrDictionary} locale={locale} />
            :
            null}
            {isInstructionOpen ?
            <Instruction isOpen={isInstructionOpen} closeFunction={this.handleCloseInstructionOrDictionary} locale={locale} />
            :
            null}
            <div>
                  <Button  variant="outlined" size="medium" className={[classes.buttonInstruction, classes.button].join(' ')} onClick={() => this.setState({isDictionaryOpen : true})}>
                          <DescriptionIcon className={classes.icon}/>
                          {translations.DICTIONARY[locale.localeName]}
                  </Button>
                  <Button  variant="outlined" size="medium" className={[classes.buttonInstruction, classes.button].join(' ')} onClick={() => this.setState({isInstructionOpen : true})}>
                          <DescriptionIcon className={classes.icon}/>
                          {translations.INSTRUCTION[locale.localeName]}
                  </Button>
                  <a href={PDF_instruction} target='_blank'>
                      <Button  variant="outlined" size="medium" className={[classes.buttonInstruction, classes.button].join(' ')}>
                          <DescriptionIcon className={classes.icon}/>
                      </Button>
                  </a>
            </div>
            <Paper className={classes.paper}>
                  <div className={classes.email}>{user.email}</div>
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} >
                      <div className={classes.panelButtons}>
                          <Link to="/change-password">
                            <Button variant="outlined" color="primary" size="small" className={classes.button} >
                              {translations.CHANGE_PASSWORD[locale.localeName]}
                            </Button>   
                          </Link>
                          <Button variant="outlined" color="primary" size="small" className={classes.button} onClick={() => {this.handleLogOut()}}>
                            {translations.LOGOUT[locale.localeName]}
                          </Button>
                        </div>
                        <div className={classes.panelButtonsMobile}>
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                                <MenuIcon />
                            </Button>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={this.handleClose}
                            >
                              <MenuItem ><Link to="/change-password" style={{color: 'black'}}>{translations.CHANGE_PASSWORD[locale.localeName]}</Link></MenuItem>
                              <MenuItem onClick={() => {this.handleLogOut()}} >{translations.LOGOUT[locale.localeName]}</MenuItem>
                            </Menu>
                        </div>
                        <div className ={classes.logo}>
                              <Image disableTransition={true} src={LogoImage} alt='logo SusPigSys' />
                        </div>
                    </div>
            </Paper>
        </div>
      )
    } else {
      return (
        <div/>
      )
    }

  }
}

export default withRouter(withStyles(styles)(UserPanel));
