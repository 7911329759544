export let  style = {
  rose_light: 'rgb(249,208,221)',
  rose_light_hover: 'rgb(246, 188, 206)',
  rose_dark: 'rgb(212,97,138)',
  rose_dark_hover: 'rgb(206,75,121)',
  violet: 'rgb(113,116,142)',
  gray: 'rgb(152,152,152)',
  light_gray: 'rgb(220, 220, 220)',
  gray_hover: 'rgb(180,180,180)',
  blue_dark: 'rgb(89, 142, 174)',
  tablet_width: 768,
};

