import React from 'react';
import {connect} from "react-redux";
import actions from "../../../actions";

export class QuestionListRemovalContainer extends React.Component {

  render() {

    const {
      Layout,
      locale,
      deleteQuestionList,
      questionListName,
      questionListIdToRemove,
      closeDeleteDialog
    } = this.props;

    return (
      <Layout
        locale={locale}
        deleteQuestionList={deleteQuestionList}
        questionListName={questionListName}
        questionListIdToRemove={questionListIdToRemove}
        closeDeleteDialog={closeDeleteDialog}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale
});

const mapDispatchToProps = {
  deleteQuestionList : actions.questionnaire.form.questionList.deleteQuestionList,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionListRemovalContainer);
