import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SUCCESS_STATUS} from "../../../util";

export const GET_FORM_LIST_BEGIN = 'GET_FORM_LIST_BEGIN';
export const GET_FORM_LIST_SUCCESS = 'GET_FORM_LIST_SUCCESS';
export const GET_FORM_LIST_FAILURE = 'GET_FORM_LIST_FAILURE';
export const GET_FORM_LIST_RESET = 'GET_FORM_LIST_RESET';
export const SET_NEXT_FORM_ID = 'SET_NEXT_FORM_ID';

export const GET_FORM_LIST_BEGIN_DIC = 'GET_FORM_LIST_BEGIN_DIC';
export const GET_FORM_LIST_SUCCESS_DIC = 'GET_FORM_LIST_SUCCESS_DIC';
export const GET_FORM_LIST_FAILURE_DIC = 'GET_FORM_LIST_FAILURE_DIC';
export const GET_FORM_LIST_RESET_DIC = 'GET_FORM_LIST_RESET_DIC';
export const SET_NEXT_FORM_ID_DIC = 'SET_NEXT_FORM_ID_DIC';

export const initialState = {
  //COMMON USER
  ...INITIAL_STATUS,
  data : null,
  nextFormId : null,
  //ADMIN
  loading_DIC: false,
  error_DIC: false,
  success_DIC: false,
  data_DIC : null,
  nextFormId_DIC : null
};


export default function appReducer(state = initialState, action) {
  switch (action.type) {
    //COMMON USER
    case GET_FORM_LIST_BEGIN: {
      return {
        ...state,
        ...BEGIN_STATUS,
        data: null,
      };
    }
    case GET_FORM_LIST_SUCCESS: {
      return {
        ...state,
        ...SUCCESS_STATUS,
        data: action.data
      };
    }
    case GET_FORM_LIST_FAILURE: {
      return {
        ...state,
        ...FAILURE_STATUS,
        data: null
      };
    }
    case SET_NEXT_FORM_ID: {
      return {
        ...state,
        nextFormId : action.nextFormId
      };
    }
    case GET_FORM_LIST_RESET: {
      return initialState;
    }
    // ADMIN
    case GET_FORM_LIST_BEGIN_DIC: {
      return {
        ...state,
        loading_DIC: true,
        error_DIC: false,
        success_DIC: false,
        data_DIC: null,
      };
    }
    case GET_FORM_LIST_SUCCESS_DIC: {
      return {
        ...state,
        loading_DIC: false,
        error_DIC: false,
        success_DIC: true,
        data_DIC: action.data_DIC
      };
    }
    case GET_FORM_LIST_FAILURE_DIC: {
      return {
        ...state,
        loading_DIC: false,
        error_DIC: true,
        success_DIC: false,
        data_DIC: null
      };
    }
    case SET_NEXT_FORM_ID_DIC: {
      return {
        ...state,
        nextFormId_DIC : action.nextFormId_DIC
      };
    }
    case GET_FORM_LIST_RESET_DIC: {
      return initialState;
    }
    default:
      return state;
  }
}
