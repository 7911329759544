import {URL} from '../../config';

function getCountryList(localeDicId) {
  return dispatch => {
    dispatch(fetchCountryListBegin());
    return fetchCountryListStart(localeDicId)
      .then(data => {
        dispatch(fetchCountryListSuccess(data));
      })
      .catch(() => {
        dispatch(fetchCountryListFailure())
      });
  };
}

const fetchCountryListBegin = () => ({
  type: 'FETCH_COUNTRY_LIST_BEGIN'
});

function fetchCountryListStart(localeDicId) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/country/list/` + localeDicId)
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const fetchCountryListSuccess = countryList => ({
  type: 'FETCH_COUNTRY_LIST_SUCCESS',
  items: countryList
});

const fetchCountryListFailure = () => ({
  type: 'FETCH_COUNTRY_LIST_FAILURE',
  error: true
});

const resetState = () => ({
  type: 'FETCH_COUNTRY_LIST_RESET'
});

const index = {
  getCountryList,
  resetState
}

export default index;
