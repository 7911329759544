import React from 'react';
import {connect} from "react-redux";

export class UserTemplateContainer extends React.Component {

  render() {

    const {
      Layout,
      children,
      pageTitle,
      user
    } = this.props;

    return (
      <Layout
        children={children}
        pageTitle={pageTitle}
        user={user}
      />
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.login.user,
});

export default connect(mapStateToProps)(UserTemplateContainer);
