import {URL} from '../../../config';
import {
  GET_OPTIONAL_QUESTION_LIST_BEGIN,
  GET_OPTIONAL_QUESTION_LIST_FAILURE, GET_OPTIONAL_QUESTION_LIST_RESET,
  GET_OPTIONAL_QUESTION_LIST_SUCCESS,
  SAVE_OPTIONAL_QUESTION_LIST_BEGIN,
  SAVE_OPTIONAL_QUESTION_LIST_FAILURE, SAVE_OPTIONAL_QUESTION_LIST_RESET,
  SAVE_OPTIONAL_QUESTION_LIST_SUCCESS
} from "../../../reducers/questionnaire/form/optionalQuestionList";

const OPERATION = {
  GET : 'get',
  SAVE : 'save'
}

function getQuestionList(localeDicId, subFormId) {
  return dispatch => {
    dispatch(setBegin(OPERATION.GET));
    return getQuestionListStart(localeDicId, subFormId)
      .then((data) => {
        dispatch(setSuccess(OPERATION.GET, data));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.GET))
      });
  };
}

function getQuestionListStart(localeDicId, subFormId) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/questionlist/optional/` + subFormId + '?localeDicId=' + localeDicId, {
      method: 'GET',
      headers: {
       "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data)
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

function saveQuestionList(body) {
  return dispatch => {
    dispatch(setBegin(OPERATION.SAVE));
    return saveQuestionListStart(body)
      .then(() => {
        dispatch(setSuccess(OPERATION.SAVE));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.SAVE))
      });
  };
}

function saveQuestionListStart(body) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/questionlist/saveFilledQuestionListConfig`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setBegin = (operation) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_OPTIONAL_QUESTION_LIST_BEGIN
      }
    case OPERATION.SAVE:
      return {
        type: SAVE_OPTIONAL_QUESTION_LIST_BEGIN
      }
    default: return null;
  }
};

const setSuccess = (operation, data) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_OPTIONAL_QUESTION_LIST_SUCCESS,
        data: data
      }
    case OPERATION.SAVE:
      return {
        type: SAVE_OPTIONAL_QUESTION_LIST_SUCCESS
      }
    default: return null;
  }
};

const setFailure = (operation) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_OPTIONAL_QUESTION_LIST_FAILURE
      }
    case OPERATION.SAVE:
      return {
        type: SAVE_OPTIONAL_QUESTION_LIST_FAILURE
      }
    default: return null;
  }
};

const resetGetQuestionList = () => ({
  type: GET_OPTIONAL_QUESTION_LIST_RESET
});

const resetSaveQuestionList = () => ({
  type: SAVE_OPTIONAL_QUESTION_LIST_RESET
});

const index = {
  getQuestionList,
  saveQuestionList,
  resetGetQuestionList,
  resetSaveQuestionList
}

export default index;
