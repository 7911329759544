import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Loading from "../Loading";
import Error from '../Error'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import {translations} from '../../dictionary/translations';
import {ERROR_MODE} from "../../util";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import ErrorContainer from "../../containers/Error";
import {style} from "../../styles/style.js";
import {withRouter} from "react-router-dom";
import QuestionBox from "../QuestionBox";
import TableSortLabel from '@material-ui/core/TableSortLabel';

const styles = {
  paper : {
    position: 'relative',
    marginBottom: 30,
    borderTopRightRadius: 20,
    overflowX:'auto'
  },
  tableHeader : {
    textAlign: 'center',
    fontWeight: 'bold',
    paddingLeft: 3,
    paddingRight: 3,
  },
  tableCell : {
    textAlign: 'center',
    color: style.violet,
    paddingLeft: 3,
    paddingRight: 3,
    paddingRight: 3,
    '@media only screen and (max-width: 768px)' : {
      fontSize: 11,
    },
  },
  button : {
    flexBasis: '20%',
    color: style.violet,
    fontSize: 10,
    backgroundColor: 'white',
    transition: 'background-color ease 0.5s, color ease 0.5s',
    '&:hover' : {
      color: 'white',
    },
    '@media only screen and (max-width: 768px)' : {
      fontSize: 8,
    },
  },
  buttonActivate: {
    border: `2px solid #ff6666`,
    '&:hover' : {
      backgroundColor: '#ff6666',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
};

export class UsersList extends React.Component {

  getUserList = () => {
    const {pagination, getUserList, searchText} = this.props;
    const {order, orderBy} = this.state;
    getUserList(0, pagination.rowsPerPage, searchText, orderBy, order);
  }

  componentDidMount() {
    this.getUserList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { searchText, activateUserStatus} = this.props;
    const {order, orderBy} = this.state;

    if (activateUserStatus.success === true && prevProps.activateUserStatus.loading === true) {
      this.getUserList();
      this.setIsActivateDialogOpen(false);
    }
    
  }

  resetError = () => {
    const { resetGetUserList, resetActivateUser } = this.props;
    resetGetUserList();
    resetActivateUser();

  }

  state = {
    isActivateDialogOpen : false,
    userIdToActivate : '',
    activate: false,
    order: 'asc',
    orderBy: 'email',
  }

  setIsActivateDialogOpen = (isOpen) => {
    this.setState({
      isActivateDialogOpen : isOpen
    })
  }

  setUserIdToActivate = (id, activate) => {
    this.setState({
      userIdToActivate : id,
      activate : activate,
    })
  }

  onRequestSort = (event, property) => {
    const {changeOrder} = this.props;
    const {orderBy, order} = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({
      order : isDesc ? 'asc' : 'desc',
      orderBy : property
    }, () => {
      const {orderBy, order} = this.state;
      changeOrder(order, orderBy);
    });

  };

  createSortHandler = property => event => {
    this.onRequestSort(event, property);
  };

  render() {
    const { dictionary, classes, locale, loading, error, success, items, pagination, history, user, activateUser, activateUserStatus } = this.props;
    const { userIdToActivate, isActivateDialogOpen, activate, order, orderBy } = this.state;


    if (error === true || activateUserStatus.error === true) {
      return (
        <ErrorContainer Layout={Error} mode={ERROR_MODE.REFRESH} content={translations.ERROR_WHILE_DOWNLOADING_DATA} callback={() => {this.resetError()}}/>
      )
    }
    if (loading === true || activateUserStatus.loading === true) {
      return (
        <Loading/>
      )
    }
    if (success === true) {
      return (
        <div>
          <Paper className={classes.paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader} key='email' sortDirection={orderBy === 'email' ? order : false}>
                    <TableSortLabel
                          active={orderBy === 'email'}
                          direction={order}
                          onClick={this.createSortHandler('email')}
                        >
                          {translations.E_MAIL_ADDRESS[locale.localeName]}
                          {orderBy === 'email' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableHeader} key='roleDicId' sortDirection={orderBy === 'roleDicId' ? order : false}>
                    <TableSortLabel
                          active={orderBy === 'roleDicId'}
                          direction={order}
                          onClick={this.createSortHandler('roleDicId')}
                        >
                          {translations.USER_TYPE[locale.localeName]}
                          {orderBy === 'roleDicId' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                    </TableSortLabel>
                  </TableCell>
                  {/*
                  <TableCell className={classes.tableHeader} key='questionnaireCount' sortDirection={orderBy === 'questionnaireCount' ? order : false}>
                    <TableSortLabel
                          active={orderBy === 'questionnaireCount'}
                          direction={order}
                          onClick={this.createSortHandler('questionnaireCount')}
                        >
                          {translations.QUESTIONNAIRE_COUNT[locale.localeName]}
                          {orderBy === 'questionnaireCount' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                    </TableSortLabel>
                  </TableCell>
                  */}
                  <TableCell className={classes.tableHeader}>
                    {translations.QUESTIONNAIRE_COUNT[locale.localeName]}
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    {translations.ACTIONS[locale.localeName]}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <UserListData
                  dictionary={dictionary}
                  classes={classes}
                  items={items}
                  locale={locale}
                  history={history}
                  user={user}
                  activateUser={activateUser}
                  setIsActivateDialogOpen={this.setIsActivateDialogOpen}
                  setUserIdToActivate={this.setUserIdToActivate}
                  order={order}
                  orderBy={orderBy}
                />
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    rowsPerPage={pagination.rowsPerPage}
                    count={pagination.total}
                    page={pagination.page}
                    onChangePage={pagination.handleChangePage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
          {isActivateDialogOpen === true ? <QuestionBox 
                  title={activate===false ? translations.USER_DEACTIVATION[locale.localeName] : translations.USER_ACTIVATION[locale.localeName]}
                  content={activate===false ? translations.ARE_YOU_SURE_TO_DEACTIVATE_THE_USER[locale.localeName] : translations.ARE_YOU_SURE_TO_ACTIVATE_THE_USER[locale.localeName]}
                  leftButtonText={translations.CANCEL[locale.localeName]}
                  rightButtonText={activate===false ? translations.DEACTIVATE_THE_USER[locale.localeName] : translations.ACTIVATE_THE_USER[locale.localeName]}
                  leftButtonCallback={() => this.setIsActivateDialogOpen(false)}
                  rightButtonCallback={() => activateUser(userIdToActivate, activate)}
          /> : false}
        
        </div>
      )
    }
    return ( <div/> )
  }
}


const handleActivate = (userIdToActivate, activate, setIsActivateDialogOpen, setUserIdToActivate) => {
  setUserIdToActivate(userIdToActivate, activate);
  setIsActivateDialogOpen(true);
}


class UserListData extends React.Component {

  state = {
    newItems: []
  }

  componentDidMount(){
    const {items, user, dictionary } = this.props;
    const arr = items.map(el => {
      if (dictionary) el.userType = dictionary.userRoleList[el.roleDicId-1].translations[user.localeDicId-1].text;
      return el;
    })
    this.setState({newItems : arr});
  }

  /*
  desc = (a, b, orderBy) => {
    if (typeof(a[orderBy]) !== 'string' && typeof(b[orderBy]) !== 'string'){
      a = a[orderBy];
      b = b[orderBy];
    }
    else{
      a = a[orderBy].toLowerCase();
      b = b[orderBy].toLowerCase();
    }

    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  }
  
  stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }
  */
  render(){
    const {dictionary, classes, items, locale, setIsActivateDialogOpen, setUserIdToActivate, user, order, orderBy } = this.props;
    const {newItems} = this.state;

      return (
        newItems.map(item => (
            <TableRow hover key={item.userId}>
              <TableCell className={classes.tableCell}component="th" scope="row">
                {item.email}
              </TableCell>
              <TableCell className={classes.tableCell} component="th" scope="row">
                {item.userType}
              </TableCell>
              <TableCell className={classes.tableCell} component="th" scope="row">
                {item.questionnaireCount}
              </TableCell>
              <TableCell className={classes.tableCell}>
                { item.isSuperUser === false ? 
                <Button 
                    variant="outlined"  
                    size="small" 
                    className={[classes.button, classes.buttonActivate].join(' ')} 
                    onClick={() => {handleActivate(item.userId, item.isActive === true ? false : true, setIsActivateDialogOpen, setUserIdToActivate)}}>
                    {item.isActive === true ? translations.DEACTIVATE[locale.localeName] : translations.ACTIVATE[locale.localeName]}
                </Button>
                : null }
              </TableCell>
            </TableRow>
        ))
      )
  }
};



export default withRouter(withStyles(styles)(UsersList));
