import React from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {translations} from '../../dictionary/translations';
import {generalTexts, QandATexts} from '../../dictionary/instructionTranslations';
import ListSubheader from '@material-ui/core/ListSubheader';
import {style} from "../../styles/style.js";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: `${style.rose_dark}`,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  subHeader: {
      fontSize: 18,
      position: 'relative',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const {isOpen, closeFunction, locale} = props;
  const classes = useStyles();
 
  const handleClose = () => {
    closeFunction();
  };


  return (
    <div>
      <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                {translations.INSTRUCTION[locale.localeName]}
            </Typography>
          </Toolbar>
        </AppBar>
        <List subheader={<ListSubheader className={classes.subHeader}>{translations.GENERAL_RULES[locale.localeName]}</ListSubheader>}>
        {generalTexts.map((el, index) => 
            <div key={index}>
                <ListItem button>
                <ListItemText 
                    secondary={el['en'].definition} />
                </ListItem>
                <Divider />
            </div>
        )}
        </List>
        <br/>
        <List subheader={<ListSubheader className={classes.subHeader}>{translations.QUESTIONS_AND_ANSWERS[locale.localeName]}</ListSubheader>}>
        {QandATexts.map((el, index) => 
            <div key={index}>
                <ListItem button>
                <ListItemText 
                    primary={el['en'].question}
                    secondary={el['en'].answer} />
                </ListItem>
                <Divider />
            </div>
        )}
        </List>
      </Dialog>
    </div>
  );
}



