import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SUCCESS_STATUS} from "../../util";

export const FARM_ADD_BEGIN = 'FARM_ADD_BEGIN';
export const FARM_ADD_SUCCESS = 'FARM_ADD_SUCCESS';
export const FARM_ADD_FAILURE = 'FARM_ADD_FAILURE';
export const FARM_ADD_RESET = 'FARM_ADD_RESET';

export const GET_USER_BEGIN = 'GET_USER_BEGIN';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USER_RESET = 'GET_USER_RESET';

export const GET_FARM_LIST_BEGIN = 'GET_FARM_LIST_BEGIN';
export const GET_FARM_LIST_SUCCESS = 'GET_FARM_LIST_SUCCESS';
export const GET_FARM_LIST_FAILURE = 'GET_FARM_LIST_FAILURE';
export const GET_FARM_LIST_RESET = 'GET_FARM_LIST_RESET';

export const ASSIGN_BEGIN = 'ASSIGN_BEGIN';
export const ASSIGN_SUCCESS = 'ASSIGN_SUCCESS';
export const ASSIGN_FAILURE = 'ASSIGN_FAILURE';
export const ASSIGN_RESET = 'ASSIGN_RESET';

const initialState = {
  ...INITIAL_STATUS,
  loadingGetUser: false,
  errorGetUser: false,
  successGetUser: false,
  gottenUser: null,
  checkFarm: null,
  loadingGetFarmList: false,
  errorGetFarmList: false,
  successGetFarmList: false,
  loadingAssign: false,
  errorAssign: false,
  successAssign: false,
  farmList: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FARM_ADD_BEGIN: {
      return {
        ...state,
        ...BEGIN_STATUS
      };
    }
    case FARM_ADD_SUCCESS: {
      return {
        ...state,
        ...SUCCESS_STATUS
      };
    }
    case FARM_ADD_FAILURE: {
      return {
        ...state,
        ...FAILURE_STATUS,
        checkFarm: action.checkFarm,
      };
    }
    case FARM_ADD_RESET: {
      return initialState;
    }
    case GET_USER_BEGIN: {
      return {
        ...state,
        loadingGetUser: true,
        errorGetUser: false,
        successGetUser: false
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        loadingGetUser: false,
        errorGetUser: false,
        successGetUser: true,
        gottenUser: action.gottenUser,
      };
    }
    case GET_USER_FAILURE: {
      return {
        ...state,
        loadingGetUser: false,
        errorGetUser: true,
        successGetUser: false
      };
    }
    case GET_USER_RESET: {
      return initialState;
    }
    case GET_FARM_LIST_BEGIN: {
      return {
        ...state,
        loadingGetFarmList: true,
        errorGetFarmList: false,
        successGetFarmList: false,
      };
    }
    case GET_FARM_LIST_SUCCESS: {
      return {
        ...state,
        loadingGetFarmList: false,
        errorGetFarmList: false,
        successGetFarmList: true,
        farmList: action.farmList,
      };
    }
    case GET_FARM_LIST_FAILURE: {
      return {
        ...state,
        loadingGetFarmList: false,
        errorGetFarmList: true,
        successGetFarmList: false,
      };
    }
    case GET_FARM_LIST_RESET: {
      return initialState;
    }

    case ASSIGN_BEGIN: {
      return {
        ...state,
        loadingAssign: true,
        errorAssign: false,
        successAssign: false,
      };
    }
    case ASSIGN_SUCCESS: {
      return {
        ...state,
        loadingAssign: false,
        errorAssign: false,
        successAssign: true,
        farmList: action.farmList,
      };
    }
    case ASSIGN_FAILURE: {
      return {
        ...state,
        loadingAssign: false,
        errorAssign: true,
        successAssign: false,
      };
    }
    case ASSIGN_RESET: {
      return initialState;
    }

    default:
      return state;

  }
}
