import React from "react";
import {withStyles} from "@material-ui/core";
import {style} from "../../../../styles/style.js";
import {withRouter} from "react-router-dom";
import {translations} from "../../../../dictionary/translations";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import Dictionary from '../../../user/Dictionary';
import Instruction from '../../../user/Instruction';
import Drawer from '@material-ui/core/Drawer';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import SideList from './SideList';
import SOP_PDF from '../../../../instruction/SOP.pdf';

const styles = {
  root: {
    display: 'flex', 
    justifyContent: 'space-between', 
    height: 60,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  button : {
    transition: 'background-color ease 0.5s, color ease 0.5s',
    marginTop: 10,
    marginRight: 10,
    backgroundColor: 'white',
    fontSize: 11,
    padding: 2,
    height: '60%',
    '&:hover' : {
      backgroundColor: style.rose_light_hover,
      color: 'white',
    },
    '@media only screen and (max-width: 768px)' : {
      fontSize: 8,
      marginRight: 5,
    },
  },
  icon: {
    '@media only screen and (max-width: 768px)' : {
      width: 20,
      marginRight: 5,
    },
  },
  list: {
    width: 500,
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white !important',
    justifyContent: 'center',
  }
};

const showQuestionnaireDicName = (props) => {
  const {questionnaireDicId, questionnaireDicList} = props;
  if (questionnaireDicList && questionnaireDicList.items && questionnaireDicList.items.length > 0) {
    const questionnaireDic = questionnaireDicList.items.find((questionnaireDic) => {
      return questionnaireDicId === questionnaireDic.id;
    })
    return questionnaireDic.name;
  } else {
    return '';
  }
}

class Header extends React.Component {

  state={
    isDictionaryOpen: false,
    isInstructinOpen: false,
    right: false,
    checkedReports: [null, null, null, null, null],
  };

  handleCloseInstructionOrDictionary = () => {
    this.setState({isInstructionOpen : false, isDictionaryOpen: false})
  }

  toggleDrawer = (side, open) => event => {
    const {openSideList} = this.props;
    openSideList(open);
    this.setState({[side]: open });
  };

  setReports = (forms) => {
    this.setState({checkedReports : forms});
  }

  render(){

  const { classes, history, locale, isReadOnly, setNavBarValue, isSideListOpen, questionnaireId, formListData, questionFilledAll, modeAdmin, choosenFarmCode} = this.props;
  const { isInstructionOpen, isDictionaryOpen, checkedReports, right } = this.state;
      
              return (
                <div className={classes.root} >
                  {isDictionaryOpen ?
                  <Dictionary isOpen={isDictionaryOpen} closeFunction={this.handleCloseInstructionOrDictionary} locale={locale} />
                  :
                  null}
                  {isInstructionOpen ?
                  <Instruction isOpen={isInstructionOpen} closeFunction={this.handleCloseInstructionOrDictionary} locale={locale} />
                  :
                  null}
                  <section className={classes.description}>
                      <h5>{translations.QUESTIONNAIRE_TYPE[locale.localeName]} : {showQuestionnaireDicName(this.props).toLowerCase()}</h5>
                      <span style={{fontSize: 20}}>{choosenFarmCode}</span>
                  </section>
                  <div className={classes.buttons} style={isSideListOpen ? {position: 'relative', right: 350} : {}}>
                          <Button  href={SOP_PDF} target='_blank' variant="outlined" size="medium" className={classes.button}>
                              <DescriptionIcon className={classes.icon} />
                              {'SOP'}
                          </Button>
                          <Button  variant="outlined" size="medium" className={classes.button} onClick={() => this.setState({isDictionaryOpen : true})}>
                                  <DescriptionIcon className={classes.icon}/>
                                  {translations.DICTIONARY[locale.localeName]}
                          </Button>
                          <Button  variant="outlined" size="medium" className={classes.button} onClick={() => this.setState({isInstructionOpen : true})}>
                                  <DescriptionIcon className={classes.icon}/>
                                  {translations.INSTRUCTION[locale.localeName]}
                          </Button>
                          {!modeAdmin ?
                              <Button className={classes.button} onClick={this.toggleDrawer('right', true)}>
                                  <AssessmentOutlinedIcon className={classes.icon}/>
                                  {translations.REPORTS[locale.localeName]}
                              </Button>
                          : null}
                          <Button
                            className={classes.button}
                            onClick={() => {
                              setNavBarValue(0);
                              history.goBack();
                            }}
                          >
                            {isReadOnly === true ? <CloseIcon className={classes.icon} /> : <SaveIcon style={{marginRight: 10}}/>}
                            {isReadOnly === true ? translations.CLOSE[locale.localeName] : translations.SAVE_AND_CLOSE[locale.localeName]}
                          </Button> 
                          {right ?
                            <Drawer 
                              anchor="right" 
                              open={right} 
                              onClose={this.toggleDrawer('right', false)} 
                              variant='persistent'
                            >
                                <SideList 
                                    close={this.toggleDrawer('right', false)} 
                                    questionnaireId={questionnaireId} 
                                    formListData={formListData} 
                                    locale={locale} 
                                    setReports={this.setReports} 
                                    checkedReports={checkedReports}
                                    questionFilledAll={questionFilledAll}
                                />
                            </Drawer>
                          : null}           
                  </div>
                </div>
              );
  }
}




export default withRouter(withStyles(styles)(Header));
