import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SUCCESS_STATUS} from "../../../util";

export const GET_QUESTION_BEGIN = 'GET_QUESTION_BEGIN';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_FAILURE = 'GET_QUESTION_FAILURE';
export const GET_QUESTION_RESET = 'GET_QUESTION_RESET';

export const SAVE_QUESTION_BEGIN = 'SAVE_QUESTION_BEGIN';
export const SAVE_QUESTION_SUCCESS = 'SAVE_QUESTION_SUCCESS';
export const SAVE_QUESTION_FAILURE = 'SAVE_QUESTION_FAILURE';
export const SAVE_QUESTION_RESET = 'SAVE_QUESTION_RESET';

export const GET_QUESTION_1069_BEGIN = 'GET_QUESTION_1069_BEGIN';
export const GET_QUESTION_1069_SUCCESS = 'GET_QUESTION_1069_SUCCESS';
export const GET_QUESTION_1069_FAILURE = 'GET_QUESTION_1069_FAILURE';
export const GET_QUESTION_1069_RESET = 'GET_QUESTION_1069_RESET';

export const ADD_FILLED_QUESTION = 'ADD_FILLED_QUESTION'
export const REMOVE_FILLED_QUESTION = 'REMOVE_FILLED_QUESTION'

const initState = {
  getStatus: {
    ...INITIAL_STATUS
  },
  saveStatus: {
    ...INITIAL_STATUS
  },
  getQuestion1069Status: {
    ...INITIAL_STATUS
  },
  data: null,
  dataQuestion1069: null,
  questionFilledAll: [],
}

export default function appReducer(state = initState, action) {

  switch (action.type) {

    case GET_QUESTION_BEGIN: {
      return {
        ...state,
        getStatus: BEGIN_STATUS,
        data: null
      };
    }

    case GET_QUESTION_SUCCESS: {
      return {
        ...state,
        getStatus: SUCCESS_STATUS,
        data: action.data
      };
    }

    case GET_QUESTION_FAILURE: {
      return {
        ...state,
        getStatus: FAILURE_STATUS,
        data: null
      };
    }

    case SAVE_QUESTION_BEGIN: {
      return {
        ...state,
        saveStatus: BEGIN_STATUS
      };
    }
    case SAVE_QUESTION_SUCCESS: {
      return {
        ...state,
        saveStatus: SUCCESS_STATUS
      };
    }
    case SAVE_QUESTION_FAILURE: {
      return {
        ...state,
        saveStatus: FAILURE_STATUS
      };
    }

    case GET_QUESTION_1069_BEGIN: {
      return {
        ...state,
        getQuestion1069Status: BEGIN_STATUS,
        dataQuestion1069: null
      };
    }

    case GET_QUESTION_1069_SUCCESS: {
      return {
        ...state,
        getQuestion1069Status: SUCCESS_STATUS,
        dataQuestion1069: action.data
      };
    }

    case GET_QUESTION_1069_FAILURE: {
      return {
        ...state,
        getQuestion1069Status: FAILURE_STATUS,
        dataQuestion1069: null
      };
    }

    case ADD_FILLED_QUESTION: {
      const temp = [...state.questionFilledAll];
      if (!temp.includes(action.id)){
        temp.push(action.id)
      }
      return {
        ...state,
        questionFilledAll: temp
      };
    }

    case REMOVE_FILLED_QUESTION: {
      const temp = [...state.questionFilledAll];
      if (temp.includes(action.id)){
        const index = temp.indexOf(action.id);
        temp.splice(index,1);
      }
      return {
        ...state,
        questionFilledAll: temp
      };
    }

    default:
      return state;
  }
}
