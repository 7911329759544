import React from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {translations} from "../../../dictionary/translations";

const styles = {

};

const QuestionListRemoval = (props) => {
  const {locale, questionListName, questionListIdToRemove, closeDeleteDialog, deleteQuestionList} = props;
  return (
    <Dialog open={true}>
      <DialogTitle>
        {translations.QUESTION_LIST_REMOVAL[locale.localeName]}
      </DialogTitle>
      <DialogContent>
        {translations.ARE_YOU_SURE_TO_REMOVE[locale.localeName]}
      </DialogContent>
      <DialogActions style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
        <Button variant="outlined" onClick={() => closeDeleteDialog()}>
          {translations.NO[locale.localeName]}
        </Button>
        <Button variant="outlined" onClick={() => {deleteQuestionList(questionListIdToRemove); closeDeleteDialog()}}>
          {translations.YES[locale.localeName]}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(QuestionListRemoval);
