/* global document */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {HashRouter as Router} from 'react-router-dom';
import {PersistGate} from 'redux-persist/es/integration/react';

import configureStore from './store';
import * as serviceWorker from './register-service-worker';
import Routes from './routes';
// Components
import Loading from './components/Loading';
// Load css
import './styles/style.scss';
import Dictionary from "./components/Dictionary";
import DictionaryContainer from "./containers/Dictionary";


const { persistor, store } = configureStore();
// persistor.purge(); // Debug to clear persist

export default class Root extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <Router>
            <DictionaryContainer Layout={Dictionary}>
              <Routes />
            </DictionaryContainer>
          </Router>
        </PersistGate>
      </Provider>
    )
  }
};

ReactDOM.render(<Root />, document.getElementById('root') || document.createElement('div'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
