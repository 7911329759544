import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {translations} from '../../../dictionary/translations';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Loading from "../../Loading";
import Error from "../../Error";
import ErrorContainer from "../../../containers/Error";
import Image from 'material-ui-image'
import LogoImage from '../../../img/logo.jpg'
import {style} from "../../../styles/style.js";


const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: 400,
    height: 'auto',
    left: 'calc(50vw - 200px)',
    top: 'calc(50vh - 200px)',
    border: `3px solid ${style.gray}`,
    borderRadius: 20,
    paddingBottom: 20,
    '@media only screen and (max-width: 768px)' : {
          width: '70vw',
          left: '15vw',
    },
  },
  logo : {
    position: 'absolute',
    width: 120,
    height:120,
    left: 'calc(50vw - 60px)',
    top: 'calc(50vh - 260px)',
    zIndex: 1,
    '-webkit-box-shadow': '0px 0px 22px 0px rgba(79,74,79,1)',
    '-moz-box-shadow': '0px 0px 22px 0px rgba(79,74,79,1)',
    'box-shadow' : '0px 0px 22px 0px rgba(79,74,79,1)',
    '@media only screen and (max-width: 768px)' : {
          width: '22vw',
          left: '39vw',
          height: '22vw',
          top: 'calc(50vh - 200px - 11vw)',
    },
  },
  form: {
    width: '90%',
    marginTop: 55,
  },
  label: {
      color: `${style.violet} !important`,
  },
  input : {
      color: `${style.violet}`,
      '&:before' : {
        borderBottom: `2px solid ${style.rose_light} !important`,
      },
      '&:after' : {
        borderBottom: `2px solid ${style.rose_dark}`,
      },
  },
  submitButton : {
    color: 'white',
      transition: 'background-color ease 0.5s',
      marginTop: 20,
      outline: 'none',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
  link : {
      marginTop: 20,
      width: '90%',
  },
  otherButton : {
      transition: 'background-color ease 0.5s',
      outline: 'none',
      backgroundColor: style.rose_light,
      color: style.violet,
      '&:hover' : {
        backgroundColor: style.rose_light_hover,
      },
  },
  helper : {
    color: 'red',
  }
};

export class UserLogin extends React.Component {

  state = {
    email : '',
    password : '',
    emailHasError: false,
    passwordHasError: false,
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    if (!this.state.email) {
      this.setState({ emailHasError: false });
    }
    if (!this.state.password) {
      this.setState({ passwordHasError: false });
    }
  };

  sendLoginRequest = (event, email, password) => {
    const {locale} = this.props;
    event.preventDefault();
    if (!this.state.email) {
      this.setState({ emailHasError: true });
    }
    if (!this.state.password) {
      this.setState({ passwordHasError: true });
    }
    if((this.state.email)&&(this.state.password)){
        const { login } = this.props;
        const form = new FormData();
        form.append('email', email);
        form.append('password', password);
        login(form, locale.localeName);
    }

  }

  componentDidMount() {
    const { history, user } = this.props;
    if (user && user.email && user.email.length > 0) {
      history.push('main-navigation');
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { history, success, user } = this.props;
    if (success === true) {
      user.roleDicId === 1 ? history.push('administration') : history.push('main-navigation');
    }
  }

  render() {
    const { classes, locale, loading, error, resetUser, errorType } = this.props;
    const { email, password } = this.state;

    if (loading === true) {
      return (
        <Loading/>
      )
    }

    if (error === true) {
      return (<ErrorContainer content={errorType} Layout={Error} callback={resetUser} />)
    }

    return (
      <div>
        <Paper className ={classes.logo}>
            <Image disableTransition={true} src={LogoImage} alt='logo SusPigSys' />
        </Paper>
        <Paper className={classes.paper}>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="email">{translations.E_MAIL_ADDRESS[locale.localeName]}</InputLabel>
              <Input className={classes.input} id="email" name="email" autoComplete="email" onChange={this.handleChange} autoFocus />
              {this.state.emailHasError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="password">{translations.PASSWORD[locale.localeName]}</InputLabel>
              <Input className={classes.input} name="password" type="password" id="password" onChange={this.handleChange} autoComplete="current-password" />
              {this.state.passwordHasError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
            </FormControl>
              <Button
                type='submit'
                color="primary"              
                fullWidth
                size="large"
                className={classes.submitButton}
                onClick={(event) => {this.sendLoginRequest(event, email, password)}}
              >
                {translations.LOG_IN[locale.localeName]}
              </Button>
          </form>
          <Link className={classes.link} to="/forgotPassword">
              <Button             
                fullWidth
                size="small"
                className={classes.otherButton}
              >
                {translations.FORGOT_PASSWORD[locale.localeName]}
              </Button>
          </Link>
          <Link className={classes.link} to="/registration">
              <Button
                fullWidth
                size="small"
                className={classes.otherButton}
              >
                {translations.REGISTRATION[locale.localeName]}
              </Button>
          </Link>
        </Paper>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(UserLogin));
