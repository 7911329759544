import React from 'react';
import FarmListContainer from "../../containers/farm/List";
import ExportContainer from "../../containers/export/Export";
import QuestionnaireListContainer from "../../containers/questionnaire/List";
import FarmList from "../farm/List";
import Export from "../export/Export";
import MainNavigationNavBar from "./NavBar";
import QuestionnaireList from "../questionnaire/List";
import TextField from "@material-ui/core/TextField";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {translations} from "../../dictionary/translations";
import {withStyles} from "@material-ui/core";
import {style} from "../../styles/style";
import UsersListContainer from "../../containers/admin/UsersListContainer";
import UsersList from "../admin/UsersList";
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
      fontSize: 11,
  },
});

const styles = {
  button : {
    minWidth: 150,
    transition: 'background-color ease 0.5s',
    outline: 'none',
    backgroundColor: style.rose_dark,
    color: 'white',
    marginTop: 10,
    position: 'relative',
    bottom: 5,
    '&:hover' : {
      backgroundColor: style.rose_dark_hover,
    },
  },
  addOrEditButtons: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    height: 30,
  },
  listHeader: {
    marginTop: 30,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  searchField : {
    marginRight: 10,
    backgroundColor: 'white',
    marginRight: 5,
    borderRadius: 5,
    paddingLeft: 5,
    maxWidth: 150,
    fontSize: '11 !important',
    '@media only screen and (max-width: 768px)' : {
      maxWidth: 100,
    },
  }
};

export class MainNavigation extends React.Component {

  state = {
    searchText: ''
  };

  setNavBarValue = (value) => {
    const { setNavBarValue } = this.props;
    setNavBarValue(value);
    this.setState({ navBarValue: value });
    this.setState({ searchText: '' });
  };

  handleSearchField = event => {
    this.setState({ searchText: event.currentTarget.value});
  }

  render() {
    const {classes, locale, navBarValue, user, dictionary, modeAdmin} = this.props;
    const {searchText} = this.state;

    const addFarmButtonStyle = navBarValue !== 1 ? {display: 'none'} : {};
    const search = navBarValue === 2 ? {display: 'none'} : {};
    const questionButtonStyle = navBarValue !== -1 ? {display: 'none'} : {};

    return (
      <div>
          <div className={classes.addOrEditButtons}>
              <Link to="/add-farm" style={addFarmButtonStyle}>
                  <Button variant="outlined" className={(classes.button)}>
                    {translations.NEW_FARM[locale.localeName]}
                  </Button>
                </Link>
                <Link to="/edit-questionnaire" style={questionButtonStyle}>
                    <Button variant="outlined" className={classes.button}>
                      {translations.EDIT_QUESTIONNAIRE[locale.localeName]}
                    </Button>
                </Link>
          </div>
          <div className={classes.listHeader}>
              <MainNavigationNavBar modeAdmin={modeAdmin} setNavBarValue={this.setNavBarValue} navBarValue={navBarValue} locale={locale} user={user} />
              <MuiThemeProvider theme={theme}>
              <TextField type="search" margin="normal" value={searchText} onChange={this.handleSearchField} placeholder={translations.SEARCH[locale.localeName]} className={classes.searchField} style={search}/>
              </MuiThemeProvider>
          </div>
          <div>
              {modeAdmin===true && navBarValue === -1 && <UsersListContainer dictionary={dictionary} Layout={UsersList} searchText={searchText} />}
              {navBarValue === 0 && <QuestionnaireListContainer Layout={QuestionnaireList} searchText={searchText} />}
              {navBarValue === 1 && <FarmListContainer Layout={FarmList} searchText={searchText} />}
              {navBarValue === 2 && <ExportContainer Layout={Export} />}
          </div>
      </div>
    )
  }
}

export default withStyles(styles)(MainNavigation);
