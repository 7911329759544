import React from 'react';
import {connect} from "react-redux";
import actions from "../../../actions";

export class OptionalQuestionListContainer extends React.Component {

  render() {

    const {
      Layout,
      locale,
      formId,
      subFormId,
      questionListId,
      optionalQuestionList,
      getQuestionList,
      getOptionalQuestionList,
      saveQuestionList,
      setSubFormId,
      prevSubFormId,
      setIsOpen,
      resetSaveQuestionList,
      resetGetQuestionList,
      getSubFormList,
      setNextSubFormId,
      setNextQuestionListId,
      setQuestionListId,
      setPreviousSubForm
    } = this.props;

    return (
      <Layout
        locale={locale}
        formId={formId}
        subFormId={subFormId}
        questionListId={questionListId}
        optionalQuestionList={optionalQuestionList}
        getQuestionList={getQuestionList}
        getOptionalQuestionList={getOptionalQuestionList}
        saveQuestionList={saveQuestionList}
        setSubFormId={setSubFormId}
        prevSubFormId={prevSubFormId}
        setIsOpen={setIsOpen}
        resetSaveQuestionList={resetSaveQuestionList}
        resetGetQuestionList={resetGetQuestionList}
        getSubFormList={getSubFormList}
        setNextSubFormId={setNextSubFormId}
        setNextQuestionListId={setNextQuestionListId}
        setQuestionListId={setQuestionListId}
        setPreviousSubForm={setPreviousSubForm}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  formId : state.questionnaire.form.form.formId,
  subFormId : state.questionnaire.form.form.subFormId,
  questionListId : state.questionnaire.form.form.questionListId,
  optionalQuestionList : state.questionnaire.form.optionalQuestionList,
  prevSubFormId : state.questionnaire.form.form.prevSubFormId,
});

const mapDispatchToProps = {
  getOptionalQuestionList : actions.questionnaire.form.optionalQuestionList.getQuestionList,
  getQuestionList : actions.questionnaire.form.questionList.getQuestionList,
  saveQuestionList : actions.questionnaire.form.optionalQuestionList.saveQuestionList,
  resetSaveQuestionList : actions.questionnaire.form.optionalQuestionList.resetSaveQuestionList,
  resetGetQuestionList : actions.questionnaire.form.optionalQuestionList.resetGetQuestionList,
  setSubFormId : actions.questionnaire.form.form.setSubFormId,
  getSubFormList : actions.questionnaire.form.subFormList.getSubFormList,
  setNextSubFormId : actions.questionnaire.form.subFormList.setNextSubFormId,
  setNextQuestionListId : actions.questionnaire.form.questionList.setNextQuestionListId,
  setQuestionListId: actions.questionnaire.form.form.setQuestionListId,
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionalQuestionListContainer);
