import {URL} from '../../config';

function getUserRoleList() {
  return dispatch => {
    dispatch(fetchUserRoleListBegin());
    return fetchUserRoleListStart()
      .then(data => {
        dispatch(fetchUserRoleListSuccess(data));
      })
      .catch(() => {
        dispatch(fetchUserRoleListFailure())
      });
  };
}

const fetchUserRoleListBegin = () => ({
  type: 'FETCH_USER_ROLE_LIST_BEGIN'
});

function fetchUserRoleListStart() {
  return new Promise((resolve, reject) => {
    fetch(URL + `/user/role/list`)
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data)
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const fetchUserRoleListSuccess = userRoleList => ({
  type: 'FETCH_USER_ROLE_LIST_SUCCESS',
  items: userRoleList
});

const fetchUserRoleListFailure = () => ({
  type: 'FETCH_USER_ROLE_LIST_FAILURE',
  error: true
});

const resetState = () => ({
  type: 'FETCH_USER_ROLE_LIST_RESET'
});


const index = {
  getUserRoleList,
  resetState
}

export default index;
