import {URL} from '../../config';

function getFarmList(start, limit, userId, searchText, orderBy, order) {
  return dispatch => {
    dispatch(setBegin());
    return getFarmListStart(start, limit, userId, searchText, orderBy, order)
      .then(json => {
        dispatch(setSuccess(json.data, json.total));
      })
      .catch(() => {
        dispatch(setFailure())
      });
  };
}

const setBegin = () => ({
  type: 'FETCH_FARM_LIST_BEGIN'
});

function getFarmListStart(start, limit, userId, searchText, orderBy, order) {
  return new Promise((resolve, reject) => {
    fetch(`${URL}/secured/farm/list?start=${start}&limit=${limit}&userId=${userId}&searchText=${searchText}${orderBy ? `&orderBy=${orderBy}` : ''}${order ? `&order=${order}` : ''}`, {
      method: 'GET',
      headers: {
       "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json)
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setSuccess = (farmList, total) => ({
  type: 'FETCH_FARM_LIST_SUCCESS',
  items: farmList,
  total: total
});

const setFailure = () => ({
  type: 'FETCH_FARM_LIST_FAILURE',
  error: true
});

const resetState = () => ({
  type: 'FETCH_FARM_LIST_RESET'
});

const index = {
  getFarmList,
  resetState
}

export default index;
