import React from 'react';
import {connect} from "react-redux";
import actions from "../../actions";

export class MainNavigationContainer extends React.Component {

  render() {

    const {
      Layout,
      locale,
      navBarValue,
      setNavBarValue,
      user,
      dictionary,
      modeAdmin
    } = this.props;

    return (
      <Layout
        locale={locale}
        navBarValue={navBarValue}
        setNavBarValue={setNavBarValue}
        user={user}
        dictionary={dictionary}
        modeAdmin={modeAdmin}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  navBarValue : state.mainNavigation.mainNavigation.mainNavigationNavBarValue,
  user: state.user.login.user,
});

const mapDispatchToProps = {
  setNavBarValue : actions.mainNavigation.mainNavigation.setMainNavigationNavBarValue,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigationContainer);
