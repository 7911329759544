import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class ResetPasswordContainer extends React.Component {

  render() {

    const token = this.props.match.params.token;
    const localeId = this.props.match.params.localeId;
    let chosenLocale='';

    switch (localeId){
      case '1':
        chosenLocale='en';
        break;
      case '2':
        chosenLocale='pl';
        break;
      case '3':
        chosenLocale='de';
        break;
      case '4':
        chosenLocale='fi';
        break;
      case '5':
        chosenLocale='it';
        break;
      case '6':
        chosenLocale='nl';
        break;
      default:
        chosenLocale='en';
    }

    const {
      Layout,
      resetPassword,
      resetPasswordStatus,
      resetResetPassword,
      login,
      loginStatus,
      resetLogin,
      userData,
      errorType,
      passwordValidator
    } = this.props;

    return (
      <Layout
        token={token}
        localeName={chosenLocale}
        resetPassword={resetPassword}
        resetPasswordStatus={resetPasswordStatus}
        resetResetPassword={resetResetPassword}
        login={login}
        loginStatus={loginStatus}
        resetLogin={resetLogin}
        userData={userData}
        errorType={errorType}
        passwordValidator={passwordValidator}
      />
    )
  }
}

const mapStateToProps = state => ({
  resetPasswordStatus: state.user.resetPassword,
  errorType: state.user.resetPassword.errorType,
  userData: state.user.resetPassword.userData,
  loginStatus: {
    loading : state.user.login.loading,
    error : state.user.login.error,
    success : state.user.login.success
  },
});

const mapDispatchToProps = {
  login: actions.user.login.login,
  resetLogin: actions.user.login.resetState,
  resetPassword: actions.user.resetPassword.resetPassword,
  resetResetPassword : actions.user.resetPassword.resetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
