import React from 'react';
import {connect} from "react-redux";
import actions from "../../../actions";
import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SAVE_STATUS, SUCCESS_STATUS} from "../../../util";
import {URL} from "../../../config";

export class AnswerTemplateContainer extends React.Component {

  state = {
    addStatus : INITIAL_STATUS,
    editStatus : INITIAL_STATUS,
    getStatus : INITIAL_STATUS,
    items: null,
  }

  OPERATION = {
    ADD_ANSWER : 'addAnswer',
    EDIT_ANSWER: 'editAnswer',
    GET_ANSWER: 'getAnswer',
  }

  setBegin = (operation) => {
    switch (operation) {
      case this.OPERATION.ADD_ANSWER:
        this.setState({
          addStatus : BEGIN_STATUS
        })
        break;
      case this.OPERATION.EDIT_ANSWER:
        this.setState({
          editStatus : BEGIN_STATUS
        })
        break;
      case this.OPERATION.GET_ANSWER:
        this.setState({
          getStatus : BEGIN_STATUS
        })
        break;
      default: return null;
    }
  };

  setSuccess = (operation, data) => {
    switch (operation) {
      case this.OPERATION.ADD_ANSWER:
        this.setState({
          addStatus : SUCCESS_STATUS
        })
        break;
      case this.OPERATION.EDIT_ANSWER:
        this.setState({
          editStatus : SUCCESS_STATUS,
        })
        break;
      case this.OPERATION.GET_ANSWER:
        this.setState({
          getStatus : SUCCESS_STATUS,
          items: data
        })
        break;
      default: return null;
    }
  };

  setFailure = (errorType, operation) => {
    switch (operation) {
      case this.OPERATION.ADD_ANSWERN:
        this.setState({
          addStatus : FAILURE_STATUS,
        })
        break;
      case this.OPERATION.EDIT_ANSWER:
        this.setState({
          editStatus : FAILURE_STATUS,
        })
        break;
      case this.OPERATION.GET_ANSWER:
        this.setState({
          getStatus : FAILURE_STATUS,
        })
        break;
      default: return null;
    }
  };

  

  addAnswer = (body) => {
    this.setBegin(this.OPERATION.ADD_ANSWER);
    this.addAnswerStart(body)
      .then(() => {
        this.setSuccess(this.OPERATION.ADD_ANSWER);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.ADD_ANSWER);
      });
  }

  addAnswerStart = (body) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/question/answer/dic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        },
        body: body
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve()
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  getAnswer = (id) => {
    this.setBegin(this.OPERATION.GET_ANSWER);
    this.getAnswerStart(id)
      .then((data) => {
        this.setSuccess(this.OPERATION.GET_ANSWER, data);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.GET_ANSWER);
      });
  }

  getAnswerStart = (id) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/question/answer/dic/list?questionDicId=${id}`, {
        method: 'GET',
        headers: {
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(json => {
          if (json.data) {
            resolve(json.data)
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  editAnswer = (id, body) => {
    this.setBegin(this.OPERATION.EDIT_ANSWER);
    this.editAnswerStart(id, body)
      .then(() => {
        this.setSuccess(this.OPERATION.EDIT_ANSWER);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.EDIT_ANSWER);
      });
  }

  editAnswerStart = (id, body) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/question/answer/dic?answerDicId=${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        },
        body: body
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve()
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }


  render() {
    const { 
      Layout,
      mode,
      locale,
      close,
      questionId,
      localeList,
      selectedAnswer,
      isMultiple,
      withInput
    } = this.props;

    const {
      addStatus,
      getStatus,
      items,
      editStatus
    } = this.state;

    return (
      <Layout
          mode={mode}
          locale={locale}
          close={close}
          addStatus={addStatus}
          addAnswer={this.addAnswer}
          localeList={localeList}
          getAnswer={this.getAnswer}
          getStatus={getStatus}
          items={items}
          editAnswer={this.editAnswer}
          editStatus={editStatus}
          questionId={questionId}
          selectedAnswer={selectedAnswer}
          isMultiple={isMultiple}
          withInput={withInput}
      />
    )
  }
}

const mapStateToProps = state => ({
  localeList: {
    loading: state.dictionary.localeList.loading,
    error: state.dictionary.localeList.error,
    success: state.dictionary.localeList.success,
    items: state.dictionary.localeList.items,
  },
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerTemplateContainer);
