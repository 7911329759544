import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';
import {URL} from "../../config";
import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SUCCESS_STATUS} from "../../util";

export class QuestionnaireListContainer extends React.Component {

  rowsPerPage = 20;

  state = {
    page : 0,
    order: 'asc',
    orderBy: 'farmCode',
    setIsQuestionnaireActiveStatus : INITIAL_STATUS
  }

  setIsQuestionnaireActive = (questionnaireId, isActive) => {
    this.setState({
      setIsQuestionnaireActiveStatus : BEGIN_STATUS
    })
    fetch(URL + `/secured/questinnaire/` + questionnaireId + `/setIsActive/` + isActive, {
      method: 'PUT',
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
    }).then(response => response.json())
      .then(json => {
        if (json.success === true) {
          this.setState({
            setIsQuestionnaireActiveStatus : SUCCESS_STATUS
          })
        } else {
          this.setState({
            setIsQuestionnaireActiveStatus : FAILURE_STATUS
          })
        }
      })
      .catch(() => {
        this.setState({
          setIsQuestionnaireActiveStatus : FAILURE_STATUS
        })
      })
  }

  resetSetIsQuestionnaireActive = () => {
    this.setState({
      setIsQuestionnaireActiveStatus : INITIAL_STATUS
    })
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      const {getQuestionnaireList, user, searchText, locale} = this.props;
      const {order, orderBy, page} = this.state;
      const {rowsPerPage} = this;
      const start = page * rowsPerPage;
      const limit = rowsPerPage;
      getQuestionnaireList(start, limit, user.id, user.roleDicId, searchText, locale.localeName, order, orderBy);
    });
  };

  changeOrder = (order, orderBy) => {
    this.setState({ order, orderBy }, () => {
      const {getQuestionnaireList, user, searchText, locale} = this.props;
      const {page} = this.state;
      const {rowsPerPage} = this;
      const start = page * rowsPerPage;
      const limit = rowsPerPage;
      getQuestionnaireList(start, limit, user.id, user.roleDicId, searchText, locale.localeName, order, orderBy);
    });
  }

  render() {

    const {
      Layout,
      parentClasses,
      locale,
      loading,
      error,
      success,
      items,
      total,
      getQuestionnaireList,
      user,
      setQuestionnaireId,
      setQuestionnaireDicId,
      setQuestionnaireReadOnly,
      searchText,
      removeQuestionnaire,
      removeQuestionnaireStatus,
      resetGetQuestionnaireList,
      resetRemoveQuestionnaire,
      setChoosenFarmCode,
      setChoosenFarmType
    } = this.props;

    const {
      page,
      setIsQuestionnaireActiveStatus
    } = this.state;

    const pagination = {
      total : total,
      rowsPerPage : this.rowsPerPage,
      page : page,
      handleChangePage : this.handleChangePage
    }

    return (
      <Layout
        parentClasses={parentClasses}
        locale={locale}
        loading={loading}
        error={error}
        success={success}
        items={items}
        getQuestionnaireList={getQuestionnaireList}
        pagination={pagination}
        user={user}
        setQuestionnaireId={setQuestionnaireId}
        setQuestionnaireDicId={setQuestionnaireDicId}
        setQuestionnaireReadOnly={setQuestionnaireReadOnly}
        searchText={searchText}
        removeQuestionnaire={removeQuestionnaire}
        removeQuestionnaireStatus={removeQuestionnaireStatus}
        resetGetQuestionnaireList={resetGetQuestionnaireList}
        resetRemoveQuestionnaire={resetRemoveQuestionnaire}
        setIsQuestionnaireActive={this.setIsQuestionnaireActive}
        setIsQuestionnaireActiveStatus={setIsQuestionnaireActiveStatus}
        resetSetIsQuestionnaireActive={this.resetSetIsQuestionnaireActive}
        setChoosenFarmCode={setChoosenFarmCode}
        changeOrder = {this.changeOrder}
        setChoosenFarmType={setChoosenFarmType}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  loading: state.questionnaire.list.loading,
  error: state.questionnaire.list.error,
  success: state.questionnaire.list.success,
  items: state.questionnaire.list.items,
  total: state.questionnaire.list.total,
  user: state.user.login.user,
  removeQuestionnaireStatus: state.questionnaire.remove
});

const mapDispatchToProps = {
  getQuestionnaireList: actions.questionnaire.list.fetchQuestionnaireList,
  resetGetQuestionnaireList: actions.questionnaire.list.resetState,
  setQuestionnaireId : actions.questionnaire.form.form.setQuestionnaireId,
  setQuestionnaireDicId : actions.questionnaire.form.form.setQuestionnaireDicId,
  setQuestionnaireReadOnly : actions.questionnaire.form.form.setQuestionnaireReadOnly,
  removeQuestionnaire : actions.questionnaire.remove.removeQuestionnaire,
  resetRemoveQuestionnaire : actions.questionnaire.remove.resetState,
  setChoosenFarmCode : actions.questionnaire.form.form.setChoosenFarmCode,
  setChoosenFarmType : actions.questionnaire.form.form.setChoosenFarmType, 
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireListContainer);
