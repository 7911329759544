import React from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {translations} from '../../dictionary/translations';
import {texts} from '../../dictionary/dictionaryTranslations';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import {style} from "../../styles/style.js";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: `${style.rose_dark}`,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const {isOpen, closeFunction, locale} = props;
  const classes = useStyles();
  const [searchValue, setSearchValue] = React.useState('');
  const [items, setItems] = React.useState(texts);
 
  const handleClose = () => {
    closeFunction();
  };

  const searchChange = (event) => {
      setSearchValue(event.target.value);
  }


  return (
    <div>
      <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                {translations.DICTIONARY[locale.localeName]}
            </Typography>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder={translations.SEARCH[locale.localeName]}
                    onChange={searchChange}
                    value={searchValue}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </div>
          </Toolbar>
        </AppBar>
        <List>
        {items.filter(elem => elem['en'].title.toLowerCase().includes(searchValue.toLowerCase())).sort((a,b) => a['en'].title.localeCompare(b['en'].title)).map((el, index) => 
            <div key={index}>
                <ListItem button>
                <ListItemText 
                    primary={el['en'].title} 
                    secondary={el['en'].definition} />
                </ListItem>
                <Divider />
            </div>
        )}
        </List>
      </Dialog>
    </div>
  );
}



