import React from 'react';
import {connect} from "react-redux";
import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SAVE_STATUS, SUCCESS_STATUS} from "../../../../util";
import {URL} from "../../../../config";
import actions from "../../../../actions";
import ErrorContainer from "../../../Error";
import Error from "../../../../components/Error";
import {withRouter} from "react-router-dom";
import {withStyles} from '@material-ui/core/styles';

const styles = {
  modal: {
    width: '100%',
    height: '100%',
    opacity: 0,
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
  },
  top: {
    zIndex: 2,
  }
};


export class QuestionContainer extends React.Component {

  state = {
    getStatus : INITIAL_STATUS,
    saveStatus : INITIAL_STATUS,
    activateQuestionStatus : INITIAL_STATUS,
    getAnswerAdminStatus : INITIAL_STATUS,
    informDialogOpen: false,
    dbQuestion : null,
    localQuestion : null,
    persistTimeoutRunning : false,
    persistTimeoutDone : false,
    answerError : false,
    errorType: '',
    startSaving: false,
    answersAdmin: null, 
  }

  OPERATION = {
    GET : 'get',
    SAVE : 'save',
    GET_ANSWER_ADMIN: 'getAnswerAdmin',
    ACTIVATE_QUESTION : 'activateQuestion',
  }

  setBegin = (operation) => {
    switch (operation) {
      case this.OPERATION.GET:
        this.setState({
          getStatus : BEGIN_STATUS,
          dbQuestion: null
        })
        break;

      case this.OPERATION.SAVE:
        this.setState({
          saveStatus : BEGIN_STATUS
        })
        break;

      case this.OPERATION.GET_ANSWER_ADMIN:
        this.setState({
          getAnswerAdminStatus : BEGIN_STATUS
        })
        break;

      case this.OPERATION.ACTIVATE_QUESTION:
        this.setState({
          activateQuestionStatus : BEGIN_STATUS
        })
        break;

      default: return null;
    }
  };

  setSuccess = (operation, question) => {
    const {setQuestionFilledAll, removeQuestionFilledAll} = this.props;
    switch (operation) {
      case this.OPERATION.GET:
        this.setState({
          getStatus : SUCCESS_STATUS,
          dbQuestion : question
        })
        
        if ((question.answers.length===0 || (question.answers.length!==0 ? question.answers[0].answer ? false : question.answers[0].text ? false : true : true : false))){
          removeQuestionFilledAll(question.id);
        }
        else{
          setQuestionFilledAll(question.id);
        }

        break;

      case this.OPERATION.SAVE:
        this.setState({
          saveStatus : SUCCESS_STATUS
        })

        break;

      case this.OPERATION.GET_ANSWER_ADMIN:
        this.setState({
          getAnswerAdminStatus : SUCCESS_STATUS,
          answersAdmin : question,
        })
        break;

      case this.OPERATION.ACTIVATE_QUESTION:
        this.setState({
          activateQuestionStatus : SUCCESS_STATUS
        })
        break;

      default: return null;
    }
  };

  setFailure = (errorType, operation) => {
    switch (operation) {
      case this.OPERATION.GET:
        this.setState({
          getStatus : FAILURE_STATUS,
          dbQuestion: null
        })
        break;

      case this.OPERATION.SAVE:
        this.setState({
          saveStatus : FAILURE_STATUS,
          informDialogOpen: true,
          errorType: errorType,
        })
        break;

      case this.OPERATION.GET_ANSWER_ADMIN:
        this.setState({
          getAnswerAdminStatus : FAILURE_STATUS,
          answersAdmin: null,
        })
        break;

      case this.OPERATION.ACTIVATE_QUESTION:
        this.setState({
          activateQuestionStatus : FAILURE_STATUS
        })
        break;

      default: return null;
    }
  };


  getQuestion = () => {
    this.setBegin(this.OPERATION.GET);
    this.getQuestionStart()
      .then((question) => {
        this.setSuccess(this.OPERATION.GET, question);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.GET);
      });
  }

  getQuestionStart = () => {
    const { questionId, locale } = this.props;
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/question/` + questionId + '?localeDicId=' + locale.id, {
        headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        }
      })
        .then(response => response.json())
        .then(json => {
          if (json.id > 0) {
            resolve(json)
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  saveQuestion = (question) => {
    return new Promise(((resolve, reject) => {
      this.setBegin(this.OPERATION.SAVE);
      this.saveQuestionStart(question)
        .then(() => {
          this.setSuccess(this.OPERATION.SAVE);
          resolve();
        })
        .catch((errorType) => {
          this.setFailure(errorType, this.OPERATION.SAVE);
          reject();
        });
    }))
  }

  saveQuestionStart = (question) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/question/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        },
        body: question
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve()
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  saveAndGetQuestion = (question) => {
    const { answerError } = this.state;
    if (answerError === false) {
      this.saveQuestion(question)
        .then(() => {
          this.getQuestion();
        })
        .catch(() => {
        })
    }
  };


  getAnswers = (questionId)  => {
    return new Promise((resolve, reject) => {
        this.setBegin(this.OPERATION.GET_ANSWER_ADMIN);
        this.getAnswersStart(questionId)
          .then((data) => {
            this.setSuccess(this.OPERATION.GET_ANSWER_ADMIN, data);
          })
          .catch((errorType) => {
            this.setFailure(errorType, this.OPERATION.GET_ANSWER_ADMIN);
          });
    });
  }
  
  getAnswersStart = (questionId) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/question/answer/dic/list?questionDicId=${questionId}`, {
        headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        }
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve(json.data)
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  activateQuestion = (questionListId, questionId, active) => {
    this.setBegin(this.OPERATION.ACTIVATE_QUESTION);
    this.activateQuestionStart(questionListId, questionId, active)
      .then(() => {
        this.setSuccess(this.OPERATION.ACTIVATE_QUESTION);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.ACTIVATE_QUESTION);
      });
  }

  activateQuestionStart = (questionListId, questionId, active) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/question/dic/setIsActive?questionListDicId=${questionListId}&questionId=${questionId}&isActive=${active}` , {
        method: 'PUT',
        headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        }
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve()
          } else {
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { questionId, questionList} = this.props;
    const { getStatus, saveStatus, dbQuestion } = this.state;
    if (prevState.getStatus.loading === true && getStatus.success === true && questionId === dbQuestion.id) {
      this.setLocalQuestion(dbQuestion);
      this.setState({startSaving: false}, () => this.setQuestionAnswersToQuestionList(dbQuestion.answers));
    }

    if (prevState.localQuestion !== this.state.localQuestion) {
      const answerError = this.getAnswerError();
      this.setState({
        answerError : answerError
      })
    }

    if ((getStatus.error === true && prevState.getStatus.loading === true) || (saveStatus.error === true && prevState.saveStatus.loading === true)) {
      this.setQuestionAnswersToQuestionList([]);
    }
  }

  setQuestionAnswersToQuestionList = (answers) => {
    const { setQuestionListData, setFillingUpStatus } = this.props;
    const questionGroupList = this.setNewAnswersToQuestionGroupList(answers);
    setQuestionListData(questionGroupList);
    const fillingUpStatus = this.markQuestionAsFilled(answers[0]);
    setFillingUpStatus(fillingUpStatus);
  }

  setNewAnswersToQuestionGroupList = (answers) => {
    const { questionId, questionList, questionListId } = this.props;
    const questionGroupList = JSON.parse(JSON.stringify(questionList));
    const theQuestionList = questionGroupList.find(el => el.id === questionListId);
    const question = theQuestionList.questionList.find(el => el.id === questionId);
    question.answers = answers;
    return questionGroupList;
  }

  markQuestionAsFilled = (answer) => {
    const { questionListId, questionId, fillingUpStatus, subFormId, questionList} = this.props;
    const newFillingUpStatus = JSON.parse(JSON.stringify(fillingUpStatus));
    const subForm = newFillingUpStatus.find(el => el.subFormId === subFormId);
    const questionGroup = subForm.questionGroupList.find(el => el.id === questionListId);
    const question = questionGroup.questionList.find(el => el.id === questionId);
    if (answer){
      if (answer.answer || answer.text){
        question.isComplete = true;
      }
      else{
          question.isComplete = false;
      }
    }
    else{
      question.isComplete = false;
    }
    return newFillingUpStatus;
  }

  setLocalQuestion = (question) => {
    this.setState({
      localQuestion : question,
      dbQuestion : null
    })
  }

  componentWillMount() {
    this.initLocalQuestion();
  }

  getAnswerError = () => {
    const { localQuestion } = this.state;
    let answerError = false;
    if (localQuestion.answerTypeDicId === 10 && (localQuestion.answers.length === 0 || localQuestion.answers[0].answer === '')) {
      answerError = true;
    }
    return answerError;
  }

  setAnswers = (answers) => {
    const { localQuestion, startSaving } = this.state;
    clearTimeout(this.persistTimeout);
    if (startSaving !== true){
      this.setState({
        startSaving: true
      });
    }
    this.setState({
      getStatus : INITIAL_STATUS,
      saveStatus : INITIAL_STATUS,
      persistTimeoutDone : false,
      persistTimeoutRunning : false,
      localQuestion : {
        ...localQuestion,
        answers : answers
      }
    });
  }

  persistAnswersOnTimeout = () => {
    
    this.persistTimeout = setTimeout(() => {
      this.setState({
        persistTimeoutDone: false,
        persistTimeoutRunning: true,
      });
      this.persistAnswers();
    }, 1000);
  }

  persistAnswersOnBlur = () => {
    clearTimeout(this.persistTimeout);
    this.setState({
      persistTimeoutDone : false,
      persistTimeoutRunning : false
    })
    this.persistAnswers();
  }

  persistAnswers = () => {
    const { localQuestion, persistTimeoutRunning, persistTimeoutDone, modeAdmin } = this.state;
    if (modeAdmin !== true){
        if (persistTimeoutDone === false && persistTimeoutRunning === false) {
          this.saveAndGetQuestion(JSON.stringify(localQuestion));
        }
        if (persistTimeoutRunning === true) {
          this.setState({
            persistTimeoutDone: true,
            persistTimeoutRunning: false
          });
          this.saveAndGetQuestion(JSON.stringify(localQuestion));
        }
    }
  }

  initLocalQuestion = () => {
    const { questionList, questionListId, questionId, prevQuestionList, modeAdmin, questionsAdmin } = this.props;
    let theQuestionList;
    let question;
    if (modeAdmin !== true) theQuestionList = questionList ? questionList.find((item) => { return item.id === questionListId; }) : prevQuestionList.find((item) => { return item.id === questionListId; });
    if (modeAdmin !== true) question = theQuestionList['questionList'].find((item) => { return item.id === questionId; });
    if (modeAdmin === true) question = questionsAdmin.find((item) => { return item.id === questionId; });
    this.setLocalQuestion(question);
  }

  getAnswersPersistStatus = () => {
    const { localQuestion, dbQuestion, getStatus, saveStatus, answerError } = this.state;
    const {modeAdmin} = this.props;
    if (modeAdmin !== true){
      if (dbQuestion == null && localQuestion == null) {
        return {
          status : SAVE_STATUS.EMPTY
        }
      }
      if (getStatus.error === true || saveStatus.error === true || answerError === true) {
        return {
          status : SAVE_STATUS.ERROR
        }
      }
      if (getStatus.loading === true || saveStatus.loading === true || (saveStatus.success && getStatus === INITIAL_STATUS)) {
        return {
          status : SAVE_STATUS.LOADING
        }
      }
      if (localQuestion) {
        if (localQuestion.answers.length === 0) {
          return {
            status : SAVE_STATUS.EMPTY
          }
        }
        if (!localQuestion.answers[0].answer && !localQuestion.answers[0].answerListId) {
          return {
            status : SAVE_STATUS.EMPTY
          }
        }
        if (localQuestion.answers.find(el => {
          return el.date ? false : true;
        })) {
          return {
            status : SAVE_STATUS.EMPTY
          }
        } else {
          const lastDate = this.getLastDate(localQuestion.answers);
          return this.getSuccess(lastDate);
        }
      }
    }
  }

  getSuccess = (lastDate) => {
    return {
      status : SAVE_STATUS.SUCCESS,
      date: lastDate
    }
  }

  getLastDate = (answers) => {
    let lastDate = null;
    answers.forEach((el) => {
      if (!lastDate) {
        lastDate = el.date;
      } else {
        if (el.date && (new Date(el.date) > new Date(lastDate))) {
          lastDate = el.date;
        }
      }
    })
    return lastDate;
  } 

  setIsInformDialogOpen = (isOpen) => {
    const {history} = this.props;
    this.setState({
      informDialogOpen: isOpen
    });
    history.goBack();
    
  }

  render() {
    const {
      Layout,
      locale,
      questionNumber,
      isAnswerReadOnly,
      isQuestionReadOnly,
      questionnaireId,
      classes, 
      modeAdmin,
      activateAnswer,
      questions,
      getQuestion1069,
      dataQuestion1069,
      formId,
      answerLastPen,
      isSideListOpen
    } = this.props;

    const {
      localQuestion,
      informDialogOpen,
      errorType,
      startSaving,
      answersAdmin,
    } = this.state;


    return (
      <div>
          <Layout
            className={classes.top}
            locale={locale}
            questionNumber={questionNumber}
            setAnswers={this.setAnswers}
            question={localQuestion}
            persistAnswersOnTimeout={this.persistAnswersOnTimeout}
            persistAnswersOnBlur={this.persistAnswersOnBlur}
            answersPersistStatus={this.getAnswersPersistStatus()}
            isAnswerReadOnly={isAnswerReadOnly}
            isQuestionReadOnly={isQuestionReadOnly}
            questionnaireId={questionnaireId}
            modeAdmin={modeAdmin}
            answersAdmin={answersAdmin}
            getAnswers={this.getAnswers}
            activateQuestion={this.activateQuestion}
            activateAnswer={activateAnswer}
            questions={questions}
            getQuestion1069={getQuestion1069}
            dataQuestion1069={dataQuestion1069}
            formId={formId}
            answerLastPen={answerLastPen}
            isSideListOpen={isSideListOpen}
          />
          {informDialogOpen === true ? <ErrorContainer 
            Layout={Error} 
            content={errorType} 
            buttonText={'OK'}
            callback={() => this.setIsInformDialogOpen(false)}/> 
          : false}
            {startSaving ? <div className={classes.modal}  ></div> : null}
    </div>
    )
  }
}

const mapStateToProps = (state) => ({
  locale: state.user.locale.locale,
  questionList : state.questionnaire.form.questionList.data,
  prevQuestionList: state.questionnaire.form.questionList.prevQuestionList,
  questionListId : state.questionnaire.form.form.questionListId,
  isAnswerReadOnly : state.questionnaire.form.form.isAnswerReadOnly,
  fillingUpStatus : state.questionnaire.form.subFormList.fillingUpStatus,
  subFormId : state.questionnaire.form.form.subFormId,
  questionnaireId : state.questionnaire.form.form.questionnaireId,
  questionsAdmin : state.questionnaire.form.questionsAdmin.data,
  answersAdmin : state.questionnaire.form.questionsAdmin.dataAnswer,
  dataQuestion1069 : state.questionnaire.form.question.dataQuestion1069,
  formId : state.questionnaire.form.form.formId,
});

const mapDispatchToProps = {
  setQuestionListData : actions.questionnaire.form.questionList.setQuestionListData,
  setFillingUpStatus : actions.questionnaire.form.subFormList.setFillingUpStatus,
  activateAnswer : actions.questionnaire.form.answer.activateAnswer,
  getQuestion1069 : actions.questionnaire.form.question.getQuestion1069,
  setQuestionFilledAll : actions.questionnaire.form.question.setQuestionFilledAll,
  removeQuestionFilledAll : actions.questionnaire.form.question.removeQuestionFilledAll,
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionContainer)));
