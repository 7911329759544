import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {translations} from '../../dictionary/translations';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {ERROR_MODE, USER_ROLE_DIC_ID} from "../../util";
import ErrorContainer from "../../containers/Error";
import {withRouter} from "react-router-dom";
import Loading from "../Loading";
import Error from "../Error";
import {style} from "../../styles/style.js";
import QuestionBox from "../QuestionBox";
import FormHelperText from '@material-ui/core/FormHelperText';
import SelectFromList from './SelectFromList';


const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: 400,
    height: 'auto',
    left: 'calc(50vw - 200px)',
    top: 'calc(50vh - 200px)',
    border: `3px solid ${style.gray}`,
    borderRadius: 20,
    paddingBottom: 20,
    '@media only screen and (max-width: 768px)' : {
          width: '70vw',
          left: '15vw',
    },
  },
  form: {
    width: '90%',
    marginTop: 10,
  },
  label: {
      color: `${style.violet} !important`,
  },
  input : {
      color: `${style.violet}`,
      '&:before' : {
        borderBottom: `2px solid ${style.rose_light} !important`,
      },
      '&:after' : {
        borderBottom: `2px solid ${style.rose_dark}`,
      },
  },
  backButton : {
    transition: 'background-color ease 0.5s',
    backgroundColor: style.gray,
    marginRight: '10%',
    color: 'white',
    outline: 'none',
    '&:hover' : {
      backgroundColor: style.gray_hover,
    },
  },
  submitButton : {
      transition: 'background-color ease 0.5s',
      outline: 'none',
      color: 'white',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
  select : {
    '&:before' : {
      borderBottom: `2px solid ${style.rose_light} !important`,
    },

    '&:after' : {
      borderBottom: `2px solid ${style.rose_dark}`,
    }
  },
  helper : {
    color: 'red',
  }
};


export class FarmAdd extends React.Component {

  state = {
    countryDicId : '',
    regionDicId : '',
    farmTypeId: '',
    email : '',
    description : '',
    isRegionActive : false,
    firstAddingError: true,
    emailValidationError: false,
    emailRequiredError: false,
    emailSmallLettersError: false,
    countryRequiredError: false,
    farmTypeRequiredError: false,
    doesUserExist: null,
    isUserFarmer: null,
    selectFromListVisible: false,
    farmerId: null,
  }

  handleChange = event => {
    const {emailValidator, user} = this.props;
    this.setState({ [event.target.name]: event.target.value }, () => {
      const { farmTypeId, email, countryDicId, firstAddingError} = this.state;
      if (farmTypeId) {
        this.setState({ farmTypeRequiredError: false });
      }
      if (countryDicId) {
        this.setState({ countryRequiredError: false });
      }
      if (user.roleDicId !== USER_ROLE_DIC_ID.HODOWCA){
            if (email) {
                this.setState({ emailRequiredError: false });
                if (email.match(emailValidator) != null){
                  this.setState({ emailValidationError: false });
                  const onlyLetters = email.split('@').join('').split('.').join('');
                  if (onlyLetters === onlyLetters.toLowerCase()){
                      this.setState({ emailSmallLettersError: false });
                  }
                  else{
                    this.setState({ emailSmallLettersError: true, isUserFarmer : null});
                  }
                }
                else{
                  this.setState({ emailValidationError: true, emailSmallLettersError: false, isUserFarmer : null});
                }
            }
            else if (firstAddingError==false){
              this.setState({ emailRequiredError: true, emailValidationError: false, isUserFarmer : null});
            }
            else{
              this.setState({emailValidationError: false, isUserFarmer : null});
            }
      }

      if (farmTypeId && countryDicId && (user.roleDicId !== USER_ROLE_DIC_ID.HODOWCA ? email : true) ) this.setState({firstAddingError: false});
    });
  };

  setIsRegionActive = (isRegionActive) => {
    this.setState({ isRegionActive: isRegionActive });
  }


  componentDidMount() {
    const {locale, getCountryList, getFarmTypeList} = this.props;
    getCountryList(locale.id);
    getFarmTypeList(locale.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {getRegionList, gottenUser, checkFarm} = this.props;
    const {countryDicId} = this.state;
    if (countryDicId !== prevState.countryDicId) {
      getRegionList(countryDicId)
        .then(() => {
          this.setIsRegionActive(true);
        }).catch(() => {
          this.setIsRegionActive(false);
        });
    }
    if (gottenUser !== prevProps.gottenUser){
      if (gottenUser === 'empty'){
        this.setState({doesUserExist : false, isUserFarmer : null});
      }
      else if (gottenUser !== null){
          if (gottenUser.roleDicId !== 4){
            this.setState({isUserFarmer : false});
          }
          else {
              this.setState({farmerId : gottenUser.id, doesUserExist : true, isUserFarmer : null});
              this.addFarmPrepare(false);
          }
      }
    }
  }

  showCountryList = () => {
    const {locale, countryList} = this.props;
    if (countryList && countryList.items && countryList.items.length > 0 && locale) {
      return (countryList.items.map(country => (
        <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
      )))
    } else {
      return (<MenuItem/>)
    }
  }

  showRegionList = () => {
    const {locale, regionList} = this.props;
    if (regionList && regionList.items && regionList.items.length > 0 && locale) {
      return (regionList.items.map(region => (
        <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
      )))
    } else {
      return (<MenuItem/>)
    }
  }

  showFarmTypeList = () => {
    const {farmTypeList} = this.props;
    if (farmTypeList && farmTypeList.items && farmTypeList.items.length > 0) {
      return (farmTypeList.items.map(farmType => (
        <MenuItem key={farmType.id} value={farmType.id}>{farmType.text}</MenuItem>
      )))
    } else {
      return (<MenuItem/>)
    }
  }

  checkIfUserExist = (email, event) => {
    const { getUser, user, emailValidator } = this.props;
    const { farmTypeId, countryDicId} = this.state;
    if (event) event.preventDefault();
    if (!farmTypeId) {
      this.setState({ farmTypeRequiredError: true });
    }
    if (!countryDicId) {
      this.setState({ countryRequiredError: true });
    }
    if (user.roleDicId !== USER_ROLE_DIC_ID.HODOWCA){
        if (!email) {
          this.setState({ emailRequiredError: true });
        }
        else if (email.match(emailValidator) == null) {
          this.setState({ emailValidationError: true });
        }
    }
    this.setState({ firstAddingError: false });

    if((!this.state.farmTypeRequiredError)&&
       (!this.state.countryRequiredError)&&
       (!this.state.emailRequiredError)&&
       (!this.state.firstAddingError)&&
       (!this.state.emailSmallLettersError)&&
       (!this.state.emailValidationError) ){
            getUser(email);
        }
  }

  addFarmPrepare = (force, event) => {
    const { addFarm, user, locale, emailValidator } = this.props;
    const { farmTypeId, email, countryDicId, firstAddingError, description, regionDicId} = this.state;
    if (event) event.preventDefault() ;
    if (!farmTypeId) {
      this.setState({ farmTypeRequiredError: true });
    }
    if (!countryDicId) {
      this.setState({ countryRequiredError: true });
    }
    if (user.roleDicId !== USER_ROLE_DIC_ID.HODOWCA){
        if (!email) {
          this.setState({ emailRequiredError: true });
        }
        else if (email.match(emailValidator) == null) {
          this.setState({ emailValidationError: true });
        }
    }
    this.setState({ firstAddingError: false });

    if((!this.state.farmTypeRequiredError)&&
       (!this.state.countryRequiredError)&&
       (!this.state.emailRequiredError)&&
       (!this.state.firstAddingError)&&
       (!this.state.emailSmallLettersError)&&
       (!this.state.emailValidationError) ){
            const body = {
              countryDicId: countryDicId,
              regionDicId : regionDicId,
              farmTypeId : farmTypeId,
              email : email,
              description : description,
              usersId : user.id,
            }
            addFarm(JSON.stringify(body), locale.localeName, force);
        }
  }

  handleRegionInputProps = () => {
    const { isRegionActive } = this.state;
    return {
      name: 'regionDicId',
      id: 'region',
      disabled: !isRegionActive
    };
  }

  handleAddFQuestionnaireSuccess = () => {
    const {resetFarmAdding, history} = this.props;
    resetFarmAdding();
    history.push('main-navigation');
  }

  handleAddQuestionnaireError = () => {
    const {resetFarmAdding} = this.props;
    resetFarmAdding();
  }

  render() {
    const { classes, history, dictionary, locale, countryList, regionList, addFarmStatus, farmTypeList, checkFarm, user, farmItems, resetStateGetUser, farmList, getFarmList, resetStateGetFarmList, assignExpert, assignExpertStatus, resetStateAssign } = this.props;
    const {countryDicId, regionDicId, farmTypeId, email, doesUserExist, description, selectFromListVisible, farmerId} = this.state;

    if (selectFromListVisible === true){
      return (
          <SelectFromList
              user={user}
              locale={locale}
              farmerId={farmerId}
              farmList={farmList}
              farmItems={farmItems}
              getFarmList={getFarmList}
              resetStateGetFarmList={resetStateGetFarmList}
              assignExpert={assignExpert}
              assignExpertStatus={assignExpertStatus}
              resetStateAssign={resetStateAssign}
          />
      )
    }


    if (doesUserExist === false){
      return(
          <QuestionBox
            title={translations.INFO[locale.localeName]}
            content={translations.FARMER_DOES_NOT_EXIST[locale.localeName]}
            leftButtonText={translations.NO[locale.localeName]}
            rightButtonText={translations.YES[locale.localeName]}
            leftButtonCallback={() => {this.setState({doesUserExist: null}); resetStateGetUser()}}
            rightButtonCallback={() => {this.addFarmPrepare(false); this.setState({doesUserExist: null})}}
          />
      )}



    if (doesUserExist && checkFarm && addFarmStatus.success === false){
      return(
          <QuestionBox
            title={translations.ADD_FARM[locale.localeName]}
            content={translations.FARMER_HAS_ALREADY_FARM[locale.localeName]}
            leftButtonText={translations.CANCEL[locale.localeName]}
            rightButtonText={translations.NEW_FARM[locale.localeName]}
            middleButtonText={translations.SELECT_FROM_LIST[locale.localeName]}
            leftButtonCallback={() => {this.setState({doesUserExist: null}); resetStateGetUser()}}
            rightButtonCallback={() => this.addFarmPrepare(true)}
            middleButtonCallback={() => this.setState({selectFromListVisible : true})}
          />
      )};

    if (countryList.loading === true || regionList.loading === true || addFarmStatus.loading === true || farmTypeList.loading === true) {
      return (
        <Loading/>
      )
    } else if (countryList.error === true || regionList.error === true || farmTypeList.error === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={translations.ERROR_WHILE_DOWNLOADING_DATA}
                        mode={ERROR_MODE.REFRESH}/>
      )
    } else if (addFarmStatus.error === true && !checkFarm) {
      return (
        <ErrorContainer Layout={Error}
                        content={translations.ERROR_WHILE_UPLOADING_DATA}
                        callback={this.handleAddQuestionnaireError}/>
      )
    } else if (addFarmStatus.success === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={translations.FARM_ADDED_SUCCESSFULLY}
                        callback={this.handleAddFQuestionnaireSuccess}/>
      )
    } else if (countryList.success === true && farmTypeList.success === true) {
      return (
        <Paper className={classes.paper}>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="country">{translations.COUNTRY[locale.localeName]}</InputLabel>
              <Select
                value={countryDicId}
                onChange={this.handleChange}
                className={classes.select}
                inputProps={{
                  name: 'countryDicId',
                  id: 'country',
                }}
              >
                {this.showCountryList(dictionary)}
              </Select>
              {this.state.countryRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel className={classes.label} htmlFor="region">{translations.REGION[locale.localeName]}</InputLabel>
              <Select
                value={regionDicId}
                onChange={this.handleChange}
                className={classes.select}
                inputProps={this.handleRegionInputProps()}
              >
                {this.showRegionList(countryDicId)}
              </Select>
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="farmType">{translations.FARM_TYPE[locale.localeName]}</InputLabel>
              <Select
                value={farmTypeId}
                onChange={this.handleChange}
                className={classes.select}
                inputProps={{
                  name: 'farmTypeId',
                  id: 'farmType'
                }}
              >
                {this.showFarmTypeList()}
              </Select>
              {this.state.farmTypeRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
            </FormControl>
            {user.roleDicId !== USER_ROLE_DIC_ID.HODOWCA ?
                <FormControl margin="normal" required fullWidth>
                  <InputLabel className={classes.label} htmlFor="email">{translations.FARMER_EMAIL_ADDRESS[locale.localeName]}</InputLabel>
                  <Input className={classes.input} id="email" name="email" autoComplete="email" onChange={this.handleChange} value={email} />
                  {this.state.emailRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
                  {this.state.emailValidationError && <FormHelperText className={classes.helper}>{translations.INVALID_EMAIL[locale.localeName]}</FormHelperText>}
                  {this.state.emailSmallLettersError && <FormHelperText className={classes.helper}>{translations.EMAIL_SMALL_LETTERS[locale.localeName]}</FormHelperText>}
                  {this.state.isUserFarmer === false && <FormHelperText className={classes.helper}>{translations.EMAIL_HAS_TO_BELONG_TO_FARMER[locale.localeName]}</FormHelperText>}
                </FormControl>
              : null }
            <FormControl margin="normal" required fullWidth>
              <TextField
                id="description"
                label={translations.DESCRIPTION[locale.localeName]}
                className={classes.input}
                onChange={this.handleChange}
                name="description"
                multiline
                rows="3"
                value={description}
              />
            </FormControl>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20, outline: 'none'}}>
              <Button
                fullWidth
                size="large"
                className={classes.backButton}
                onClick={() => {history.goBack()}}
              >
                {translations.GO_BACK[locale.localeName]}
              </Button>
              <Button
                type="submit"
                fullWidth
                size="large"
                className={classes.submitButton}
                onClick={(event) => {user.roleDicId === USER_ROLE_DIC_ID.HODOWCA ? this.addFarmPrepare(false, event) : this.checkIfUserExist(email, event)}}
              >
                {translations.SUBMIT[locale.localeName]}
              </Button>
            </div>
          </form>
        </Paper>
      )
    }
    return (<div/>)
  }
}

export default withRouter(withStyles(styles)(FarmAdd));
