import {URL} from '../../config';

function registerUser(form, language) {
  return dispatch => {
    dispatch(registerUserBegin());
    return registerUserStart(form, language)
      .then((userData) => {
        dispatch(registerUserSuccess(userData));
      })
      .catch((errorType) => {
        dispatch(registerUserFailure(errorType))
      });
  };
}

const registerUserBegin = () => ({
  type: 'REGISTER_USER_BEGIN'
});

function registerUserStart(form, language) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/user/register`, {
      method: 'POST',
      body: form,
      headers: {
        'Accept-Language': language,
      },
    }).then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data)
        } else {
          reject(json.error);
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const registerUserSuccess = (userData) => ({
  type: 'REGISTER_USER_SUCCESS',
  userData: userData,
});

const registerUserFailure = (errorType) => ({
  type: 'REGISTER_USER_FAILURE',
  error: true,
  errorType
});

const resetState = () => ({
  type: 'REGISTER_USER_RESET'
});

const index = {
  registerUser,
  resetState
}

export default index;
