import React from 'react';
import Chart from 'chart.js';


const wrappedLabels = (labels) => labels.map(elem =>{
  const splitted = elem.split(' ');
  const newLabels = []
  let lineLength = 0;
  let newLabel = '';
  splitted.forEach((word, index) => {
    lineLength += word.length;
    newLabel += `${word} `
    if (lineLength > 20){
      newLabels.push(newLabel);
      lineLength = 0;
      newLabel = '';
    } else if (index === splitted.length-1){
      newLabels.push(newLabel);
    } 
  })

  return newLabels
})

class RadarChart extends React.Component {

  chartRef = React.createRef();

  createChart = (data, title, setChoosenChildren, choosenChildren, dataAverage) => {
    const labels = [];
    const values = [];
    const ids = []
 
    data.forEach(el => {
          labels.push(el.name);
          values.push(el.value ? el.value.toFixed(2) : 0);
          ids.push(el.indicator_dic_id);
    });

    const dataForChart = {
        labels: wrappedLabels(labels),
        ids: ids,
        datasets: [{
          backgroundColor: "rgba(200,0,0,0.2)",
          data: values,
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'black',
          borderWidth: '4',
          radius: 4,
          hoverRadius: 10,
          hitRadius: 50,
        },
        {
          data: dataAverage,
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: 'blue',
          borderWidth: '2',
          radius: 2,
          hoverRadius: 8,
          hitRadius: 30,
        }]
      };

    this.myChart = new Chart(this.chartRef.current, {
        type: 'radar',
        data: dataForChart,
        options: {
            onHover : function (evt, item) {
                if (item[0] !== undefined){
                    evt.target.style.cursor = 'pointer';
                }
                else{
                    evt.target.style.cursor = 'default';
                }
            },
            onClick : function (evt, item) {
                if (item[0] !== undefined){
                  const choosenChildId =  this.data.ids[item[0]._index];
                  setChoosenChildren(choosenChildId);
                }
            },
            title: {
                display: true,
                text: title==='Themes' ? title : `${title} - ${choosenChildren}`,
                fontSize: 20,
            },
            legend : {
                display: false,
            },
            scale: { 
                pointLabels: {
                    fontSize: 15,
                },
                ticks: {
                    min: 0,
                    stepSize: 20,
                    max: 100,
                },
                scaleStep: 20,
                gridLines: { 
                    circular: true,
                    
                    color: ['rgba(252, 3, 3, 0.8)',    
                            'rgba(252, 161, 3, 0.8)',   
                            'rgba(219, 216, 29, 0.8)', 
                            'rgba(169, 252, 3, 0.8)',  
                            'rgba(3, 252, 19, 0.8)'
                    ],
                    lineWidth: 10
                } ,
                angleLines: { 
                    color: '#4b5959',
                }
            }
        }
    });
  }

  componentDidMount() {
    const { data, title, setChoosenChildren, choosenChildren, dataAverage} = this.props;
    this.createChart(data, title, setChoosenChildren, choosenChildren, dataAverage);
  }

  componentDidUpdate(prevProps) {
    const { data, title, choosenChildren, dataAverage} = this.props;
    if (choosenChildren !== prevProps.choosenChildren || dataAverage.length !== prevProps.dataAverage){
        const labels = [];
        const values = [];
        const ids = [];
        data.forEach(el => {
                labels.push(el.name);
                values.push(el.value ? el.value.toFixed(2) : 0);
                ids.push(el.indicator_dic_id);
        });
        this.myChart.data.labels = wrappedLabels(labels);
        this.myChart.data.ids = ids;
        this.myChart.options.title.text = title==='Themes' ? title : `${title} - ${choosenChildren}`;
        this.myChart.data.datasets[0].data = values;
        this.myChart.data.datasets[1].data = dataAverage;
        this.myChart.update(data);
    }
  }

  render() {
    return <canvas ref={this.chartRef}/>
  }

}


export default RadarChart;
