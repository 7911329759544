import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class QuestionnaireAddContainer extends React.Component {

  state = {
    questionnaireDicId : ''
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {

    const {
      Layout,
      dictionary,
      locale,
      getQuestionnaireDicList,
      addQuestionnaire,
      questionnaireAdd,
      questionnaireDicList,
      resetQuestionnaireAdding,
      user,
      setQuestionnaireId,
      setQuestionnaireDicId,
      farmId,
      resetFarm,
      setQuestionnaireReadOnly,
      modeAdmin
    } = this.props;

    const {
      questionnaireDicId
    } = this.state;

    const {
      handleChange
    } = this;

    return (
      <Layout
        dictionary={dictionary}
        locale={locale}
        getQuestionnaireDicList={getQuestionnaireDicList}
        addQuestionnaire={addQuestionnaire}
        questionnaireAdd={questionnaireAdd}
        questionnaireDicList={questionnaireDicList}
        resetQuestionnaireAdding={resetQuestionnaireAdding}
        user={user}
        setQuestionnaireId={setQuestionnaireId}
        setQuestionnaireDicId={setQuestionnaireDicId}
        questionnaireDicId={questionnaireDicId}
        farmId={farmId}
        resetFarm={resetFarm}
        handleChange={handleChange}
        setQuestionnaireReadOnly={setQuestionnaireReadOnly}
        modeAdmin={modeAdmin}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  questionnaireDicList: {
    loading: state.dictionary.questionnaireList.loading,
    error: state.dictionary.questionnaireList.error,
    success: state.dictionary.questionnaireList.success,
    items: state.dictionary.questionnaireList.items,
  },
  user: state.user.login.user,
  farmId: state.farm.farm.id,
  questionnaireAdd : state.questionnaire.add
});

const mapDispatchToProps = {
  getQuestionnaireDicList: actions.dictionary.questionnaireList.getQuestionnaireList,
  addQuestionnaire: actions.questionnaire.add.addQuestionnaire,
  resetQuestionnaireAdding : actions.questionnaire.add.resetState,
  setQuestionnaireId : actions.questionnaire.form.form.setQuestionnaireId,
  setQuestionnaireDicId : actions.questionnaire.form.form.setQuestionnaireDicId,
  resetFarm: actions.farm.farm.resetState,
  setQuestionnaireReadOnly : actions.questionnaire.form.form.setQuestionnaireReadOnly
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireAddContainer);
