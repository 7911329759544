import React from "react";
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import QuestionTemplate from "./Template";
import {translations} from "../../../../dictionary/translations";
import QuestionTemplateContainer from "../../../../containers/questionnaire/form/question/Template";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {style} from "../../../../styles/style.js";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import AnswerTemplate from '../AnswerTemplate';
import AnswerTemplateContainer from '../../../../containers/questionnaire/form/AnswerTemplate';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { green, red } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import Popover from "@material-ui/core/Popover";
import ListItem from '@material-ui/core/ListItem';


const styles = theme => ({
  select : {
    '&:before' : {
      borderBottom: `2px solid ${style.roz_jasny} !important`,
    },

    '&:after' : {
      borderBottom: `2px solid ${style.roz_ciemny}`,
    }
  },
  label: {
    color: `${style.fiolet} !important`,
    '@media only screen and (max-width: 600px)':{
        fontSize: style.mobile_font_size,
    }
  },
  popover: {
    pointerEvents: 'none',
    maxWidth: '60vw'
  },
});

const MySwitch = withStyles({
  switchBase: {
    color: red[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

class ChoiceQuestion extends React.Component {

  componentDidMount(){
    const {getAnswers, question, modeAdmin, answerLastPen, setAnswers, persistAnswersOnTimeout} = this.props;
    const {value} = this.state;
    if (modeAdmin===true){
        getAnswers(question.id);
    }
    else{
      if (question.answers[0] && !value){
        if (question.answers[0].answerListId) this.setState({value: question.answers[0].answerListId });
      }
      else if (answerLastPen){
        this.setState({value: answerLastPen});
        setAnswers([{
          answerListId: answerLastPen,
          answer: null
        }]);
        persistAnswersOnTimeout();
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {getAnswers, question, answersAdmin, answerLastPen, setAnswers, persistAnswersOnTimeout} = this.props;
    const {value} = this.state;
    if (this.state.add === false && prevState.add === true) {
      getAnswers(question.id)
    }
    else if (this.state.edit === false && prevState.edit === true) {
      getAnswers(question.id)
    }
    if (answersAdmin !== prevProps.answersAdmin){
      let act = [];
          if (answersAdmin){
            if (answersAdmin.length!==0){
                  answersAdmin.map((item, key) => (
                  act.push(item.isActive)
                ))
            }
          }
          this.setState({activation : act});
    }

    if (answerLastPen !== prevProps.answerLastPen && !value){
      this.setState({value: answerLastPen});
      setAnswers([{
        answerListId: answerLastPen,
        answer: null
      }]);
      persistAnswersOnTimeout();
    }
  }

  state = {
    activation: [],
    add: false,
    edit: false,
    selectedAnswer: null,
    anchorEl : null,
    value : '',
  }

  handleChange = async (event) => {
    const { setAnswers, persistAnswersOnTimeout, modeAdmin, question } = this.props;
    const {value} = this.state;
    if (modeAdmin !== true){ 
        const val = event.target.value;
        if (val){
            const isActive = question['answerList'].filter(answer => answer.id===val)[0].isActive;
            if (isActive){
                const newValue = Number.parseInt(val);
                if (newValue!=value){
                  await setAnswers([{
                    answerListId:  newValue,
                    answer: null
                  }]);
                  persistAnswersOnTimeout();
                  this.setState({value: newValue});
                }
                else{
                  await setAnswers([]);
                  persistAnswersOnTimeout();
                  this.setState({value: ''});
                }
            }
        }
    }
  };

  choiceList = () => {
    const { question, modeAdmin, answersAdmin } = this.props;
    const { activation } = this.state;
    if (modeAdmin !== true){
      return question['answerList'].map(answer => (
        <MenuItem  key={answer.id} disabled={answer.isActive ? false : true}  value={answer.id}>{answer.text}</MenuItem >
      ))
    }
    else{
      if (answersAdmin){
        const answersAdminSorted = answersAdmin.sort(function(a, b) {
          return a.orderKey - b.orderKey;
        });
        return answersAdminSorted.map((answer, key) => (
          <MenuItem key={answer.id} value={answer.id} style={{paddingRight: 100}}>
                    {answer.text.translations[0].text}
                    <ListItemSecondaryAction style={{display: 'flex', width: 80, justifyContent: 'space-between', alignItems: 'center'}} >
                      <IconButton aria-label="edit" style={{flexGrow:1}} onClick={() => this.setState({edit: true, selectedAnswer: answer.id})}>
                          <EditOutlinedIcon fontSize='small'/>
                      </IconButton>
                      <FormGroup >
                          <MySwitch  size="small" style={{flexGrow:1}} checked={activation[key] ? activation[key] : false} onChange={() => this.setActive(answer.id, !activation[key], key)} />
                      </FormGroup>
                    </ListItemSecondaryAction>
          </MenuItem>
        ))
      }
    }
  }

  setActive = (id, active, key) => {
    const {activateAnswer} = this.props;
    let act = [...this.state.activation];
    act[key] = active;
    activateAnswer(id, active);
    this.setState({activation : act});
  }

  closeEditOrAddWindow = () => {
    this.setState({
      add : false,
      edit: false
    })
  }

  handlePopoverOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  render() {
    const { classes, locale, questionNumber, question, answersPersistStatus, isSideListOpen, isAnswerReadOnly, isQuestionReadOnly, modeAdmin, activateQuestion, answerLastPen, setAnswers, persistAnswersOnTimeout} = this.props;
    const { add, edit, selectedAnswer, anchorEl, value } = this.state;
    const open = Boolean(anchorEl);
    return (
          <QuestionTemplateContainer 
              Layout={QuestionTemplate} 
              modeAdmin={modeAdmin} 
              questionNumber={questionNumber} 
              question={question} 
              answersPersistStatus={answersPersistStatus} 
              isQuestionReadOnly={isQuestionReadOnly} 
              isAnswerReadOnly={isAnswerReadOnly}
              activateQuestion={activateQuestion}
              isSideListOpen={isSideListOpen}
            >
            <FormControl required style={{minWidth: 200}}>
              <InputLabel className={classes.label} htmlFor="locale">{translations.CHOOSE_ANSWER[locale.localeName]}:</InputLabel>
                  <Select
                    value={modeAdmin !== true ? value : ''}
                    onClick={this.handleChange}
                    className={classes.select}
                  >
                    {this.choiceList()}
                    
                  </Select>
            </FormControl>
            <Popover
                className={classes.popover}
                open={open}
                onClose={this.handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div style={{margin: 15}}>
                    {translations.ADD_ANSWER[locale.localeName]}
                </div>
            </Popover>
            {modeAdmin === true ? 
                  <Fab size="small" color="secondary" aria-label="add" onClick={() => this.setState({add: true})} onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose}>
                          <AddIcon size='small'/>
                  </Fab>
                :
                  null
            }
            {edit === true ?
                    <AnswerTemplateContainer Layout={AnswerTemplate}  questionId={question.id} selectedAnswer={selectedAnswer} mode='edit' locale={locale} close={this.closeEditOrAddWindow} />
            :null
            }
            {add === true ?
                    <AnswerTemplateContainer Layout={AnswerTemplate}  questionId={question.id} mode='add' locale={locale} close={this.closeEditOrAddWindow} />
            :null
            }
          </QuestionTemplateContainer>
      )
  }
}

export default withStyles(styles)(ChoiceQuestion);
