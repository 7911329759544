import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import {translations} from '../../dictionary/translations';
import {withRouter} from "react-router-dom";
import {ERROR_MODE, trimDate} from "../../util";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Loading from "../Loading";
import Error from "../Error";
import ErrorContainer from "../../containers/Error";
import {style} from "../../styles/style.js";
import QuestionBox from "../QuestionBox";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {URL} from '../../config';

const styles = {
  paper : {
    position: 'relative',
    marginBottom: 30,
    borderTopRightRadius: 20,
    overflowX:'auto'
  },
  tableHeader : {
    textAlign: 'center',
    fontWeight: 'bold',
    paddingLeft: 3,
    paddingRight: 3,
  },
  tableCell : {
    textAlign: 'center',
    color: style.violet,
    paddingLeft: 3,
    paddingRight: 3,
    '@media only screen and (max-width: 768px)' : {
      fontSize: 11,
    },
  },
  tableCellButtons : {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    paddingLeft: 3,
    paddingRight: 3,
    '@media only screen and (max-width: 500px)' : {
      flexDirection: 'column',
    },
  },
  button : {
    marginTop: 5,
    marginBottom: 5,
    minWidth: '20%',
    color: style.violet,
    fontSize: 10,
    marginRight: '5%',
    marginLeft: '5%',
    backgroundColor: 'white',
    transition: 'background-color ease 0.5s, color ease 0.5s',
    '&:hover' : {
      color: 'white',
    },
    '@media only screen and (max-width: 768px)' : {
      fontSize: 8,
    },
  },
  buttonNew : {
    border: `2px solid ${style.rose_dark}`,
    '&:hover' : {
        backgroundColor: style.rose_dark,
    },
  },
  buttonEdit: {
    border: `2px solid ${style.rose_dark}`,
    '&:hover' : {
      backgroundColor: style.rose_dark,
    },
  },
  buttonDelete: {
    border: `2px solid #ff6666`,
    '&:hover' : {
      backgroundColor: '#ff6666',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
};

export class FarmList extends React.Component {

  getFarmList = () => {
    const {changeOrder} = this.props;
    const {orderBy, order} = this.state;
    changeOrder(order, orderBy);
  }

  componentDidMount() {
    const {getCountryList, locale, getFarmTypeList, items} = this.props;
    this.getFarmList();
    getFarmTypeList(locale.id);
    getCountryList(locale.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { items } = this.props;
    if (prevProps.searchText !== this.props.searchText) {
      this.getFarmList();
    }
    if (prevProps.removeFarmStatus.loading === true && this.props.removeFarmStatus.success === true) {
      this.setIsDeleteDialogOpen(false);
      this.getFarmList();
    }
    if (prevProps.items !== items && items){
      let selectedCountries = items.map(el => el.countryDicId);
      selectedCountries = selectedCountries.filter((x, i, a) => a.indexOf(x) == i)
      const regions_temp = [];
      async function loadData() {
          for (const el of selectedCountries){
            await fetch(`${URL}/region/list/${el}`)
              .then(response => response.json())
              .then(json => {
                if (json.success === true) {
                    for (const item of json.data){
                        regions_temp.push(item);
                    }
                }
              })
          }
      }
      loadData()
          .then(() => {
            this.setState({regions: regions_temp})
          });
    }

  }

  state = {
    isFarmWithQuestionnairesError : false,
    isDeleteDialogOpen : false,
    farmIdToRemove: null,
    order: 'asc',
    orderBy: 'farmCode',
    regions: [],
  }

  setIsDeleteDialogOpen = (isOpen) => {
    this.setState({
      isDeleteDialogOpen : isOpen
    })
  }

  setIsFarmWithQuestionnairesError = (isFarmWithQuestionnairesError) => {
    this.setState({
      isFarmWithQuestionnairesError : isFarmWithQuestionnairesError
    })
  }

  setFarmIdToRemove = (id) => {
    this.setState({farmIdToRemove : id});
  }

  deleteFarm = () =>{
    const {removeFarm} = this.props;
    const {farmIdToRemove} = this.state;
    removeFarm(farmIdToRemove);
  }

  onRequestSort = (event, property) => {
    const {changeOrder} = this.props;
    const {orderBy, order} = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({
      order : isDesc ? 'asc' : 'desc',
      orderBy : property
    }, () => {
      const {orderBy, order} = this.state;
      changeOrder(order, orderBy);
    });

  };

  createSortHandler = property => event => {
    this.onRequestSort(event, property);
  };

  render() {
    const { classes, locale, loading, error, success, items, setChoosenFarmType, pagination, setFarmId, history, removeFarmStatus, user, countryList, farmTypeList, regionList } = this.props;
    const { isFarmWithQuestionnairesError, isDeleteDialogOpen, order, orderBy, regions } = this.state;

    if (error === true || removeFarmStatus.error === true || countryList.error === true || farmTypeList.error === true) {
      return (
        <ErrorContainer Layout={Error} mode={ERROR_MODE.REFRESH} content={translations.ERROR_WHILE_DOWNLOADING_DATA}/>
      )
    }

    if (loading === true || removeFarmStatus.loading === true || countryList.loading === true || farmTypeList.loading === true) {
      return (
        <Loading/>
      )
    }

    if (success === true && countryList.success === true && farmTypeList.success === true) {
      return (
        <div>
            <Paper className={classes.paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader} key='farmCode' sortDirection={orderBy === 'farmCode' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'farmCode'}
                          direction={order}
                          onClick={this.createSortHandler('farmCode')}
                        >
                          {translations.FARM_NUMBER[locale.localeName]}
                          {orderBy === 'farmCode' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeader} key='countryDicId' sortDirection={orderBy === 'countryDicId' ? order : false}>
                      <TableSortLabel
                          active={orderBy === 'countryDicId'}
                          direction={order}
                          onClick={this.createSortHandler('countryDicId')}
                        >
                          {translations.COUNTRY[locale.localeName]}
                          {orderBy === 'countryDicId' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeader} key='regionDicId' sortDirection={orderBy === 'regionDicId' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'regionDicId'}
                          direction={order}
                          onClick={this.createSortHandler('regionDicId')}
                        >
                          {translations.REGION[locale.localeName]}
                          {orderBy === 'regionDicId' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeader} key='description' sortDirection={orderBy === 'description' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'description'}
                          direction={order}
                          onClick={this.createSortHandler('description')}
                        >
                          {translations.DESCRIPTION[locale.localeName]}
                          {orderBy === 'description' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeader} key='farmTypeId' sortDirection={orderBy === 'farmTypeId' ? order : false}>
                      <TableSortLabel
                          active={orderBy === 'farmTypeId'}
                          direction={order}
                          onClick={this.createSortHandler('farmTypeId')}
                        >
                          {translations.FARM_TYPE[locale.localeName]}
                          {orderBy === 'farmTypeId' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/*
                    <TableCell className={classes.tableHeader} key='email' sortDirection={orderBy === 'email' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'email'}
                          direction={order}
                          onClick={this.createSortHandler('email')}
                        >
                          {translations.FARMER_EMAIL_ADDRESS[locale.localeName]}
                          {orderBy === 'email' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                    </TableCell>
                    */}
                    <TableCell className={classes.tableHeader}>
                      {translations.FARMER_EMAIL_ADDRESS[locale.localeName]}
                    </TableCell>
                    <TableCell className={classes.tableHeader} key='numberQuestionnaire' sortDirection={orderBy === 'numberQuestionnaire' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'numberQuestionnaire'}
                          direction={order}
                          onClick={this.createSortHandler('numberQuestionnaire')}
                        >
                          {translations.QUESTIONNAIRE_COUNT[locale.localeName]}
                          {orderBy === 'numberQuestionnaire' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeader} key='dataCreation' sortDirection={orderBy === 'dataCreation' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'dataCreation'}
                          direction={order}
                          onClick={this.createSortHandler('dataCreation')}
                        >
                        {translations.ADDING_DATE[locale.localeName]}
                          {orderBy === 'dataCreation' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      {translations.ACTIONS[locale.localeName]}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FarmListData
                    farmTypeList={farmTypeList}
                    countryList={countryList}
                    regions={regions}
                    classes={classes}
                    items={items}
                    locale={locale}
                    setFarmId={setFarmId}
                    history={history}
                    setIsFarmWithQuestionnairesError={this.setIsFarmWithQuestionnairesError}
                    user={user}
                    setIsDeleteDialogOpen={this.setIsDeleteDialogOpen}
                    setFarmIdToRemove={this.setFarmIdToRemove}
                    order={order}
                    orderBy={orderBy}
                    setChoosenFarmType={setChoosenFarmType}
                  />
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      rowsPerPage={pagination.rowsPerPage}
                      count={pagination.total}
                      page={pagination.page}
                      onChangePage={pagination.handleChangePage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          {isFarmWithQuestionnairesError === true ? <ErrorContainer Layout={Error}
                            content={translations.CANNOT_REMOVE_FARM_WITH_ASSIGNED_QUESTIONNAIRES}
                            callback={() => {
                              this.setIsFarmWithQuestionnairesError(false);
                            }}/> : null}
        {isDeleteDialogOpen === true ? <QuestionBox title={translations.DELETE_FARM[locale.localeName]}
        content={translations.ARE_YOU_SURE_TO_REMOVE[locale.localeName]}
        leftButtonText={translations.CANCEL[locale.localeName]}
        rightButtonText={translations.REMOVE[locale.localeName]}
        leftButtonCallback={() => this.setIsDeleteDialogOpen(false)}
        rightButtonCallback={() => this.deleteFarm()}
        /> : null}

          </div>
      )
    }
    return (<div/>)

  }
}

class FarmListData extends React.Component {

  state = {
    newItems: []
  }

  componentDidMount(){
    const { items, farmTypeList, countryList } = this.props;
    const arr = items.map(el => {
      el.farmType = farmTypeList.items[el.farmTypeId-1].name;
      el.farmerEmail = el.farmer.email;
      el.countryName = countryList.items.filter(elem => elem.id===el.countryDicId)[0].name;
      return el;
    })
    this.setState({newItems : arr});
  }

  componentDidUpdate(prevProps){
    const {regions} = this.props;
    const {newItems} = this.state;
    if (prevProps.regions !== regions) {
        const arr = newItems.map(el => {
          el.regionDicId ? el.regionName = regions.filter(elem => elem.id===el.regionDicId)[0].name : el.regionName='';
          return el;
        });
        this.setState({newItems : arr});
    }
  }

  handleSubmitButton = (event) => {
    const {setFarmId, history, setChoosenFarmType} = this.props;
    const farmId = event.currentTarget.attributes.tablerowkey.value;
    const farmTypeId = event.currentTarget.attributes.farmTypeId.value;
    if (farmId && Number.isInteger(Number.parseInt(farmId))) {
      setChoosenFarmType(farmTypeId);
      setFarmId(Number.parseInt(farmId));
      history.push('add-questionnaire');
    }
  }

  handleEdit = (event) => {
    const {setFarmId, history} = this.props;
    const farmId = event.currentTarget.attributes.tablerowkey.value;
    const description = !event.currentTarget.attributes.description ? '' : event.currentTarget.attributes.description.value;
    if (farmId && Number.isInteger(Number.parseInt(farmId))) {
      setFarmId(Number.parseInt(farmId));
      history.push({
        pathname: 'edit-farm',
        description: description
      });
    }
  }

  handleDelete = (id, questionnaireNumber, setIsFarmWithQuestionnairesError, setIsDeleteDialogOpen, setFarmIdToRemove) => {
    if (questionnaireNumber > 0) {
      setIsFarmWithQuestionnairesError(true);
    }
    if (questionnaireNumber === 0) {
      setFarmIdToRemove(id);
      setIsDeleteDialogOpen(true);
    }
  }

  /*
  desc = (a, b, orderBy) => {
    if (typeof(a[orderBy]) !== 'string' && typeof(b[orderBy]) !== 'string'){
      a = a[orderBy];
      b = b[orderBy];
    }
    else{
      a = a[orderBy].toLowerCase();
      b = b[orderBy].toLowerCase();
    }

    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  }
  
  stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }
  */
  render() {
  const { classes, locale, setIsFarmWithQuestionnairesError, user, setIsDeleteDialogOpen, setFarmIdToRemove, order, orderBy} = this.props;
  const {newItems} = this.state;

  const showDate = (UTCdate) => {
    const newUTCdate = UTCdate.replace(/-/g, '/').substring(0,10);
    const generalDate = new Date(newUTCdate);
    const newDate = new Date(generalDate.getTime() - generalDate.getTimezoneOffset()*60*1000);
    const year = newDate.getFullYear();
    const month = newDate.getUTCMonth();
    const monthAsString = (month + 1).toString().length < 2 ? '0'+(month + 1).toString() : (month + 1);
    const date = newDate.getDate();
    const dateAsString = (date).toString().length < 2 ? '0'+(date).toString() : date;
    const selectedDate = `${year}-${monthAsString}-${dateAsString}`;
    return <span>{selectedDate}</span>
  }

  return (
    newItems.map(item => (
      <TableRow hover key={item.id}>
        <TableCell className={classes.tableCell} component="th" scope="row">
          {item.farmCode}
        </TableCell>
        <TableCell className={classes.tableCell} component="th" scope="row">
          {item.countryName}
        </TableCell>
        <TableCell className={classes.tableCell} component="th" scope="row">
          {item.regionName}
        </TableCell>
        <TableCell className={classes.tableCell} component="th" scope="row">
          {item.description}
        </TableCell>
        <TableCell className={classes.tableCell} component="th" scope="row">
          { item.farmType }
        </TableCell>
        <TableCell className={classes.tableCell} component="th" scope="row">
          {item.farmerEmail}
        </TableCell>
        <TableCell className={classes.tableCell} component="th" scope="row">
          {item.numberQuestionnaire}
        </TableCell>
        <TableCell className={classes.tableCell} component="th" scope="row">
          {showDate(item.dataCreation)}
        </TableCell>
        <TableCell className={classes.tableCellButtons}>
          <Button variant="outlined" size="small" className={[classes.button, classes.buttonNew].join(' ')} tablerowkey={item.id} farmTypeId={item.farmTypeId} onClick={this.handleSubmitButton}>
            {translations.NEW_QUESTIONNAIRE[locale.localeName]}
          </Button>
          {user.id === item.usersId ?          
              <Button variant="outlined"  size="small" className={[classes.button, classes.buttonEdit].join(' ')} tablerowkey={item.id} description={item.description} onClick={this.handleEdit}>
                {translations.EDIT[locale.localeName]}
              </Button>
          : null }
          {user.id === item.usersId ?  
              <Button variant="outlined"  size="small" className={[classes.button, classes.buttonDelete].join(' ')} onClick={() => { this.handleDelete(item.id, item.anyQuestionnaireNumber, setIsFarmWithQuestionnairesError, setIsDeleteDialogOpen, setFarmIdToRemove) }}>
                {translations.DELETE[locale.localeName]}
              </Button>
          : null }
        </TableCell>
      </TableRow>
    ))
  )
  }
};

export default withRouter(withStyles(styles)(FarmList));
