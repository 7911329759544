import {URL} from '../../config';

function forgotPassword(email, language) {
  return dispatch => {
    dispatch(forgotPasswordBegin());
    return forgotPasswordStart(email, language)
      .then(() => {
        dispatch(forgotPasswordSuccess());
      })
      .catch((errorType) => {
        dispatch(forgotPasswordFailure(errorType))
      });
  };
}

const forgotPasswordBegin = () => ({
  type: 'FORGOT_PASSWORD_BEGIN'
});

function forgotPasswordStart(email, language) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/user/forgot-password?mail=${email}`, {
      method: 'POST',
      headers: {
        'Accept-Language': language,
      },
    }).then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject(json.error);
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const forgotPasswordSuccess = () => ({
  type: 'FORGOT_PASSWORD_SUCCESS'
});

const forgotPasswordFailure = (errorType) => ({
  type: 'FORGOT_PASSWORD_FAILURE',
  error: true,
  errorType: errorType,
});

const resetState = () => ({
  type: 'FORGOT_PASSWORD_RESET'
});

const index = {
  forgotPassword,
  resetState
}

export default index;
