import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {withStyles} from "@material-ui/core";
import {style} from "../../../../styles/style.js";
import {USER_ROLE_DIC_ID} from "../../../../util";
import clsx from 'clsx';

const styles = {
  appBar: {
    width: '80%',
    position: 'relative',
    marginTop: 40,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: 'transparent',
    zIndex: 1,
    '@media only screen and (max-width: 768px)' : {
      width: '95%',
    },
    '@media only screen and (max-width: 400px)' : {
      width: '95%',
      marginTop: 90,
    },
  },
  appBarSmall: {
    width: 'calc(80% - 350px)',
  },
  tabs : {
    width: 'calc(100% + 90px)',
    '@media only screen and (max-width: 768px)' : {
        width: 'calc(100% + 30px)',
  },
  },
  tab : {
    border: `3px solid ${style.gray}`,
    borderBottom: 'none',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    position: 'relative',
    '-webkit-box-shadow': '-10px 0px 5px -5px rgba(0,0,0,0.75)',
    '-moz-box-shadow': '-10px 0px 5px -5px rgba(0,0,0,0.75)',
    'box-shadow': '-10px 0px 5px -5px rgba(0,0,0,0.75)',
    backgroundColor: '#cccccc',
    opacity: 1,
    paddingLeft: '3vw',
    paddingRight: '3vw',
    '@media only screen and (max-width: 768px)' : {
        paddingLeft: '1vw',
        paddingRight: '1vw',
        fontSize: 10,
    },
  },
  tab2 : {
    left: -30,
    '@media only screen and (max-width: 768px)' : {
      left: -10,
    },
  },
  tab3 : {
    left: -60,
    '@media only screen and (max-width: 768px)' : {
      left: -20,
    },
  },
  tab4 : {
    left: -90,
    '@media only screen and (max-width: 768px)' : {
      left: -30,
    },
  },
  tab5 : {
    left: -120,
    '@media only screen and (max-width: 768px)' : {
      left: -40,
    },
  },
  active: {
    zIndex: 1,
    backgroundColor: 'white',
  },
  indicator : {
     backgroundColor: 'white',
  }
};


class FormBar extends React.Component {

  handleChange = (event, value) => {
    const {setNextFormId, modeAdmin} = this.props;
    setNextFormId(value);
  };

  render() {
    const { classes, formId, formListData, modeAdmin, isAnswerReadOnly, user, isSideListOpen } = this.props;
    let tabClass;
    const choosenId = formId - formListData[0].id;

    return (
        <AppBar position="static" color="default" className={isSideListOpen ? clsx(classes.appBarSmall, classes.appBar) : classes.appBar} >
          <Tabs
            value={modeAdmin===true ? formId ? formId : 1 : formId ? formId : formListData[0].id}
            variant="fullWidth"
            classes={{indicator : classes.indicator}}
            onChange={this.handleChange}
            className = {classes.tabs}
          >
            { formListData.map((item) => {
              tabClass = [classes.tab];

              if ((item.formDicId === 1 || item.id === 1) && choosenId === 0) {
                tabClass.push(classes.active);
              }
              if (item.formDicId === 2 || item.id === 2) {
                if (choosenId === 1) {
                  tabClass.push(classes.tab2, classes.active);
                } else {
                  tabClass.push(classes.tab2);
                }
              }
              if (item.formDicId === 3 || item.id === 3) {
                if (choosenId === 2) {
                  tabClass.push(classes.tab3, classes.active);
                } else {
                  tabClass.push(classes.tab3);
                }
              }
              if (item.formDicId === 4 || item.id === 4) {
                if (choosenId === 3) {
                  tabClass.push(classes.tab4, classes.active);
                } else {
                  tabClass.push(classes.tab4);
                }
              }
              if (item.formDicId === 5 || item.id === 5) {
                if (choosenId === 4) {
                  tabClass.push(classes.tab5, classes.active);
                } else {
                  tabClass.push(classes.tab5);
                }
              }
              if (modeAdmin !== true ){
                return <Tab label={item.formName} disabled={!isAnswerReadOnly && item.status===true && user.roleDicId !== USER_ROLE_DIC_ID.EKSPERT_NAUKOWY  ? true : false } style={!isAnswerReadOnly && item.status===true && user.roleDicId !== USER_ROLE_DIC_ID.EKSPERT_NAUKOWY ? {backgroundColor: style.gray, opacity: 1} : {} } key={item.id} value={item.id} className={tabClass.join(' ')}/>
              }
              else{
                return <Tab label={item.formText} key={item.id} value={item.id} className={tabClass.join(' ')}/>
              }
            })}

          </Tabs>
        </AppBar>
    )
  }
}

export default withStyles(styles)(FormBar);
