import {URL} from '../../config';
import {FARM_ADD_BEGIN, 
        FARM_ADD_FAILURE, 
        FARM_ADD_RESET, 
        FARM_ADD_SUCCESS,
        GET_USER_BEGIN, 
        GET_USER_FAILURE, 
        GET_USER_RESET, 
        GET_USER_SUCCESS,
        GET_FARM_LIST_BEGIN, 
        GET_FARM_LIST_FAILURE, 
        GET_FARM_LIST_RESET, 
        GET_FARM_LIST_SUCCESS,
        ASSIGN_BEGIN, 
        ASSIGN_FAILURE, 
        ASSIGN_SUCCESS,
        ASSIGN_RESET
        } from "../../reducers/farm/add";

function addFarm(form, language, force) {
  return dispatch => {
    dispatch(setBegin());
    return addFarmStart(form, language, force)
      .then(() => {
        dispatch(setSuccess());
      })
      .catch((checkFarm) => {
        dispatch(setFailure(checkFarm))
      });
  };
}

const setBegin = () => ({
  type: FARM_ADD_BEGIN
});

function addFarmStart(body, language, force) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/farm/add?force=${force}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        'Accept-Language': language,
      },
      body: body
    }).then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject(json.data);
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setSuccess = () => ({
  type: FARM_ADD_SUCCESS
});

const setFailure = (checkFarm) => ({

  type: FARM_ADD_FAILURE,
  checkFarm: checkFarm
});

const resetState = () => ({
  type: FARM_ADD_RESET
});


function getUser(email) {
  return dispatch => {
    dispatch(setBeginGetUser());
    return getUserStart(email)
      .then((data) => {
        dispatch(setSuccessGetUser(data));
      })
      .catch(() => {
        dispatch(setFailureGetUser())
      });
  };
}

const setBeginGetUser = () => ({
  type: GET_USER_BEGIN
});

function getUserStart(email) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/user/get?email=${email}`, {
      method: 'GET',
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
    }).then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data)
        } 
        else if (json.empty === true){
          resolve('empty');
        }
        else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setSuccessGetUser = (data) => ({
  type: GET_USER_SUCCESS,
  gottenUser: data,
});

const setFailureGetUser = () => ({
  type: GET_USER_FAILURE,
});

const resetStateGetUser = () => ({
  type: GET_USER_RESET
});


function getFarmList(id) {
  return dispatch => {
    dispatch(setBeginGetFarmList());
    return getFarmListStart(id)
      .then((data) => {
        dispatch(setSuccessGetFarmList(data));
      })
      .catch(() => {
        dispatch(setFailureGetFarmList())
      });
  };
}

const setBeginGetFarmList = () => ({
  type: GET_FARM_LIST_BEGIN
});

function getFarmListStart(id) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/farm/listByUserId?userId=${id}`, {
      method: 'GET',
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
    }).then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data)
        } 
        else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setSuccessGetFarmList = (data) => ({
  type: GET_FARM_LIST_SUCCESS,
  farmList: data,
});

const setFailureGetFarmList = () => ({
  type: GET_FARM_LIST_FAILURE,
});

const resetStateGetFarmList = () => ({
  type: GET_FARM_LIST_RESET
});


function assignExpert(farmId, userId) {
  return dispatch => {
    dispatch(setBeginAssignExpert());
    return assignExpertStart(farmId, userId)
      .then(() => {
        dispatch(setSuccessAssignExpert());
      })
      .catch(() => {
        dispatch(setFailureAssignExpert())
      });
  };
}

const setBeginAssignExpert = () => ({
  type: ASSIGN_BEGIN
});

function assignExpertStart(farmId, userId) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/farm/assign?userId=${userId}&farmId=${farmId}`, {
      method: 'POST',
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
    }).then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } 
        else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setSuccessAssignExpert = (data) => ({
  type: ASSIGN_SUCCESS,
});

const setFailureAssignExpert = () => ({
  type: ASSIGN_FAILURE,
});

const resetStateAssign = () => ({
  type: ASSIGN_RESET
});


const index = {
  addFarm,
  resetState,
  resetStateGetUser,
  getUser,
  getFarmList,
  resetStateGetFarmList,
  assignExpert,
  resetStateAssign
}

export default index;
