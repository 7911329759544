import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {translations} from '../../dictionary/translations';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {withRouter} from "react-router-dom";
import {style} from "../../styles/style.js";
import ErrorContainer from "../../containers/Error";
import Error from "../Error";
import Loading from "../Loading";
import QuestionBox from "../QuestionBox";
import FormHelperText from '@material-ui/core/FormHelperText';
import LogoImage from '../../img/logo.jpg'
import Image from 'material-ui-image'
import {ERROR_MODE} from "../../util";


const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: 400,
    height: 'auto',
    left: 'calc(50vw - 200px)',
    top: 'calc(50vh - 200px)',
    border: `3px solid ${style.gray}`,
    borderRadius: 20,
    paddingBottom: 20,
    '@media only screen and (max-width: 768px)' : {
          width: '70vw',
          left: '15vw',
    },
  },
  logo : {
    position: 'absolute',
    width: 120,
    height:120,
    left: 'calc(50vw - 60px)',
    top: 'calc(50vh - 260px)',
    zIndex: 1,
    '-webkit-box-shadow': '0px 0px 22px 0px rgba(79,74,79,1)',
    '-moz-box-shadow': '0px 0px 22px 0px rgba(79,74,79,1)',
    'box-shadow' : '0px 0px 22px 0px rgba(79,74,79,1)',
    '@media only screen and (max-width: 768px)' : {
      width: '22vw',
      left: '39vw',
      height: '22vw',
      top: 'calc(50vh - 200px - 11vw)',
    },
  },
  text : {
    fontSize: 15,
    width: '90%',
    marginTop: 80,
    marginBottom: 20,
  },
  submitButton : {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      transition: 'background-color ease 0.5s',
      outline: 'none',
      color: 'white',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
};

export class Activation extends React.Component {

  setActivation = () => {
    const {activate, token, localeName} = this.props;
    activate(token, localeName);
  }

  goToMainPage = () => {
    const {history} = this.props;
    history.replace('');
  }

  render() {
    const { classes, localeName, roleId, activationStatus, resetActivation, errorType} = this.props;

    if (activationStatus.error === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={errorType}
                        callback={resetActivation}
                        localeName={localeName}/>
      )
    }

    if (activationStatus.loading === true) {
      return (
        <Loading/>
      )
    }

    if (activationStatus.success === true) {
      this.goToMainPage();
    }


      return (
        <div>
          <Paper className ={classes.logo}>
            <Image disableTransition={true} src={LogoImage} className={classes.logoImage} alt='logo SusPigSys' />
        </Paper>
        <Paper className={classes.paper}>
          <div className={classes.text}>
            {roleId==='3' || roleId==='4' ? translations.ACTIVATION_OK[localeName] : translations.ACTIVATION_WAIT[localeName] }
          </div>
          <Button
            type="submit"
            fullWidth
            size="large"
            className={classes.submitButton}
            onClick={() => {this.setActivation()}}
          >
            {translations.SUBMIT[localeName]}
          </Button>
            
        </Paper>

        </div>

      )
      
     
}};

export default withRouter(withStyles(styles)(Activation));
