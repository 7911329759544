import React from "react";
import Fab from '@material-ui/core/Fab';
import {withStyles} from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {style} from "../../../../styles/style.js";
import Chip from "@material-ui/core/Chip";
import OptionalQuestionList from "../OptionalQuestionList";
import OptionalQuestionListContainer from "../../../../containers/questionnaire/form/OptionalQuestionList";
import QuestionListRemoval from "../QuestionListRemoval";
import QuestionListRemovalContainer from "../../../../containers/questionnaire/form/QuestionListRemoval";
import {translations} from "../../../../dictionary/translations";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import QuestionListTemplate from '../QuestionListTemplate';
import QuestionListTemplateContainer from '../../../../containers/questionnaire/form/QuestionListTemplate';
import QuestionTemplate from '../QuestionTemplate';
import QuestionTemplateContainer from '../../../../containers/questionnaire/form/QuestionTemplate';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import Popover from "@material-ui/core/Popover";
import { green, red } from '@material-ui/core/colors';
import clsx from 'clsx';


const styles = {
  root: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    border: `1px solid ${style.gray}`,
  },
  button: {
    paddingLeft: 0,
    paddingRight: 0,
    border: `3px solid ${style.gray}`,
    borderBottom: 'none',
    backgroundColor: style.rose_light_hover,
    '@media only screen and (max-width: 768px)' : {
      fontSize: 11,
    },
  },
  selected : {},
  buttonList : {
    display: 'flex', 
    flexWrap: 'wrap', 
    alignItems: 'center', 
    width: '100%',
    alignContent: 'flex-start',
  },
  buttonListAll : {
    display: 'flex', 
    justifyContent: 'space-between', 
    width: '100%'
  },
  buttonListAllSmall : {
    width: 'calc(100% - 350px)'
  },
  plus: {
    marginRight: 5,
    marginLeft: 'auto',
  },
  plusButton: {
    padding: '0 10',
    color: 'white', 
    backgroundColor: style.rose_dark, 
    border: `1px solid ${style.gray}`,
    '&:hover' : {
      backgroundColor : style.rose_dark_hover,
    },
    '@media only screen and (max-width: 768px)' : {
      fontSize: 11,
    },
  },
  active: {
    backgroundColor : `${style.rose_dark} !important`,
    color: 'white',
    '&:hover' : {
      backgroundColor : style.rose_dark,
    },
  },
  popover: {
    pointerEvents: 'none',
    maxWidth: '60vw',
  },
};

const MySwitch = withStyles({
  switchBase: {
    color: red[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

class QuestionGroupBar extends React.Component {

  componentDidMount(){
    const {modeAdmin, questionList } = this.props;
    if (modeAdmin === true){
      let act = [];
      if (questionList.data){
        if (questionList.data.length!==0){
              questionList.data.map((item, key) => (
              act.push(item.isActive)
            ))
        }
      }
      this.setState({activation : act});
    }
  }

  onButtonClick = event => {
    const { setQuestionListId } = this.props;
    const questionListId = Number.parseInt(event.currentTarget.attributes["question-list-id"].value);
    setQuestionListId(questionListId);
  }

  onDeleteClick = (id, questionListName) => {
    this.setState({
      isQuestionListRemovalOpen : true,
      questionListName : questionListName,
      questionListIdToRemove : id
    })
  }

  onPlusClick = () => {
    const {questionList, isReadOnly, addQuestionList} = this.props;
    if (isReadOnly === true) return null;
    const body = {
      filledSubformId: questionList.data[0].filledSubformId,
      questionListDicId: questionList.data[0].questionListDicId
    }
    addQuestionList(body);
  }

  closeDeleteDialog = () => {
    this.setState({
      isQuestionListRemovalOpen : false
    })
  }

  setIsEditOpen = (isOpen, isCancelling) => {
    this.setState({
      isEditOpen: isOpen
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {locale, questionList, questionListId, subFormId, setNextSubFormId, setNextQuestionListId, getQuestionList, setQuestionListId, modeAdmin, setAfterQuestionListAdding, setAfterQuestionListEditing, getQuestionsAdmin} = this.props;
    const {questionListIdToRemove, isGoBack, selectedQuestionListId} = this.state;
    
    if (prevProps.questionList.addStatus.success !== true && questionList.addStatus.success === true) {
      setNextSubFormId(subFormId);
      setNextQuestionListId(questionListId);

      const refreshPage = async () => {
        await setAfterQuestionListAdding(true);
        await getQuestionList(locale.id, subFormId, modeAdmin);
      }
      refreshPage().then(() => setAfterQuestionListAdding(false));
      
    }
    

    if (questionList.deleteStatus.error === true && prevProps.questionList.deleteStatus.loading === true) {
      this.setState({
        questionListIdToRemove : null
      });
    }

    if (prevProps.questionList.deleteStatus.success !== true && questionList.deleteStatus.success === true) {
      if (questionListIdToRemove !== questionListId) {
        setNextQuestionListId(questionListId);
      }
      getQuestionList(locale.id, subFormId, modeAdmin);

      this.setState({
        questionListIdToRemove : null
      });
    }

    if (this.state.add === false && prevState.add === true) {
      if (!isGoBack) {
        const refreshPage = async () => {
          await setAfterQuestionListAdding(true);
          await getQuestionList(locale.id, subFormId, modeAdmin);
          await getQuestionList(locale.id, subFormId, modeAdmin);
        }
        refreshPage().then(() => setAfterQuestionListAdding(false));
      }
    }
    else if (this.state.edit === false && prevState.edit === true) {
      if (!isGoBack) {
        const refreshPage = async () => {
          await setAfterQuestionListEditing(true);
          await getQuestionList(locale.id, subFormId, modeAdmin);
          await setQuestionListId(selectedQuestionListId);
        }
        refreshPage().then(() => {
          setAfterQuestionListEditing(false);
          getQuestionsAdmin(selectedQuestionListId);
        });
      }
    }
    else if (this.state.addQuestion === false && prevState.addQuestion === true) {
      if (!isGoBack) {
        async function refreshPage() {
          await setQuestionListId(null);
        }
        refreshPage().then(() => setQuestionListId(selectedQuestionListId));
      }
    }
  }


  getOnDelete = (questionList) => {
    const { isReadOnly } = this.props;
    if (isReadOnly === true) {
      return false;
    } else {
      return () => {this.onDeleteClick(questionList.id, questionList.questionListName)};
    }
  }

  state = {
    isQuestionListRemovalOpen : false,
    questionListName : '',
    questionListIdToRemove : '',
    isEditOpen : false,
    activation: '',
    add: false,
    edit: false,
    selectedQuestionList: null,
    addQuestion: false,
    anchorEl : null,
    popoverText: '',
    isGoBack: false,
    selectedQuestionListId: null,
    afterAdding: null,
  }

  getAllQuestionCount = (questionList) => {
    return questionList.questionList.length;
  }

  getSuccessQuestionCount = (questionList) => {
    let count = 0
    questionList.questionList.forEach((el, index) => {
      if (el.answers.length > 0){
        if (el.answers[0].text || el.answers[0].answerListId || el.answers[0].answer) {
          count++;
        }
      }
    });
    return count;
  }

  getSkippedQuestionsForAllQuestionLists = () => {
    const { questionList } = this.props;
    if (questionList.data){
            const skipped = questionList.data.map(group => {
            let skippedAnswers = 0;
            let skippedQuestions = 0;
            let disabledIndex = [];
            group.questionList.forEach((elem, index) => {

              // for question number 1069
              if (elem.questionId===1069 && (elem.answers[0] ? ! parseFloat(elem.answers[0].answer) : true)){
                for (let i = index; i < group.questionList.length; i++) {
                  disabledIndex.push(i+1);
                  skippedQuestions ++;
                }
                skippedQuestions --;
              }


              elem.answerList.forEach(el => {
                if (Number.isInteger(el.skipQuestions)) {
                  const answer = elem.answers.find(i => {
                    return i.answerListId === el.id;
                  })
                  if (answer !== undefined) {
                    skippedQuestions += el.skipQuestions;
                    if (el.skipQuestions > 0) {
                      for (let i = 0; i < el.skipQuestions; i++) {
                        disabledIndex.push(index + i + 1);
                      }
                    }
                  }
                }
              })
              if (elem.answers.length > 0 && disabledIndex.includes(index)) {
                if (elem.answers[0].text || elem.answers[0].answerListId || elem.answers[0].answer) {
                  skippedAnswers++;
                }
              }
            });
              return {questionListId: group.id, skippedAnswers: skippedAnswers, skippedQuestions: skippedQuestions}
            })
          return skipped
    }
    return null
  }

  getGroupName = (questionListName, questionList, modeAdmin, index) => {
    const {locale} = this.props;
    if (modeAdmin !== true ){
      const skipped = this.getSkippedQuestionsForAllQuestionLists();
      let skippedNumberOfQuestions = 0;
      let skippedNumberOfAnswers = 0;
      if (skipped){
        skipped.forEach(el => {
          if (el.questionListId === questionList.id){
            skippedNumberOfQuestions = el.skippedQuestions;
            skippedNumberOfAnswers = el.skippedAnswers;
          }
        })
      }
      const successCount = this.getSuccessQuestionCount(questionList) - skippedNumberOfAnswers;
      const allCount = this.getAllQuestionCount(questionList) - skippedNumberOfQuestions;
      const countStyle = (successCount === allCount ) ? {fontWeight: 'bold'} : {};
      return <div>
        <span>{questionListName}</span>
        <span style={countStyle}>{' (' + successCount + '/' + allCount + ')'}</span>
      </div>;
    }
    else{
      let name ='';
      questionListName ? name = questionListName : name = translations.NO_NAME[locale.localeName] ;
      if (questionList.farmType !== null){
        return <span>{`${name} (${questionList.farmType})`}</span>
      }
      else{
        return <span>{`${name}`}</span>
      }
    }
  }

  // funkcja sortująca dla questionList z qlNumber === 0
  compare = (a, b) => {
    if (a.id < b.id) {
        return -1
    } else if (a.id > b.id) {
        return 1
    }
    //if (a === b)
    return 0
  }

  setActive = (id, active, key) => {
    const {activateQuestionList} = this.props;
    let act = [...this.state.activation];
    act[key] = active;
    activateQuestionList(id, active);
    this.setState({activation : act});
  }

  closeEditOrAddWindow = (isGoBack, selectedQuestionListId) => {
    this.setState({
      add : false,
      edit: false,
      addQuestion: false,
      isGoBack: isGoBack,
      selectedQuestionListId: selectedQuestionListId,
    })
  }

  handlePopoverOpen = (event, option) => {
    const { locale } = this.props;
    if (option==='group'){
      this.setState({
        anchorEl: event.currentTarget,
        popoverText: translations.ADD_QUESTION_LIST[locale.localeName]
      });
    }
    else{
      this.setState({
        anchorEl: event.currentTarget,
        popoverText: translations.ADD_QUESTION[locale.localeName]
      });
    }
  }
  

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  render() {
    const { classes, questionList, answersForLastPen, setAnswersForLastPen, questionListId, isSideListOpen, isReadOnly, choosenFarmCode, prevQuestionList, modeAdmin, locale, subFormId, setQuestionListId, afterQuestionListAdding} = this.props;
    const { activation, add, edit, selectedQuestionList, addQuestion, anchorEl, popoverText } = this.state;
    const open = Boolean(anchorEl);
    const lengthOfQuestionList = questionList.data ? questionList.data.length : null;
    if (questionList.data){
            const listDicId = questionList.data[0].questionListDicId;
            if (afterQuestionListAdding) {
              if (lengthOfQuestionList){
                setQuestionListId(questionList.data[lengthOfQuestionList-1].id);
                if (listDicId>=140 && listDicId<=147 ){
                    if (!answersForLastPen && modeAdmin!==true){
                      const answersLastPen = {
                        id: questionList.data[lengthOfQuestionList-1].id,
                        questionList: questionList.data
                      };
                      setAnswersForLastPen(answersLastPen);
                    }
                    else if (answersForLastPen.id !== questionList.data[lengthOfQuestionList-1].id && modeAdmin!==true){
                      const answersLastPen = {
                        id: questionList.data[lengthOfQuestionList-1].id,
                        questionList: questionList.data
                      };
                      setAnswersForLastPen(answersLastPen);
                    }
                }
              }
            }  
    }
    
    if (modeAdmin !== true){
          const qlNumber = questionList.data ? modeAdmin!==true ? questionList.data[0].qlNumber : questionList.data.qlNumber : modeAdmin!==true ? prevQuestionList[0].qlNumber : prevQuestionList.qlNumber ;
          if (qlNumber === -1) {
            const item = questionList.data ? modeAdmin!==true ? questionList.data[0] : questionList.data : modeAdmin!==true ? prevQuestionList[0] : prevQuestionList;
            let butClass = [classes.button, classes.active];
            return (
              <div className={isSideListOpen ? clsx(classes.buttonListAll, classes.buttonListAllSmall) : classes.buttonListAll} >
                <div className={classes.buttonList}>
                      <Chip key={item.id}  
                            className={butClass.join(' ')}  
                            question-list-id={item.id}
                            onClick={this.onButtonClick}
                            label={this.getGroupName(item.questionListName, item, modeAdmin, 0)}
                      />
                </div>
              </div>
            )
          }
          else if (qlNumber === 0 || qlNumber === 1) {
            let counter = 0;
            let questionListFinal = [];
            questionList.data ? questionListFinal = questionList.data : questionListFinal = prevQuestionList;
            if (qlNumber === 0) questionListFinal.sort(this.compare);
            return (
              <div className={isSideListOpen ? clsx(classes.buttonListAll, classes.buttonListAllSmall) : classes.buttonListAll} >
                <div className={classes.buttonList} >
                {questionListFinal.map((questionList, index) => {
                  counter = counter + 1;
                  let butClass = [classes.button];
                  if (questionListId === questionList.id) {
                    butClass = [classes.button, classes.active];
                  }
                  let newQuestionListName = questionList.questionListName;
                  if (qlNumber === 1) {
                    return (
                      <Chip key={questionList.id}  
                            className={butClass.join(' ')}  
                            question-list-id={questionList.id}
                            onClick={this.onButtonClick}
                            label={this.getGroupName(newQuestionListName, questionList, modeAdmin, index)}
                      />
                    )}
                  else{
                    if (questionList.questionListDicId === 9 || questionList.questionListDicId === 43){
                      newQuestionListName = choosenFarmCode.concat(`_B${counter}`);
                    }
                    else{
                      newQuestionListName = questionList.questionListName.concat(' ' + counter);
                    }
                    // gdy jest 1 element to go nie można usunąć
                    if (questionListFinal.length === 1){
                        return (
                          <Chip key={questionList.id}  
                                className={butClass.join(' ')}  
                                question-list-id={questionList.id}
                                onClick={this.onButtonClick}
                                label={this.getGroupName(newQuestionListName, questionList, modeAdmin, index)}
                          />
                        )
                    }
                    else{
                      return (
                        <Chip key={questionList.id}  
                              className={butClass.join(' ')}  
                              question-list-id={questionList.id}
                              onClick={this.onButtonClick}
                              onDelete={this.getOnDelete(questionList)}
                              label={this.getGroupName(newQuestionListName, questionList, modeAdmin, index)}
                        />
                      )
                    }
                  }
                })} 
                </div>
                {qlNumber === 0 ? <Plus classes={classes} label={questionListFinal[0].questionListName} className={classes.plus} qlNumber={qlNumber} onClick={this.onPlusClick} isReadOnly={isReadOnly}/> : null}
                {this.state.isQuestionListRemovalOpen === true ? <QuestionListRemovalContainer Layout={QuestionListRemoval} questionListName={this.state.questionListName} questionListIdToRemove={this.state.questionListIdToRemove} closeDeleteDialog={this.closeDeleteDialog} refreshQuestionGroupList={this.refreshQuestionGroupList} /> : null}
              </div>
            )
          }

          else if (qlNumber === 2) {
            let counter = 0;
            let questionListFinal = [];
            questionList.data ? questionListFinal = questionList.data : questionListFinal = prevQuestionList;
            return (
              <div className={isSideListOpen ? clsx(classes.buttonListAll, classes.buttonListAllSmall) : classes.buttonListAll} >
                <div className={classes.buttonList}>
                  {questionListFinal.map((questionList, index) => {
                    counter = counter + 1;
                    let butClass = [classes.button];
                    if (questionListId === questionList.id) {
                      butClass = [classes.button, classes.active];
                    }
                    return (
                      <Chip
                        key={questionList.id}
                        question-list-id={questionList.id}
                        label={this.getGroupName(questionList.questionListName, questionList, modeAdmin, index)}
                        onClick={this.onButtonClick}
                        onDelete={this.getOnDelete(questionList)}
                        className={butClass.join(' ')}
                      />
                    )
                  })}
                </div>
                {<Edit onClick={() => this.setIsEditOpen(true)} isReadOnly={isReadOnly} />}
                {this.state.isEditOpen === true ? <OptionalQuestionListContainer Layout={OptionalQuestionList} setIsOpen={this.setIsEditOpen} questionList={questionList.data}/> : null}
                {this.state.isQuestionListRemovalOpen === true ? <QuestionListRemovalContainer Layout={QuestionListRemoval} questionListName={this.state.questionListName} questionListIdToRemove={this.state.questionListIdToRemove} closeDeleteDialog={this.closeDeleteDialog} refreshQuestionGroupList={this.refreshQuestionGroupList} /> : null}
              </div>
            )
          }

          return (
            <div/>
          )
    }
    else{
            let questionListFinal = [];
            questionList.data ? questionListFinal = questionList.data : questionListFinal = prevQuestionList;
            let qlNumber = questionListFinal ? questionListFinal[0] ? questionListFinal[0].qlNumber : null : null;
            return (
              <div className={isSideListOpen ? clsx(classes.buttonListAll, classes.buttonListAllSmall) : classes.buttonListAll} >
                <div className={classes.buttonList} >
                {questionListFinal.map((questionList, key) => {
                  let butClass = [classes.button];
                  if (questionListId === questionList.id) {
                    butClass = [classes.button, classes.active];
                  }
                  let newQuestionListName = questionList.name;
                    return (
                      <div className={classes.root} key={questionList.id}>
                          <Chip key={questionList.id}  
                                className={butClass.join(' ')}  
                                question-list-id={questionList.id}
                                onClick={this.onButtonClick}
                                label={this.getGroupName(newQuestionListName, questionList, modeAdmin)}
                          
                          />
                          <IconButton aria-label="edit" style={{flexGrow:1}} onClick={() => this.setState({edit: true, selectedQuestionList: questionList.id})} >
                                    <EditOutlinedIcon fontSize='small'/>
                          </IconButton>
                          <FormGroup >
                              <MySwitch size="small" checked={activation[key] ? activation[key] : false} onChange={() => this.setActive(questionList.id, !activation[key], key)} />
                          </FormGroup>
                      </div>
                    )})}
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  {modeAdmin === true && (qlNumber==1 || qlNumber==2 || qlNumber===null) ? 
                      <Fab size="small" color="secondary" aria-label="add" style={{marginRight: 15}} onClick={() => this.setState({add: true})} onMouseEnter={event => this.handlePopoverOpen(event, 'group')} onMouseLeave={this.handlePopoverClose} >
                              <AddIcon size='small'/>
                      </Fab>
                  : null }
                      <Fab size="small" color="secondary" aria-label="add" onClick={() => this.setState({addQuestion: true})} onMouseEnter={event => this.handlePopoverOpen(event, 'question')} onMouseLeave={this.handlePopoverClose}>
                          <AddBoxOutlinedIcon size='small'/>
                      </Fab>
                  </div>
                  <Popover
                      className={classes.popover}
                      open={open}
                      onClose={this.handlePopoverClose}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <div style={{padding: 5,}}>
                          {popoverText}
                      </div>
                  </Popover>
                {add === true ?
                    <QuestionListTemplateContainer Layout={QuestionListTemplate} qlNumberRigid={qlNumber} addOrderKey={questionListFinal.length} subFormId={subFormId} mode='add' locale={locale} close={this.closeEditOrAddWindow} />
                :null
                }
                {edit === true ?
                    <QuestionListTemplateContainer Layout={QuestionListTemplate} qlNumberRigid={qlNumber} addOrderKey={questionListFinal.length} subFormId={subFormId} selectedQuestionList={selectedQuestionList} mode='edit' locale={locale} close={this.closeEditOrAddWindow} />
                :null
                }
                {addQuestion === true ?
                    <QuestionTemplateContainer Layout={QuestionTemplate}  questionListId={questionListId} mode='add' locale={locale} close={this.closeEditOrAddWindow} />
                :null
                }
              </div>
            )
    }
  }
}

const Plus = (props) => {
  const {onClick, isReadOnly, label, classes} = props;
  return (
    <Chip icon={<AddIcon/>} label={ `Add ${label}`} className={classes.plusButton} onClick={onClick} disabled={isReadOnly} />
  )}

const Edit = (props) => {
  const {onClick, isReadOnly} = props;
  return (
    <Fab size="small" style={{marginLeft: '1%'}} onClick={onClick} disabled={isReadOnly}>
      <EditIcon/>
    </Fab>
  )
}

export default withStyles(styles)(QuestionGroupBar);
