import {
  QUESTIONNAIRE_FORM_RESET,
  SET_FORM_ID, 
  SET_QUESTIONNAIRE_DIC_ID,
  SET_QUESTIONNAIRE_ID, 
  SET_QUESTION_LIST_ID,
  SET_SUBFORM_ID, 
  RESET_SUBFORM_ID, 
  RESET_QUESTION_LIST_ID, 
  SET_QUESTIONNAIRE_READONLY, 
  SET_IS_QUESTION_GROUP_INITIAL, 
  QUESTION_1069_HAS_VALUE,
  AFTER_QUESTION_LIST_ADDING,
  AFTER_QUESTION_LIST_EDITING,
  AFTER_SUBFORM_ADDING,
  AFTER_SUBFORM_EDITING,
  SET_CHOOSEN_FARM_CODE,
  SET_CHOOSEN_FARM_TYPE
} from "../../../reducers/questionnaire/form/form";

const setQuestionnaireDicId = (id) => ({
  type: SET_QUESTIONNAIRE_DIC_ID,
  id: id
});

const setQuestionnaireId = (id) => ({
  type: SET_QUESTIONNAIRE_ID,
  id: id
});

const setFormId = (id) => ({
  type: SET_FORM_ID,
  id: id
});

const setSubFormId = (id) => ({
  type: SET_SUBFORM_ID,
  id: id
});

const resetSubFormId = () => ({
  type: RESET_SUBFORM_ID
});

const setQuestionListId = (id) => ({
  type: SET_QUESTION_LIST_ID,
  id: id
});

const setQuestionnaireReadOnly = (isAnswerReadOnly) => ({
  type: SET_QUESTIONNAIRE_READONLY,
  isAnswerReadOnly: isAnswerReadOnly
});

const setIsQuestionGroupInitial = (isQuestionGroupInitial) => ({
  type: SET_IS_QUESTION_GROUP_INITIAL,
  isQuestionGroupInitial: isQuestionGroupInitial
});

const resetQuestionListId = (id) => ({
  type: RESET_QUESTION_LIST_ID,
  id: id
});

const resetState = () => ({
  type: QUESTIONNAIRE_FORM_RESET
});

const setQuestion1069value = (is) => ({
  type: QUESTION_1069_HAS_VALUE,
  question1069hasValue: is
});

const setAfterQuestionListAdding = (is) => ({
  type: AFTER_QUESTION_LIST_ADDING,
  afterQuestionListAdding: is
});

const setAfterQuestionListEditing = (is) => ({
  type: AFTER_QUESTION_LIST_EDITING,
  afterQuestionListEditing: is
});

const setAfterSubformAdding = (is) => ({
  type: AFTER_SUBFORM_ADDING,
  afterSubformAdding: is
});

const setAfterSubformEditing = (is) => ({
  type: AFTER_SUBFORM_EDITING,
  afterSubformEditing: is
});

const setChoosenFarmCode = (code) => ({
  type: SET_CHOOSEN_FARM_CODE,
  code: code
});

const setChoosenFarmType = (code) => ({
  type: SET_CHOOSEN_FARM_TYPE,
  code: code
});

const index = {
  setQuestionnaireDicId,
  setQuestionnaireId,
  setFormId,
  setSubFormId,
  resetSubFormId,
  setQuestionListId,
  setQuestionnaireReadOnly,
  setIsQuestionGroupInitial,
  resetQuestionListId,
  resetState,
  setQuestion1069value,
  setAfterQuestionListAdding,
  setAfterSubformAdding,
  setAfterSubformEditing,
  setAfterQuestionListEditing,
  setChoosenFarmCode,
  setChoosenFarmType
}

export default index;
