import React from "react";
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import QuestionTemplate from "./Template";
import {translations} from "../../../../dictionary/translations";
import QuestionTemplateContainer from "../../../../containers/questionnaire/form/question/Template";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';


const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "inline-block",
    marginRight: '1%'
  },
  helper : {
    color: 'red',
    fontSize: 15,
  }
});

class DateQuestion extends React.Component {

  state = {
    isError: false,
    value: null,
  }

  convertDate = (answer) => {
      if (answer){
        const generalDate = new Date(answer);
        const year = generalDate.getFullYear();
        const month = generalDate.getMonth();
        const monthAsString = (month + 1).toString().length < 2 ? '0'+(month + 1).toString() : (month + 1);
        const date = generalDate.getDate();
        const dateAsString = (date).toString().length < 2 ? '0'+(date).toString() : date;
        const selectedDate = `${year}-${monthAsString}-${dateAsString}`;
        this.setState({ value: selectedDate });
      }
  }

  componentDidMount(){
    const {modeAdmin, question, answerLastPen, setAnswers, persistAnswersOnTimeout} = this.props;
    if (modeAdmin!==true){
      if (question.answers[0]){
        this.convertDate(question.answers[0].answer);
      }
      else if (answerLastPen){
        this.setState({ value: answerLastPen });
        setAnswers([{
          answerListId: null,
          answer: answerLastPen
        }]);
        persistAnswersOnTimeout();
      }
    }
  }

  componentDidUpdate(prevProps){
    const {question, answerLastPen, setAnswers, persistAnswersOnTimeout} = this.props;
    const {value} = this.state;
    if (question !== prevProps.question){
      if (question.answers[0]){
        this.convertDate(question.answers[0].answer);
      }
    }

    if (answerLastPen !== prevProps.answerLastPen && !value){
      setAnswers([{
        answerListId: null,
        answer: answerLastPen
      }]);
      persistAnswersOnTimeout();
      this.setState({value: answerLastPen });
    }
  }

  handleChange = (event) => {
    const { setAnswers, persistAnswersOnTimeout } = this.props;
    if (event){
      const year = event.getFullYear();
      let month = event.getMonth();
      const date = event.getDate();
      month++;
      if (year && month && date){
            const monthAsString = (month).toString().length < 2 ? '0'+(month).toString() : (month);
            const dateAsString = (date).toString().length < 2 ? '0'+(date).toString() : date;
            const selectedDate = `${year}-${monthAsString}-${dateAsString}`;
            this.setState({isError: false});
            setAnswers([{
              answerListId: null,
              answer: selectedDate
            }]);
            persistAnswersOnTimeout();
      }
      else {
        this.setState({isError: true});
        setAnswers([{
          answerListId: null,
          answer: null
        }]);
        persistAnswersOnTimeout();
      }
    }
    else{
        this.setState({isError: false});
        setAnswers([{
          answerListId: null,
          answer: null
        }]);
        persistAnswersOnTimeout();
    }
  };

  render() {
    const { locale, questionNumber, question, classes, isSideListOpen, answersPersistStatus, isAnswerReadOnly, isQuestionReadOnly, modeAdmin, activateQuestion} = this.props;
    const { isError, value } = this.state;

    return (
      <QuestionTemplateContainer 
          Layout={QuestionTemplate} 
          modeAdmin={modeAdmin} 
          questionNumber={questionNumber} 
          question={question} 
          answersPersistStatus={answersPersistStatus} 
          isQuestionReadOnly={isQuestionReadOnly} 
          isAnswerReadOnly={isAnswerReadOnly}
          activateQuestion={activateQuestion}
          isSideListOpen={isSideListOpen}
        >
        <FormControl component="fieldset">
          <FormLabel>{translations.CHOOSE_DATE[locale.localeName]}:</FormLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                onChange={modeAdmin!== true ? this.handleChange : null}
                disabled={modeAdmin!== true ? false : true}
                //onBlur={modeAdmin!==true ? this.handleChange : null}
                value={value}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiPickersUtilsProvider>
            {isError && <FormHelperText className={classes.helper}>{translations.INVALID_DATE_FORMAT[locale.localeName]}</FormHelperText>}
        </FormControl>
      </QuestionTemplateContainer>
    )
  }
}

export default withStyles(styles)(DateQuestion);
