import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Loading from "../Loading";
import Error from '../Error'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import {translations} from '../../dictionary/translations';
import {ERROR_MODE, USER_ROLE_DIC_ID} from "../../util";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import ErrorContainer from "../../containers/Error";
import {style} from "../../styles/style.js";
import {withRouter} from "react-router-dom";
import QuestionBox from "../QuestionBox";
import TableSortLabel from '@material-ui/core/TableSortLabel';

const styles = {
  paper : {
    position: 'relative',
    marginBottom: 30,
    borderTopRightRadius: 20,
  },
  tableHeader : {
    textAlign: 'center',
    fontWeight: 'bold',
    paddingLeft: 3,
    paddingRight: 3,
  },
  tableCell : {
    textAlign: 'center',
    color: style.violet,
    paddingLeft: 3,
    paddingRight: 3,
    '@media only screen and (max-width: 768px)' : {
      fontSize: 11,
    },
  },
  button : {
    margin: 5,
    flexBasis: '20%',
    color: style.violet,
    fontSize: 10,
    backgroundColor: 'white',
    transition: 'background-color ease 0.5s, color ease 0.5s',
    '&:hover' : {
      color: 'white',
    },
    '@media only screen and (max-width: 768px)' : {
      fontSize: 8,
    },
  },
  buttonLook : {
    border: `2px solid ${style.rose_dark}`,
    '&:hover' : {
        backgroundColor: style.rose_dark,
    },
  },
  buttonEdit: {
    border: `2px solid ${style.rose_dark}`,
    '&:hover' : {
      backgroundColor: style.rose_dark,
    },
  },
  buttonDelete: {
    border: `2px solid #ff6666`,
    '&:hover' : {
      backgroundColor: '#ff6666',
    },
  },
  buttonReport: {
    border: `2px solid ${style.rose_light_hover}`,
    '&:hover' : {
      backgroundColor: style.rose_light_hover,
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
};

export class QuestionnaireList extends React.Component {

  getQuestionnaireList = () => {
    const {orderBy, order} = this.state;
    const {pagination, getQuestionnaireList, user, searchText, locale} = this.props;
    getQuestionnaireList(0, pagination.rowsPerPage, user.id, user.roleDicId, searchText, locale.localeName, order, orderBy);
  }

  componentDidMount() {
    this.getQuestionnaireList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { searchText, removeQuestionnaireStatus, setIsQuestionnaireActiveStatus } = this.props;
    if (prevProps.searchText !== searchText) {
      this.getQuestionnaireList();
    }
    if (removeQuestionnaireStatus.success === true && prevProps.removeQuestionnaireStatus.loading === true) {
      this.getQuestionnaireList();
      this.setIsDeleteDialogOpen(false);
    }
    if (setIsQuestionnaireActiveStatus.success === true && prevProps.setIsQuestionnaireActiveStatus.loading === true) {
      this.getQuestionnaireList();
    }
  }

  resetError = () => {
    const { resetGetQuestionnaireList, resetRemoveQuestionnaire, resetSetIsQuestionnaireActive } = this.props;
    resetGetQuestionnaireList();
    resetRemoveQuestionnaire();
    resetSetIsQuestionnaireActive();
  }

  state = {
    isDeleteDialogOpen : false,
    questionnaireIdToRemove : '',
    isEditErrorOpen : false,
    order: 'asc',
    orderBy: 'farmCode',
  }

  setIsDeleteDialogOpen = (isOpen) => {
    this.setState({
      isDeleteDialogOpen : isOpen
    })
  }

  setQuestionnaireIdToRemove = id => {
    this.setState({
      questionnaireIdToRemove : id
    })
  }

  setIsEditErrorOpen = isOpen => {
    this.setState({
      isEditErrorOpen : isOpen
    })
  }

  onRequestSort = (event, property) => {
    const {changeOrder} = this.props;
    const {orderBy, order} = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({
      order : isDesc ? 'asc' : 'desc',
      orderBy : property
    }, () => {
      const {orderBy, order} = this.state;
      changeOrder(order, orderBy);
    });

  };

  createSortHandler = property => event => {
    this.onRequestSort(event, property);
  };

  render() {
    const { classes, locale, loading, error, success, items, pagination, history, setChoosenFarmType, setChoosenFarmCode, setQuestionnaireId, setQuestionnaireDicId, setQuestionnaireReadOnly, user, removeQuestionnaire, removeQuestionnaireStatus, setIsQuestionnaireActiveStatus, setIsQuestionnaireActive } = this.props;
    const { questionnaireIdToRemove, isDeleteDialogOpen, isEditErrorOpen, order, orderBy } = this.state;

    if (error === true || removeQuestionnaireStatus.error === true || setIsQuestionnaireActiveStatus.error === true) {
      return (
        <ErrorContainer Layout={Error} mode={ERROR_MODE.REFRESH} content={translations.ERROR_WHILE_DOWNLOADING_DATA} callback={() => {this.resetError()}}/>
      )
    }
    if (loading === true || removeQuestionnaireStatus.loading === true || setIsQuestionnaireActiveStatus.loading === true) {
      return (
        <Loading/>
      )
    }
    if (success === true) {
      return (
        <div>
          <Paper className={classes.paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader} key='farmCode' sortDirection={orderBy === 'farmCode' ? order : false}>
                       <TableSortLabel
                          active={orderBy === 'farmCode'}
                          direction={order}
                          onClick={this.createSortHandler('farmCode')}
                        >
                          {translations.FARM_NUMBER[locale.localeName]}
                          {orderBy === 'farmCode' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                  </TableCell>
                  {/*
                  <TableCell className={classes.tableHeader} key='descStatus' sortDirection={orderBy === 'descStatus' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'descStatus'}
                          direction={order}
                          onClick={this.createSortHandler('descStatus')}
                        >
                          {translations.STATUS[locale.localeName]}
                          {orderBy === 'descStatus' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                  </TableCell>
                  */}
                  <TableCell className={classes.tableHeader}>
                    {translations.STATUS[locale.localeName]}
                  </TableCell>
                  <TableCell className={classes.tableHeader} key='questionnaryDate' sortDirection={orderBy === 'questionnaryDate' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'questionnaryDate'}
                          direction={order}
                          onClick={this.createSortHandler('questionnaryDate')}
                        >
                          {translations.ADDING_DATE[locale.localeName]}
                          {orderBy === 'questionnaryDate' ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableHeader}>
                    {translations.ACTIONS[locale.localeName]}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <QuestionnaireListData
                  classes={classes}
                  items={items}
                  locale={locale}
                  history={history}
                  setChoosenFarmCode={setChoosenFarmCode}
                  setQuestionnaireId={setQuestionnaireId}
                  setQuestionnaireDicId={setQuestionnaireDicId}
                  setQuestionnaireReadOnly={setQuestionnaireReadOnly}
                  user={user}
                  removeQuestionnaire={removeQuestionnaire}
                  setIsDeleteDialogOpen={this.setIsDeleteDialogOpen}
                  setQuestionnaireIdToRemove={this.setQuestionnaireIdToRemove}
                  setIsEditErrorOpen={this.setIsEditErrorOpen}
                  setIsQuestionnaireActive={setIsQuestionnaireActive}
                  order={order}
                  orderBy={orderBy}
                  setChoosenFarmType={setChoosenFarmType}
                />
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    rowsPerPage={pagination.rowsPerPage}
                    count={pagination.total}
                    page={pagination.page}
                    onChangePage={pagination.handleChangePage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
          {isDeleteDialogOpen === true ? <QuestionBox title={translations.QUESTIONNAIRE_REMOVAL[locale.localeName]}
                       content={translations.ARE_YOU_SURE_TO_REMOVE_THE_QUESTIONNAIRE_DATA_WILL_BE_ERASED_PERMANENTLY[locale.localeName]}
                       leftButtonText={translations.CANCEL[locale.localeName]}
                       rightButtonText={translations.REMOVE[locale.localeName]}
                       leftButtonCallback={() => this.setIsDeleteDialogOpen(false)}
                       rightButtonCallback={() => removeQuestionnaire(questionnaireIdToRemove, user.id)}
          /> : false}
          {isEditErrorOpen === true ? <ErrorContainer Layout={Error} content={translations.YOU_CANNOT_EDIT_INACTIVE_QUESTIONNAIRE} callback={() => {this.setIsEditErrorOpen(false)}}/> : false}
        </div>
      )
    }
    return ( <div/> )
  }
}

const handleEdit = (history, questionnaire, questionnaireDicId, setQuestionnaireId, setQuestionnaireDicId, setQuestionnaireReadOnly, setIsEditErrorOpen, setChoosenFarmCode, farmCode, setChoosenFarmType, farmTypeId) => {
  if (questionnaire.isActive === false) {
    setIsEditErrorOpen(true);
  } else {
    setChoosenFarmType(farmTypeId);
    setChoosenFarmCode(farmCode);
    setQuestionnaire(history, questionnaire.id, questionnaireDicId, setQuestionnaireId, setQuestionnaireDicId, setQuestionnaireReadOnly, false);
  }
}

const handleView = (history, questionnaireId, questionnaireDicId, setQuestionnaireId, setQuestionnaireDicId, setQuestionnaireReadOnly, setChoosenFarmCode, farmCode, setChoosenFarmType, farmTypeId) => {
  setChoosenFarmCode(farmCode);
  setChoosenFarmType(farmTypeId);
  setQuestionnaire(history, questionnaireId, questionnaireDicId, setQuestionnaireId, setQuestionnaireDicId, setQuestionnaireReadOnly, true);
}

const handleDelete = (questionnaireIdToRemove, setIsDeleteDialogOpen, setQuestionnaireIdToRemove) => {
  setQuestionnaireIdToRemove(questionnaireIdToRemove);
  setIsDeleteDialogOpen(true);
}

const handleActivate = (questionnaire, setIsQuestionnaireActive) => {
  let isActivate;
  if (questionnaire.isActive === true) {
    isActivate = false;
  } else {
    isActivate = true;
  }
  setIsQuestionnaireActive(questionnaire.id, isActivate);
}

const handleReports = (history, questionnaireId, setQuestionnaireId, setChoosenFarmType, farmTypeId, setChoosenFarmCode, farmCode) => {
  setQuestionnaireId(questionnaireId);
  setChoosenFarmType(farmTypeId);
  setChoosenFarmCode(farmCode);
  history.push('reports/theme');
}

const setQuestionnaire = (history, questionnaireId, questionnaireDicId, setQuestionnaireId, setQuestionnaireDicId, setQuestionnaireReadOnly, readOnly) => {
  setQuestionnaireId(questionnaireId);
  setQuestionnaireDicId(questionnaireDicId);
  setQuestionnaireReadOnly(readOnly);
  history.push('fill-questionnaire');
}

const QuestionnaireListData = (props) => {
  const { classes, items, order, orderBy, locale, history, setChoosenFarmType, setChoosenFarmCode, setQuestionnaireId, setQuestionnaireDicId, setQuestionnaireReadOnly, setIsDeleteDialogOpen, setQuestionnaireIdToRemove, user, setIsEditErrorOpen, setIsQuestionnaireActive } = props;

  const handleStatus = (formList, isActive) => {
    const formStatus = formList.map(({formName, status})=> {return {formName: formName, status: status}})
    const submittedForms = formStatus.filter(({status}) => status===true);
    const ineditionForms = formStatus.filter(({status}) => status===false)
    if (isActive){
      return(
      <div>
        {submittedForms.length!==0 ? <p>{`${translations.SUBMITTED[locale.localeName]} : ${submittedForms.map(({formName}) => `${formName}`).join(', ')}`}</p> : null}
        {ineditionForms.length!==0 ? <p>{`${translations.IN_EDITION[locale.localeName]} : ${ineditionForms.map(({formName}) => `${formName}`).join(', ')}`}</p> : null}
      </div>
      )
    }
    else{
      return (translations.DEACTIVATED[locale.localeName]);
    }
  }

  /*
  const desc = (a, b, orderBy) => {
    if (typeof(a[orderBy]) !== 'string' && typeof(b[orderBy]) !== 'string'){
      a = a[orderBy];
      b = b[orderBy];
    }
    else{
      a = a[orderBy].toLowerCase();
      b = b[orderBy].toLowerCase();
    }

    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  }
  
  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  const getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  */

  const arr = items.map(el => {
    el.descStatus = handleStatus(el.formList, el.isActive);
    return el;
  })

  const showDate = (UTCdate) => {
    const newUTCdate = UTCdate.replace(/-/g, '/');
    const generalDate = new Date(newUTCdate);
    const newDate = new Date(generalDate.getTime() - generalDate.getTimezoneOffset()*60*1000);
    const year = newDate.getFullYear();
    const month = newDate.getUTCMonth();
    const monthAsString = (month + 1).toString().length < 2 ? '0'+(month + 1).toString() : (month + 1);
    const date = newDate.getDate();
    const dateAsString = (date).toString().length < 2 ? '0'+(date).toString() : date;
    const selectedDate = `${year}-${monthAsString}-${dateAsString}`;
    return <span>{selectedDate}</span>
  }

  return (
    arr.map(item => (
        <TableRow hover key={item.id}>
          <TableCell className={classes.tableCell}component="th" scope="row">
            {item.farmCode}
          </TableCell>
          <TableCell className={classes.tableCell} component="th" scope="row" >
            {item.descStatus}
          </TableCell>
          <TableCell className={classes.tableCell} component="th" scope="row">
            {showDate(item.questionnaryDate)}
          </TableCell>
          <TableCell className={classes.tableCell} component="th" scope="row">
            <Button variant="outlined" className={[classes.button, classes.buttonLook].join(' ')} size="small" onClick={() => {handleView(history, item.id, item.questionnaireDicId, setQuestionnaireId, setQuestionnaireDicId, setQuestionnaireReadOnly, setChoosenFarmCode, item.farmCode, setChoosenFarmType, item.farmTypeId)}}>
              {translations.SHOW[locale.localeName]}
            </Button>
            {item.formList.map(({status})=> status).some(el => el===false) || user.roleDicId === USER_ROLE_DIC_ID.EKSPERT_NAUKOWY ? <Button variant="outlined"  size="small" className={[classes.button, classes.buttonEdit].join(' ')} onClick={() => {handleEdit(history, item, item.questionnaireDicId, setQuestionnaireId, setQuestionnaireDicId, setQuestionnaireReadOnly, setIsEditErrorOpen, setChoosenFarmCode, item.farmCode, setChoosenFarmType, item.farmTypeId)}}>
              {translations.EDIT[locale.localeName]}
            </Button> : null}
            {user.roleDicId === USER_ROLE_DIC_ID.EKSPERT_NAUKOWY ? <Button variant="outlined"  size="small" className={[classes.button, classes.buttonEdit].join(' ')} onClick={() => {handleActivate(item, setIsQuestionnaireActive)}}>
              {item.isActive === true ? translations.DEACTIVATE[locale.localeName] : translations.ACTIVATE[locale.localeName]}
            </Button> : null}
            {item.userId === user.id && item.formList.map(({status})=> status).every(el => el===false) && item.isActive===true ? <Button variant="outlined"  size="small" className={[classes.button, classes.buttonDelete].join(' ')} onClick={() => {handleDelete(item.id, setIsDeleteDialogOpen, setQuestionnaireIdToRemove)}}>
              {translations.DELETE[locale.localeName]}
            </Button> : null}
            {item.formList.map(({status})=> status).some(el => el===true) && item.isActive===true ? <Button variant="outlined"  size="small" className={[classes.button, classes.buttonReport].join(' ')} onClick={() => {handleReports(history, item.id, setQuestionnaireId, setChoosenFarmType, item.farmTypeId, setChoosenFarmCode, item.farmCode)}} >
              {translations.REPORTS[locale.localeName]}
            </Button> : null}
            
          </TableCell>
        </TableRow>
    ))
  )
};



export default withRouter(withStyles(styles)(QuestionnaireList));
