import React from 'react';
import {connect} from "react-redux";
import actions from "../../actions";

export class ThemeContainer extends React.Component {

  render() {

    const {
      Layout,
      locale,
      user,
      dictionary,
      questionnaireId,
      formId,
      setNextFormId,
      formList,
      getFormList,
      setFormId,
      choosenFarmType,
      choosenFarmCode
    } = this.props;

    return (
      <Layout
        locale={locale}
        user={user}
        dictionary={dictionary}
        questionnaireId={questionnaireId}
        formId={formId}
        setNextFormId={setNextFormId}
        formList={formList}
        getFormList={getFormList}
        setFormId={setFormId}
        choosenFarmType={choosenFarmType}
        choosenFarmCode={choosenFarmCode}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  user: state.user.login.user,
  questionnaireId : state.questionnaire.form.form.questionnaireId,
  formId : state.questionnaire.form.form.formId,
  formList: {
    loading: state.questionnaire.form.list.loading,
    error: state.questionnaire.form.list.error,
    success: state.questionnaire.form.list.success,
    data: state.questionnaire.form.list.data,
  },
  choosenFarmType : state.questionnaire.form.form.choosenFarmType,
  choosenFarmCode : state.questionnaire.form.form.choosenFarmCode,
});

const mapDispatchToProps = {
  setNextFormId : actions.questionnaire.form.list.setNextFormId,
  getFormList : actions.questionnaire.form.list.getFormList,
  setFormId : actions.questionnaire.form.form.setFormId,
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeContainer);
