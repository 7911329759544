import {URL} from '../../../config';
import {
  GET_QUESTIONS_BEGIN,
  GET_QUESTIONS_FAILURE,
  GET_QUESTIONS_RESET,
  GET_QUESTIONS_SUCCESS,
} from "../../../reducers/questionnaire/form/questionsAdmin";

const OPERATION = {
  GET : 'get',
}

function getQuestions(questionListDicId) {
  return dispatch => {
    dispatch(setBegin(OPERATION.GET, questionListDicId));
    return getQuestionsStart(questionListDicId)
      .then((data) => {
        dispatch(setSuccess(OPERATION.GET, data));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.GET))
      });
  };
}

function getQuestionsStart(questionListDicId) {
  return new Promise((resolve, reject) => {
    if (questionListDicId){
        fetch(URL + `/secured/question/dic/list/group?questionListDicId=${questionListDicId}`, {
          headers: {
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
          }
        })
          .then(response => response.json())
          .then(json => {
            if (json.success === true) {
              resolve(json.data)
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          })
    }
    else{
      resolve()
    }
  });
}



const setBegin = (operation) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_QUESTIONS_BEGIN,
      }
    default: return null;
  }
};

const setSuccess = (operation, data) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_QUESTIONS_SUCCESS,
        data: data
      }
    default: return null;
  }
};

const setFailure = (operation) => {
  switch (operation) {
    case OPERATION.GET:
      return {
        type: GET_QUESTIONS_FAILURE,
      }
    default: return null;
  }
};

const resetGetQuestionsState = () => {
  return {
    type: GET_QUESTIONS_RESET,
  }
};



const index = {
  getQuestions,
  resetGetQuestionsState,
}

export default index;
