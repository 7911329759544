import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class UserRegistrationContainer extends React.Component {

  render() {

    const {
      Layout,
      dictionary,
      locale,
      loading,
      error,
      success,
      login,
      loginStatus,
      resetLogin,
      registerUser,
      resetUserRegistration,
      errorType,
      userData,
      emailValidator, 
      passwordValidator,
    } = this.props;

    return (
      <Layout
        dictionary={dictionary}
        locale={locale}
        loading={loading}
        error={error}
        success={success}
        login={login}
        loginStatus={loginStatus}
        resetLogin={resetLogin}
        registerUser={registerUser}
        resetUserRegistration={resetUserRegistration}
        errorType={errorType}
        userData={userData}
        emailValidator = {emailValidator}
        passwordValidator = {passwordValidator}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  loading: state.user.registration.loading,
  error: state.user.registration.error,
  userData: state.user.registration.userData,
  errorType: state.user.registration.errorType,
  success: state.user.registration.success,
  loginStatus: {
    loading : state.user.login.loading,
    error : state.user.login.error,
    success : state.user.login.success
  },
});

const mapDispatchToProps = {
  login: actions.user.login.login,
  registerUser: actions.user.registration.registerUser,
  resetUserRegistration: actions.user.registration.resetState,
  resetLogin: actions.user.login.resetState
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRegistrationContainer);
