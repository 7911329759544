import {URL} from '../../config';

function fetchQuestionnaireList(start, limit, userId, userRoleId, searchText, language, order, orderBy) {
  return dispatch => {
    dispatch(fetchQuestionnaireListBegin());
    return fetchQuestionnaireListStart(start, limit, userId, userRoleId, searchText, language, order, orderBy)
      .then(json => {
        dispatch(fetchQuestionnaireListSuccess(json.data, json.total));
      })
      .catch(() => {
        dispatch(fetchQuestionnaireListFailure())
      });
  };
}

const fetchQuestionnaireListBegin = () => ({
  type: 'FETCH_QUESTIONNAIRE_LIST_BEGIN'
});

function fetchQuestionnaireListStart(start, limit, userId, userRoleId, searchText, language, order, orderBy) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/questinnaire/list?start=` + start + `&limit=` + limit + `&userId=` + userId + `&userRoleId=` + userRoleId + `&searchText=` + searchText + `&orderBy=` + orderBy + `&order=` + order, {
      method: 'GET',
      headers: {
       "Authorization" : "Bearer " + sessionStorage.getItem('token'),
       'Accept-Language': language,
      },

    }).then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json)
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const fetchQuestionnaireListSuccess = (questionnaireList, total) => ({
  type: 'FETCH_QUESTIONNAIRE_LIST_SUCCESS',
  items: questionnaireList,
  total: total
});

const fetchQuestionnaireListFailure = () => ({
  type: 'FETCH_QUESTIONNAIRE_LIST_FAILURE',
  error: true
});

const resetState = () => ({
  type: 'FETCH_QUESTIONNAIRE_LIST_RESET'
});

const index = {
  fetchQuestionnaireList,
  resetState
}

export default index;
