const setLocale = (locale) => ({
  type: 'SET_LOCALE',
  locale: locale
});

const resetState = () => ({
  type: 'RESET_LOCALE'
});

const index = {
  setLocale,
  resetState
}

export default index;
