import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {translations} from '../../dictionary/translations';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {withRouter} from "react-router-dom";
import {style} from "../../styles/style.js";
import ErrorContainer from "../../containers/Error";
import Error from "../Error";
import Loading from "../Loading";
import QuestionBox from "../QuestionBox";
import FormHelperText from '@material-ui/core/FormHelperText';


const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: 400,
    height: 'auto',
    left: 'calc(50vw - 200px)',
    top: 'calc(50vh - 200px)',
    border: `3px solid ${style.gray}`,
    borderRadius: 20,
    paddingBottom: 20,
    '@media only screen and (max-width: 768px)' : {
          width: '70vw',
          left: '15vw',
    },
  },
  form: {
    width: '90%',
    marginTop: 10,
  },
  label: {
      color: `${style.violet} !important`,
  },
  input : {
      color: `${style.violet}`,
      '&:before' : {
        borderBottom: `2px solid ${style.rose_light} !important`,
      },
      '&:after' : {
        borderBottom: `2px solid ${style.rose_dark}`,
      },
  },
  backButton : {
    transition: 'background-color ease 0.5s',
    backgroundColor: style.gray,
    marginRight: '10%',
    color: 'white',
    outline: 'none',
    '&:hover' : {
      backgroundColor: style.gray_hover,
    },
  },
  submitButton : {
      transition: 'background-color ease 0.5s',
      outline: 'none',
      color: 'white',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
  helper : {
    color: 'red',
  }
};


export class ChangePassword extends React.Component {


  state={newPassword: '',
         newPasswordRepeat: '',
         oldPassword: '',
         isEditDialogOpen: false,
         passwordValidationError: false,
         firstClickError: true,
         passwordRequiredError: false,
         passwordRepeatRequiredError: false,
         oldPasswordRequiredError: false,
         passwordRepeatCompatibilityError: false,
        };

  handleChange = (event) => {
    const {passwordValidator} = this.props;

    const name = event.currentTarget.name;
    this.setState({[name]: event.currentTarget.value}, () => {

      const { newPassword, firstClickError, newPasswordRepeat, oldPassword} = this.state;

        if (oldPassword) {
          this.setState({ oldPasswordRequiredError: false });
        }
        if (newPasswordRepeat) {
          this.setState({ passwordRepeatRequiredError: false });
          if (newPasswordRepeat === newPassword){
            this.setState({ passwordRepeatCompatibilityError: false });
          }
          else{
            this.setState({ passwordRepeatCompatibilityError: true });
          }
        }
        else if (firstClickError===false) {
          this.setState({ passwordRepeatRequiredError: true, passwordRepeatCompatibilityError: false });
        }
        else{
          this.setState({ passwordRepeatCompatibilityError: false });
        }
        if (newPassword) {
          this.setState({ passwordRequiredError: false });
          if (newPassword.match(passwordValidator) !== null){
            this.setState({ passwordValidationError: false });
          }
          else{
            this.setState({ passwordValidationError: true });
          }
        }
        else if (firstClickError===false) {
          this.setState({ passwordRequiredError: true, passwordValidationError: false });
        }
        else{
          this.setState({passwordValidationError: false});
        }
        if (newPassword && newPasswordRepeat && oldPassword) this.setState({firstClickError: false});
      }
    )
  }

  setIsEditDialogOpen = (isOpen) => {
    this.setState({
      isEditDialogOpen : isOpen
    })
  }


  changePassword = (event) => {
    event.preventDefault();
    const {passwordValidator} = this.props;
    const { newPassword, newPasswordRepeat, oldPassword} = this.state;
    if (!newPasswordRepeat) {
      this.setState({ passwordRepeatRequiredError: true });
    }
    if (!oldPassword) {
      this.setState({ oldPasswordRequiredError: true });
    }
    if (!newPassword) {
      this.setState({ passwordRequiredError: true });
    }
    else if (!newPassword.match(passwordValidator) == null){
      this.setState({ passwordValidationError: true });
    }
    this.setState({ firstClickError: false });

    if((!this.state.passwordRequiredError)&&
       (!this.state.passwordValidationError)&&
       (!this.state.passwordRepeatRequiredError)&&
       (!this.state.oldPasswordRequiredError)&&
       (!this.state.firstClickError)&&
       (!this.state.passwordRepeatCompatibilityError)){
           
        this.setIsEditDialogOpen(true);
            
    };
  };

  changePasswordPrepare = () => {
    const { editPassword, user, locale } = this.props;
    const { newPassword, oldPassword } = this.state;
    const body = {
      userId : user.id,
      oldPassword : oldPassword,
      newPassword : newPassword,
    }
    editPassword(JSON.stringify(body), locale.localeName);
  }

  handleEditPasswordSuccess = () => {
    const {resetEditPassword, history, user} = this.props;
    resetEditPassword();
    if (user.roleDicId === 1){
      history.push('administration');
    }
    else{
      history.push('main-navigation');
    }
  }

  
  handleEditPasswordError = () => {
    const {resetEditPassword} = this.props;
    this.setIsEditDialogOpen(false);
    resetEditPassword();
    
  }

  render() {
    const { classes, locale, history, editPasswordStatus, resetEditPassword, errorType} = this.props;
    const {oldPassword, newPassword, newPasswordRepeat, isEditDialogOpen } = this.state;

    if (editPasswordStatus.error === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={errorType}
                        callback={this.handleEditPasswordError}/>
      )
    }

    if (editPasswordStatus.loading === true) {
      return (
        <Loading/>
      )
    }

    if (editPasswordStatus.success === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={translations.THE_PASSWORD_CHANGE_SUCCESSFULLY}
                        callback={this.handleEditPasswordSuccess}/>
      )
    }

      return (
        <div>
        <Paper className={classes.paper}>
          <form className={classes.form}>

          <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="oldPassword">{translations.OLD_PASSWORD[locale.localeName]}</InputLabel>
              <Input className={classes.input} type="password" id="oldPassword" name="oldPassword" value={oldPassword} autoComplete="old password" onChange={this.handleChange}/>
              {this.state.oldPasswordRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="newPassword">{translations.NEW_PASSWORD[locale.localeName]}</InputLabel>
              <Input className={classes.input} type="password" id="newPassword" name="newPassword" value={newPassword} autoComplete="new password" onChange={this.handleChange}/>
              {this.state.passwordRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
              {this.state.passwordValidationError && <FormHelperText className={classes.helper}>{translations.INVALID_PASSWORD_STRUCTURE[locale.localeName]}</FormHelperText>}
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="newPasswordRepeat">{translations.NEW_PASSWORD_REPEAT[locale.localeName]}</InputLabel>
              <Input className={classes.input} type="password" id="newPasswordRepeat" name="newPasswordRepeat" value={newPasswordRepeat} autoComplete="repeat new password" onChange={this.handleChange}/>
              {this.state.passwordRepeatRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
              {this.state.passwordRepeatCompatibilityError && <FormHelperText className={classes.helper}>{translations.THE_PASSWORDS_MUST_BE_IDENTICAL[locale.localeName]}</FormHelperText>}
            </FormControl>
     
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20, outline: 'none'}}>
              <Button
                fullWidth
                size="large"
                className={classes.backButton}
                onClick={() => {history.goBack()}}
              >
                {translations.GO_BACK[locale.localeName]}
              </Button>
              <Button
                type="submit"
                fullWidth
                size="large"
                className={classes.submitButton}
                onClick={(event) => {this.changePassword(event)}}
              >
                {translations.SUBMIT[locale.localeName]}
              </Button>
            </div>
          </form>
        </Paper>

        {isEditDialogOpen === true ? <QuestionBox title={translations.CHANGE_PASSWORD[locale.localeName]}
        content={translations.ARE_YOU_SURE_TO_CHANGE_YOUR_PASSWORD[locale.localeName]}
        leftButtonText={translations.CANCEL[locale.localeName]}
        rightButtonText={translations.EDIT_PASSWORD[locale.localeName]}
        leftButtonCallback={() => this.setIsEditDialogOpen(false)}
        rightButtonCallback={() => this.changePasswordPrepare()}
        /> : false}

        </div>

      )
      
     
}};

export default withRouter(withStyles(styles)(ChangePassword));
