import React from "react";
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import QuestionTemplate from "./Template";
import {translations} from "../../../../dictionary/translations";
import QuestionTemplateContainer from "../../../../containers/questionnaire/form/question/Template";
import {style} from "../../../../styles/style.js";
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';


const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {main: style.rose_dark},
  }
});

const styles = theme => ({
  radio: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  label: {
    paddingTop: 12,
    paddingBottom: 12,
    color: `${style.fiolet} !important`,
  }
});



class BooleanQuestion extends React.Component {

  componentDidMount(){
    const { modeAdmin, question, answerLastPen, setAnswers, persistAnswersOnTimeout } =  this.props;
    if (modeAdmin!==true){
      if (question.answers[0]){
        this.setState({value: question.answers[0].answer });
      }
      else if (answerLastPen){
        setAnswers([{
          answerListId: null,
          answer: answerLastPen
        }]);
        persistAnswersOnTimeout();
        this.setState({value: answerLastPen });
      }
    }
  }

  componentDidUpdate(prevProps){
    const {answerLastPen, setAnswers, persistAnswersOnTimeout } =  this.props;
    const {value} = this.state;
    if (answerLastPen !== prevProps.answerLastPen && !value){
        setAnswers([{
          answerListId: null,
          answer: answerLastPen
        }]);
        persistAnswersOnTimeout();
        this.setState({value: answerLastPen });
    }
  }

  handleChange = async (event) => {
    const { setAnswers, persistAnswersOnTimeout, modeAdmin } = this.props;
    const {value} = this.state;
    if (modeAdmin!==true){
      if (event.target.value!==value){
          this.setState({value: event.target.value});
          await setAnswers([{
            answerListId: null,
            answer: event.target.value
          }]);
          persistAnswersOnTimeout();
      }
      else{
          this.setState({value: ''});
          await setAnswers([]);
          persistAnswersOnTimeout();
      }
    }
  };

  state = {
    value: ''
  }

  radioButtonList = () => {
    const { classes, locale } = this.props;
    const list = [];
    for (let index=0; index < 2; index++) {
      let value;
      let label;
      switch (index) {
        case 0 :
          value = false;
          label = translations.NO[locale.localeName];
          break;
        case 1 :
          value = true;
          label = translations.YES[locale.localeName];
          break;
        default: return null;
      }
      list.push(<FormControlLabel key = {index} value={value.toString()} control={<Radio color="primary" className={classes.radio}/>} label={label} />);

    }
    list.reverse();
    return list;
  }

  render() {
    const { classes, locale, questionNumber, question, answersPersistStatus, isAnswerReadOnly, isQuestionReadOnly, modeAdmin, activateQuestion, isSideListOpen } = this.props;
    const {value} = this.state;
    return (
      <QuestionTemplateContainer 
          Layout={QuestionTemplate} 
          modeAdmin={modeAdmin} 
          questionNumber={questionNumber} 
          question={question} 
          answersPersistStatus={answersPersistStatus} 
          isQuestionReadOnly={isQuestionReadOnly} 
          isAnswerReadOnly={isAnswerReadOnly}
          activateQuestion={activateQuestion}
          isSideListOpen={isSideListOpen}
      >
        <FormControl component="fieldset" disabled={isQuestionReadOnly}>
          <FormLabel filled={false} focused={false} className={classes.label}>{translations.CHOOSE_ANSWER[locale.localeName]}:</FormLabel>
          <MuiThemeProvider theme={theme}>
          <RadioGroup
            value={modeAdmin!==true ? value : ''}
            onClick={this.handleChange}
            aria-label={question.id.toString()}
            name={question.id.toString()}
          >
            {
              this.radioButtonList()
            }
          </RadioGroup>
          </MuiThemeProvider>
        </FormControl>
      </QuestionTemplateContainer>
    )
  }
}

export default withStyles(styles)(BooleanQuestion);
