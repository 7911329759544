export const initialState = {
  loading: false,
  error: false,
  success: false,
  data: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_QUESTIONNAIRE_BEGIN: {
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        data: null
      };
    }
    case ADD_QUESTIONNAIRE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.data
      };
    }
    case ADD_QUESTIONNAIRE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        data: null
      };
    }
    case ADD_QUESTIONNAIRE_RESET: {
      return initialState;
    }
    default:
      return state;
  }
}

export const ADD_QUESTIONNAIRE_BEGIN = 'ADD_QUESTIONNAIRE_BEGIN';
export const ADD_QUESTIONNAIRE_SUCCESS = 'ADD_QUESTIONNAIRE_SUCCESS';
export const ADD_QUESTIONNAIRE_FAILURE = 'ADD_QUESTIONNAIRE_FAILURE';
export const ADD_QUESTIONNAIRE_RESET = 'ADD_QUESTIONNAIRE_RESET';
