import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {translations} from '../../dictionary/translations';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {withRouter} from "react-router-dom";
import {style} from "../../styles/style.js";
import ErrorContainer from "../../containers/Error";
import Error from "../Error";
import Loading from "../Loading";
import QuestionBox from "../QuestionBox";
import FormHelperText from '@material-ui/core/FormHelperText';
import LogoImage from '../../img/logo.jpg'
import Image from 'material-ui-image'
import {ERROR_MODE} from "../../util";


const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: 400,
    height: 'auto',
    left: 'calc(50vw - 200px)',
    top: 'calc(50vh - 200px)',
    border: `3px solid ${style.gray}`,
    borderRadius: 20,
    paddingBottom: 20,
    '@media only screen and (max-width: 768px)' : {
          width: '70vw',
          left: '15vw',
    },
  },
  logo : {
    position: 'absolute',
    width: 120,
    height:120,
    left: 'calc(50vw - 60px)',
    top: 'calc(50vh - 260px)',
    zIndex: 1,
    '-webkit-box-shadow': '0px 0px 22px 0px rgba(79,74,79,1)',
    '-moz-box-shadow': '0px 0px 22px 0px rgba(79,74,79,1)',
    'box-shadow' : '0px 0px 22px 0px rgba(79,74,79,1)',
    '@media only screen and (max-width: 768px)' : {
      width: '22vw',
      left: '39vw',
      height: '22vw',
      top: 'calc(50vh - 200px - 11vw)',
    },
  },
  form: {
    width: '90%',
    marginTop: 55,
  },
  label: {
      color: `${style.violet} !important`,
  },
  input : {
      color: `${style.violet}`,
      '&:before' : {
        borderBottom: `2px solid ${style.rose_light} !important`,
      },
      '&:after' : {
        borderBottom: `2px solid ${style.rose_dark}`,
      },
  },
  backButton : {
    transition: 'background-color ease 0.5s',
    backgroundColor: style.gray,
    marginRight: '10%',
    color: 'white',
    outline: 'none',
    '&:hover' : {
      backgroundColor: style.gray_hover,
    },
  },
  submitButton : {
      transition: 'background-color ease 0.5s',
      outline: 'none',
      color: 'white',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
  helper : {
    color: 'red',
  }
};


export class ResetPassword extends React.Component {


  state={newPassword: '',
         newPasswordRepeat: '',
         isResetDialogOpen: false,
         passwordValidationError: false,
         firstClickError: true,
         passwordRequiredError: false,
         passwordRepeatRequiredError: false,
         passwordRepeatCompatibilityError: false,
        };

  handleChange = (event) => {
    const {passwordValidator} = this.props;

    const name = event.currentTarget.name;
    this.setState({[name]: event.currentTarget.value}, () => {

      const { newPassword, firstClickError, newPasswordRepeat} = this.state;

        if (newPasswordRepeat) {
          this.setState({ passwordRepeatRequiredError: false });
          if (newPasswordRepeat === newPassword){
            this.setState({ passwordRepeatCompatibilityError: false });
          }
          else{
            this.setState({ passwordRepeatCompatibilityError: true });
          }
        }
        else if (firstClickError===false) {
          this.setState({ passwordRepeatRequiredError: true, passwordRepeatCompatibilityError: false });
        }
        else{
          this.setState({ passwordRepeatCompatibilityError: false });
        }
        if (newPassword) {
          this.setState({ passwordRequiredError: false });
          if (newPassword.match(passwordValidator) !== null){
            this.setState({ passwordValidationError: false });
          }
          else{
            this.setState({ passwordValidationError: true });
          }
        }
        else if (firstClickError===false) {
          this.setState({ passwordRequiredError: true, passwordValidationError: false });
        }
        else{
          this.setState({passwordValidationError: false});
        }
        if (newPassword && newPasswordRepeat) this.setState({firstClickError: false});
      }
    )
  }

  setIsResetDialogOpen = (isOpen) => {
    this.setState({
      isResetDialogOpen : isOpen
    })
  }

  resetPassword = (event) => {
    const {passwordValidator} = this.props;
    event.preventDefault();
    const { newPassword, newPasswordRepeat, oldPassword} = this.state;
    if (!newPasswordRepeat) {
      this.setState({ passwordRepeatRequiredError: true });
    }
    if (!oldPassword) {
      this.setState({ oldPasswordRequiredError: true });
    }
    if (!newPassword) {
      this.setState({ passwordRequiredError: true });
    }
    else if (!newPassword.match(passwordValidator) == null){
      this.setState({ passwordValidationError: true });
    }
    this.setState({ firstClickError: false });

    if((!this.state.passwordRequiredError)&&
       (!this.state.passwordValidationError)&&
       (!this.state.passwordRepeatRequiredError)&&
       (!this.state.firstClickError)&&
       (!this.state.passwordRepeatCompatibilityError)){
          this.setIsResetDialogOpen(true);    
    };
  };

  resetPasswordPrepare = () => {
    const { resetPassword, token, localeName} = this.props;
    const { newPassword} = this.state;
    const body = {
      newPassword : newPassword,
      token : token
    }
    resetPassword(JSON.stringify(body), localeName);
  }

  handleResetPasswordSuccess = () => {
    const {history, login, userData, localeName} = this.props;
    if (userData.isActive == false){
      history.replace('');
    }
    else{
      const form = new FormData();
      form.append('email', userData.email);
      form.append('password', this.state.newPassword);
      login(form, localeName);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {history, loginStatus, userData, resetResetPassword} = this.props;
    if (loginStatus.success === true) {
      if (userData.roleDicId === 1){
        resetResetPassword();
        history.replace('/administration')
      }
      else{
        resetResetPassword();
        history.replace('/main-navigation')
      }
    }
  }

  
  handleResetPasswordError = () => {
    const {resetResetPassword} = this.props;
    this.setIsResetDialogOpen(false);
    resetResetPassword();
  }

  handleLoginError = () => {
    const {resetLogin, history} = this.props;
    resetLogin();
    history.replace('');
  }

  render() {
    const { classes, localeName, history, resetPasswordStatus, loginStatus, errorType } = this.props;
    const {newPassword, newPasswordRepeat, isResetDialogOpen } = this.state;

    if (resetPasswordStatus.error === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={errorType}
                        callback={this.handleResetPasswordError}
                        localeName={localeName} />

      )
    }
    if (loginStatus.error === true) {
      return (
        <ErrorContainer Layout={Error} 
                        mode={ERROR_MODE.GO_HOME_PAGE} 
                        content={translations.ERROR_WHILE_LOGIN}
                        callback={this.handleLoginError}
                        localeName={localeName} />
      )
    }

    if (resetPasswordStatus.loading === true || loginStatus.loading === true) {
      return (
        <Loading/>
      )
    }

    if (resetPasswordStatus.success === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={translations.THE_PASSWORD_CHANGE_SUCCESSFULLY}
                        callback={this.handleResetPasswordSuccess}
                        localeName={localeName}/>
      )
    }

      return (
        <div>
          <Paper className ={classes.logo}>
            <Image disableTransition={true} src={LogoImage} className={classes.logoImage} alt='logo SusPigSys' />
        </Paper>
        <Paper className={classes.paper}>
          <form className={classes.form}>
  
            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="newPassword">{translations.NEW_PASSWORD[localeName]}</InputLabel>
              <Input className={classes.input} type="password" id="newPassword" name="newPassword" value={newPassword} autoComplete="new password" onChange={this.handleChange}/>
              {this.state.passwordRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[localeName]}</FormHelperText>}
              {this.state.passwordValidationError && <FormHelperText className={classes.helper}>{translations.INVALID_PASSWORD_STRUCTURE[localeName]}</FormHelperText>}
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="newPasswordRepeat">{translations.NEW_PASSWORD_REPEAT[localeName]}</InputLabel>
              <Input className={classes.input} type="password" id="newPasswordRepeat" name="newPasswordRepeat" value={newPasswordRepeat} autoComplete="repeat new password" onChange={this.handleChange}/>
              {this.state.passwordRepeatRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[localeName]}</FormHelperText>}
              {this.state.passwordRepeatCompatibilityError && <FormHelperText className={classes.helper}>{translations.THE_PASSWORDS_MUST_BE_IDENTICAL[localeName]}</FormHelperText>}
            </FormControl>
     
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20, outline: 'none'}}>
              <Button
                fullWidth
                size="large"
                className={classes.backButton}
                onClick={() => { history.replace('')}}
              >
                {translations.MAIN_PAGE[localeName]}
              </Button>
              <Button
                type="submit"
                fullWidth
                size="large"
                className={classes.submitButton}
                onClick={(event) => {this.resetPassword(event)}}
              >
                {translations.SUBMIT[localeName]}
              </Button>
            </div>
          </form>
        </Paper>

        {isResetDialogOpen === true ? <QuestionBox title={translations.RESET_PASSWORD[localeName]}
        content={translations.ARE_YOU_SURE_TO_RESET_YOUR_PASSWORD[localeName]}
        leftButtonText={translations.CANCEL[localeName]}
        rightButtonText={translations.SUBMIT[localeName]}
        leftButtonCallback={() => this.setIsResetDialogOpen(false)}
        rightButtonCallback={() => this.resetPasswordPrepare()}
        /> : false}

        </div>

      )
      
     
}};

export default withRouter(withStyles(styles)(ResetPassword));
