import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SUCCESS_STATUS} from "../../../util";

export const ACTIVATE_ANSWER_BEGIN = 'ACTIVATE_ANSWER_BEGIN';
export const ACTIVATE_ANSWER_SUCCESS = 'ACTIVATE_ANSWER_SUCCESS';
export const ACTIVATE_ANSWER_FAILURE = 'ACTIVATE_ANSWER_FAILURE';
export const ACTIVATE_ANSWER_RESET = 'ACTIVATE_ANSWER_RESET';



const initState = {
  activateStatus: {
    ...INITIAL_STATUS
  },
  data: null
}

export default function appReducer(state = initState, action) {

  switch (action.type) {

    case ACTIVATE_ANSWER_BEGIN: {
      return {
        ...state,
        getStatus: BEGIN_STATUS,
      };
    }

    case ACTIVATE_ANSWER_SUCCESS: {
      return {
        ...state,
        getStatus: SUCCESS_STATUS,
      };
    }

    case ACTIVATE_ANSWER_FAILURE: {
      return {
        ...state,
        getStatus: FAILURE_STATUS,
      };
    }

    default:
      return state;
  }
}
