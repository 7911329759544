import React from "react";
import {withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {style} from "../../../../styles/style.js";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import SubformTemplate from '../SubformTemplate';
import SubformTemplateContainer from '../../../../containers/questionnaire/form/SubformTemplate';
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Popover from "@material-ui/core/Popover";
import {translations} from '../../../../dictionary/translations';
import { green, red } from '@material-ui/core/colors';

const styles = {
  paper: {
    borderLeft: `3px solid ${style.gray}`,
    borderBottom: `3px solid ${style.gray}`,
  },
  nav : {
    padding: 0,
  },
  listItem : {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'flex-start',
    padding: '8px 16px 8px 16px !important',
    cursor: 'pointer',
    '&:hover' : {
      backgroundColor : style.rose_light,
    },
    '&$selected' : {
      backgroundColor : style.rose_dark,
      color: style.szary,
      '&:hover' : {
        backgroundColor : style.rose_dark,
      },
    }
  },
  listItemText : {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    '@media only screen and (max-width: 768px)' : {
      fontSize: 11,
    },
  },
  selected : {
  },
  complete : {
    cursor: 'pointer',
    '&$selected' : {
      backgroundColor: style.green,
      '&:hover' : {
        backgroundColor : style.green_hover,
      },
    },
    '&:hover' : {
      backgroundColor : style.green_hover,
    },
    backgroundColor: style.green,
  },
  notComplete : {
    cursor: 'pointer',
    '&$selected' : {
      backgroundColor: style.white,
      color: `black !important`,
      '&:hover' : {
        backgroundColor : style.white_hover,
        color: `black !important`
      },
    },
    '&:hover' : {
      backgroundColor : style.white_hover,
      color: `black !important`
    },
    backgroundColor: style.white,
    color: `black !important`
  },
  item: {
      color: 'white',
  },
  popover: {
    pointerEvents: 'none',
    maxWidth: '60vw',
  },
};

const MySwitch = withStyles({
  switchBase: {
    color: red[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);


class SubFormBar extends React.Component {

  componentDidMount(){
    const {prevSubformList, modeAdmin } = this.props;
    if (modeAdmin === true){
      let act = [];
      if (prevSubformList){
        if (prevSubformList.length!==0){
            prevSubformList.map((item, key) => (
              act.push(item.isActive)
            ))
        }
      }
      this.setState({activation : act});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {getSubFormList, locale, formId, setAfterSubformAdding, setSubFormId, setAfterSubformEditing, getQuestionList, questionList, subFormId, modeAdmin} = this.props;
    const { isGoBack, selectedSubform } = this.state;
    if (this.state.add === false && prevState.add === true) {
      if (!isGoBack) {
        const refreshPage = async () => {
          await setAfterSubformAdding(true);
          await getSubFormList(locale.id, formId, true);
      }
      refreshPage().then(() => setAfterSubformAdding(false));
      }
    }
    else if (this.state.edit === false && prevState.edit === true) {
      if (!isGoBack) {
        async function refreshPage() {
          await setAfterSubformEditing(true)
          await getSubFormList(locale.id, formId, true);
          await setSubFormId(selectedSubform);
          await getQuestionList(locale.id, selectedSubform, true);  
      }
      refreshPage().then(() => {
        setAfterSubformEditing(false);
      });
      }
    }

    if (questionList !== prevProps.questionList && modeAdmin!==true){
      const { fillingUpStatus, questionList, updateFillingUpStatus} = this.props;
      updateFillingUpStatus(fillingUpStatus, questionList, subFormId, true)
    }
  }

  state = {
    activation: '',
    add: false,
    edit: false,
    selectedSubform: null,
    items: null,
    anchorEl : null,
    isGoBack: false,
    afterAdding: null,
  }


  handleChange = (event) => {
      const {setNextSubFormId} = this.props;
      this.sel = Number.parseInt(event.currentTarget.attributes["subform-id"].value);
      setNextSubFormId(Number.parseInt(event.currentTarget.attributes["subform-id"].value));
  }

  sel = 0;

  getClasses = (subFormId) => {
    const { classes} = this.props;
    const subFormStatus = this.getSubformStatus(subFormId);
    if (subFormStatus.questionGroupList.length === 0) {
      return classes.notComplete;
    }
    if (subFormStatus.questionGroupList.length === 1 && subFormStatus.questionGroupList[0].qlNumber === -1) {
      let isComplete = true;
      subFormStatus.questionGroupList[0].questionList.forEach(el => {
        if (el.isComplete === false) {
          isComplete = false;
        }
      })
      return isComplete === true ? classes.complete : classes.notComplete;
    }

    const successQuestionGroupCount = this.getSuccessQuestionGroupCount(subFormId);
    const allQuestionGroupCount = this.getAllQuestionGroupCount(subFormId);
    if (successQuestionGroupCount === 0 && allQuestionGroupCount === 0) {
      return classes.notComplete;
    }
    return successQuestionGroupCount === allQuestionGroupCount ? classes.complete : classes.notComplete;
  }

  getSuccessQuestionGroupCount = (subFormId) => {
    const questionGroupList = this.getQuestionGroupList(subFormId);
    let successCount = 0;
    questionGroupList.forEach(group => {
      let isSuccess = true;
      group.questionList.forEach(questionList => {
        if (questionList.isComplete === false) {
          isSuccess = false;
        }
      })
      if (isSuccess === true) successCount++;
    })
    return successCount;
  }

  getSuccessQuestionCount = (questionList) => {
    let successCount = 0;
    questionList.forEach(question => {
      if (question.isComplete === true) {
        successCount++;
      }
    })
    return successCount;
  }

  getAllQuestionGroupCount = (subFormId) => {
    const questionGroupList = this.getQuestionGroupList(subFormId);
    return questionGroupList.length;
  }

  getQuestionGroupList = (subFormId) => {
    const subFormStatus = this.getSubformStatus(subFormId);
    return subFormStatus.questionGroupList;
  }

  getSubformStatus = (subFormId) => {
    const { fillingUpStatus } = this.props;
    return fillingUpStatus.find(el => el.subFormId === subFormId);
  }

  getText = (id, code) => {
    const subFormStatus = this.getSubformStatus(id);
    if (subFormStatus){
          if (subFormStatus.questionGroupList.length === 0) {
            return <span>{code}</span>;
          } 
          else {
            const successCount = this.getSuccessQuestionGroupCount(id);
            const allCount = this.getAllQuestionGroupCount(id);
            const countStyle = (successCount === allCount && allCount > 0) ? {fontWeight: 'bold'} : {};
            return <div>
              <span>{code}</span>
              <span style={countStyle}>{' (' + successCount + '/' + allCount + ')'}</span>
            </div>
          }
    }
  }

  setActive = (id, active, key) => {
    const {activateSubform} = this.props;
    let act = [...this.state.activation];
    act[key] = active;
    activateSubform(id, active);
    this.setState({activation : act});
  }

  closeEditOrAddWindow = (isGoBack, selectedSubform) => {
    this.setState({
      add : false,
      edit: false,
      isGoBack: isGoBack,
      selectedSubform: selectedSubform,
    });
  }

  getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? 'lightblue' : 'lightgrey',
  });

  getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: "rgb(235,235,235)"
    })
  });

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    if (result.source.index-result.destination.index===1 || result.source.index-result.destination.index===-1){
    const res = this.reorder(
      this.state.items,
      result.source.index,
      result.destination.index,
    );
    this.setState({
      items : res[0],
      activation : res[1]
    });
  }
  }

  reorder = (list, startIndex, endIndex, startId, endId) => {
    const {swapSubform} = this.props;
    const {activation} = this.state;
    const result = Array.from(list);
    const result_act = Array.from(activation);
    const [removed] = result.splice(startIndex, 1);
    const [removed_act] = result_act.splice(startIndex, 1);
    swapSubform(list[startIndex].id, list[endIndex].id);
    result.splice(endIndex, 0, removed);
    result_act.splice(endIndex, 0, removed_act);
    return [result, result_act];
  };

  handlePopoverOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    })
  }


  render() {
    const { classes, subFormListData, subFormId, prevSubformList, modeAdmin, formId, locale, afterSubformAdding, setNextSubFormId} = this.props;
    const { activation, add, edit, selectedSubform, items, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    if (afterSubformAdding) {
      if (subFormListData) setNextSubFormId(subFormListData[subFormListData.length-1].id);
    }
    if (this.sel === 0) this.sel = subFormId;
    return (
      <div>
        {modeAdmin !== true ?
        <Paper className={classes.paper} >
          <List component="nav" className={classes.nav} >
            { prevSubformList ? prevSubformList.length!==0 ? items===null ? this.setState({items: prevSubformList}) : null : null : null}
            { items ? items.map((item, key) => (
                    <ListItem
                      classes={(item.id === subFormId) ? {root : classes.listItem, selected : classes.selected} : { root: classes.listItem }}
                      selected={item.id === subFormId}
                      divider key={item.id}
                      subform-id={item.id}
                      onClick={this.handleChange}
                    >
                        { prevSubformList!==subFormListData ?
                          <ListItemText  className={classes.listItemText} disableTypography primary={<span>{item.subformText}</span>} value={item.id}/>
                          :
                          <ListItemText  className={classes.listItemText} disableTypography primary={this.getText(item.id, item.subformText)} value={item.id}/>
                        }
                  </ListItem>
            )) : null}
          </List>
        </Paper>

        :

        <Paper className={classes.paper} >
          <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <RootRef rootRef={provided.innerRef}>
                            <List component="nav" className={classes.nav} style={this.getListStyle(snapshot.isDraggingOver)}>
                              { prevSubformList ? prevSubformList.length!==0 ? items===null ? this.setState({items: prevSubformList}) : null : null : null}
                              { items ? items.map((item, key) => (
                                <Draggable key={item.id} draggableId={item.id} index={key}>
                                {(provided, snapshot) => (
                                    <ListItem
                                        classes={(item.id === subFormId) ? {root : classes.listItem, selected : classes.selected} : { root: classes.listItem }}
                                        selected={item.id === subFormId}
                                        divider key={item.id}
                                        subform-id={item.id}
                                        onClick={this.handleChange}
                                        ContainerComponent="li"
                                        ContainerProps={{ ref: provided.innerRef }}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        style={this.getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                        >
                                          {item.farmType !== null ?
                                            <ListItemText  className={classes.listItemText} disableTypography primary={<span className={classes.listItemText}><span>{`${item.name}`}</span><span>{`(${item.farmType})`}</span></span>} value={item.id}/>
                                            :
                                            <ListItemText className={classes.listItemText} disableTypography primary={<span>{`${item.name}`}</span>} value={item.id}/>
                                          }
                                            <ListItemSecondaryAction style={{display: 'flex', width: '40%', justifyContent: 'space-between', alignItems: 'center'}} >
                                              <IconButton aria-label="edit" style={{flexGrow:1}} onClick={() => this.setState({edit: true, selectedSubform: item.id})}>
                                                  <EditOutlinedIcon fontSize='small'/>
                                              </IconButton>
                                              <FormGroup >
                                                  <MySwitch  size="small" checked={activation[key] ? activation[key] : false} onChange={() => this.setActive(item.id, !activation[key], key)} />
                                              </FormGroup>
                                            </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                                </Draggable>
                              )) : null}
                            {provided.placeholder}
                            </List>

                     </RootRef>
                )}
            </Droppable>
          </DragDropContext>
          <Fab size="small" color="secondary" aria-label="add" onClick={() => this.setState({add: true})} onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose}>
                  <AddIcon size='small' alt='sss'/>
          </Fab>
          <Popover
                className={classes.popover}
                open={open}
                onClose={this.handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div style={{padding: 5,}}>
                {translations.ADD_SUBFORM[locale.localeName]}
                </div>
          </Popover>

          {add === true ?
              <SubformTemplateContainer Layout={SubformTemplate} formId={formId} mode='add' locale={locale} close={this.closeEditOrAddWindow} />
          :null
          }
          {edit === true ?
              <SubformTemplateContainer Layout={SubformTemplate} formId={formId} subformId={selectedSubform} mode='edit' locale={locale} close={this.closeEditOrAddWindow} />
          :null
          }
        </Paper> }
      </div>
      )
  }
}

export default withStyles(styles)(SubFormBar);
