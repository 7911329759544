import React from "react";
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import QuestionTemplate from "./Template";
import {translations} from "../../../../dictionary/translations";
import QuestionTemplateContainer from "../../../../containers/questionnaire/form/question/Template";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "inline-block",
    marginRight: '1%'
  },
  helper : {
    color: 'red',
    fontSize: 15,
  }
});

class DecimalNumberQuestion extends React.Component {

  state = {
    error: false,
    text: '',
    value: '',
  }

  componentDidMount(){
    const { modeAdmin, question, answerLastPen, setAnswers, persistAnswersOnTimeout } =  this.props;
    if (modeAdmin!==true){
      if (question.answers[0]){
        this.setState({value: question.answers[0].answer });
      }
      else if (answerLastPen){
        setAnswers([{
          answerListId: null,
          answer: answerLastPen
        }]);
        persistAnswersOnTimeout();
        this.setState({value: answerLastPen });
      }
    }
  }

  componentDidUpdate(prevProps){
    const {answerLastPen, setAnswers, persistAnswersOnTimeout } =  this.props;
    const {value} = this.state;
    if (answerLastPen !== prevProps.answerLastPen && !value){
        setAnswers([{
          answerListId: null,
          answer: answerLastPen
        }]);
        persistAnswersOnTimeout();
        this.setState({value: answerLastPen });
    }
  }
 

  handleChange = (event) => {
    const { setAnswers, persistAnswersOnTimeout, question, questions, locale } = this.props;
    const regex = new RegExp("^(?:[1-9]\\d*|0)?$");
    const val = event.target.value;
    const value = parseInt(val);
    const min = question.min;
    const max = question.max;
    const maxQuestionId = question.maxAnswerQuestionId;
    let maxFromAnotherQuestion = null;
    if (maxQuestionId){
      maxFromAnotherQuestion = questions.filter((el) => el.questionId === maxQuestionId)[0].answers[0].answer;
    }
    if (regex.test(value)) {
      this.setState({[event.target.name]: value}, () => {
          if (min!==null && max!==null){
            if (value < min || value > max){
              setAnswers([{
                answerListId: null,
                answer: ''
              }]);
              persistAnswersOnTimeout();
              this.setState({ error: true, text: translations.VALUE_BETWEEN[locale.localeName]+`${min} - ${max}`})
            }
            else{
              setAnswers([{
                answerListId: null,
                answer: value
              }]);
              persistAnswersOnTimeout();
              this.setState({ error: false, text: ''})
            }
          }
          else if (min!==null && maxQuestionId!==null){
            if (value < min || value > parseFloat(maxFromAnotherQuestion)){
              setAnswers([{
                answerListId: null,
                answer: ''
              }]);
              persistAnswersOnTimeout();
              if (maxFromAnotherQuestion){
                this.setState({ error: true, text: translations.VALUE_BETWEEN[locale.localeName]+`${min} - ${maxFromAnotherQuestion}`})
              }
              else{
                this.setState({ error: true, text: translations.VALUE_GREATER_THEN[locale.localeName]+`${min}`})
              }
            }
            else{
              setAnswers([{
                answerListId: null,
                answer: value
              }]);
              persistAnswersOnTimeout();
              this.setState({ error: false, text: ''})
            }
          }
          else if (min!==null){
            if (value < min){
              setAnswers([{
                answerListId: null,
                answer: ''
              }]);
              persistAnswersOnTimeout();
              this.setState({ error: true, text: translations.VALUE_GREATER_THEN[locale.localeName]+`${min}`})
            }
            else{
              setAnswers([{
                answerListId: null,
                answer: value
              }]);
              persistAnswersOnTimeout();
              this.setState({ error: false, text: ''})
            }
          }
          else if (max!==null){
            if (value > max){
              setAnswers([{
                answerListId: null,
                answer: ''
              }]);
              persistAnswersOnTimeout();
              this.setState({ error: true, text: translations.VALUE_LESS_THEN[locale.localeName]+`${max}`})
            }
            else{
              setAnswers([{
                answerListId: null,
                answer: value
              }]);
              persistAnswersOnTimeout();
              this.setState({ error: false, text: ''})
            }
          }
          else if (maxQuestionId!==null){
            if (value > parseFloat(maxFromAnotherQuestion) ){
              if (maxFromAnotherQuestion){
                setAnswers([{
                  answerListId: null,
                  answer: ''
                }]);
                persistAnswersOnTimeout();
                this.setState({ error: true, text: translations.VALUE_LESS_THEN[locale.localeName]+`${maxFromAnotherQuestion}`})
              }
              else{
                setAnswers([{
                  answerListId: null,
                  answer: value
                }]);
                persistAnswersOnTimeout();
                this.setState({ error: false, text: ''})
              }
              
            }
            else{
              setAnswers([{
                answerListId: null,
                answer: value
              }]);
              persistAnswersOnTimeout();
              this.setState({ error: false, text: ''})
            }
          }
          else{
              setAnswers([{
                answerListId: null,
                answer: value
              }]);
              persistAnswersOnTimeout();
          }
      });
    } 
    else if (val) {
      setAnswers([{
        answerListId: null,
        answer: ''
      }]);
      persistAnswersOnTimeout();
      this.setState({ [event.target.name]: val,  error: true, text: translations.VALUE_SHOULD_BE_NUMBER[locale.localeName]});
    }
    else{
      setAnswers([{
        answerListId: null,
        answer: null
      }]);
      persistAnswersOnTimeout();
      this.setState({ [event.target.name]: val,  error: false, text: ''});
    }
  };

  render() {
    const { locale, questionNumber, question, isSideListOpen, persistAnswersOnBlur, answersPersistStatus, isAnswerReadOnly, isQuestionReadOnly, modeAdmin, activateQuestion, classes} = this.props;
    const { error, text, value } = this.state;
    return (
      <QuestionTemplateContainer 
          Layout={QuestionTemplate} 
          modeAdmin={modeAdmin} 
          questionNumber={questionNumber} 
          question={question} 
          answersPersistStatus={answersPersistStatus} 
          isQuestionReadOnly={isQuestionReadOnly} 
          isAnswerReadOnly={isAnswerReadOnly}
          activateQuestion={activateQuestion}
          isSideListOpen={isSideListOpen}
        >
        <FormControl component="fieldset">
          <InputLabel htmlFor="value">{translations.WRITE_ANSWER[locale.localeName]}</InputLabel>
          <Input
            
            name="value"
            value={value}
            onChange={modeAdmin!==true ? this.handleChange : null}
            //onBlur={modeAdmin!==true ? this.handleChange : null}
          />
          {error && <FormHelperText className={classes.helper}>{text}</FormHelperText>}
        </FormControl>
      </QuestionTemplateContainer>
    )
  }
}

export default withStyles(styles)(DecimalNumberQuestion);
