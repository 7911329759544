import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class ActivationContainer extends React.Component {

  render() {

    const token = this.props.match.params.token;
    const localeId = this.props.match.params.localeId;
    const roleId = this.props.match.params.roleId;
    let chosenLocale='';

    switch (localeId){
      case '1':
        chosenLocale='en';
        break;
      case '2':
        chosenLocale='pl';
        break;
      case '3':
        chosenLocale='de';
        break;
      case '4':
        chosenLocale='fi';
        break;
      case '5':
        chosenLocale='it';
        break;
      case '6':
        chosenLocale='nl';
        break;
      default:
        chosenLocale='en';
    }

    const {
      Layout,
      activate,
      activationStatus,
      resetActivation,
      errorType,
    } = this.props;

    return (
      <Layout
        token={token}
        localeName={chosenLocale}
        activate={activate}
        activationStatus={activationStatus}
        resetActivation={resetActivation}
        roleId={roleId}
        errorType={errorType}
      />
    )
  }
}

const mapStateToProps = state => ({
  activationStatus: state.user.activation,
  errorType: state.user.activation.errorType,
});

const mapDispatchToProps = {
  activate: actions.user.activation.activate,
  resetActivation : actions.user.activation.resetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivationContainer);
