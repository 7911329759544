import {URL} from '../../config';

function login(form, language) {
  return dispatch => {
    dispatch(loginUserBegin());
    return loginUserStart(form, language)
      .then(data => {
        dispatch(loginSuccess(data));
      })
      .catch((errorType) => {
        dispatch(loginUserFailure(errorType))
      });
  };
}

const loginUserBegin = () => ({
  type: 'LOGIN_USER_BEGIN'
});

function loginUserStart(form, language) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/user/login/web`, {
      method: 'POST',
      body: form,
      headers: {
        'Accept-Language': language,
      },
    }).then(response => response.json())
      .then(json => {
        if (json.success === true && json.data !== null && json.data.user !== null && json.data.token !==null) {
          sessionStorage.setItem('token', json.data.token);
          resolve(json.data.user);
        } else {
          reject(json.error);
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const loginSuccess = (user) => ({
  type: 'LOGIN_USER_SUCCESS',
  user: user
});

const loginUserFailure = (errorType) => ({
  type: 'LOGIN_USER_FAILURE',
  error: true,
  errorType: errorType,

});

const resetState = () => ({
  type: 'LOGIN_USER_RESET'
});

const index = {
  login,
  loginSuccess,
  resetState
}

export default index;
