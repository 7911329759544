import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SUCCESS_STATUS} from "../../util";

export const initialState = INITIAL_STATUS;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVATE_USER_BEGIN: {
      return {
        ...state,
        ...BEGIN_STATUS
      };
    }
    case ACTIVATE_USER_SUCCESS: {
      return {
        ...state,
        ...SUCCESS_STATUS
      };
    }
    case ACTIVATE_USER_FAILURE: {
      return {
        ...state,
        ...FAILURE_STATUS
      };
    }
    case ACTIVATE_USER_RESET: {
      return initialState;
    }
    default:
      return state;
  }
}

export const ACTIVATE_USER_BEGIN = 'ACTIVATE_USER_BEGIN';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE';
export const ACTIVATE_USER_RESET = 'ACTIVATE_USER_RESET';
