import React from 'react';
import {connect} from "react-redux";

export class ErrorContainer extends React.Component {

  render() {

    const {
      Layout,
      locale,
      mode,
      content,
      buttonText,
      isGoBack,
      isGoHomePage,
      callback,
      localeName,
    } = this.props;

    return (
      <Layout
        localeName={localeName}
        locale={locale}
        mode={mode}
        content={content}
        buttonText={buttonText}
        isGoBack={isGoBack}
        isGoHomePage={isGoHomePage}
        callback={callback}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
});

export default connect(mapStateToProps)(ErrorContainer);
