import {URL} from '../../config';
import {ACTIVATION_BEGIN, ACTIVATION_FAILURE, ACTIVATION_RESET, ACTIVATION_SUCCESS} from "../../reducers/user/activation";

function activate(token, language) {
  return dispatch => {
    dispatch(setBegin());
    return activateStart(token, language)
      .then(() => {
        dispatch(setSuccess());
      })
      .catch((errorType) => {
        dispatch(setFailure(errorType))
      });
  };
}

const setBegin = () => ({
  type: ACTIVATION_BEGIN
});

function activateStart(token, language) {
  return new Promise((resolve, reject) => {
    
    fetch(URL + `/user/confirm-user/token/${token}`, {
      method: 'PUT',
      headers: {
        'Accept-Language': language,
      },
    })
    .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject(json.error);
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setSuccess = () => ({
  type: ACTIVATION_SUCCESS
});

const setFailure = (errorType) => ({
  type: ACTIVATION_FAILURE,
  errorType: errorType,
});

const resetState = () => ({
  type: ACTIVATION_RESET
});

const index = {
  activate,
  resetState
}

export default index;
