import React from 'react';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import {ERROR_MODE} from "../util";
import {translations} from "../dictionary/translations";
import {withRouter} from "react-router-dom";
import {style} from "../styles/style.js";
import {withStyles} from '@material-ui/core/styles';


const styles = {
  button : {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      outline: 'none',
      backgroundColor: style.rose_light,
      color: style.fiolet,
      '&:hover' : {
          backgroundColor: style.rose_light_hover,
      },
  },
  dialogContentText : {
      textAlign: 'center',
  },
  dialogContent : {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 100,
    paddingRight: 100,
    '@media only screen and (max-width: 768px)' : {
      paddingLeft: '10vw',
      paddingRight: '10vw',
    },
  },
};



class Error extends React.Component {

  state = {
    isOpen : true
  }

  render() {
    const {classes, mode, history, locale, content, buttonText, isGoBack, isGoHomePage, callback, localeName} = this.props;
    const {isOpen} = this.state;

    const defaultContent = translations.ERROR;
    const defaultButtonText = translations.GO_BACK;

    let modeContent;
    let modeButtonText;
    let modeIsGoBack;
    let modeIsGoHomePage;
    let modeCallback;

    const normalError = () => {
      modeContent = defaultContent;
      modeButtonText = defaultButtonText;
    }

    switch(mode) {

      case ERROR_MODE.GO_BACK:
        modeContent=defaultContent;
        modeButtonText = defaultButtonText;
        modeIsGoBack = true;
        break;

      case ERROR_MODE.GO_HOME_PAGE:
        modeContent=defaultContent;
        modeButtonText = defaultButtonText;
        modeIsGoBack = false;
        modeIsGoHomePage = true;
        break;

      case ERROR_MODE.REFRESH:
        modeContent=defaultContent;
        modeButtonText = translations.RELOAD_PAGE;
        modeCallback = () => { window.location.reload() }
        break;

      default:
        normalError();
    }

    const theContent = (content) ? content : modeContent;
    const theButtonText = (buttonText) ? buttonText : modeButtonText;
    const theIsGoBack = (isGoBack !== null && isGoBack !== undefined) ? isGoBack : modeIsGoBack;
    const theIsGoHomePage = (isGoHomePage !== null && isGoHomePage !== undefined) ? isGoHomePage : modeIsGoHomePage;
    const theCallback = (callback) ? callback : modeCallback;

    this.handleButton = () => {
      this.setState({isOpen : false})
      if (theCallback) {
        theCallback();
      }
      if (theIsGoHomePage) {
        history.push('/');
      }
      if (theIsGoBack) {
        history.goBack();
      }
    }
    
    return (
      <div>
        <Dialog open={isOpen} >
          <DialogContent className={classes.dialogContent}>
            <DialogContentText className={classes.dialogContentText}>
              {content ? content.length>0 ? content : localeName ? theContent[localeName] : theContent[locale.localeName] : localeName ? theContent[localeName] : theContent[locale.localeName] }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              variant="outlined"
              onClick={() => {
                this.handleButton();
              }}
            >
              {buttonText ? buttonText : localeName ? theButtonText[localeName] : theButtonText[locale.localeName]}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  };
};

export default withRouter(withStyles(styles)(Error));
