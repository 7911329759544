import {URL} from '../../config';
import {
  ACTIVATE_USER_BEGIN,
  ACTIVATE_USER_FAILURE,
  ACTIVATE_USER_RESET,
  ACTIVATE_USER_SUCCESS
} from "../../reducers/admin/activate";

function activateUser(userId, activate) {
  return dispatch => {
    dispatch(activateUserBegin());
    return activateUserStart(userId, activate)
      .then(() => {
        dispatch(activateUserSuccess());
      })
      .catch(() => {
        dispatch(activateUserFailure())
      });
  };
}


function activateUserStart(userId, activate) {
  return new Promise((resolve, reject) => {
    // symulacja fetch
    fetch(URL + `/secured/user/${userId}/isActive/${activate}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve();
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const activateUserBegin = () => ({
  type: ACTIVATE_USER_BEGIN
});

const activateUserSuccess = () => ({
  type: ACTIVATE_USER_SUCCESS
});

const activateUserFailure = () => ({
  type: ACTIVATE_USER_FAILURE
});

const resetState = () => ({
  type: ACTIVATE_USER_RESET
});

const index = {
  activateUser,
  resetState
}

export default index;
