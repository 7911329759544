import React from 'react';
import {connect} from "react-redux";
import actions from '../../../actions';

export class QuestionnaireFormContainer extends React.Component {

  render() {

    const {
      Layout,
      locale,
      user,
      questionnaireDicList,
      getQuestionnaireDicList,
      formList,
      subFormList,
      questionList,
      getFormList,
      getSubFormList,
      getQuestionList,
      setQuestionnaireId,
      setFormId,
      setSubFormId,
      setNextSubFormId,
      resetSubFormId,
      setQuestionListId,
      resetQuestionListId,
      questionnaireId,
      questionnaireDicId,
      formId,
      nextSubFormId,
      subFormId,
      questionListId,
      isAnswerReadOnly,
      isQuestionGroupInitial,
      setIsQuestionGroupInitial,
      prevSubFormId,
      setNextQuestionListId,
      nextQuestionListId,
      setNextFormId,
      nextFormId,
      fillingUpStatus,
      updateFillingUpStatus,
      setPrevSubformList,
      setPrevQuestionList,
      prevQuestionList,
      modeAdmin,
      resetSubformList,
      questionsAdmin,
      afterSubformEditing
    } = this.props;

    return (
      <Layout
        locale={locale}
        user={user}
        questionnaireDicList={questionnaireDicList}
        getQuestionnaireDicList={getQuestionnaireDicList}
        formList={formList}
        subFormList={subFormList}
        questionList={questionList}
        getFormList={getFormList}
        getSubFormList={getSubFormList}
        getQuestionList={getQuestionList}
        setQuestionnaireId={setQuestionnaireId}
        setFormId={setFormId}
        setSubFormId={setSubFormId}
        setNextSubFormId={setNextSubFormId}
        resetSubFormId={resetSubFormId}
        setQuestionListId={setQuestionListId}
        resetQuestionListId={resetQuestionListId}
        questionnaireId={questionnaireId}
        questionnaireDicId={questionnaireDicId}
        formId={formId}
        nextSubFormId={nextSubFormId}
        subFormId={subFormId}
        questionListId={questionListId}
        isAnswerReadOnly={isAnswerReadOnly}
        isQuestionGroupInitial={isQuestionGroupInitial}
        setIsQuestionGroupInitial={setIsQuestionGroupInitial}
        prevSubFormId={prevSubFormId}
        setNextQuestionListId={setNextQuestionListId}
        nextQuestionListId={nextQuestionListId}
        setNextFormId={setNextFormId}
        nextFormId={nextFormId}
        fillingUpStatus={fillingUpStatus}
        updateFillingUpStatus={updateFillingUpStatus}
        setPrevSubformList={setPrevSubformList}
        setPrevQuestionList={setPrevQuestionList}
        prevQuestionList={prevQuestionList}
        modeAdmin={modeAdmin}
        resetSubformList={resetSubformList}
        questionsAdmin={questionsAdmin}
        afterSubformEditing={afterSubformEditing}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  user: state.user.login.user,
  questionnaireDicList: {
    loading: state.dictionary.questionnaireList.loading,
    error: state.dictionary.questionnaireList.error,
    success: state.dictionary.questionnaireList.success,
    items: state.dictionary.questionnaireList.items,
  },
  questionnaireDicId : state.questionnaire.form.form.questionnaireDicId,
  formList: {
    loading: state.questionnaire.form.list.loading,
    error: state.questionnaire.form.list.error,
    success: state.questionnaire.form.list.success,
    data: state.questionnaire.form.list.data,
  },
  subFormList: {
    loading: state.questionnaire.form.subFormList.getStatus.loading,
    error: state.questionnaire.form.subFormList.getStatus.error,
    success: state.questionnaire.form.subFormList.getStatus.success,
    data: state.questionnaire.form.subFormList.data,
    prevSubformList: state.questionnaire.form.subFormList.prevSubformList,
  },
  prevQuestionList: state.questionnaire.form.questionList.prevQuestionList,
  questionList: state.questionnaire.form.questionList,
  questionnaireId : state.questionnaire.form.form.questionnaireId,
  formId : state.questionnaire.form.form.formId,
  nextFormId: state.questionnaire.form.list.nextFormId,
  nextSubFormId: state.questionnaire.form.subFormList.nextSubFormId,
  subFormId : state.questionnaire.form.form.subFormId,
  questionListId : state.questionnaire.form.form.questionListId,
  isAnswerReadOnly : state.questionnaire.form.form.isAnswerReadOnly,
  isQuestionGroupInitial : state.questionnaire.form.form.isQuestionGroupInitial,
  prevSubFormId : state.questionnaire.form.form.prevSubFormId,
  nextQuestionListId : state.questionnaire.form.questionList.nextQuestionListId,
  fillingUpStatus : state.questionnaire.form.subFormList.fillingUpStatus,
  questionsAdmin : state.questionnaire.form.questionsAdmin.data,
  afterSubformEditing : state.questionnaire.form.form.afterSubformEditing,
});

const mapDispatchToProps = {
  getQuestionnaireDicList: actions.dictionary.questionnaireList.getQuestionnaireList,
  getFormList : actions.questionnaire.form.list.getFormList,
  getSubFormList : actions.questionnaire.form.subFormList.getSubFormList,
  getQuestionList : actions.questionnaire.form.questionList.getQuestionList,
  setQuestionnaireId : actions.questionnaire.form.form.setQuestionnaireId,
  setFormId : actions.questionnaire.form.form.setFormId,
  setSubFormId : actions.questionnaire.form.form.setSubFormId,
  setNextSubFormId : actions.questionnaire.form.subFormList.setNextSubFormId,
  setNextFormId : actions.questionnaire.form.list.setNextFormId,
  resetSubFormId : actions.questionnaire.form.form.resetSubFormId,
  setQuestionListId : actions.questionnaire.form.form.setQuestionListId,
  setIsQuestionGroupInitial : actions.questionnaire.form.form.setIsQuestionGroupInitial,
  resetQuestionListId : actions.questionnaire.form.form.resetQuestionListId,
  setNextQuestionListId : actions.questionnaire.form.questionList.setNextQuestionListId,
  updateFillingUpStatus : actions.questionnaire.form.subFormList.updateFillingUpStatus,
  setPrevSubformList : actions.questionnaire.form.subFormList.setPrevSubformList,
  setPrevQuestionList : actions.questionnaire.form.questionList.setPrevQuestionList,
  resetSubformList : actions.questionnaire.form.subFormList.resetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireFormContainer);
