import {URL} from '../../config';
import {PASSWORD_EDIT_BEGIN, PASSWORD_EDIT_FAILURE, PASSWORD_EDIT_RESET, PASSWORD_EDIT_SUCCESS} from "../../reducers/user/changePassword";

function editPassword(body, language) {
  return dispatch => {
    dispatch(setBegin());
    return editPasswordStart(body, language)
      .then(() => {
        dispatch(setSuccess());
      })
      .catch((errorType) => {
        dispatch(setFailure(errorType))
      });
  };
}

const setBegin = () => ({
  type: PASSWORD_EDIT_BEGIN
});

function editPasswordStart(body, language) {
  return new Promise((resolve, reject) => {
    
    fetch(URL + `/secured/user/change-password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        'Accept-Language': language,
      },
      body: body
    })
    .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject(json.error);
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setSuccess = () => ({
  type: PASSWORD_EDIT_SUCCESS
});

const setFailure = (errorType) => ({
  type: PASSWORD_EDIT_FAILURE,
  errorType: errorType,
});

const resetState = () => ({
  type: PASSWORD_EDIT_RESET
});

const index = {
  editPassword,
  resetState
}

export default index;
