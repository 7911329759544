import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class UsersListContainer extends React.Component {

  rowsPerPage = 10;

  state = {
    page : 0,
    order: 'asc',
    orderBy: 'email',
  }

  handleChangePage = (event, page) => {
    this.setState({page}, () => {
      const {getUserList, searchText} = this.props;
      const {order, orderBy, page} = this.state;
      const {rowsPerPage} = this;
      const start = page * rowsPerPage;
      const limit = rowsPerPage;
      getUserList(start, limit, searchText, orderBy, order);
    });
  };

  changeOrder = (order, orderBy) => {
    this.setState({ order, orderBy }, () => {
      const {page} = this.state;
      const {getUserList, searchText} = this.props;
      const {rowsPerPage} = this;
      const start = page * rowsPerPage;
      const limit = rowsPerPage;
      getUserList(start, limit, searchText, orderBy, order);
    });
  }

  render() {

    const {
      Layout,
      parentClasses,
      locale,
      dictionary,
      items,
      total,
      getUserList,
      user,
      loading,
      error,
      success,
      searchText,
      activateUser,
      activateUserStatus,
      resetGetUserList,
      resetActivateUser,
    } = this.props;

    const {
      page,
    } = this.state;

    const pagination = {
      total : total,
      rowsPerPage : this.rowsPerPage,
      page : page,
      handleChangePage : this.handleChangePage
    }

    return (
      <Layout
        parentClasses={parentClasses}
        locale={locale}
        loading={loading}
        error={error}
        success={success}
        items={items}
        getUserList={getUserList}
        pagination={pagination}
        user={user}
        dictionary={dictionary}
        searchText={searchText}
        activateUser = {activateUser}
        activateUserStatus = {activateUserStatus}
        resetGetUserList={resetGetUserList}
        resetActivateUser = {resetActivateUser}
        changeOrder = {this.changeOrder}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  loading: state.admin.list.loading,
  error: state.admin.list.error,
  success: state.admin.list.success,
  items: state.admin.list.items,
  total: state.admin.list.total,
  user: state.user.login.user,
  activateUserStatus: state.admin.activate
});

const mapDispatchToProps = {
  getUserList: actions.admin.list.fetchUserList,
  resetGetUserList: actions.admin.list.resetState,
  activateUser : actions.admin.activate.activateUser,
  resetActivateUser : actions.admin.activate.resetState,

};

export default connect(mapStateToProps, mapDispatchToProps)(UsersListContainer);
