import {URL} from '../../config';

function getLocaleList() {
  return dispatch => {
    dispatch(fetchLocaleListBegin());
    return fetchLocaleListStart()
      .then(data => {
        dispatch(fetchLocaleListSuccess(data));
      })
      .catch(() => {
        dispatch(fetchLocaleListFailure())
      });
  };
}

const fetchLocaleListBegin = () => ({
  type: 'FETCH_LOCALE_LIST_BEGIN'
});

function fetchLocaleListStart() {
  return new Promise((resolve, reject) => {
    fetch(URL + `/user/locale/list`)
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const fetchLocaleListSuccess = localeList => ({
  type: 'FETCH_LOCALE_LIST_SUCCESS',
  items: localeList
});

const fetchLocaleListFailure = () => ({
  type: 'FETCH_LOCALE_LIST_FAILURE',
  error: true
});

const resetState = () => ({
  type: 'FETCH_LOCALE_LIST_RESET'
});

const index = {
  getLocaleList,
  resetState
}

export default index;
