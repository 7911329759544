import React from "react";
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import QuestionTemplate from "./Template";
import {translations} from "../../../../dictionary/translations";
import QuestionTemplateContainer from "../../../../containers/questionnaire/form/question/Template";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import {style} from "../../../../styles/style.js";
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import AnswerTemplate from '../AnswerTemplate';
import AnswerTemplateContainer from '../../../../containers/questionnaire/form/AnswerTemplate';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { green, red } from '@material-ui/core/colors';
import Popover from "@material-ui/core/Popover";


const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {main: style.rose_dark},
  }
});

const styles = theme => ({
  label: {
    paddingTop: 12,
    paddingBottom: 12,
    color: `${style.violet} !important`,
  },
  popover: {
    pointerEvents: 'none',
    maxWidth: '60vw'
  },
});

const MySwitch = withStyles({
  switchBase: {
    color: red[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

class MultiChoiceQuestion extends React.Component {

  componentDidMount(){
    const {getAnswers, question, modeAdmin, getQuestion1069, formId, answerLastPen, setAnswers, persistAnswersOnTimeout} = this.props;
    const {valueList} = this.state;
    if (modeAdmin===true) getAnswers(question.id);
    else{
      if (question.questionId === 1075) {
          getQuestion1069(formId);
      }
      if (question.answers[0] && valueList.length===0){
        const valueList = question.answers.map(({answerListId}) => answerListId);
        this.setState({ valueList : valueList});
      }
      else if (answerLastPen[0].answerListId !== null){
        const valueList = answerLastPen.map(({answerListId}) => answerListId);
        this.setState({ valueList : valueList});
        const answers = question.answers;
        const newAnswers = JSON.parse(JSON.stringify(answers));
        valueList.forEach(el => {
          newAnswers.push({answerListId: el});
        })
        setAnswers(newAnswers);
        persistAnswersOnTimeout();
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {getAnswers, question, answersAdmin, dataQuestion1069, answerLastPen, setAnswers, persistAnswersOnTimeout} = this.props;
    const {valueList} = this.state;
    if (this.state.add === false && prevState.add === true) {
      getAnswers(question.id)
    }
    else if (this.state.edit === false && prevState.edit === true) {
      getAnswers(question.id)
    }
    if (answersAdmin !== prevProps.answersAdmin){
      let act = [];
          if (answersAdmin){
            if (answersAdmin.length!==0){
                  answersAdmin.map((item, key) => (
                  act.push(item.isActive)
                ))
            }
          }
          this.setState({activation : act});
      }
    if (dataQuestion1069 !== prevProps.dataQuestion1069 && dataQuestion1069!==null){
      let arr = new Array(5);
      // breeding-to-finishing-farm
      if (dataQuestion1069.length === 5){
          arr = dataQuestion1069.map(({answers}) => {
          return answers.length != 0 ? parseFloat(answers[0].answer) ? true : false : false
        })
      }
      // breeding farm
      else if (dataQuestion1069.length === 3){
          arr[0] = dataQuestion1069[0].answers.length != 0 ? parseFloat(dataQuestion1069[0].answers[0].answer) ? true : false : false;
          arr[1] = dataQuestion1069[1].answers.length != 0 ? parseFloat(dataQuestion1069[1].answers[0].answer) ? true : false : false;
          arr[4] = dataQuestion1069[2].answers.length != 0 ? parseFloat(dataQuestion1069[2].answers[0].answer) ? true : false : false;
      }
      // finishing-farm
      else if (dataQuestion1069.length === 2){
          arr[2] = dataQuestion1069[0].answers.length != 0 ? parseFloat(dataQuestion1069[0].answers[0].answer) ? true : false : false;
          arr[3] = dataQuestion1069[1].answers.length != 0 ? parseFloat(dataQuestion1069[1].answers[0].answer) ? true : false : false;
      }
      this.setState({areAnswersFrom1069: arr});
    }

    if (JSON.stringify(answerLastPen) !== JSON.stringify(prevProps.answerLastPen) && valueList.length===0){
        console.log('peny?');
        const valueList = answerLastPen.map(({answerListId}) => answerListId);
        this.setState({ valueList : valueList});
        const answers = question.answers;
        const newAnswers = JSON.parse(JSON.stringify(answers));
        valueList.forEach(el => {
          newAnswers.push({answerListId: el});
        })
        setAnswers(newAnswers);
        persistAnswersOnTimeout();
    }

  }

  state = {
    activation: [],
    add: false,
    edit: false,
    selectedAnswer: null,
    anchorEl : null,
    value: [],
    valueList: [],
    areAnswersFrom1069: [],
  }

  removeById = (array, id) => {
    const item = array.find(el => {
      return el.answerListId === id ? true : false;
    });
    const index = array.indexOf(item);
    if(index !== -1){
      array.splice(index, 1);
    }
  }

  handleChange = async event => {
    const { question, setAnswers, persistAnswersOnTimeout } = this.props;
    const {valueList} = this.state;
    const newValueList = [...valueList];
    const id = Number.parseInt(event.target.value);
    const answers = question.answers;
    const newAnswers = JSON.parse(JSON.stringify(answers));
    const newAnswersWithoutDate = newAnswers.map((el) => {
      el.date='';
      return el
    });
    if (event.target.checked === true) {
      newAnswersWithoutDate.push({
        answerListId: id,
        date: '',
      });
      newValueList.push(id);
      this.setState({valueList : newValueList})
    } else {
      this.removeById(newAnswersWithoutDate, id);
      const indexValueList = newValueList.indexOf(id);
      if(indexValueList !== -1){
        newValueList.splice(indexValueList, 1);
        this.setState({valueList : newValueList});
      }
    }
    await setAnswers(newAnswersWithoutDate);
    persistAnswersOnTimeout();
  };

  isChecked = id => {
    const {valueList} = this.state;
    const answer = valueList.find(item => {
      if (item === id) {
        return true;
      } else {
        return false;
      }
    })
    if (answer !== undefined) {
      return true
    } else {
      return false;
    }
  }

  choiceList = () => {
    const { question, modeAdmin, answersAdmin} = this.props;
    const { activation, areAnswersFrom1069} = this.state;
    if (modeAdmin!==true){
        return question['answerList'].map((answer, key) => (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={this.isChecked(answer.id)}
                onChange={this.handleChange}
                value={answer.id.toString()}
              />
            }
            key={answer.id}
            label={answer.text}
            disabled={this.isChecked(answer.id) ? false : answer.isActive ? question.questionId===1075 ? areAnswersFrom1069[key] ? false : true : false : true}
          />
        ))
    }
    else{
      if (answersAdmin){
        const answersAdminSorted = answersAdmin.sort(function(a, b) {
          return a.orderKey - b.orderKey;
        });
        return answersAdminSorted.map((answer, key) => (
          <div style={{display: 'flex', alignItems: 'flex-end'}} key={answer.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={null}
                    onChange={null}
                    value={answer.id.toString()}
                  />
                }
                key={answer.id}
                label={answer.text.translations[0].text}
              />
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                          <IconButton aria-label="edit" onClick={() => this.setState({edit: true, selectedAnswer: answer.id})}>
                              <EditOutlinedIcon fontSize='small'/>
                          </IconButton>
                          <FormGroup >
                              <MySwitch size="small"  checked={activation[key] ? activation[key] : false} onChange={() => this.setActive(answer.id, !activation[key], key)} />
                          </FormGroup>
                </div>
            </div>
        ))
      }
      else{
        return(
          null
        )
      }
    }
  }

  setActive = (id, active, key) => {
    const {activateAnswer} = this.props;
    let act = [...this.state.activation];
    act[key] = active;
    activateAnswer(id, active);
    this.setState({activation : act});
  }

  closeEditOrAddWindow = () => {
    this.setState({
      add : false,
      edit: false
    })
  }

  handlePopoverOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  render() {
    const { classes, locale, questionNumber, isSideListOpen, question, answersPersistStatus, isAnswerReadOnly, isQuestionReadOnly, modeAdmin, activateQuestion } = this.props;
    const { add, edit, selectedAnswer, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <QuestionTemplateContainer Layout={QuestionTemplate} modeAdmin={modeAdmin} isSideListOpen={isSideListOpen} activateQuestion={activateQuestion} questionNumber={questionNumber} question={question} answersPersistStatus={answersPersistStatus} isQuestionReadOnly={isQuestionReadOnly} isAnswerReadOnly={isAnswerReadOnly}>
        <MuiThemeProvider theme={theme}>
        <FormControl component="fieldset">
          <FormLabel className={classes.label} filled={false} focused={false}>{translations.CHOOSE_ANSWER[locale.localeName]}:</FormLabel>
          <FormGroup>
            {this.choiceList()}
          </FormGroup>
        </FormControl>
        <Popover
                className={classes.popover}
                open={open}
                onClose={this.handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div style={{margin: 15}}>
                    {translations.ADD_ANSWER[locale.localeName]}
                </div>
        </Popover>
        {modeAdmin === true ? 
                  <Fab size="small" color="secondary" aria-label="add" onClick={() => this.setState({add: true})} onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose}>
                          <AddIcon size='small'/>
                  </Fab>
                :
                  null
            }
            {edit === true ?
                    <AnswerTemplateContainer isMultiple={true} Layout={AnswerTemplate}  questionId={question.id} selectedAnswer={selectedAnswer} mode='edit' locale={locale} close={this.closeEditOrAddWindow} />
            :null
            }
            {add === true ?
                    <AnswerTemplateContainer isMultiple={true} Layout={AnswerTemplate}  questionId={question.id} mode='add' locale={locale} close={this.closeEditOrAddWindow} />
            :null
            }
        </MuiThemeProvider>
      </QuestionTemplateContainer>
    )
  }
}

export default withStyles(styles)(MultiChoiceQuestion);
