import React from 'react';
import {connect} from "react-redux";
import actions from "../../../../actions";

export class QuestionListContainer extends React.Component {

  render() {
    const {
      Layout,
      questionList,
      questionListId,
      prevQuestionList,
      modeAdmin,
      getQuestionsAdmin,
      questionsAdmin,
      question1069hasValue,
      afterQuestionListAdding,
      afterQuestionListEditing,
      answersForLastPen,
      setAnswersForLastPen,
      isSideListOpen
    } = this.props;

    return (
      <Layout
        questionList={questionList}
        questionListId={questionListId}
        prevQuestionList={prevQuestionList}
        modeAdmin={modeAdmin}
        getQuestionsAdmin={getQuestionsAdmin}
        questionsAdmin={questionsAdmin}
        question1069hasValue={question1069hasValue}
        afterQuestionListAdding={afterQuestionListAdding}
        afterQuestionListEditing={afterQuestionListEditing}
        answersForLastPen={answersForLastPen}
        setAnswersForLastPen={setAnswersForLastPen}
        isSideListOpen={isSideListOpen}
      />
    )
  }
}

const mapStateToProps = state => ({
  prevQuestionList: state.questionnaire.form.questionList.prevQuestionList,
  questionList : state.questionnaire.form.questionList.data,
  questionListId : state.questionnaire.form.form.questionListId,
  questionsAdmin : state.questionnaire.form.questionsAdmin.data,
  question1069hasValue : state.questionnaire.form.form.question1069hasValue,
  afterQuestionListAdding : state.questionnaire.form.form.afterQuestionListAdding,
  afterQuestionListEditing : state.questionnaire.form.form.afterQuestionListEditing,
  answersForLastPen : state.questionnaire.form.questionList.answersForLastPen,
});

const mapDispatchToProps = {
  getQuestionsAdmin : actions.questionnaire.form.questionsAdmin.getQuestions,
  setAnswersForLastPen : actions.questionnaire.form.questionList.setAnswersForLastPen,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionListContainer);
