import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class ForgotPasswordContainer extends React.Component {

  render() {
  
    const {
      Layout,
      locale,
      loading,
      error,
      success,
      forgotPassword,
      resetForgotPassword,
      errorType,
      emailValidator
    } = this.props;

    return (
      <Layout
        locale={locale}
        loading={loading}
        error={error}
        success={success}
        forgotPassword={forgotPassword}
        resetForgotPassword={resetForgotPassword}
        errorType={errorType}
        emailValidator={emailValidator}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  loading: state.user.forgotPassword.loading,
  error: state.user.forgotPassword.error,
  success: state.user.forgotPassword.success,
  errorType: state.user.forgotPassword.errorType,
});

const mapDispatchToProps = {
  forgotPassword: actions.user.forgotPassword.forgotPassword,
  resetForgotPassword: actions.user.forgotPassword.resetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);
