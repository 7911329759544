import {URL} from '../../config';

function getQuestionnaireList(localeDicId) {
  return dispatch => {
    dispatch(fetchQuestionnaireDicListBegin());
    return fetchQuestionnaireDicListStart(localeDicId)
      .then(data => {
        dispatch(fetchQuestionnaireDicListSuccess(data));
      })
      .catch(() => {
        dispatch(fetchQuestionnaireDicListFailure())
      });
  };
}

const fetchQuestionnaireDicListBegin = () => ({
  type: 'FETCH_QUESTIONNAIRE_DIC_LIST_BEGIN'
});

function fetchQuestionnaireDicListStart(localeDicId) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/questinnaire/dic/list/` + localeDicId, {
      headers : {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      }
    }).then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const fetchQuestionnaireDicListSuccess = data => ({
  type: 'FETCH_QUESTIONNAIRE_DIC_LIST_SUCCESS',
  items: data
});

const fetchQuestionnaireDicListFailure = () => ({
  type: 'FETCH_QUESTIONNAIRE_DIC_LIST_FAILURE',
  error: true
});

const resetState = () => ({
  type: 'FETCH_QUESTIONNAIRE_DIC_LIST_RESET'
});

const index = {
  getQuestionnaireList,
  resetState
}

export default index;
