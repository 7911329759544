import React from "react";
import Avatar from "@material-ui/core/Avatar";
import {withStyles} from '@material-ui/core/styles';


const styles = {
  avatar: {
    cursor: 'pointer',
    width: 25,
    height: 25,
    opacity: 0.8,
    '&:hover' : {
      opacity: 1,
  },
  },
  languages: {
      backgroundColor: 'rgba(255,255,255,0.5)',
      display: 'flex',
      justifyContent: 'space-around',
      padding: 10,
      marginRight: 0,
      marginLeft: 'auto',
      width: 200,
      borderRadius: 10,
  }
};

class Language extends React.Component {
  

  handleButton = (item) => {
    const {setLocale } = this.props;
    setLocale(item);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.languages}>
      {this.props.dictionary ? this.props.dictionary.localeList.map((item) => (
            <Avatar src={"./images/flags/" + item.localeName + ".jpg"} key={item.localeName} onClick={() => {this.handleButton(item)}} className={classes.avatar} />  
      )) : null }
      </div>
    )
  }

};

export default withStyles(styles)(Language);
