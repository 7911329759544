import {URL} from '../../config';
import {PASSWORD_RESET_BEGIN, PASSWORD_RESET_FAILURE, PASSWORD_RESET_RESET, PASSWORD_RESET_SUCCESS} from "../../reducers/user/resetPassword";

function resetPassword(body, language) {
  return dispatch => {
    dispatch(setBegin());
    return resetPasswordStart(body, language)
      .then(json => {
        dispatch(setSuccess(json.data));
      })
      .catch((errorType) => {
        dispatch(setFailure(errorType))
      });
  };
}

const setBegin = () => ({
  type: PASSWORD_RESET_BEGIN
});

function resetPasswordStart(body, language) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/user/change-password/token`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': language,
      },
      body: body
    })
    .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json)
        } else {
          reject(json.error);
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setSuccess = (userData) => ({
  type: PASSWORD_RESET_SUCCESS,
  userData: userData,
});

const setFailure = (errorType) => ({
  type: PASSWORD_RESET_FAILURE,
  errorType: errorType,
});

const resetState = () => ({
  type: PASSWORD_RESET_RESET
});

const index = {
  resetPassword,
  resetState
}

export default index;
