import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SUCCESS_STATUS} from "../../../util";

export const GET_QUESTIONS_BEGIN = 'GET_QUESTIONS_BEGIN';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAILURE = 'GET_QUESTIONS_FAILURE';
export const GET_QUESTIONS_RESET = 'GET_QUESTIONS_RESET';

export const GET_ANSWERS_BEGIN = 'GET_ANSWERS_BEGIN';
export const GET_ANSWERS_SUCCESS = 'GET_ANSWERS_SUCCESS';
export const GET_ANSWERS_FAILURE = 'GET_ANSWERS_FAILURE';
export const GET_ANSWERS_RESET = 'GET_ANSWERS_RESET';

const initState = {
  getStatus: {
    ...INITIAL_STATUS
  },
  getAnswerStatus: {
    ...INITIAL_STATUS
  },
  data: null
}

export default function appReducer(state = initState, action) {

  switch (action.type) {
    case GET_QUESTIONS_BEGIN: {
      return {
        ...state,
        getStatus: BEGIN_STATUS,
        data: null
      };
    }
    case GET_QUESTIONS_SUCCESS: {
      return {
        ...state,
        getStatus: SUCCESS_STATUS,
        data: action.data
      };
    }
    case GET_QUESTIONS_FAILURE: {
      return {
        ...state,
        getStatus: FAILURE_STATUS,
        data: null
      };
    }
    case GET_QUESTIONS_RESET: {
      return {
        ...state,
      };
    }
    case GET_ANSWERS_BEGIN: {
      return {
        ...state,
        getAnswerStatus: BEGIN_STATUS,
        dataAnswer: null
      };
    }
    case GET_ANSWERS_SUCCESS: {
      return {
        ...state,
        getAnswerStatus: SUCCESS_STATUS,
        dataAnswer: action.dataAnswer
      };
    }
    case GET_ANSWERS_FAILURE: {
      return {
        ...state,
        getAnswerStatus: FAILURE_STATUS,
        dataAnswer: null
      };
    }
    case GET_ANSWERS_RESET: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
}
