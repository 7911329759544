import React from 'react';
import {connect} from "react-redux";
import actions from "../../../../actions";

export class QuestionTemplateContainer extends React.Component {

  render() {

    const {
      Layout,
      children,
      locale,
      questionNumber,
      question,
      answersPersistStatus,
      isQuestionReadOnly,
      isAnswerReadOnly,
      modeAdmin,
      activateQuestion,
      questionListId,
      setQuestionListId,
      formListData,
      isSideListOpen
    } = this.props;

    return (
      <Layout
        children={children}
        locale={locale}
        questionNumber={questionNumber}
        question={question}
        answersPersistStatus={answersPersistStatus}
        isQuestionReadOnly={isQuestionReadOnly}
        isAnswerReadOnly={isAnswerReadOnly}
        modeAdmin={modeAdmin}
        activateQuestion={activateQuestion}
        questionListId={questionListId}
        setQuestionListId={setQuestionListId}
        formListData={formListData}
        isSideListOpen={isSideListOpen}
      />
    )
  }
}

const mapStateToProps = state => ({
  formListData: state.questionnaire.form.list.data,
  locale: state.user.locale.locale,
  questionListId : state.questionnaire.form.form.questionListId,
});

const mapDispatchToProps = {
  setQuestionListId: actions.questionnaire.form.form.setQuestionListId,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTemplateContainer);
