import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class Export extends React.Component {

  render() {

    const {
      Layout,
      parentClasses,
      locale,
      loading,
      error,
      success,
      items,
      getFarmList,
      user,
      getRegionList,
      getCountryList,
      countryList,
      regionList,
      getFarmTypeList,
      farmTypeList,
      formList,
      getFormList
    } = this.props;

    return (
      <Layout
        parentClasses={parentClasses}
        locale={locale}
        loading={loading}
        error={error}
        success={success}
        items={items}
        getFarmList={getFarmList}
        user={user}
        getRegionList={getRegionList}
        getCountryList={getCountryList}
        regionList={regionList}
        countryList={countryList}
        getFarmTypeList={getFarmTypeList}
        farmTypeList={farmTypeList}
        formList={formList}
        getFormList={getFormList}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  loading: state.farm.list.loading,
  error: state.farm.list.error,
  success: state.farm.list.success,
  items: state.farm.list.items,
  user: state.user.login.user,
  countryList: {
    loading: state.dictionary.countryList.loading,
    error: state.dictionary.countryList.error,
    success: state.dictionary.countryList.success,
    items: state.dictionary.countryList.items,
  },
  farmTypeList: {
    loading: state.dictionary.farmTypeList.loading,
    error: state.dictionary.farmTypeList.error,
    success: state.dictionary.farmTypeList.success,
    items: state.dictionary.farmTypeList.items,
  },
  formList: {
    loading: state.questionnaire.form.list.loading,
    error: state.questionnaire.form.list.error,
    success: state.questionnaire.form.list.success,
    data: state.questionnaire.form.list.data,
  },
});

const mapDispatchToProps = {
  getCountryList: actions.dictionary.countryList.getCountryList,
  getFarmList: actions.farm.list.getFarmList,
  getFarmTypeList: actions.dictionary.farmTypeList.getFarmTypeList,
  getFormList : actions.questionnaire.form.list.getFormList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Export);
