import {URL} from '../../../config';
import {
  ACTIVATE_ANSWER_BEGIN,
  ACTIVATE_ANSWER_FAILURE,
  ACTIVATE_ANSWER_SUCCESS

} from "../../../reducers/questionnaire/form/answer";

const OPERATION = {
  ACTIVATE : 'activate',
}


function activateAnswer(id, active) {
  return dispatch => {
    dispatch(setBegin(OPERATION.ACTIVATE));
    return activateAnswerStart(id, active)
      .then((data) => {
        dispatch(setSuccess(OPERATION.ACTIVATE));
      })
      .catch(() => {
        dispatch(setFailure(OPERATION.ACTIVATE))
      });
  };
}

function activateAnswerStart(id, active) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/question/answer/dic/setIsActive?answerDicId=${id}&isActive=${active}`, {
      method: 'PUT',
      headers: {
      "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}



const setBegin = (operation) => {
  switch (operation) {
    case OPERATION.ACTIVATE:
      return {
        type: ACTIVATE_ANSWER_BEGIN,
      }
    default: return null;
  }
};

const setSuccess = (operation) => {
  switch (operation) {
    case OPERATION.ACTIVATE:
      return {
        type: ACTIVATE_ANSWER_SUCCESS,
      }
    default: return null;
  }
};

const setFailure = (operation) => {
  switch (operation) {
    case OPERATION.ACTIVATE:
      return {
        type: ACTIVATE_ANSWER_FAILURE,
      }
    default: return null;
  }
};

const index = {
  activateAnswer,
}

export default index;
