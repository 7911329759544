export const initialState = {
  loading: false,
  error: false,
  success: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'FORGOT_PASSWORD_BEGIN': {
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    }
    case 'FORGOT_PASSWORD_SUCCESS': {
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
    }
    case 'FORGOT_PASSWORD_FAILURE': {
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        errorType: action.errorType,
      };
    }
    case 'FORGOT_PASSWORD_RESET': {
      return initialState;
    }
    default:
      return state;
  }
}
