import React from "react";
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import QuestionTemplate from "./Template";
import {translations} from "../../../../dictionary/translations";
import QuestionTemplateContainer from "../../../../containers/questionnaire/form/question/Template";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import AnswerTemplate from '../AnswerTemplate';
import AnswerTemplateContainer from '../../../../containers/questionnaire/form/AnswerTemplate';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import FormHelperText from '@material-ui/core/FormHelperText';
import { green, red } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import Popover from "@material-ui/core/Popover";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "inline-block",
    marginRight: '1%',
  },
  helper : {
    color: 'red',
    fontSize: 15,
  },
  popover: {
    pointerEvents: 'none',
    maxWidth: '60vw'
  },
});

const MySwitch = withStyles({
  switchBase: {
    color: red[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

class ChoiceQuestion extends React.Component {

  componentDidMount(){
    const {getAnswers, question, modeAdmin, answerLastPen, setAnswers, persistAnswersOnTimeout} = this.props;
    const {valueList} = this.state;
    if (modeAdmin===true) getAnswers(question.id);
    if (modeAdmin!==true){
      if (question.answers[0] && !valueList){
        this.setState({value: question.answers[0].answer ? question.answers[0].answer.replace('.',',') : '', valueList : question.answers[0].answerListId});
      }
      else if (answerLastPen.answerListId){
        this.setState({value: answerLastPen.answer.replace('.',','), valueList: answerLastPen.answerListId});
        setAnswers([{
          answerListId: answerLastPen.answerListId,
          answer: answerLastPen.answer.replace(',','.')
        }]);
        persistAnswersOnTimeout();
      }
    }
  }

  state = {
    activation: [],
    add: false,
    edit: false,
    selectedAnswer: null,
    error: false,
    text: '',
    value: '',
    valueList: '',
    anchorEl : null,
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {getAnswers, question, answersAdmin, answerLastPen, setAnswers, persistAnswersOnTimeout} = this.props;
    const {valueList} = this.state;
    if (this.state.add === false && prevState.add === true) {
      getAnswers(question.id)
    }
    else if (this.state.edit === false && prevState.edit === true) {
      getAnswers(question.id)
    }
    if (answersAdmin !== prevProps.answersAdmin){
      let act = [];
          if (answersAdmin){
            if (answersAdmin.length!==0){
                  answersAdmin.map((item, key) => (
                  act.push(item.isActive)
                ))
            }
          }
          this.setState({activation : act});
    }

    if (answerLastPen.answerListId !== prevProps.answerLastPen.answerListId && !valueList){
      this.setState({value: answerLastPen.answer, valueList: answerLastPen.answerListId});
        setAnswers([{
          answerListId: answerLastPen.answerListId,
          answer: answerLastPen.answer
        }]);
        persistAnswersOnTimeout();
    }
  }

  handleChangeCombo = async (event) => {
    const { setAnswers, question, persistAnswersOnTimeout, locale } = this.props;
    const {valueList} = this.state;
    const newValue = Number.parseInt(event.target.value);
    if (event.target.value){
        const isActive = question['answerList'].filter(answer => answer.id===newValue)[0].isActive;
        if (isActive){
          if (newValue!=valueList){
            this.setState({valueList : newValue, value: '', error: true, text: translations.FIELD_IS_REQUIRED[locale.localeName]});
            /*
            await setAnswers([{
              answer: '',
              answerListId: newValue
            }]);
            persistAnswersOnTimeout();
            */
          }
          else{
            this.setState({valueList : '', value: '', error: false});
            await setAnswers([]);
            persistAnswersOnTimeout();
          }
        }
    }
  }

  handleChangeInput = (event) => {
    const { setAnswers, question, persistAnswersOnTimeout, questions, locale } = this.props;
    const { valueList } = this.state;
    let val = event.target.value;
    const theAnswerList = question.answerList.filter(el => el.id===valueList);
    let min = null;
    let max = null;
    let maxQuestionId = null;
    if (theAnswerList[0]){
       min = theAnswerList[0].min;
       max = theAnswerList[0].max;
       maxQuestionId = theAnswerList[0].maxAnswerQuestionId;
    }
    let maxFromAnotherQuestion = null;
    if (maxQuestionId){
      let maxFromAnotherQuestion_tmp = questions.filter((el) => el.questionId === maxQuestionId)[0].answers[0];
      maxFromAnotherQuestion = maxFromAnotherQuestion_tmp ? maxFromAnotherQuestion_tmp.answer : null;
    }
    const answers = question.answers[0];
    const regex = new RegExp("^(\\d+(\\,)?\\d*)?$");
    if (regex.test(val) && val) {
          this.setState({value: val, error: false, text: ''}, () => {
            if (min!==null && max!==null){
              if (val < min || val > max){
                /*
                setAnswers([{
                  ...answers,
                  date: '',
                  answer: ''
                }]);
                persistAnswersOnTimeout();
                */
                this.setState({ error: true, text: translations.VALUE_BETWEEN[locale.localeName]+`${min} - ${max}`})
              }
              else{
                setAnswers([{
                  ...answers,
                  answerListId: valueList,
                  date: '',
                  answer: val.replace(',','.') 
                }]);
                persistAnswersOnTimeout();
                this.setState({ error: false, text: ''})
              }
            }
            else if (min!==null && maxQuestionId!==null){
              if (val < min || val > parseFloat(maxFromAnotherQuestion)){
                /*
                setAnswers([{
                  ...answers,
                  date: '',
                  answer: ''
                }]);
                persistAnswersOnTimeout();
                */
                if (maxFromAnotherQuestion){
                  this.setState({ error: true, text: translations.VALUE_BETWEEN[locale.localeName]+`${min} - ${maxFromAnotherQuestion}`})
                }
                else{
                  this.setState({ error: true, text: translations.VALUE_GREATER_THEN[locale.localeName]+`${min}`})
                }
              }
              else{
                setAnswers([{
                  ...answers,
                  answerListId: valueList,
                  date: '',
                  answer: val.replace(',','.') 
                }]);
                persistAnswersOnTimeout();
                this.setState({ error: false, text: ''})
              }
            }
            else if (min!==null){
              if (val < min){
                /*
                setAnswers([{
                  ...answers,
                  date: '',
                  answer: ''
                }]);
                persistAnswersOnTimeout();
                */
                this.setState({ error: true, text: translations.VALUE_GREATER_THEN[locale.localeName]+`${min}`})
              }
              else{
                setAnswers([{
                  ...answers,
                  answerListId: valueList,
                  date: '',
                  answer: val.replace(',','.') 
                }]);
                persistAnswersOnTimeout();
                this.setState({ error: false, text: ''})
              }
            }
            else if (max!==null){
              if (val > max){
                /*
                setAnswers([{
                  ...answers,
                  date: '',
                  answer: ''
                }]);
                persistAnswersOnTimeout();
                */
                this.setState({ error: true, text: translations.VALUE_LESS_THEN[locale.localeName]+`${max}`})
              }
              else{
                setAnswers([{
                  ...answers,
                  answerListId: valueList,
                  date: '',
                  answer: val.replace(',','.') 
                }]);
                persistAnswersOnTimeout();
                this.setState({ error: false, text: ''})
              }
            }
            else if (maxQuestionId!==null){
              if (val > parseFloat(maxFromAnotherQuestion) ){
                if (maxFromAnotherQuestion){
                  /*
                    setAnswers([{
                      ...answers,
                      date: '',
                      answer: ''
                    }]);
                    persistAnswersOnTimeout();
                    */
                    this.setState({ error: true, text: translations.VALUE_LESS_THEN[locale.localeName]+`${maxFromAnotherQuestion}`})
                }
                else{
                    setAnswers([{
                      ...answers,
                      answerListId: valueList,
                      date: '',
                      answer: val.replace(',','.') 
                    }]);
                    persistAnswersOnTimeout();
                    this.setState({ error: false, text: ''})
                }
              }
              else{
                setAnswers([{
                  ...answers,
                  date: '',
                  answer: val.replace(',','.'),
                  answerListId: valueList 
                }]);
                persistAnswersOnTimeout();
                this.setState({ error: false, text: ''})
              }
            }
            else{
                setAnswers([{
                  ...answers,
                  date: '',
                  answer: val.replace(',','.'),
                  answerListId: valueList 
                }]);
                persistAnswersOnTimeout();
                this.setState({ error: false, text: ''})
            }
          });
      }
      else if (val){
        //const newVal = this.cutLastLetter(val.toString());
        const regexWithComma = new RegExp("^(\\d+(\\.)?\\d*)?$");
        /*
        setAnswers([{
          ...answers,
          date: '',
          answer: ''
        }]);
        persistAnswersOnTimeout();
        */
        if (regexWithComma.test(val)){
          this.setState({value: val, error: true, text: translations.ONLY_COMMA[locale.localeName]});
        }
        else{
          this.setState({value: val, error: true, text: translations.VALUE_SHOULD_BE_NUMBER[locale.localeName]});
        }
      }
      else{
        //this.setState({value: val, error: true, text: translations.FIELD_IS_REQUIRED[locale.localeName]});
        this.setState({valueList : '', value: '', error: false});
        setAnswers([]);
        persistAnswersOnTimeout();
        /*
        setAnswers([{
          ...answers,
          date: '',
          answer: ''
        }]);
        persistAnswersOnTimeout();
        */
      }
  }

  choiceList = () => {
    const { question, modeAdmin, answersAdmin } = this.props;
    const { activation } = this.state;
    if (modeAdmin!==true){
        return question['answerList'].map(answer => (
          <MenuItem key={answer.id} value={answer.id} disabled={answer.isActive ? false : true} >{answer.text}</MenuItem>
        ))
    }
    else{
      if (answersAdmin){
          const answersAdminSorted = answersAdmin.sort(function(a, b) {
            return a.orderKey - b.orderKey;
          });
          return answersAdminSorted.map((answer, key) => (
            <MenuItem key={answer.id} value={answer.id} style={{paddingRight: 100}}>
                {answer.text.translations[0].text}
                <ListItemSecondaryAction style={{display: 'flex', width: 80, justifyContent: 'space-between', alignItems: 'center'}} >
                      <IconButton aria-label="edit" style={{flexGrow:1}} onClick={() => this.setState({edit: true, selectedAnswer: answer.id})}>
                          <EditOutlinedIcon fontSize='small'/>
                      </IconButton>
                      <FormGroup >
                          <MySwitch size="small" style={{flexGrow:1}} checked={activation[key] ? activation[key] : false} onChange={() => this.setActive(answer.id, !activation[key], key)} />
                      </FormGroup>
                </ListItemSecondaryAction>
            </MenuItem>
          ))
      }
    }
  }

  setActive = (id, active, key) => {
    const {activateAnswer} = this.props;
    let act = [...this.state.activation];
    act[key] = active;
    activateAnswer(id, active);
    this.setState({activation : act});
  }

  closeEditOrAddWindow = () => {
    this.setState({
      add : false,
      edit: false
    })
  }

  isHidden = (style) => {
    /*
    const {question} = this.props;
    const answers = question.answers;
    if (answers ? answers.length === 0 : false) {
      return {...style, visibility: 'hidden'};
    } else {
      return {style};
    }
    */
    const {valueList} = this.state;
    if (!valueList) {
      return {...style, visibility: 'hidden'};
    } else {
      return {style};
    }

  }

  handlePopoverOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  render() {
    const { locale, questionNumber, question, answerLastPen, setAnswers, isSideListOpen, persistAnswersOnTimeout, answersPersistStatus, isAnswerReadOnly, isQuestionReadOnly, modeAdmin, activateQuestion, classes} = this.props;
    const { add, edit, selectedAnswer, error, text, value, anchorEl, valueList } = this.state;
    const open = Boolean(anchorEl);

    return (
      <QuestionTemplateContainer 
          Layout={QuestionTemplate} 
          modeAdmin={modeAdmin} 
          questionNumber={questionNumber} 
          question={question} 
          answersPersistStatus={answersPersistStatus} 
          isQuestionReadOnly={isQuestionReadOnly} 
          isAnswerReadOnly={isAnswerReadOnly}
          activateQuestion={activateQuestion}
          isSideListOpen={isSideListOpen}
        >
        <div style={{display: 'flex', alignItems: 'flex-end'}}>
          <FormControl required style={{minWidth: 200, marginRight: 15}}>
            <InputLabel htmlFor="locale">{translations.CHOOSE_ANSWER[locale.localeName]}:</InputLabel>
            <Select
              value={modeAdmin !== true ? valueList : ''}
              onClick={modeAdmin !== true ? this.handleChangeCombo : null}
            >
              {this.choiceList()}
            </Select>
          </FormControl>
          <FormControl required style={this.isHidden(this, {minWidth: 200})}>
            <Input 
                   name="value"
                   value={modeAdmin !== true ? value : ''}
                   onChange={modeAdmin !== true ? this.handleChangeInput : null}
                   placeholder={translations.WRITE_ANSWER[locale.localeName]}
            />
            {error && <FormHelperText className={classes.helper}>{text}</FormHelperText>}
          </FormControl>
          <Popover
                className={classes.popover}
                open={open}
                onClose={this.handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div style={{margin: 15}}>
                    {translations.ADD_ANSWER[locale.localeName]}
                </div>
            </Popover>
          {modeAdmin === true ? 
                  <Fab size="small" color="secondary" aria-label="add" onClick={() => this.setState({add: true})} onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose}>
                          <AddIcon size='small'/>
                  </Fab>
                :
                  null
            }
            {edit === true ?
                    <AnswerTemplateContainer withInput={true} Layout={AnswerTemplate}  questionId={question.id} selectedAnswer={selectedAnswer} mode='edit' locale={locale} close={this.closeEditOrAddWindow} />
            :null
            }
            {add === true ?
                    <AnswerTemplateContainer withInput={true} Layout={AnswerTemplate}  questionId={question.id} mode='add' locale={locale} close={this.closeEditOrAddWindow} />
            :null
            }
        </div>
      </QuestionTemplateContainer>
    )
  }
}

export default withStyles(styles)(ChoiceQuestion);
