export const SET_MAIN_NAVIGATION_NAV_BAR_VALUE = 'SET_MAIN_NAVIGATION_NAV_BAR_VALUE';

const initialState = {
  mainNavigationNavBarValue: 0
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MAIN_NAVIGATION_NAV_BAR_VALUE: {
      return {
        ...state,
        mainNavigationNavBarValue: action.mainNavigationNavBarValue
      };
    }
    default:
      return state;
  }
}
