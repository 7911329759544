import React from 'react';
import {connect} from "react-redux";
import actions from '../../../actions';

export class UserLoginContainer extends React.Component {

  render() {

    const {
      Layout,
      dictionary,
      locale,
      loading,
      error,
      success,
      user,
      login,
      resetUser,
      setLocale,
      errorType
    } = this.props;

    return (
      <Layout
        dictionary={dictionary}
        locale={locale}
        loading={loading}
        error={error}
        success={success}
        user={user}
        login={login}
        resetUser={resetUser}
        setLocale={setLocale}
        errorType={errorType}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  loading: state.user.login.loading,
  error: state.user.login.error,
  success: state.user.login.success,
  user: state.user.login.user,
  errorType: state.user.login.errorType,
});

const mapDispatchToProps = {
  login: actions.user.login.login,
  resetUser: actions.user.login.resetState,
  setLocale: actions.user.locale.setLocale
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLoginContainer);
