import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class FarmAddContainer extends React.Component {

  state = {
    countryDicId : '',
    regionDicId : '',
    farmTypeId: '',
    email : '',
    description : '',
    isRegionActive : false
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setIsRegionActive = (isRegionActive) => {
    this.setState({ isRegionActive: isRegionActive });
  }

  render() {

    const {
      Layout,
      dictionary,
      locale,
      countryList,
      getCountryList,
      regionList,
      getRegionList,
      farmTypeList,
      getFarmTypeList,
      addFarm,
      addFarmStatus,
      user,
      resetFarmAdding,
      checkFarm,
      emailValidator,
      getUser,
      gottenUser,
      resetStateGetUser,
      getFarmList,
      resetStateGetFarmList,
      farmList,
      assignExpertStatus,
      assignExpert,
      resetStateAssign,
      farmItems
    } = this.props;



    return (
      <Layout
        dictionary={dictionary}
        locale={locale}
        countryList={countryList}
        getCountryList={getCountryList}
        regionList={regionList}
        farmTypeList={farmTypeList}
        getFarmTypeList={getFarmTypeList}
        getRegionList={getRegionList}
        addFarm={addFarm}
        addFarmStatus={addFarmStatus}
        user={user}
        resetFarmAdding={resetFarmAdding}
        checkFarm={checkFarm}
        emailValidator={emailValidator}
        getUser={getUser}
        gottenUser={gottenUser}
        resetStateGetUser={resetStateGetUser}
        getFarmList={getFarmList}
        resetStateGetFarmList={resetStateGetFarmList}
        farmList={farmList}
        assignExpertStatus={assignExpertStatus}
        assignExpert={assignExpert}
        resetStateAssign={resetStateAssign}
        farmItems={farmItems}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  countryList: {
    loading: state.dictionary.countryList.loading,
    error: state.dictionary.countryList.error,
    success: state.dictionary.countryList.success,
    items: state.dictionary.countryList.items,
  },
  regionList: {
    loading: state.dictionary.regionList.loading,
    error: state.dictionary.regionList.error,
    success: state.dictionary.regionList.success,
    items: state.dictionary.regionList.items,
  },
  farmTypeList: {
    loading: state.dictionary.farmTypeList.loading,
    error: state.dictionary.farmTypeList.error,
    success: state.dictionary.farmTypeList.success,
    items: state.dictionary.farmTypeList.items,
  },
  addFarmStatus: {
    loading: state.farm.add.loading,
    error: state.farm.add.error,
    success: state.farm.add.success,
  },
  user: state.user.login.user,
  checkFarm: state.farm.add.checkFarm,
  gottenUser: state.farm.add.gottenUser,
  farmItems: state.farm.list.items,
  farmList: {
    loading: state.farm.add.loadingGetFarmList,
    error: state.farm.add.errorGetFarmList,
    success: state.farm.add.successGetFarmList,
    items: state.farm.add.farmList,
  },
  assignExpertStatus: {
    loading: state.farm.add.loadingAssign,
    error: state.farm.add.errorAssign,
    success: state.farm.add.successAssign,
  },
});

const mapDispatchToProps = {
  getCountryList: actions.dictionary.countryList.getCountryList,
  getRegionList: actions.dictionary.regionList.getRegionList,
  getFarmTypeList: actions.dictionary.farmTypeList.getFarmTypeList,
  addFarm: actions.farm.add.addFarm,
  getUser: actions.farm.add.getUser,
  resetStateGetUser: actions.farm.add.resetStateGetUser,
  resetFarmAdding : actions.farm.add.resetState,
  getFarmList: actions.farm.add.getFarmList,
  resetStateGetFarmList: actions.farm.add.resetStateGetFarmList,
  assignExpert: actions.farm.add.assignExpert,
  resetStateAssign: actions.farm.add.resetStateAssign,
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmAddContainer);
