import React from 'react';
import {Helmet} from 'react-helmet';
import Language from "../../Language";
import {withStyles} from '@material-ui/core/styles';
import CookieConsent from "react-cookie-consent";
import {translations} from '../../../dictionary/translations';
import Button from "@material-ui/core/Button";
import {style} from "../../../styles/style.js";


const styles = {
  changeLanguageImage: {
      marginRight: 20,
      width: 40,
      display: 'inline-block',
  },
  changeLanguageButton: {
      backgroundColor: 'white',
      width: 150,
      left: 'calc(100vw - 170px)',
      outline: 'none',
      borderRadius: 10,
      padding: 10,
      '&:hover' : {
          backgroundColor: 'rgb(240,240,240)',
      },
      '@media only screen and (max-width: 768px)' : {
          padding: 5,
      },
  },
  button : {
    fontSize: 11,
    flexGrow: 1,
    padding: 5,
    color: 'white',
    backgroundColor: style.rose_dark_hover,
    position: 'absolute',
    right: '15px',
    left: 'auto',
    '&:hover' : {
      backgroundColor: style.rose_dark_hover,
    },
  },
};

function showCookie(name) {
  if (document.cookie !== "") {
      const cookies = document.cookie.split(/; */);

      for (let i=0; i<cookies.length; i++) {
          const cookiesPart = cookies[i].split("=");
          const cookieName = cookiesPart[0];
          const cookieVal = cookiesPart[1];
          if (cookieName === decodeURIComponent(name)) {
              return decodeURIComponent(cookieVal);
          }
      }
  }
  return false;
}

export class UserLoginTemplate extends React.Component {

  state={
    showMessage: !showCookie("areYouOkWithCookie"),
    isButtonVisible: true
  };

  componentDidMount(){
    this.setState({isButtonVisible: !showCookie("areYouOkWithCookie")})
  }

  render() {
    const {classes, children, pageTitle, dictionary, setLocale, locale } = this.props;
    const { showMessage, isButtonVisible } = this.state;

    return (
      <div>
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>
            <Language dictionary={dictionary} setOpen={this.setOpen} setLocale={setLocale}/>
            {children}
            <div>
                { isButtonVisible ?
                <Button  style={{bottom: showMessage ? '150px' : '15px'}} variant="outlined" size="medium" className={classes.button} onClick={() => this.setState({showMessage : !showMessage})}>
                            {translations.RODO[locale.localeName]}
                </Button> 
                : null }
                <CookieConsent
                    location="bottom"
                    buttonText={translations.I_UNDERSTAND[locale.localeName]}
                    cookieName="areYouOkWithCookie"
                    style={{background: "#2B373B", height: showMessage ? '150px' : '15px', fontSize: 12, overflowY: showMessage ? 'auto' : 'hidden'}}
                    buttonStyle={{ display: !showCookie("areYouOkWithCookie") ? 'block' : 'none', backgroundColor: 'rgb(212,97,138)', color: "white", fontSize: "13px", marginTop: 20 }}
                    expires={150}
                    onAccept={() => {
                      this.setState({showMessage : false})
                    }}
                    hideOnAccept={false}
                >
                    {showMessage ? translations.THIS_WEBSITE_USES_COOKIES[locale.localeName] : ''}
                </CookieConsent>
            </div>
      </div>
    )
  }
};

export default withStyles(styles)(UserLoginTemplate);
