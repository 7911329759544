import {URL} from '../../config';
import {
  ADD_QUESTIONNAIRE_BEGIN,
  ADD_QUESTIONNAIRE_FAILURE,
  ADD_QUESTIONNAIRE_RESET,
  ADD_QUESTIONNAIRE_SUCCESS
} from "../../reducers/questionnaire/add";

function addQuestionnaire(form) {
  return dispatch => {
    dispatch(addQuestionnaireBegin());
    return addQuestionnaireStart(form)
      .then((data) => {
        dispatch(addQuestionnaireSuccess(data));
      })
      .catch(() => {
        dispatch(addQuestionnaireFailure())
      });
  };
}

const addQuestionnaireBegin = () => ({
  type: ADD_QUESTIONNAIRE_BEGIN
});

function addQuestionnaireStart(body) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/questinnaire/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
      body: body
    }).then(response => response.json())
      .then(json => {
        if (json.id > 0) {
          resolve(json)
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const addQuestionnaireSuccess = (data) => ({
  type: ADD_QUESTIONNAIRE_SUCCESS,
  data: data
});

const addQuestionnaireFailure = () => ({
  type: ADD_QUESTIONNAIRE_FAILURE
});

const resetState = () => ({
  type: ADD_QUESTIONNAIRE_RESET
});

const index = {
  addQuestionnaire,
  resetState
}

export default index;
