import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class UserCheckContainer extends React.Component {

  render() {

    const {
      Layout,
      children,
      user,
      localeList,
      setLocale,
      locale,
    } = this.props;

    return (
      <Layout
        children={children}
        user={user}
        localeList={localeList}
        setLocale={setLocale}
        locale={locale}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  user: state.user.login.user,
  localeList: state.dictionary.localeList.items
});

const mapDispatchToProps = {
  setLocale: actions.user.locale.setLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCheckContainer);
