import React from 'react';
import {connect} from "react-redux";
import actions from "../../../actions";
import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SAVE_STATUS, SUCCESS_STATUS} from "../../../util";
import {URL} from "../../../config";

export class QuestionTemplateContainer extends React.Component {

  state = {
    addStatus : INITIAL_STATUS,
    editStatus : INITIAL_STATUS,
    getStatus : INITIAL_STATUS,
    items: null,
  }

  OPERATION = {
    ADD_QUESTION : 'addQuestion',
    EDIT_QUESTION: 'editQuestion',
    GET_QUESTION: 'getQuestion',
  }

  setBegin = (operation) => {
    switch (operation) {
      case this.OPERATION.ADD_QUESTION:
        this.setState({
          addStatus : BEGIN_STATUS
        })
        break;
      case this.OPERATION.EDIT_QUESTION:
        this.setState({
          editStatus : BEGIN_STATUS
        })
        break;
      case this.OPERATION.GET_QUESTION:
        this.setState({
          getStatus : BEGIN_STATUS
        })
        break;
      default: return null;
    }
  };

  setSuccess = (operation, data) => {
    switch (operation) {
      case this.OPERATION.ADD_QUESTION:
        this.setState({
          addStatus : SUCCESS_STATUS
        })
        break;
      case this.OPERATION.EDIT_QUESTION:
        this.setState({
          editStatus : SUCCESS_STATUS,
        })
        break;
      case this.OPERATION.GET_QUESTION:
        this.setState({
          getStatus : SUCCESS_STATUS,
          items: data
        })
        break;
      default: return null;
    }
  };

  setFailure = (errorType, operation) => {
    switch (operation) {
      case this.OPERATION.ADD_QUESTIONN:
        this.setState({
          addStatus : FAILURE_STATUS,
        })
        break;
      case this.OPERATION.EDIT_QUESTION:
        this.setState({
          editStatus : FAILURE_STATUS,
        })
        break;
      case this.OPERATION.GET_QUESTION:
        this.setState({
          getStatus : FAILURE_STATUS,
        })
        break;
      default: return null;
    }
  };

  

  addQuestion = (body) => {
    this.setBegin(this.OPERATION.ADD_QUESTION);
    this.addQuestionStart(body)
      .then(() => {
        this.setSuccess(this.OPERATION.ADD_QUESTION);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.ADD_QUESTION);
      });
  }

  addQuestionStart = (body) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/question/dic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        },
        body: body
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve()
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  getQuestion = (id) => {
    this.setBegin(this.OPERATION.GET_QUESTION);
    this.getQuestionStart(id)
      .then((data) => {
        this.setSuccess(this.OPERATION.GET_QUESTION, data);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.GET_QUESTION);
      });
  }

  getQuestionStart = (id) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/question/dic/${id}`, {
        method: 'GET',
        headers: {
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(json => {
          if (json) {
            resolve(json)
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  editQuestion = (body) => {
    this.setBegin(this.OPERATION.EDIT_QUESTION);
    this.editQuestionStart(body)
      .then(() => {
        this.setSuccess(this.OPERATION.EDIT_QUESTION);
      })
      .catch((errorType) => {
        this.setFailure(errorType, this.OPERATION.EDIT_QUESTION);
      });
  }

  editQuestionStart = (body) => {
    return new Promise((resolve, reject) => {
      fetch(URL + `/secured/question/dic`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
        },
        body: body
      })
        .then(response => response.json())
        .then(json => {
          if (json.success === true) {
            resolve()
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  render() {
    const { 
      Layout,
      mode,
      locale,
      close,
      questionListId,
      questionId,
      localeList,
      getQuestionList,
      subFormId,
    } = this.props;

    const {
      addStatus,
      getStatus,
      items,
      editStatus
    } = this.state;

    return (
      <Layout
          mode={mode}
          locale={locale}
          close={close}
          questionListId={questionListId}
          addStatus={addStatus}
          addQuestion={this.addQuestion}
          localeList={localeList}
          getQuestion={this.getQuestion}
          getStatus={getStatus}
          items={items}
          editQuestion={this.editQuestion}
          editStatus={editStatus}
          questionId={questionId}
          getQuestionList={getQuestionList}
          subFormId={subFormId}
      />
    )
  }
}

const mapStateToProps = state => ({
  localeList: {
    loading: state.dictionary.localeList.loading,
    error: state.dictionary.localeList.error,
    success: state.dictionary.localeList.success,
    items: state.dictionary.localeList.items,
  },
  subFormId : state.questionnaire.form.form.subFormId,
});

const mapDispatchToProps = {
  getQuestionList : actions.questionnaire.form.questionList.getQuestionList,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTemplateContainer);
