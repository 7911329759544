import dictionary from './dictionary'
import farm from "./farm";
import questionnaire from "./questionnaire";
import user from "./user";
import mainNavigation from "./mainNavigation";
import admin from "./admin";

const index = {
  dictionary,
  farm,
  questionnaire,
  user,
  mainNavigation,
  admin
}

export default index;
