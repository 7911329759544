import {URL} from '../../config';
import {ADD_QUESTIONNAIRE_BEGIN} from "../../reducers/questionnaire/add";
import {
  REMOVE_QUESTIONNAIRE_BEGIN,
  REMOVE_QUESTIONNAIRE_FAILURE,
  REMOVE_QUESTIONNAIRE_RESET,
  REMOVE_QUESTIONNAIRE_SUCCESS
} from "../../reducers/questionnaire/remove";

function removeQuestionnaire(id, userId) {
  return dispatch => {
    dispatch(removeQuestionnaireBegin());
    return removeQuestionnaireStart(id, userId)
      .then(() => {
        dispatch(removeQuestionnaireSuccess());
      })
      .catch(() => {
        dispatch(removeQuestionnaireFailure())
      });
  };
}


function removeQuestionnaireStart(id, userId) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/questinnaire/` + id + '?userId=' + userId, {
      method: 'DELETE',
      headers: {
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
       },
    }).then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json)
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const removeQuestionnaireBegin = () => ({
  type: REMOVE_QUESTIONNAIRE_BEGIN
});

const removeQuestionnaireSuccess = () => ({
  type: REMOVE_QUESTIONNAIRE_SUCCESS
});

const removeQuestionnaireFailure = () => ({
  type: REMOVE_QUESTIONNAIRE_FAILURE
});

const resetState = () => ({
  type: REMOVE_QUESTIONNAIRE_RESET
});

const index = {
  removeQuestionnaire,
  resetState
}

export default index;
