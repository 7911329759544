import React from 'react';
import { CircularProgress } from '@material-ui/core';

const style = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10%'
};

const Loading = () => (
    <div style={style}>
      <CircularProgress  color="secondary" />
    </div>
);

export default Loading;
