import add from './add';
import farm from './farm';
import list from './list';
import edit from "./edit";
import remove from "./remove";

const index = {
  add,
  edit,
  remove,
  farm,
  list
}

export default index;
