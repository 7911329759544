export const initialState = {
  id: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_FARM_ID': {
      return {
        ...state,
        id: action.id,
      };
    }
    case 'RESET_FARM': {
      return initialState;
    }
    default:
      return state;
  }
}
