import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {URL} from '../../config';
import {translations} from "../../dictionary/translations";
import {style} from "../../styles/style.js";
import {withRouter} from "react-router-dom";
import Loading from "../Loading";
import Modal from '@material-ui/core/Modal';
import RadarChart from './charts/RadarChart';
import VerticalChart from './charts/VerticalChart';
import Table from './charts/Table';
import Button from "@material-ui/core/Button";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LogoImage from '../../img/logo.jpg'
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { renderToString } from "react-dom/server";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  radarCharts: {
    width: '100%',
    display: 'flex',
    '@media only screen and (max-width: 768px)' : {
      flexDirection: 'column'
    },
  },
  checkbox: {
    position: 'absolute',
    left: '10px'
  },
  verticalCharts: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  radarChart: {
    marginTop: 30,
    marginBottom: 20,
    width: '50%',
    '@media only screen and (max-width: 768px)' : {
      width: '80%',
    },
  },
  verticalChart: {
    width: '70vw',  
    maxWidth: 800,
    '@media only screen and (max-width: 768px)' : {
      width: '80vw',  
    },

  },
  error:{
    position: 'relative',
    top: 100,
    width: '50vw',
    left: '25vw',
    color: 'red',
    textAlign: 'center',
    fontSize: 25,
  },
  button : {
    width: 'fit-content',
    height: 'fit-content',
    transition: 'background-color ease 0.5s, color ease 0.5s',
    backgroundColor: style.rose_light,
    fontSize: 11,
    padding: 10,
    marginBottom: 20,
    '&:hover' : {
      backgroundColor: style.rose_light_hover,
      color: 'white',
    },
    '@media only screen and (max-width: 768px)' : {
      fontSize: 8,
    },
  },
  buttonPrint:{
    position: 'absolute',
    left: '10px',
    top: '40px',
    transition: 'background-color ease 0.5s, color ease 0.5s',
    fontSize: 11,
    padding: 10,
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: style.rose_light,
    '&:hover' : {
      backgroundColor: style.rose_light_hover,
      color: 'white',
    },
    '@media only screen and (max-width: 768px)' : {
      fontSize: 8,
    },
  },
  title:{
    paddingLeft:15,
    fontSize:20,
    color:'rgba(0, 0, 0, 0.87)',
  },
  table:{
    marginLeft: 'auto',
    marginRight: 'auto',
  }
}));

const Charts = ({formDicId, questionnaireId, locale, choosenFarmType, choosenFormName, choosenFarmCode, selectedFormName}) => {
    const classes = useStyles();

    const [selectedThemeId, selectTheme] = useState(null);
    const [selectedSubthemeId, selectSubtheme] = useState(null);
    const [isTableVisible, setIsTableVisible] = useState(false);

    const [loading, setLoading] = useState(false);
    const [isAverageEnabled, setIsAverageEnabled] = useState(false);
    const [success, setSuccess] = useState(null);
    const [data, setData] = useState([]);
    const [averageData, setAverageData] = useState([]);

    const [selectedForm, setSelectedForm] = useState(null);

    if (selectedForm !== formDicId){
      setSuccess(null);
      setSelectedForm(formDicId);
      selectTheme(null);
      selectSubtheme(null);
    }

    useEffect(() => {
      setIsTableVisible(false);
    }, [formDicId]);

    const getData = async (questionnaireId, formDicId) => {
      const req = await fetch(`${URL}/secured/indicator/${questionnaireId}?formDicId=${formDicId}&localeDicId=${locale.id}`, {
                          method: 'GET',
                          headers: {
                          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
                          }
                        })
                        .then(res => res.json())
                        .catch(error => false )

      if (req){ 
        if (req.success == true){
          setData(req.data);
          setSuccess(true);
        }
        else{
          setSuccess(false); 
        }
      }
      else{
        setSuccess(false); 
      }
      setLoading(false);
    };

    const getAverageData = async (formDicId) => {
      const req = await fetch(`${URL}/secured/indicator/average/${formDicId}?farmTypeId=${choosenFarmType}`, {
                          method: 'GET',
                          headers: {
                          "Authorization" : "Bearer " + sessionStorage.getItem('token'),
                          }
                        })
                        .then(res => res.json())
                        .catch(error => false )

      if (req){ 
        if (req.success == true){
          setAverageData(req.data);
        }
      }
    };

    if (success===null && loading===false && questionnaireId && formDicId){
      const loadData = async() => {
        setLoading(true);
        await getAverageData(formDicId)
        await getData(questionnaireId, formDicId);
      };
      loadData();
    }

    const setChoosenChildrenTheme = (id) => {
        selectSubtheme(null);
        selectTheme(id);
      };

    const setChoosenChildrenSubtheme = (id) => {
        selectSubtheme(id);
    }

    const selectedTheme = selectedThemeId ? data.find(({indicator_dic_id}) => indicator_dic_id===selectedThemeId) : null;
    const themeName = selectedTheme ? selectedTheme.name : null;
    const subthemeData = selectedTheme ? selectedTheme.subIndicators : null;
    const selectedSubtheme = selectedSubthemeId ? subthemeData.find(({indicator_dic_id}) => indicator_dic_id===selectedSubthemeId) : null;
    const subthemeName = selectedSubtheme ?  selectedSubtheme.name : null;
    const indicatorData = selectedSubtheme ?  selectedSubtheme.subIndicators : null;

    const toggleVisibility = () => {
      setIsTableVisible(!isTableVisible);
    }

    const handleChangeCheckobox = (event) => {
      setIsAverageEnabled(event.target.checked);
    };

    const dataAverageTheme = isAverageEnabled && !loading ? data.map(({indicator_dic_id}) => averageData.find((theme) => indicator_dic_id===theme.indicator_dic_id).value).map((value) => value ? value.toFixed(2) : 0) : [];
    const dataAverageThemeSubindicators = isAverageEnabled && selectedThemeId ? averageData.find(({indicator_dic_id}) => selectedThemeId===indicator_dic_id).subIndicators : [];
    const dataAverageSubtheme = isAverageEnabled && selectedThemeId ? subthemeData.map(({indicator_dic_id}) => dataAverageThemeSubindicators.find((subtheme) => indicator_dic_id===subtheme.indicator_dic_id).value).map((value) => value ? value.toFixed(2) : 0) : [];
    const dataAverageSubthemeSubindicators = isAverageEnabled && selectedSubthemeId ? dataAverageThemeSubindicators.find(({indicator_dic_id}) => selectedSubthemeId===indicator_dic_id).subIndicators : [];
    const dataAverageIndicator = isAverageEnabled && selectedSubthemeId && indicatorData.length>0 ? indicatorData.map(({indicator_dic_id}) => dataAverageSubthemeSubindicators.find((indicator) => indicator_dic_id===indicator.indicator_dic_id).value).map((value) => value ? value.toFixed(2) : 0) : [];

    const Prints = () => (
      <div></div>
    );
    function noScroll() {
      window.scrollTo(0, 0);
    }

    console.log('selectedFormName', selectedFormName)
    const printDocument = () => {
      const string = renderToString(<Prints/>);
      const pdf = new jsPDF();
      const width=pdf.internal.pageSize.getWidth();
      const height=pdf.internal.pageSize.getHeight();
      console.log(formDicId);
      window.addEventListener('scroll', noScroll);
      const farmDocument=translations.FARM[locale.localeName];
      const documentName=translations.REPORT[locale.localeName] + ".pdf";
      window.scrollTo(0, 0);
      console.log(locale);
      console.log(choosenFarmType);
      console.log(choosenFormName);
      pdf.html(string, {
        callback: function (pdf) {
          pdf.save(documentName);
        }
      });
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(35);
      pdf.setTextColor(89, 142, 174);
      pdf.text((width/2) - 20, 25, 'SusPigSys', {
        align: 'center',
      });
      pdf.setFont('helvetica', 'normal');
      pdf.setTextColor(249,208,221);
      pdf.text(width/2, 48, selectedFormName, {
        align: 'center',
      });
      pdf.setFontSize(20);
      pdf.setTextColor(152,152,152);
      pdf.text(width/2, 58, farmDocument+': '+ choosenFarmCode, {
        align: 'center',
      });
      pdf.addImage(LogoImage, 'JPEG', 170, 5, 30, 30);
      const input1 = document.getElementById('radarChart1');
      html2canvas(input1)
        .then((canvas) => {
          const imgData1 = canvas.toDataURL('image/png');
          pdf.addImage(imgData1, 'PNG', 0, 63, 208, 104);
        });
      if(selectedThemeId){
        const input2 = document.getElementById('radarChart2');
        html2canvas(input2)
        .then((canvas) => {
          const imgData2 = canvas.toDataURL('image/png');
          pdf.addImage(imgData2, 'PNG', 0, 171, 208, 104);
        });
        if(indicatorData){
          const input3 = document.getElementById('radarChart3');
          html2canvas(input3)
          .then((canvas) => {
            const imgData3 = canvas.toDataURL('image/png');
            pdf.addPage();
            pdf.addImage(LogoImage, 'JPEG', 170, 5, 30, 30);
            pdf.addImage(imgData3, 'PNG', 15, 40, 178, 89);
          });
          if(isTableVisible){
            const input4 = document.getElementById('table1');
            console.log(input4)
            const divheight = input4.clientHeight;
            const divwidth = input4.clientWidth;
            const ratio=divheight/divwidth;
            console.log("Divwidth:", divwidth);
            console.log("Divheight:", divheight);
            input4.style.width="950px";
            var addedValueHeight = 20;
            if(divheight>150){
              addedValueHeight = 25;
            }
            if(divheight>300){
              addedValueHeight = 40;
            }
            if(divheight>450){
              addedValueHeight = 50;
            }
            html2canvas(input4)
            .then((canvas) => {
              const imgData4 = canvas.toDataURL('image/png');
              const heightPdfImage=parseInt(208*ratio+addedValueHeight);
              console.log(heightPdfImage);
              if(divheight<700){
                pdf.addImage(imgData4, 'PNG', -15, 140, 230, heightPdfImage);
              }
              else{
                pdf.addPage();
                pdf.addImage(LogoImage, 'JPEG', 170, 5, 30, 30);
                pdf.addImage(imgData4, 'PNG', -15, 40, 230, heightPdfImage);
              }
              
            });
            input4.style.width=divwidth+"px";
          }
        }
      }
      window.removeEventListener('scroll', noScroll);
    }

    return (
      <>
        <Modal open={loading}><div style={{outline: 'none'}}><Loading/></div></Modal>
        <Paper variant="outlined" square className={classes.root}>
            {success===true ?
              <>
                <section className={classes.radarCharts}>
                <FormControlLabel
                      className={classes.checkbox}
                     control={<Checkbox checked={isAverageEnabled} onChange={handleChangeCheckobox} name="checkedA" />}
                      label={translations.SHOW_AVERAGE[locale.localeName]}
                />
                <Button 
                    className={classes.buttonPrint} 
                    onClick={() => {
                        printDocument();
                      }
                    }
                    >
                      {translations.PRINT_CHART[locale.localeName]}
                  </Button>
                    <div className={classes.radarChart} id="radarChart1">
                      <RadarChart data={data} dataAverage={dataAverageTheme} setChoosenChildren={setChoosenChildrenTheme} title="Themes" /> 
                    </div>
                    <div className={classes.radarChart} id="radarChart2">
                      {selectedThemeId ? <RadarChart data={subthemeData} dataAverage={dataAverageSubtheme} choosenChildren={themeName} setChoosenChildren={setChoosenChildrenSubtheme} title="Subthemes"/> : null } 
                    </div>
                </section>
                <hr style={{margin: 0}}></hr>
                {indicatorData &&
                    <section className={classes.verticalCharts}>
                        <div className={classes.verticalChart} id="radarChart3">
                          <VerticalChart data={indicatorData} dataAverage={dataAverageIndicator} choosenChildren={subthemeName} title="Indicators" formDicId={formDicId}/>
                        </div>
                        <Button
                            className={classes.button}
                            onClick={() => toggleVisibility()}
                          >
                                {isTableVisible ? translations.HIDE_ORIGINAL_VALUE[locale.localeName] : translations.SHOW_ORIGINAL_VALUE[locale.localeName]}
                        </Button>   
                    </section>
                }
                {isTableVisible && indicatorData && 
                  <div className={classes.table} id="table1">
                    <Table data={indicatorData} localeName={locale.localeName} dataAverage={dataAverageIndicator}/>
                  </div>
                  }  
              </>
            :
              success===false ? <div className={classes.error}>{`${translations.ERROR[locale.localeName]}`}</div> : null
            }
        </Paper>
      </>
    );
}

export default withRouter(Charts);