import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {ERROR_MODE} from "../../../util";
import ErrorContainer from "../../../containers/Error";
import Error from "../../../components/Error";
import {translations} from "../../../dictionary/translations";
import Loading from "../../../components/Loading";
import FormBarContainer from "../../../containers/questionnaire/form/navigation/FormBar";
import FormBar from "./navigation/FormBar";
import SubFormBarContainer from "../../../containers/questionnaire/form/navigation/SubFormBar";
import SubFormBar from "../../../components/questionnaire/form/navigation/SubFormBar";
import QuestionGroupBar from "../../../components/questionnaire/form/navigation/QuestionGroupBar";
import QuestionGroupBarContainer from "../../../containers/questionnaire/form/navigation/QuestionGroupBar";
import QuestionListContainer from "../../../containers/questionnaire/form/question/List";
import QuestionList from "../../../components/questionnaire/form/question/List";
import OptionalQuestionListContainer from "../../../containers/questionnaire/form/OptionalQuestionList";
import OptionalQuestionList from "../../../components/questionnaire/form/OptionalQuestionList";
import Header from "../../../components/questionnaire/form/navigation/Header";
import HeaderContainer from '../../../containers/questionnaire/form/navigation/Header';
import {withRouter} from "react-router-dom";
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Modal from '@material-ui/core/Modal';

const styles = {
  subformContent : {
    display: 'flex',
    alignItems: 'stretch',
    marginBottom: 15,
  },
  subform : {
    width: '20%',
  },
  questionListContent : {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
};

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    if (anchor) anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <Zoom in={trigger} style={{position: 'fixed', top: 'auto', bottom: 50, left: 'auto', right: 50, zIndex: 5}}>
      <div onClick={handleClick} role="presentation" >
        {children}
      </div>
    </Zoom>
  );
}




export class QuestionnaireForm extends React.Component {

  state = {
    isModalOpen : true,
    isSideListOpen: false,
  }

  componentDidMount() {
    const {history, getQuestionnaireDicList, locale, questionnaireId, questionnaireDicId, getFormList, setNextFormId, modeAdmin} = this.props;
        if (!Number.isInteger(questionnaireId) && modeAdmin !== true) {
          history.push('/');
        } 
        getQuestionnaireDicList(locale.id);
        setNextFormId(null);
        if (modeAdmin !== true){
          getFormList(locale.id, questionnaireId, modeAdmin);
        }
        else{
          getFormList(locale.id, questionnaireDicId, modeAdmin);
        }
        
    }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      locale,
      formList,
      subFormList,
      questionList,
      subFormId,
      nextSubFormId,
      setNextSubFormId,
      getQuestionList,
      setFormId,
      getSubFormList,
      setSubFormId,
      setQuestionListId,
      setNextQuestionListId,
      setIsQuestionGroupInitial,
      nextQuestionListId,
      questionListId,
      setNextFormId,
      nextFormId,
      fillingUpStatus,
      updateFillingUpStatus,
      modeAdmin,
      setPrevQuestionList, 
      setPrevSubformList, 
      resetSubformList,
      afterSubformEditing,
      isAnswerReadOnly
    } = this.props;

    if (formList.success === true && prevProps.formList.success === false) {
      let initialForm = !modeAdmin ? isAnswerReadOnly ? formList.data[0] : formList.data.find(({status}) => status===false) : formList.data[0];
      if (!initialForm) initialForm = formList.data[0];
      const initFormId = initialForm.id;
      setFormId(initFormId);
      setPrevSubformList(null);
      setPrevQuestionList(null);
      resetSubformList();
      getSubFormList(locale.id, initFormId, modeAdmin);
    }

    if (nextFormId > 0) {
      setFormId(nextFormId);
      getSubFormList(locale.id, nextFormId, modeAdmin);
      setNextFormId(null);
    }

    if (subFormList.success === true && prevProps.subFormList.success === false) {
      const initSubFormId = subFormList.data[0].id;
      if (!afterSubformEditing){
        setSubFormId(initSubFormId);
        getQuestionList(locale.id, initSubFormId, modeAdmin);
      }
    }

    if (nextSubFormId > 0) {
      setSubFormId(nextSubFormId);
      getQuestionList(locale.id, nextSubFormId, modeAdmin);
      setNextSubFormId(null);
    }

    if (questionList.getStatus.success === true && prevProps.questionList.getStatus.success === false) {
      if (modeAdmin !== true) {
        updateFillingUpStatus(fillingUpStatus, questionList, subFormId);
      }
      if (nextQuestionListId > 0) {
        setQuestionListId(nextQuestionListId);
        setNextQuestionListId(null);
      } else {
        if (questionList.data.length === 0 && modeAdmin!==true) {
          setIsQuestionGroupInitial(true);
          return null;
        }
        if (modeAdmin !== true)
            setQuestionListId(questionList.data[0].id);
        else{
            if (questionList.data.length !== 0 ) {
              setQuestionListId(questionList.data[0].id);
            }
        }
      }
    }
  }


  setIsOptionalQuestionListOpen = (isOpen, isCancelling) => {
    const { setIsQuestionGroupInitial, setSubFormId, prevSubFormId, getQuestionList, locale, modeAdmin } = this.props;
    if (isCancelling === true) {
      setSubFormId(prevSubFormId);
      getQuestionList(locale.id, prevSubFormId, modeAdmin);
    }
    setIsQuestionGroupInitial(isOpen);
  }

  setPreviousSubForm = () => {
    const { setIsQuestionGroupInitial, setSubFormId, prevSubFormId, getQuestionList, locale, modeAdmin } = this.props;
    setSubFormId(prevSubFormId);
    getQuestionList(locale.id, prevSubFormId, modeAdmin);
    setIsQuestionGroupInitial(false);
  }

  setPreviousList = (list) =>{
    this.props.setPrevSubformList(list);
  }

  setPreviousQuestionList = (list) =>{
    this.props.setPrevQuestionList(list);
  }
  
  setIsModalOpen = (isOpen) => {
    this.setState({
      isModalOpen: isOpen
    })
  }

  setIsSideListOpen = (isOpen) => {
    this.setState({
      isSideListOpen: isOpen
    })
  }
  

  render() {
    const {classes, locale, questionnaireDicList, formList, subFormList,  questionList, isAnswerReadOnly, isQuestionGroupInitial, prevQuestionList, modeAdmin, questionListId, questionsAdmin } = this.props;
    const {isModalOpen, isSideListOpen} = this.state;

    if (isQuestionGroupInitial === true) {
      if (isAnswerReadOnly === true) {
        return (
          <ErrorContainer Layout={Error}
                          content={translations.QUESTION_GROUP_LIST_IS_EMPTY}
                          callback={() => { this.setPreviousSubForm(); }}/>
        )
      } else {
        return (
          <OptionalQuestionListContainer setPreviousSubForm={this.setPreviousSubForm} Layout={OptionalQuestionList} setIsOpen={this.setIsOptionalQuestionListOpen}/>
        )
      }
    }

    if (questionnaireDicList.error === true || formList.error === true || subFormList.error === true || questionList.getStatus.error === true ) {
      return (
        <ErrorContainer Layout={Error} mode={ERROR_MODE.GO_HOME_PAGE}/>
      )
    }

    return (
    
      <div>
            <Modal open={isModalOpen}><div style={{outline: 'none'}}><Loading/></div></Modal>
            <div>
              <span id="back-to-top-anchor"></span>
              {questionnaireDicList.loading=== true ? <Loading /> : <HeaderContainer isSideListOpen={isSideListOpen} openSideList={this.setIsSideListOpen} Layout={Header} modeAdmin={modeAdmin} locale={locale}/>}
              {formList.loading === true ? <Loading/> : formList.success === true ? 
                  <div>
                    {prevQuestionList ? subFormList.success===false && subFormList.data==null && isModalOpen===false ? this.setIsModalOpen(true) : false : false}
                      <FormBarContainer modeAdmin={modeAdmin} Layout={FormBar} isSideListOpen={isSideListOpen} />
                      { questionList.data === null ? 
                      <div className={classes.subformContent}>
                        {subFormList.success===true && isModalOpen===false ? this.setIsModalOpen(true) : false} 
                          <div className={classes.subform} >
                            <SubFormBarContainer modeAdmin={modeAdmin} Layout={SubFormBar}/> 
                          </div>
                          <div className={classes.questionListContent}>
                            {prevQuestionList ? 
                                <span>
                                    <QuestionGroupBarContainer modeAdmin={modeAdmin} Layout={QuestionGroupBar} isSideListOpen={isSideListOpen}/>
                                    <QuestionListContainer modeAdmin={modeAdmin} Layout={QuestionList} isSideListOpen={isSideListOpen}/>
                                </span>
                            : false }  
                          </div>
                      </div>
                      :
                      modeAdmin !== true ?
                      questionList.data.length != 0  ? 
                        <div className={classes.subformContent}> 
                            {subFormList.success===true && isModalOpen ? this.setIsModalOpen(false) : false}
                            {subFormList.data != null ? subFormList.prevSubformList != subFormList.data ? this.setPreviousList(subFormList.data) : false : false}
                            <div className={classes.subform} >
                                <SubFormBarContainer modeAdmin={modeAdmin} Layout={SubFormBar}/>
                            </div>
                            <div className={classes.questionListContent}>
                                  <QuestionGroupBarContainer modeAdmin={modeAdmin} Layout={QuestionGroupBar} isSideListOpen={isSideListOpen} />
                                  <QuestionListContainer modeAdmin={modeAdmin} Layout={QuestionList} isSideListOpen={isSideListOpen} />                 
                                  {prevQuestionList != questionList.data ? this.setPreviousQuestionList(questionList.data) : false}
                            </div>
                        </div> : null :
                      questionList.data ? 
                      <div className={classes.subformContent}> 
                          {subFormList.success===true && isModalOpen ? this.setIsModalOpen(false) : false}
                          {subFormList.data != null ? subFormList.prevSubformList != subFormList.data ? this.setPreviousList(subFormList.data) : false : false}
                          <div className={classes.subform} >
                              <SubFormBarContainer modeAdmin={modeAdmin} Layout={SubFormBar}/>
                          </div>
                          <div className={classes.questionListContent}> 
                              <span>
                                <QuestionGroupBarContainer modeAdmin={modeAdmin} Layout={QuestionGroupBar} isSideListOpen={isSideListOpen}/>
                                {questionsAdmin===null ? isModalOpen===false ? <Modal open={true}><div style={{outline: 'none'}}><Loading/></div></Modal>  : null : null}
                                {questionList.data.length != 0 ? <QuestionListContainer modeAdmin={modeAdmin} Layout={QuestionList} isSideListOpen={isSideListOpen}/> : null}               
                              </span>
                          </div>
                      </div> : null
                      } 
                  </div>
                : null }

            </div>
            <ScrollTop {...this.props}>
              <Fab color="secondary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
              </Fab>
            </ScrollTop>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(QuestionnaireForm));
