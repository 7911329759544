import React from 'react';
import {connect} from "react-redux";
import actions from '../../../actions';

export class UserLoginTemplateContainer extends React.Component {

  render() {

    const {
      children,
      Layout,
      pageTitle,
      dictionary,
      setLocale,
      locale
    } = this.props;

    return (
      <Layout
        children={children}
        pageTitle={pageTitle}
        dictionary={dictionary}
        setLocale={setLocale}
        locale={locale}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
});

const mapDispatchToProps = {
  setLocale: actions.user.locale.setLocale
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLoginTemplateContainer);
