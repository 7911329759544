import activate from './activate';
import list from './list';


const index = {
  activate,
  list,
}

export default index;
