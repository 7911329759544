const setFarmId = (id) => ({
  type: 'SET_FARM_ID',
  id: id
});

const resetState = () => ({
  type: 'RESET_FARM'
});

const index = {
  setFarmId,
  resetState
}

export default index;
