import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SUCCESS_STATUS} from "../../../util";

export const GET_OPTIONAL_QUESTION_LIST_BEGIN = 'GET_OPTIONAL_QUESTION_LIST_BEGIN';
export const GET_OPTIONAL_QUESTION_LIST_SUCCESS = 'GET_OPTIONAL_QUESTION_LIST_SUCCESS';
export const GET_OPTIONAL_QUESTION_LIST_FAILURE = 'GET_OPTIONAL_QUESTION_LIST_FAILURE';
export const GET_OPTIONAL_QUESTION_LIST_RESET = 'GET_OPTIONAL_QUESTION_LIST_RESET';

export const SAVE_OPTIONAL_QUESTION_LIST_BEGIN = 'SAVE_OPTIONAL_QUESTION_LIST_BEGIN';
export const SAVE_OPTIONAL_QUESTION_LIST_SUCCESS = 'SAVE_OPTIONAL_QUESTION_LIST_SUCCESS';
export const SAVE_OPTIONAL_QUESTION_LIST_FAILURE = 'SAVE_OPTIONAL_QUESTION_LIST_FAILURE';
export const SAVE_OPTIONAL_QUESTION_LIST_RESET = 'SAVE_OPTIONAL_QUESTION_LIST_RESET';

export const initialState = {
  getStatus: INITIAL_STATUS,
  saveStatus: INITIAL_STATUS,
  data : null
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case GET_OPTIONAL_QUESTION_LIST_BEGIN: {
      return {
        ...state,
        getStatus: BEGIN_STATUS,
        data: null
      };
    }
    case GET_OPTIONAL_QUESTION_LIST_SUCCESS: {
      return {
        ...state,
        getStatus: SUCCESS_STATUS,
        data: action.data
      };
    }
    case GET_OPTIONAL_QUESTION_LIST_FAILURE: {
      return {
        ...state,
        getStatus: FAILURE_STATUS,
        data: null
      };
    }
    case GET_OPTIONAL_QUESTION_LIST_RESET: {
      return {
        ...state,
        getStatus: INITIAL_STATUS,
        data: null
      };
    }

    case SAVE_OPTIONAL_QUESTION_LIST_BEGIN: {
      return {
        ...state,
        saveStatus: BEGIN_STATUS
      };
    }
    case SAVE_OPTIONAL_QUESTION_LIST_SUCCESS: {
      return {
        ...state,
        saveStatus: SUCCESS_STATUS
      };
    }
    case SAVE_OPTIONAL_QUESTION_LIST_FAILURE: {
      return {
        ...state,
        saveStatus: FAILURE_STATUS
      };
    }
    case SAVE_OPTIONAL_QUESTION_LIST_RESET: {
      return {
        ...state,
        saveStatus: INITIAL_STATUS
      };
    }

    default:
      return state;
  }
}
