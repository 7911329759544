import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class ChangePasswordContainer extends React.Component {

  render() {

    const {
      Layout,
      locale,
      editPassword,
      editPasswordStatus,
      resetEditPassword,
      userId,
      errorType,
      passwordValidator,
      user
    } = this.props;

    return (
      <Layout
        locale={locale}
        editPassword={editPassword}
        editPasswordStatus={editPasswordStatus}
        resetEditPassword={resetEditPassword}
        userId={userId}
        errorType={errorType}
        passwordValidator={passwordValidator}
        user={user}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  editPasswordStatus: state.user.changePassword,
  userId: state.user.login.user.id,
  user: state.user.login.user,
  errorType: state.user.changePassword.errorType,
});

const mapDispatchToProps = {
  editPassword: actions.user.changePassword.editPassword,
  resetEditPassword : actions.user.changePassword.resetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordContainer);
