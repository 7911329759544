import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SUCCESS_STATUS} from "../../util";

export const initialState = INITIAL_STATUS;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_QUESTIONNAIRE_BEGIN: {
      return {
        ...state,
        ...BEGIN_STATUS
      };
    }
    case REMOVE_QUESTIONNAIRE_SUCCESS: {
      return {
        ...state,
        ...SUCCESS_STATUS
      };
    }
    case REMOVE_QUESTIONNAIRE_FAILURE: {
      return {
        ...state,
        ...FAILURE_STATUS
      };
    }
    case REMOVE_QUESTIONNAIRE_RESET: {
      return initialState;
    }
    default:
      return state;
  }
}

export const REMOVE_QUESTIONNAIRE_BEGIN = 'REMOVE_QUESTIONNAIRE_BEGIN';
export const REMOVE_QUESTIONNAIRE_SUCCESS = 'REMOVE_QUESTIONNAIRE_SUCCESS';
export const REMOVE_QUESTIONNAIRE_FAILURE = 'REMOVE_QUESTIONNAIRE_FAILURE';
export const REMOVE_QUESTIONNAIRE_RESET = 'REMOVE_QUESTIONNAIRE_RESET';
