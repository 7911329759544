import React, { useState } from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {translations} from '../../dictionary/translations';
import {withRouter} from "react-router-dom";
import {ERROR_MODE} from "../../util";
import Loading from "../Loading";
import Error from "../Error";
import ErrorContainer from "../../containers/Error";
import {style} from "../../styles/style.js";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {URL} from '../../config';
import FormLabel from "@material-ui/core/FormLabel";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import Modal from '@material-ui/core/Modal';
import XLS from '../../instruction/Question_list_97_2003.xls';

const styles = {
  paper : {
    position: 'relative',
    marginBottom: 30,
    borderTopRightRadius: 20,
    top: -1
  },
  form : {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  section : {
    marginTop: 20,
    width: '70%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  sectionButton : {
    marginTop: 20,
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  formControl : {
    width: '40%'
  },
  select : {
    '&:before' : {
      borderBottom: `2px solid ${style.rose_light} !important`,
    },
    '&:after' : {
      borderBottom: `2px solid ${style.rose_dark}`,
    }
  },
  submitButton : {
    margin: '0px 10px',
    width: 100,
    transition: 'background-color ease 0.5s',
    outline: 'none',
    color: 'white',
    backgroundColor: style.rose_dark,
    '&:hover' : {
      backgroundColor: style.rose_dark_hover,
    },
  },
  submitButtonInactive : {
    width: 100,
    transition: 'background-color ease 0.5s',
    outline: 'none',
    color: 'white',
    backgroundColor: style.gray,
    '&:hover' : {
      backgroundColor: style.gray,
    },
  },
  helper : {
    color: 'red',
  }
};

export class Export extends React.Component {

  componentDidMount() {
    const {getCountryList, locale, getFarmTypeList, user, getFarmList, getFormList} = this.props;
    getFarmList(0, '', user.id, '');
    getFarmTypeList(locale.id);
    getCountryList(locale.id);
    // fetch form list as an admin (short questionnaire)
    getFormList(locale.id, 1, true);
  }


  state = {
    choosenFarmCode : [],
    choosenFarmTypeId : [],
    choosenCountryId : [],
    choosenRegionId : [],
    regions : [],
    choosenFormType : [],
    choosenDateFrom : null,
    choosenDateTo : null,
    isRegionActive: false,
    dateError: false,
    exportStatus: {
      loading: null,
      success: null,
      isEmpty: null
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {choosenCountryId, regions, choosenRegionId} = this.state;
    if (choosenCountryId !== prevState.choosenCountryId) {
      if (choosenCountryId.length===0) {
        this.setState({choosenRegionId : []})
      }
      else{
          let regions_temp = [];
          for (const el of choosenCountryId){
            new Promise(() => {
              fetch(URL + `/region/list/` + el)
                .then(response => response.json())
                .then(json => {
                  if (json.success === true) {
                    for (const item of json.data){
                        regions_temp.push(item);
                    }
                    this.setState({isRegionActive : true});
                  } else {
                    this.setState({isRegionActive : false})
                  }
                })
                .then(() => {this.setState({regions : regions_temp}, () => {
                                  let choosenRegionId_temp = [...choosenRegionId];
                                  for (const el of choosenRegionId_temp){
                                    if (regions_temp.some(function(item) {
                                      return item.id === el;
                                    }) === false) {choosenRegionId_temp.splice(choosenRegionId_temp.indexOf(el),1)};
                                  };
                                  this.setState({choosenRegionId : choosenRegionId_temp});
                              }); 
                })
                .catch(() => {
                  this.setState({isRegionActive : false})
                })
            });
          }
      }
    }
  }

  getData = async () => {
    const { locale } = this.props;
    const { choosenFarmCode, choosenFarmTypeId, choosenCountryId, choosenRegionId, choosenDateFrom, choosenDateTo, choosenFormType} = this.state;
    this.setState({exportStatus : {
      loading: true,
      success: null
    }});
    const req = await fetch(`${URL}/secured/synchronization/export?localeDicId=${locale.id}${choosenFarmCode.length !==0 ? `&farmId=${choosenFarmCode.join(",")}` : ''}${choosenFarmTypeId.length !==0 ? `&farmTypeId=${choosenFarmTypeId.join(",")}` : ''}${choosenCountryId.length !==0 ? `&countryId=${choosenCountryId.join(",")}` : ''}${choosenRegionId.length !==0 ? `&regionId=${choosenRegionId.join(",")}` : ''}${choosenFormType.length !==0 ? `&formId=${choosenFormType.join(",")}` : ''}${choosenDateFrom ? `&dateFrom=${choosenDateFrom}` : ''}${choosenDateTo ? `&dateTo=${choosenDateTo}` : ''}`, 
                        {
                        method: 'GET',
                        headers: {
                        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
                        }
                      })
                      .then(res => res.blob())
                      .catch(error => 'error' )
    
      if (req!=='error'){ 
          
          if (req.size !== 0){
              const url = window.URL.createObjectURL(new Blob([req]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `suspigsys_export.csv`);
              // 3. Append to html page
              document.body.appendChild(link);
              // 4. Force download
              link.click();
              // 5. Clean up and remove the link
              link.parentNode.removeChild(link);
              this.setState({exportStatus : {
                loading: false,
                success: true
              }});
          }
          else{
              this.setState({exportStatus : {
                loading: false,
                success: true,
                isEmpty: true,
              }})
          }
        
      }
      else{
    
          this.setState({exportStatus : {
            loading: false,
            success: false
          }})
      }
  }
  


  exportData = (event) => {
    const { choosenFarmCode, choosenFarmTypeId, choosenCountryId, choosenRegionId, choosenDateFrom, choosenDateTo, dateError, choosenFormType} = this.state;
    event.preventDefault();
    if (!dateError){
        console.log('choosenFarmCode', choosenFarmCode);
        console.log('choosenFarmTypeId', choosenFarmTypeId);
        console.log('choosenCountryId', choosenCountryId);
        console.log('choosenRegionId', choosenRegionId);
        console.log('choosenDateFrom', choosenDateFrom);
        console.log('choosenDateTo', choosenDateTo);
        console.log('choosenFormType', choosenFormType);

        this.getData();

    }
  }

  handleChange = event => {
    const {items, farmTypeList, countryList, formList} = this.props;
    const {choosenFarmCode, choosenFarmTypeId, choosenCountryId, choosenRegionId, regions, choosenFormType} = this.state;
    this.setState({exportStatus : {
      loading: null,
      success: null,
      isEmpty: null,
    }})
    if (!event.target.value.includes(-1)){
      this.setState({[event.target.name] : event.target.value});
    }
    else{
      switch(event.target.name){
          case 'choosenFarmCode':
              if (choosenFarmCode.length === items.length){
                  this.setState({choosenFarmCode : []});
              }
              else{
                  let arr = items.map((item) => {return item.farmCode});
                  this.setState({choosenFarmCode : arr});
              }
              break;
          case 'choosenFarmTypeId':
                if (choosenFarmTypeId.length === farmTypeList.items.length){
                    this.setState({choosenFarmTypeId : []});
                }
                else{
                    let arr = farmTypeList.items.map((item) => {return item.id});
                    this.setState({choosenFarmTypeId : arr});
                }
                break;
          case 'choosenCountryId':
                if (choosenCountryId.length === countryList.items.length){
                    this.setState({choosenCountryId : []});
                }
                else{
                    let arr = countryList.items.map((item) => {return item.id});
                    this.setState({choosenCountryId : arr});
                }
                break;
          case 'choosenRegionId':
                if (choosenRegionId.length === regions.length){
                    this.setState({choosenRegionId : []});
                }
                else{
                    let arr = regions.map((item) => {return item.id});
                    this.setState({choosenRegionId : arr});
                }
                break;
          case 'choosenFormType':
                if (choosenFormType.length === formList.data.length){
                    this.setState({choosenFormType : []});
                }
                else{
                    const arr = formList.data.map((item) => item.id);
                    this.setState({choosenFormType : arr});
                }
                break;
      }
    }
  };

  handleChangeDate = (event, type) => {
    const {choosenDateTo, choosenDateFrom, dateError} = this.state;
    this.setState({exportStatus : {
      loading: null,
      success: null,
      isEmpty: null,
    }})
    if (event){
      if (event.getFullYear() && event.getMonth().toString() && event.getDate()){
        let month = (event.getMonth() + 1).toString().length < 2 ? '0'+(event.getMonth() + 1).toString() : (event.getMonth() + 1);
        let selectedDate = `${event.getFullYear()}-${month}-${event.getDate()}`;
        console.log(selectedDate)
        if (type === 'from'){
          if (choosenDateTo !== null){
              if (selectedDate < choosenDateTo){
                this.setState({choosenDateFrom : selectedDate, dateError:false});
              }
              else{
                this.setState({choosenDateFrom : selectedDate, dateError:true});
              }
          }
          else{
            this.setState({choosenDateFrom : selectedDate, dateError:false});
          }
        }
        else if (type === 'to'){
          if (choosenDateFrom !== null){
            if (selectedDate > choosenDateFrom){
              this.setState({choosenDateTo : selectedDate, dateError:false});
            }
            else{
              this.setState({choosenDateTo : selectedDate, dateError:true});
            }
          }
          else{
            this.setState({choosenDateTo : selectedDate, dateError:false});
          }
        }
      }
    }
    else{
      if (type === 'from'){
        this.setState({choosenDateFrom : null, dateError:false});
      }
      else if (type === 'to'){
        this.setState({choosenDateTo : null, dateError:false});
      }
    }
  };

  showFarms = (items) => {
      const itemsSorted = items.sort((a, b) => {
        return a.farmCode.localeCompare( b.farmCode );
      })
      return (itemsSorted.map(item => (
        <MenuItem key={item.id} value={item.farmCode}>{item.farmCode}</MenuItem>
      )))
  }

  showFormTypes = () => {
    const {formList} = this.props;
    return (formList.data.map(item => (
      <MenuItem key={item.id} value={item.id}>{item.formText}</MenuItem>
    )))
  }

  showFarmTypes = () => {
    const {farmTypeList} = this.props;
    const itemsSorted = farmTypeList.items.sort((a, b) => {
      return a.text.localeCompare( b.text );
    })
    return (itemsSorted.map(item => (
      <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>
    )))
  }

  showCountries = () => {
    const {countryList} = this.props;
    const itemsSorted = countryList.items.sort((a, b) => {
      return a.name.localeCompare( b.name );
    })
    return (itemsSorted.map(item => (
      <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
    )))
  }

  showRegions = () => {
    const {regions} = this.state;
    if (regions.length !== 0){
        const itemsSorted = regions.sort((a, b) => {
          return a.name.localeCompare( b.name );
        })
        return (itemsSorted.map(item => (
          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
        )))
    }
  }

  render() {
    const { classes, locale, loading, error, success, items, countryList, farmTypeList, formList } = this.props;
    const { choosenFarmCode, choosenFarmTypeId, choosenCountryId, choosenRegionId, choosenDateFrom, choosenFormType, choosenDateTo, isRegionActive, exportStatus} = this.state;

    if (error === true || countryList.error === true || farmTypeList.error === true || exportStatus.success === false || formList.error === true) {
      return (
        <ErrorContainer Layout={Error} mode={ERROR_MODE.GO_HOME_PAGE} content={translations.ERROR_WHILE_DOWNLOADING_DATA}/>
      )
    }

    if (loading === true  || countryList.loading === true || farmTypeList.loading === true || formList.loading === true ) {
      return (
        <Loading/>
      )
    }

    const isReady = choosenFarmCode.length !== 0 || (choosenFarmTypeId.length !== 0 && choosenCountryId.length !== 0 && choosenRegionId.length !== 0) ? true : false;

    if (success === true && countryList.success === true && farmTypeList.success === true && formList.success) {
      return (
        <>
            <Modal open={exportStatus.loading === true ? true : false}><div style={{outline: 'none'}}><Loading/></div></Modal>
            <Paper className={classes.paper}>
              <form className={classes.form} >
                <section className={classes.section}>
                    <FormControl margin="normal" disabled={choosenFarmTypeId.length !== 0 || choosenCountryId.length !== 0 ? true : false} className={classes.formControl}>
                      <InputLabel  className={classes.label} htmlFor="locale">{translations.CHOOSE_FARM[locale.localeName]}</InputLabel>
                      <Select
                        multiple
                        className={classes.select}
                        value={choosenFarmCode}
                        onChange={this.handleChange}
                        inputProps={{
                          name: 'choosenFarmCode',
                          id: 'choosenFarmCode',
                        }}
                      >
                        <MenuItem key={-1} value={-1}>{translations.ALL[locale.localeName]}</MenuItem>
                        {this.showFarms(items)}
                      </Select>
                      {choosenFarmCode.length === 0 &&  choosenFarmTypeId.length === 0 && <FormHelperText className={classes.helper}>{translations.CHOOSE_FARM_OR_FARM_TYPE[locale.localeName]}</FormHelperText>}
                    </FormControl>
                    <FormControl margin="normal" disabled={choosenFarmCode.length !== 0 ? true : false} className={classes.formControl}>
                      <InputLabel  className={classes.label} htmlFor="locale">{translations.FARM_TYPE[locale.localeName]}</InputLabel>
                      <Select
                        multiple
                        className={classes.select}
                        value={choosenFarmTypeId}
                        onChange={this.handleChange}
                        inputProps={{
                          name: 'choosenFarmTypeId',
                          id: 'choosenFarmTypeId',
                        }}
                      >
                        <MenuItem key={-1} value={-1}>{translations.ALL[locale.localeName]}</MenuItem>
                        {this.showFarmTypes()}
                      </Select>
                      {choosenFarmCode.length === 0 &&  choosenFarmTypeId.length === 0 && <FormHelperText className={classes.helper}>{translations.CHOOSE_FARM_OR_FARM_TYPE[locale.localeName]}</FormHelperText>}
                    </FormControl>
                </section>
                <section className={classes.section}>
                    <FormControl margin="normal" disabled={choosenFarmCode.length !== 0 ? true : false} className={classes.formControl}>
                      <InputLabel  className={classes.label} htmlFor="locale">{translations.COUNTRY[locale.localeName]}</InputLabel>
                      <Select
                        multiple
                        className={classes.select}
                        value={choosenCountryId}
                        onChange={this.handleChange}
                        inputProps={{
                          name: 'choosenCountryId',
                          id: 'choosenCountryId',
                        }}
                      >
                        <MenuItem key={-1} value={-1}>{translations.ALL[locale.localeName]}</MenuItem>
                        {this.showCountries(items)}
                      </Select>
                      {choosenFarmTypeId.length !== 0 && choosenCountryId.length === 0 && <FormHelperText className={classes.helper}>{translations.CHOOSE_FARM_OR_FARM_TYPE[locale.localeName]}</FormHelperText>}
                    </FormControl>
                    <FormControl margin="normal" disabled={choosenFarmCode.length !== 0  || choosenCountryId.length === 0 || !isRegionActive ? true : false} className={classes.formControl}>
                      <InputLabel  className={classes.label} htmlFor="locale">{translations.REGION[locale.localeName]}</InputLabel>
                      <Select
                        multiple
                        className={classes.select}
                        value={choosenRegionId}
                        onChange={this.handleChange}
                        inputProps={{
                          name: 'choosenRegionId',
                          id: 'choosenRegionId',
                        }}
                      >
                        <MenuItem key={-1} value={-1}>{translations.ALL[locale.localeName]}</MenuItem>
                        {this.showRegions()}
                      </Select>
                      {choosenFarmTypeId.length !== 0 && choosenRegionId.length === 0 && <FormHelperText className={classes.helper}>{translations.CHOOSE_FARM_OR_FARM_TYPE[locale.localeName]}</FormHelperText>}
                    </FormControl>
                </section>
                <section className={classes.section} style={{justifyContent: 'flex-start'}}>
                    <FormControl margin="normal" className={classes.formControl}>
                      <InputLabel  className={classes.label} htmlFor="locale">{translations.CHOOSE_FORM_TYPE[locale.localeName]}</InputLabel>
                      <Select
                        multiple
                        className={classes.select}
                        value={choosenFormType}
                        onChange={this.handleChange}
                        inputProps={{
                          name: 'choosenFormType',
                          id: 'choosenFormType',
                        }}
                      >
                        <MenuItem key={-1} value={-1}>{translations.ALL[locale.localeName]}</MenuItem>
                        {this.showFormTypes()}
                      </Select>
                    </FormControl>
                </section>
                <section className={classes.section}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel>{translations.DATE_FROM[locale.localeName]}:</FormLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              onChange={(event) => this.handleChangeDate(event, 'from')}
                              value={choosenDateFrom}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          {this.state.dateError && <FormHelperText className={classes.helper}>{translations.DATE_ERROR[locale.localeName]}</FormHelperText>}
                      </FormControl>
                      <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel>{translations.DATE_TO[locale.localeName]}:</FormLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              onChange={(event) => this.handleChangeDate(event, 'to')}
                              value={choosenDateTo}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          {this.state.dateError && <FormHelperText className={classes.helper}>{translations.DATE_ERROR[locale.localeName]}</FormHelperText>}
                      </FormControl>
                </section>
                <section className={classes.sectionButton}>
                    <Button
                      disabled = {!isReady}
                      fullWidth
                      size="large"
                      className={isReady ? classes.submitButton : classes.submitButtonInactive }
                      onClick={isReady ? (event) => this.exportData(event) : null }
                    >
                          {translations.EXPORT[locale.localeName]}
                    </Button>
                    {exportStatus.isEmpty === true && <FormHelperText className={classes.helper}>{translations.EXPORT_RESULT_IS_EMPTY[locale.localeName]}</FormHelperText>}
                    <Button
                      fullWidth
                      size="large"
                      className={classes.submitButton}
                    >
                        <a href = {XLS} target = "_blank">{translations.QUESTION_LIST[locale.localeName]}</a>
                    </Button>
                </section>
              </form>
            </Paper>
        </>
      )
    }
    return (null)

  }
}

export default withRouter(withStyles(styles)(Export));
