import countryList from './countryList';
import localeList from './localeList';
import questionnaireList from './questionnaireList';
import regionList from './regionList';
import userRoleList from './userRoleList';
import farmTypeList from "./farmTypeList";

const index = {
  countryList,
  localeList,
  questionnaireList,
  regionList,
  farmTypeList,
  userRoleList
}

export default index;
