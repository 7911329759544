import React from 'react';
import {connect} from "react-redux";
import actions from '../../../../actions';

export class HeaderContainer extends React.Component {

  render() {

    const {
      Layout,
      locale,
      isReadOnly,
      questionnaireDicId,
      questionnaireDicList,
      setNavBarValue,
      resetPreviousQuestionList,
      questionnaireId,
      formListData,
      questionFilledAll,
      modeAdmin,
      openSideList,
      isSideListOpen,
      choosenFarmCode
    } = this.props;

    return (
      <Layout
        locale={locale}
        isReadOnly={isReadOnly}
        questionnaireDicId={questionnaireDicId}
        questionnaireDicList={questionnaireDicList}
        setNavBarValue={setNavBarValue}
        resetPreviousQuestionList={resetPreviousQuestionList}
        questionnaireId={questionnaireId}
        formListData={formListData}
        questionFilledAll={questionFilledAll}
        modeAdmin={modeAdmin}
        openSideList={openSideList}
        isSideListOpen={isSideListOpen}
        choosenFarmCode={choosenFarmCode}
      />
    )
  }
}

const mapStateToProps = state => ({
  formListData: state.questionnaire.form.list.data,
  questionnaireDicId : state.questionnaire.form.form.questionnaireDicId,
  questionnaireId : state.questionnaire.form.form.questionnaireId,
  questionnaireDicList: {
    loading: state.dictionary.questionnaireList.loading,
    error: state.dictionary.questionnaireList.error,
    success: state.dictionary.questionnaireList.success,
    items: state.dictionary.questionnaireList.items,
  },
  isReadOnly : state.questionnaire.form.form.isAnswerReadOnly,
  questionFilledAll : state.questionnaire.form.question.questionFilledAll,
  choosenFarmCode : state.questionnaire.form.form.choosenFarmCode,
});

const mapDispatchToProps = {
  setNavBarValue : actions.mainNavigation.mainNavigation.setMainNavigationNavBarValue,
}



export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
