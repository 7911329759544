import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class FarmListContainer extends React.Component {

  rowsPerPage = 20;

  state = {
    page : 0,
    order: 'asc',
    orderBy: 'farmCode',
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      const {getFarmList, user, searchText} = this.props;
      const {order, orderBy, page} = this.state;
      const {rowsPerPage} = this;
      const start = page * rowsPerPage;
      const limit = rowsPerPage;
      getFarmList(start, limit, user.id, searchText, orderBy, order);
    });
  };

  changeOrder = (order, orderBy) => {
    this.setState({ order, orderBy }, () => {
      const {getFarmList, user, searchText} = this.props;
      const {order, orderBy, page} = this.state;
      const {rowsPerPage} = this;
      const start = page * rowsPerPage;
      const limit = rowsPerPage;
      getFarmList(start, limit, user.id, searchText, orderBy, order);
    });
  }

  render() {

    const {
      Layout,
      parentClasses,
      locale,
      loading,
      error,
      success,
      items,
      total,
      getFarmList,
      setFarmId,
      user,
      searchText,
      removeFarmStatus,
      removeFarm,
      resetRemoveFarm,
      getRegionList,
      getCountryList,
      countryList,
      regionList,
      getFarmTypeList,
      farmTypeList,
      setChoosenFarmType
    } = this.props;

    const {
      rowsPerPage,
      handleChangePage
    } = this;

    const {
      page
    } = this.state;

    const pagination = {
      total : total,
      rowsPerPage : rowsPerPage,
      page : page,
      handleChangePage : handleChangePage
    }

    return (
      <Layout
        parentClasses={parentClasses}
        locale={locale}
        loading={loading}
        error={error}
        success={success}
        items={items}
        getFarmList={getFarmList}
        pagination={pagination}
        setFarmId={setFarmId}
        user={user}
        searchText={searchText}
        removeFarmStatus={removeFarmStatus}
        removeFarm={removeFarm}
        resetRemoveFarm={resetRemoveFarm}
        getRegionList={getRegionList}
        getCountryList={getCountryList}
        regionList={regionList}
        countryList={countryList}
        getFarmTypeList={getFarmTypeList}
        farmTypeList={farmTypeList}
        changeOrder = {this.changeOrder}
        setChoosenFarmType={setChoosenFarmType}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  loading: state.farm.list.loading,
  error: state.farm.list.error,
  success: state.farm.list.success,
  items: state.farm.list.items,
  total: state.farm.list.total,
  user: state.user.login.user,
  removeFarmStatus: state.farm.remove,
  countryList: {
    loading: state.dictionary.countryList.loading,
    error: state.dictionary.countryList.error,
    success: state.dictionary.countryList.success,
    items: state.dictionary.countryList.items,
  },
  regionList: {
    loading: state.dictionary.regionList.loading,
    error: state.dictionary.regionList.error,
    success: state.dictionary.regionList.success,
    items: state.dictionary.regionList.items,
  },
  farmTypeList: {
    loading: state.dictionary.farmTypeList.loading,
    error: state.dictionary.farmTypeList.error,
    success: state.dictionary.farmTypeList.success,
    items: state.dictionary.farmTypeList.items,
  },
});

const mapDispatchToProps = {
  getCountryList: actions.dictionary.countryList.getCountryList,
  getRegionList: actions.dictionary.regionList.getRegionList,
  getFarmList: actions.farm.list.getFarmList,
  setFarmId : actions.farm.farm.setFarmId,
  removeFarm : actions.farm.remove.removeFarm,
  resetRemoveFarm : actions.farm.remove.resetState,
  getFarmTypeList: actions.dictionary.farmTypeList.getFarmTypeList,
  setChoosenFarmType : actions.questionnaire.form.form.setChoosenFarmType, 
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmListContainer);
