import registration from "./registration";
import login from "./login";
import locale from "./locale";
import changePassword from "./changePassword";
import forgotPassword from "./forgotPassword";
import resetPassword from "./resetPassword";
import activation from "./activation";

const index = {
  locale,
  login,
  registration,
  changePassword,
  forgotPassword,
  resetPassword,
  activation
}

export default index;
