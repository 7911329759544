import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import {URL} from '../../../../config';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import {translations} from "../../../../dictionary/translations";
import {style} from "../../../../styles/style.js";
import {withRouter} from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import GroupIcon from '@material-ui/icons/Group';
import Checkbox from '@material-ui/core/Checkbox';
import Loading from "../../../Loading";
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles(theme => ({
  list: {
    width: 350,
  },
  root: {
    width: '100%',
  },
  buttonCreate : {
    margin: 5,
    border: `2px solid ${style.rose_dark}`,
    '&:hover' : {
        backgroundColor: style.rose_dark,
    },
    color: style.rose_dark,
    fontSize: 10,
    backgroundColor: 'white',
    transition: 'background-color ease 0.5s, color ease 0.5s',
    '&:hover' : {
      color: 'white',
      backgroundColor: style.rose_dark,
    },
    '@media only screen and (max-width: 768px)' : {
      fontSize: 8,
    },
  },
  buttonShow : {
    margin: 5,
    border: `2px solid green`,
    '&:hover' : {
        backgroundColor: 'green',
    },
    color: 'green',
    fontSize: 10,
    backgroundColor: 'white',
    transition: 'background-color ease 0.5s, color ease 0.5s',
    '&:hover' : {
      color: 'white',
      backgroundColor: 'green',
    },
    '@media only screen and (max-width: 768px)' : {
      fontSize: 8,
    },
  },
  buttonEdit: {
    border: `2px solid ${style.rose_dark}`,
    '&:hover' : {
      backgroundColor: style.rose_dark,
    },
  },
  listItem : {
    padding: 0,
  },




  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    '@media only screen and (max-width: 768px)' : {
      width: 20,
      marginRight: 5,
    },
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));



const SideList = ({close, questionnaireId, formListData, locale, history, setReports, checkedReports, questionFilledAll}) => {

    const classes = useStyles();

    const [loading, setLoading] = useState([false, false, false, false, false]);
    const [success, setSuccess] = useState([null, null, null, null, null]);
    const [message, setMessage] = useState([null, null, null, null, null]);
    
    async function getData(questionnaireId, id) {
        const req = await fetch(`${URL}/secured/questinnaire/checkRecommendedAnswers/${questionnaireId}?formDicId=${id}`, {
                            method: 'GET',
                            headers: {
                            "Authorization" : "Bearer " + sessionStorage.getItem('token'),
                            }
                          })
                          .then(res => res.json())
                          .catch(error => false )

        if (req){ 
          if (req.data == true){

            const reqGenerate = await fetch(`${URL}/secured/indicator/${questionnaireId}?formDicId=${id}`, {
              method: 'POST',
              headers: {
              "Authorization" : "Bearer " + sessionStorage.getItem('token'),
              }
            })
            .then(res => res.json())

 
            if (reqGenerate.success == true){
              const successTemp = [...success];
              successTemp[id-1] = true;
              setSuccess(successTemp);
              const messageTemp = [...message];
              messageTemp[id-1] = '';
              setMessage(messageTemp)
            }
            else{
              const successTemp = [...success];
              successTemp[id-1] = false;
              setSuccess(successTemp);
              const messageTemp = [...message];
              messageTemp[id-1] = reqGenerate.message;
              setMessage(messageTemp)
            }

            const formsTemp = [...checkedReports];
            formsTemp[id-1] = null;
            setReports(formsTemp);

          }
          else{
            const filledForms = req.data.filledFormList;
            const formsTemp = [...checkedReports];
            formsTemp[id-1] = filledForms;
            setReports(formsTemp);
          }
        }
        else{
          const successTemp = [...success];
          successTemp[id-1] = false;
          setSuccess(successTemp); 
        }

        const loadingTemp = [...loading];
        loadingTemp[id-1] = false;
        setLoading(loadingTemp);
    };

    const showReport = () => {
      history.push('reports/theme');
    }

    const generateReport = (id) => {
      const loadingTemp = [...loading];
      loadingTemp[id-1] = true;
      setLoading(loadingTemp);

      getData(questionnaireId, id);

    }


    return (
        <div
            className={classes.list}
            role="presentation"
        >
            <Button className={classes.button} onClick={() => close()}>
                <AssessmentOutlinedIcon className={classes.icon}/>
                {translations.CLOSE[locale.localeName]}
            </Button>
            
            {formListData ? formListData.map(({formName, formDicId, status}) => (
                <div className={classes.root} key={formDicId}>
                    <ExpansionPanel >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <div className={classes.column}>
                        <Typography className={classes.heading}>{formName}</Typography>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                    <List component="nav" style={{width: '90%'}}>
                      {checkedReports[formDicId-1] && checkedReports[formDicId-1][0]!==false ? checkedReports[formDicId-1].map( el => (
                        <div key={el.id}>
                            <ListItem button key={el.id} className={classes.listItem} >
                              <ListItemIcon>
                                <ListAltIcon />
                              </ListItemIcon>
                              <ListItemText primary={el.formName} />
                            </ListItem>
                            <List component="nav" style={{marginLeft: 40, width: '90%'}}>
                              {el.filledSubForms.map( sub => (
                                  <div key={sub.id}>
                                    <ListItem button key={sub.id} className={classes.listItem}>
                                      <ListItemIcon>
                                        <FormatListBulletedIcon />
                                      </ListItemIcon>
                                      <ListItemText primary={sub.subformName} />
                                    </ListItem>
                                    <List component="nav" style={{marginLeft: 40, width: '90%'}}>
                                    {sub.questionListList.sort((a, b) => a.id - b.id).map( (group, key) => (
                                        <div key={group.id}>
                                            <ListItem button key={group.id} className={classes.listItem}>
                                              <ListItemIcon>
                                                <GroupIcon />
                                              </ListItemIcon>
                                               {/* <ListItemText primary={sub.questionListList.every(el => el.questionListDicId===group.questionListDicId) && sub.questionListList.length !== 1 ?  `${group.questionListName} ${key+1}` : group.questionListName} /> */}
                                               <ListItemText primary={group.questionListName} />
                                            </ListItem>
                                            <List component="nav" style={{marginLeft: 40, width: '90%'}}>
                                              {group.questionList.map( question => (
                                                    <ListItem button key={question.id} className={classes.listItem}>
                                                      <Checkbox
                                                        checked={questionFilledAll.includes(question.id) ? true : false}
                                                        color="secondary"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                      />
                                                      <ListItemText primary={`nr ${question.orderKey}`} />
                                                    </ListItem>
                                              ))}
                                            </List>
                                        </div>
                                      ))}
                                    </List>
                                  </div>
                              ))}
                            </List>
                            <Divider />
                          </div>
                      )) : null}
                    </List>

                    {message[formDicId-1] ? <p style={{color: 'red', wordBreak: 'break-all'}} >{message[formDicId-1]}</p> : null}
                    
                    </ExpansionPanelDetails>
                    <Divider />
                    <ExpansionPanelActions>
                        <Modal open={loading[formDicId-1]}><div style={{outline: 'none'}}><Loading/></div></Modal>
                        {success[formDicId-1]===true || status===true ? <Button className={classes.buttonShow} onClick={() => showReport()} >{translations.SHOW_REPORT[locale.localeName]}</Button> : null}
                        {success[formDicId-1]===false ? <span style={{color: 'red'}}>{translations.ERROR[locale.localeName]}</span> : null }
                        { formDicId !== 1 ? <Button className={classes.buttonCreate} onClick={() => generateReport(formDicId)} >{translations.GENERATE_REPORT[locale.localeName]}</Button> : null }
                    </ExpansionPanelActions>
                    </ExpansionPanel>
                </div>
            )) : null}
        

        </div>
    );
}

export default withRouter(SideList);