import React from 'react';
import PropTypes from 'prop-types';
import {Col, Container, Row,} from 'reactstrap';
import {Helmet} from 'react-helmet';

export default class NothingTemplate extends React.Component {

  static propTypes = {
    pageTitle: PropTypes.string,
    children: PropTypes.element.isRequired,
  }

  render() {
    const { pageTitle, children } = this.props;

    return (
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        <Container fluid>
          <Row>
            <Col sm="12" className="px-sm-5 py-sm-5">
              {children}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
};
