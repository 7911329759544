import React from "react";
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import QuestionTemplate from "./Template";
import {translations} from "../../../../dictionary/translations";
import QuestionTemplateContainer from "../../../../containers/questionnaire/form/question/Template";
import {style} from "../../../../styles/style.js";
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';


const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {main: style.rose_dark},
  }
});

const styles = theme => ({
  radio: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  label: {
    paddingTop: 12,
    paddingBottom: 12,
    color: `${style.fiolet} !important`,
      '@media only screen and (max-width: 600px)':{
          fontSize: style.mobile_font_size,
      }
  }
});

class LickertQuestion extends React.Component {

  componentDidMount(){
    const { modeAdmin, question, answerLastPen, setAnswers, persistAnswersOnTimeout } =  this.props;
    if (modeAdmin!==true){
      if (question.answers[0]){
        this.setState({value: question.answers[0].answer });
      }
      else if (answerLastPen){
        setAnswers([{
          answerListId: null,
          answer: answerLastPen
        }]);
        persistAnswersOnTimeout();
        this.setState({value: answerLastPen });
      }
    }
  }

  componentDidUpdate(prevProps){
    const {answerLastPen, setAnswers, persistAnswersOnTimeout } =  this.props;
    const {value} = this.state;
    if (answerLastPen !== prevProps.answerLastPen && !value){
        setAnswers([{
          answerListId: null,
          answer: answerLastPen
        }]);
        persistAnswersOnTimeout();
        this.setState({value: answerLastPen });
    }
  }

  state = {
    value: ''
  }

  handleChange = async (event) => {
    const { setAnswers, persistAnswersOnBlur } = this.props;
    const{value} = this.state;
    if (event.target.value!==value){
        this.setState({value: event.target.value});
        await setAnswers([{
          answerListId: null,
          answer: event.target.value
        }]);
        persistAnswersOnBlur();
    }
    else{
      this.setState({value: ''});
        await setAnswers([]);
        persistAnswersOnBlur();
    }
  };

  radioButtonList = () => {
    const { classes, locale } = this.props;
    const list = [];
    for (let index=0; index < 5; index++) {
      list.push(<FormControlLabel
        key = {index}
        value={(index + 1).toString()}
        control={
          <Radio
            className={classes.radio}
            color="primary"
          />
        }
        label={index + 1} />);
    }
    return list;
  }

  render() {
    const { classes, locale, questionNumber, isSideListOpen, question, answersPersistStatus, isAnswerReadOnly, isQuestionReadOnly, modeAdmin, activateQuestion } = this.props;
    const {value} = this.state;

    return (
      <QuestionTemplateContainer
           Layout={QuestionTemplate} 
           modeAdmin={modeAdmin} 
           questionNumber={questionNumber} 
           question={question} 
           answersPersistStatus={answersPersistStatus} 
           isQuestionReadOnly={isQuestionReadOnly} 
           isAnswerReadOnly={isAnswerReadOnly}
           activateQuestion={activateQuestion}
           isSideListOpen={isSideListOpen}
        >
        <FormControl component="fieldset">
          <FormLabel className={classes.label} >{translations.CHOOSE_ANSWER[locale.localeName]}:</FormLabel>
          <MuiThemeProvider theme={theme}>
          <RadioGroup
            value={modeAdmin!==true ? value : ''}
            onClick={modeAdmin!==true ? this.handleChange : null}
            aria-label={question.id.toString()}
            name={question.id.toString()}
          >
            {
              this.radioButtonList()
            }
          </RadioGroup>
          </MuiThemeProvider>
        </FormControl>
      </QuestionTemplateContainer>
    )
  }
}

export default withStyles(styles)(LickertQuestion);
