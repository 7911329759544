import React from 'react';
import {withRouter} from "react-router-dom";
import Loading from "../Loading";

export class UserCheck extends React.Component {

  componentDidMount() {
    const { user, localeList, setLocale, locale } = this.props;
    if (user && user.email && user.email.length > 0) {
      if (!Number.isInteger(locale.id)) {
        const userLocale = localeList.find(item => {
          return item.id === user.localeDicId;
        });
        setLocale(userLocale);
      }
    }
  }

  render() {
    const { children, history, user, locale } = this.props;
    if (user && user.email && user.email.length > 0) {
      if (Number.isInteger(locale.id)) {
        return (
          <div>
            {children}
          </div>
        )
      } else {
        return (<Loading/>)
      }
    } else {
      history.push('/');
      return (<div/>);
    }
  }
};

export default withRouter(UserCheck);
