import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {translations} from '../../dictionary/translations';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {withRouter} from "react-router-dom";
import {ERROR_MODE} from "../../util";
import ErrorContainer from "../../containers/Error";
import Error from "../Error";
import Loading from "../Loading";
import {style} from "../../styles/style.js";


const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: 400,
    height: 'auto',
    left: 'calc(50vw - 200px)',
    top: 'calc(50vh - 200px)',
    border: `3px solid ${style.gray}`,
    borderRadius: 20,
    paddingBottom: 20,
    '@media only screen and (max-width: 768px)' : {
          width: '70vw',
          left: '15vw',
    },
  },
  form: {
    width: '90%',
    marginTop: 10,
  },
  label: {
      color: `${style.violet} !important`,
  },
  submitButton : {
      transition: 'background-color ease 0.5s',
      color: 'white',
      outline: 'none',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
  backButton : {
    transition: 'background-color ease 0.5s',
    backgroundColor: style.gray,
    marginRight: '10%',
    color: 'white',
    outline: 'none',
    '&:hover' : {
      backgroundColor: style.gray_hover,
    },

    '@media only screen and (max-width: 600px)':{
      fontSize: style.mobile_font_size,
    }
  },
  select : {
    '&:before' : {
      borderBottom: `2px solid ${style.rose_light} !important`,
    },

    '&:after' : {
      borderBottom: `2px solid ${style.rose_dark}`,
    }
  },
  textField : {
  }
};


export class SelectFromList extends React.Component {

  componentDidMount() {
    const { getFarmList, farmerId} = this.props;
    getFarmList(farmerId);
  }

  showFarmList = () => {
    const {farmList, farmItems} = this.props;
    if (farmList && farmList.items && farmList.items.length > 0 ) {
      return (farmList.items.map(item => (
        <MenuItem disabled={farmItems.some(({id}) => id===item.id) ? true : false}  key={item.id} value={item.id}>{item.farmCode}</MenuItem>
      )))
    } else {
      return (<MenuItem/>)
    }
  }

  state = {
    farmId: '',
  }

  assignExpert = () => {
    const { user, assignExpert} = this.props;
    const { farmId } = this.state;
    assignExpert(farmId, user.id);
  }

  handleAssignError = () => {
    const {resetStateAssign} = this.props;
    resetStateAssign();
  }

  handleAssignSuccess = () => {
    const {resetStateAssign, history} = this.props;
    resetStateAssign();
    history.push('main-navigation');

  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { classes, history, locale, farmList, assignExpertStatus } = this.props;
    const {farmId} = this.state;

    if (farmList.loading === true || assignExpertStatus.loading === true) {
      return (
        <Loading/>
      )
    } else if (farmList.error === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={translations.ERROR_WHILE_DOWNLOADING_DATA}
                        mode={ERROR_MODE.REFRESH}/>
      )
    } else if (assignExpertStatus.error === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={translations.ERROR_WHILE_UPLOADING_DATA}
                        callback={this.handleAssignError}/>
      )
    } else if (assignExpertStatus.success === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={translations.FARM_ASSIGNED_SUCCESSFULLY}
                        callback={this.handleAssignSuccess}/>
      )
    } else if (farmList.success === true) {
      return (
        <Paper className={classes.paper}>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="questionnaire">{translations.CHOOSE_FARM[locale.localeName]}</InputLabel>
              <Select
                className={classes.select}
                value={farmId}
                onChange={this.handleChange}
                inputProps={{
                  name: 'farmId',
                }}
              >
                {this.showFarmList()}
              </Select>
            </FormControl>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20, outline: 'none'}}>
              <Button
                fullWidth
                size="large"
                className={classes.backButton}
                onClick={() => {history.goBack()}}
              >
                {translations.GO_BACK[locale.localeName]}
              </Button>
              <Button
                fullWidth
                size="large"
                className={classes.submitButton}
                onClick={() => this.assignExpert()}
              >
                {translations.SUBMIT[locale.localeName]}
              </Button>
            </div>
              </form>
        </Paper>
      )
    }
    return (<div/>)

  }
}

export default withStyles(styles)(withRouter(SelectFromList));
