import {URL} from '../../config';

function getRegionList(countryDicId) {
  return dispatch => {
    dispatch(fetchRegionListBegin());
    return fetchRegionListStart(countryDicId)
      .then(data => {
        dispatch(fetchRegionListSuccess(data));
      })
      .catch(() => {
        dispatch(fetchRegionListFailure())
      });
  };
}

const fetchRegionListBegin = () => ({
  type: 'FETCH_REGION_LIST_BEGIN'
});

function fetchRegionListStart(countryDicId) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/region/list/` + countryDicId)
      .then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve(json.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const fetchRegionListSuccess = data => ({
  type: 'FETCH_REGION_LIST_SUCCESS',
  items: data
});

const fetchRegionListFailure = () => ({
  type: 'FETCH_REGION_LIST_FAILURE',
  error: true
});

const resetState = () => ({
  type: 'FETCH_REGION_LIST_RESET'
});

const index = {
  getRegionList,
  resetState
}

export default index;
