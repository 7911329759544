import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SUCCESS_STATUS} from "../../util";

export const ACTIVATION_BEGIN = 'ACTIVATION_BEGIN';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAILURE = 'ACTIVATION_FAILURE';
export const ACTIVATION_RESET = 'ACTIVATION_RESET';

const initialState = {
  ...INITIAL_STATUS
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case ACTIVATION_BEGIN: {
      return {
        ...state,
        ...BEGIN_STATUS
      };
    }

    case ACTIVATION_SUCCESS: {
      return {
        ...state,
        ...SUCCESS_STATUS
      };
    }

    case ACTIVATION_FAILURE: {
      return {
        ...state,
        ...FAILURE_STATUS,
        errorType: action.errorType,
      };
    }

    case ACTIVATION_RESET: {
      return initialState;
    }

    default:
      return state;

  }
}
