import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {translations} from '../../../dictionary/translations';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {ERROR_MODE} from "../../../util";
import ErrorContainer from "../../../containers/Error";
import Error from "../../Error";
import {style} from "../../../styles/style.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = {
  dialogTitle: {
    width: 500,
    '@media only screen and (max-width: 768px)' : {
      width: 300,
    },
  },
  form: {
    width: '90%',
    marginTop: 10,
    position: 'relative',
    left: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
      color: `${style.violet} !important`,
  },
  section: {
    marginTop: 10,
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  input : {
      width:'100%',
      color: `${style.violet}`,
      '&:before' : {
        borderBottom: `2px solid ${style.rose_light} !important`,
      },
      '&:after' : {
        borderBottom: `2px solid ${style.rose_dark}`,
      },
  },
  backButton : {
    transition: 'background-color ease 0.5s',
    backgroundColor: style.gray,
    marginRight: '10%',
    color: 'white',
    outline: 'none',
    '&:hover' : {
      backgroundColor: style.gray_hover,
    },
  },
  submitButton : {
      transition: 'background-color ease 0.5s',
      outline: 'none',
      color: 'white',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
  select : {
    '&:before' : {
      borderBottom: `2px solid ${style.rose_light} !important`,
    },

    '&:after' : {
      borderBottom: `2px solid ${style.rose_dark}`,
    }
  },
  helper : {
    color: 'red',
  },
  hidden: {
    width: '100%',
    height: '100%',
    backgroundColor: `${style.light_gray} !important`,
    pointerEvents: 'none !important',
  },
  visible: {
    width: '100%',
    height: '100%',
  }
};


export class QuestionListTemplate extends React.Component {

  componentDidMount() {
    const {localeList, locale, getFarmTypeList, mode, getQuestionList, questionListId, addOrderKey, qlNumberRigid} = this.props;
    this.setState({trans: new Array(localeList.items.length).fill(-1)})
    getFarmTypeList(locale.id);
    if (mode === 'edit') getQuestionList(questionListId);
    if (mode === 'add'){
      if(addOrderKey != 0){
        this.setState({qlNumber : qlNumberRigid===-1 ? 'minus' : qlNumberRigid===0 ? 'zero' : qlNumberRigid});
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {items} = this.props;
    const {trans} = this.state;
    let arr = [...trans];
    if(items !== prevProps.items){
      const  tr = items.text.translations.map((item, key) => {
        arr[key] = item.text;
      })

       this.setState({
        farmTypeId: items.farmTypeId ? items.farmTypeId : -1,
        textId: items.text.id,
        qlNumber : items.qlNumber===-1 ? 'minus' : items.qlNumber===0 ? 'zero' : items.qlNumber,
       })
       if (items.qlNumber!== -1) this.setState({trans: arr});
    }
  }

  showFarmTypeList = () => {
    const {farmTypeList} = this.props;
    if (farmTypeList && farmTypeList.items && farmTypeList.items.length > 0) {
      return (farmTypeList.items.map(farmType => (
        <MenuItem key={farmType.id} value={farmType.id}>{farmType.text}</MenuItem>
      )))
    } else {
      return (<MenuItem/>)
    }
  }

  state = {
    qlNumber: '',
    farmTypeId: '',
    farmTypeRequiredError: false,
    qlNumberRequiredError: false,
    firstClickingError: true,
    trans: [],
    transRequiredError: false,
    textId: null,
  }

  questionListPrepare = (event) => {
    const { subFormId, addQuestionList, mode, editQuestionList, addOrderKey, questionListId} = this.props;
    const { farmTypeId, qlNumber, trans, textId} = this.state;
    if (event) event.preventDefault() ;
    if (!farmTypeId) {
      this.setState({ farmTypeRequiredError: true });
    }
    if (qlNumber==='minus' ? false : qlNumber==='' ? false : !trans.every(el => {return el !== -1})) {
      this.setState({ transRequiredError: true });
    }
    if (!qlNumber) {
      this.setState({ qlNumberRequiredError: true });
    }
    this.setState({ firstClickingError: false });

    if((!this.state.farmTypeRequiredError)&&
       (!(qlNumber==='minus' ? false : qlNumber==='' ? false : !trans.every(el => {return el !== -1})))&&
       (!this.state.qlNumberRequiredError)&&
       (!this.state.firstClickingError) ){
         const  tr = trans.map((item, key) => {
           return {
             localeDicId: key+1,
             text: item
            }
         });
        const body = {
          id: questionListId,
          orderKey : (qlNumber===-1 || qlNumber===0) ? '' : addOrderKey,
          subFormDicId: subFormId,
          qlNumber: qlNumber==='minus' ? -1 : qlNumber==='zero' ? 0 : qlNumber,
          farmTypeId : farmTypeId !== -1 ? farmTypeId : '',
          text: {
            id: qlNumber==='minus' ? '' : textId,
            translations: qlNumber==='minus' ? [] : tr,
          }
        }
        mode==='add' ? addQuestionList(JSON.stringify(body)) : editQuestionList(JSON.stringify(body));
      }
  }

  questionListSuccess = () => {
    const { close, questionListId} = this.props;
    close(false, questionListId);
  }

  handleChangeName = event => {
      const {farmTypeId, qlNumber, trans} = this.state;
      let arr = [...trans];
      arr[event.target.name.substr(4) - 1] = event.target.value;
      this.setState({trans: arr}, () => {
        if (farmTypeId) {
          this.setState({ farmTypeRequiredError: false });
        }
        if (qlNumber) {
          this.setState({ qlNumberRequiredError: false });
        }
        if (qlNumber==='minus' ? true : qlNumber==='' ? true : trans.every(el => {return el !== -1})) {
          this.setState({ transRequiredError: false });
        }
        if (farmTypeId && qlNumber && trans.every(el => {return el !== -1})) this.setState({firstClickingError: false});
      });
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      const {farmTypeId, qlNumber, trans} = this.state;
      if (farmTypeId) {
        this.setState({ farmTypeRequiredError: false });
      }
      if (qlNumber) {
        this.setState({ qlNumberRequiredError: false });
      }
      if (qlNumber==='minus' ? true : qlNumber==='' ? true : trans.every(el => {return el !== -1})) {
        this.setState({ transRequiredError: false });
      }
      if (farmTypeId && qlNumber && qlNumber!=='minus' ? trans.every(el => {return el !== -1}) : true) this.setState({firstClickingError: false});
    });
  };


  render() {
    const { classes, mode, locale, close, farmTypeList, addStatus, localeList, getStatus, editStatus, addOrderKey} = this.props;
    const {farmTypeId, qlNumber, trans} = this.state;
    if (farmTypeList.error === true || localeList.error === true || getStatus.error === true) {
      return (
        <ErrorContainer Layout={Error}
                content={translations.ERROR_WHILE_DOWNLOADING_DATA}
                mode={ERROR_MODE.REFRESH}/>
      )
    }

    else if (addStatus.error === true || editStatus.error === true) {
      return (
        <ErrorContainer Layout={Error}
                content={translations.ERROR_WHILE_UPLOADING_DATA}
                mode={ERROR_MODE.REFRESH}/>
      )
    }

    else if (farmTypeList.loading === true || addStatus.loading===true || localeList.loading===true || getStatus.loading===true || editStatus.loading===true) {
      return (
        //<Loading/>
        null
      )
    }

    else if (addStatus.success === true || editStatus.success === true) {
      return (
        <ErrorContainer Layout={Error}
                        content={mode==='add' ? translations.QUESTION_LIST_ADDED_SUCCESSFULLY : translations.QUESTION_LIST_EDITED_SUCCESSFULLY}
                        callback={this.questionListSuccess}/>
      )
    }
    
    return (
      <Dialog open={true} scroll={'paper'} >
        <DialogTitle id="scroll-dialog-title" className={classes.dialogTitle}  >{mode === 'add' ? translations.ADD_QUESTION_LIST[locale.localeName] : translations.EDIT_QUESTION_LIST[locale.localeName]}</DialogTitle>
        <DialogContent dividers={true} >
              <form className={classes.form}>
                <FormControl margin="normal" required disabled={mode==='add' ? addOrderKey!=0 ? true : false : addOrderKey!=1 ? true : false } fullWidth>
                    <InputLabel className={classes.label} htmlFor="qlNumber">{translations.QL_NUMBER[locale.localeName]}</InputLabel>
                    <Select
                        value={qlNumber}
                        onChange={this.handleChange}
                        className={classes.select}
                        inputProps={{
                          name: 'qlNumber',
                          id: 'qlNumber'
                        }}
                    >
                          <MenuItem key={-1} value={'minus'}>-1</MenuItem>
                          <MenuItem key={0} value={'zero'}>0</MenuItem>
                          <MenuItem key={1} value={1}>1</MenuItem>
                          <MenuItem key={2} value={2}>2</MenuItem>
                    </Select>
                  {this.state.qlNumberRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
                </FormControl>
                {localeList.items.map((item) => (
                  <div key={item.localeName} className={qlNumber==='minus' ? classes.hidden : qlNumber==='' ? classes.hidden : classes.visible}>
                    <section className={classes.section} >
                      <Avatar src={"./images/flags/" + item.localeName + ".jpg"} style={{width: 30, height: 30, marginRight: 20,}} />
                      <FormControl margin="normal" required fullWidth style={{marginTop: 8}}>
                        <InputLabel className={classes.label} htmlFor={`name${item.id}`}>{translations.NAME[locale.localeName]}</InputLabel>
                        <Input className={classes.input} value={trans[item.id-1]!==-1 ? trans[item.id-1] : ''} id={`name${item.id}`} name={`name${item.id}`}  onChange={this.handleChangeName} />
                        {this.state.transRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
                      </FormControl>
                    </section>
                  </div>
                ))}
                <FormControl margin="normal" required fullWidth>
                  <InputLabel className={classes.label} htmlFor="farmType">{translations.FARM_TYPE[locale.localeName]}</InputLabel>
                  <Select
                    value={farmTypeId}
                    onChange={this.handleChange}
                    className={classes.select}
                    inputProps={{
                      name: 'farmTypeId',
                      id: 'farmType'
                    }}
                  >
                    {this.showFarmTypeList()}
                    <MenuItem key={-1} value={-1}>{translations.ALL[locale.localeName]}</MenuItem>
                  </Select>
                  {this.state.farmTypeRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
                </FormControl>
              </form>
          </DialogContent>
          <DialogActions>
                  <Button
                    fullWidth
                    size="large"
                    className={classes.backButton}
                    onClick={() => {close(true)}}
                  >
                      {translations.GO_BACK[locale.localeName]}
                  </Button>
                  <Button
                    fullWidth
                    size="large"
                    className={classes.submitButton}
                    onClick={(event) => {this.questionListPrepare(event)}}
                  >
                    {translations.SUBMIT[locale.localeName]}
                  </Button>
          </DialogActions>
      </Dialog>
    )

  }
}

export default withStyles(styles)(QuestionListTemplate);
