export const generalTexts = [
    {
        en: {
            definition: "Carefully read each sheet. For translation, remove the protection of the sheet (no password). Line break in cell: press Alt + enter. After you have translated it you should protect it again to avoid entering data in wrong cells. Do not merge any cells, even if it might look better!",
        }
    },{
        en: {  
            definition: "After you have translated a column, hide the English column to prevent it from printing (right mouse button on column header (the letter on the top) > hide). You might want to make additional adjustments for printing > check the print preview!",
        }
    },{
        en: {
            definition: "Cells with grey filling = column-row combination not needed (e.g. MMA treatments for weaners)",
        }
    },{
        en: {
            definition: "Cells with light yellow filling or no filling = enter data",
        }
    },{
        en: {
            definition: "Except for Int1, Int3, Int4 and Int5 you can add rows or columns by inserting. Always insert an entire row or entire column, never just insert cells (it will wreck the table structure)!",
        }
    },{
        en: {
            definition: `Where there are answers to choose from, you enter the number in front of the answer. If several answers apply, enter all numbers. Example: Which fruit do you like? 1) apples 2) pearsFamer sais "both" > you enter "1, 2" in the answer cell.For numbers: in many cases you can specify mean values (preferable) or the smallest (min) and largest (max) value. If you only have the mean value, enter it in the [min] fields.`,
        }
    },{
        en: {
            definition: `If there is no answer available choose or enter "NA" (e.g. farmer does not want to or cannot answer, or m^2 of an outdoor run if there is no outdoor run). There is a difference between NA and zero: Weaner losses if there are weaners and none died = 0, weaner losses if there are no weaners on the farm = NA`,
        }
    },{
        en: {
            definition: `All questions refer to the calendar year of 2017. Except for environmental Key Performance Indicators (KPI) they only refer to things related to pig production or production of own pig feed.For farrow-to-finish farms we would like to attribute resources to the piglet production part and the growing-finishing part for separate calculations. E.g. tractor 1 is used 3 days per week in the sow barn, 2 days per week in the finishing barn and 1 day per week for the combustion plant > % used for sows = 50, % used for finishing = 33. If you only know: tractor`,
        }
    },{
        en: {
            definition: `Production records:a) if possible: split information for sows (piglet production), weaners and rearer-finishersb) if (a) not possible: split for [sows + weaners] vs [finishers]c) if a + b not possible: overall values`,
        }
    },{
        en: {
            definition: `You have to make your own list with which farm ID means which farm.If after the interview you notice that something is different than the farmer said: Discuss it with the farmer and change answer depending on discussion. Add a remark.`,
        }
    }
];

export const QandATexts = [
    {
        en: {
            question: "Why do we neet from kg-to kg feed was fed? It complicates; it is better in days of feeding?",
            answer: "Economy need to know, from which weight to which weight a feed was fed.",
        }
    },{
        en: {
            question: "How should we deal with solar panels on the roof of the finishing farm? He does not use the electricity for himself but sells it.",
            answer: "Please register the receipts of the sales of electricity",
        }
    },{
        en: {
            question: "Our pilot farm separated the farm in two farms (one belongs only him and the other partly his wife) to decrease the taxes?",
            answer: "Treat the farm as one farm",
        }
    },{
        en: {
            question: "We should also include the family members working salary from other jobs (teacher, …). What is the percentage of the income from the farm to the total income?",
            answer: "Do not collect income from other jobs",
        }
    },{
        en: {
            question: "The feed amounts fed per pig: the farmer knew how much feed was consumed but did not know how much feed was actually eaten by the animals. Is all the feed given = eaten? Shall we try to estimate the wastage?",
            answer: "We need to assume that the feed that is administered is also eaten",
        }
    },{
        en: {
            question: "The farmer also could not say how many days and how much exactly different types of feeds are fed, only an approximated mean. Therefore we had problems calculating the kg/pig/day values. Shall we use this estimated period of time? Or Leave this empty if the farmer does not know exactly?",
            answer: `Use the approximated mean and try not to leave the cell empty. A best guess of the farmer is better than "no information".`,
        }
    },{
        en: {
            question: "The farm does not weigh the animals at all. Shall we take the Finnish mean values for weights? Or the mean values estimated by the farmer? Or leave table 5 empty?",
            answer: "Take the mean values estimated by the farmer",
        }
    },{
        en: {
            question: "What do we do if the farmer has only data for the financial year (July 2016-June2017)?",
            answer: "Collect data for the entire year July 2016 - June 2017. Data about feed and animal prices will be updated to the calendar year using market prices of reference market places.",
        }
    },{
        en: {
            question: "Why do we assess m^2 solid/slatted etc concrete twice (Int2H2 + directObs)?",
            answer: "Economy needs total m^2 solid or slatted per building (use construction plans). Animal needs to know m^2 per animal and because construction plans and real life sometimes differ, we do additional measurements.",
        }
    },{
        en: {
            question: "Int5: Why do we need mean weight 01.01.2017 AND 31.12.2017 - they will be the same?",
            answer: "For each pig category the average weight at 1.1 and at 31.1 is needed for economic calculation of the pig meat production. In some cases they may be the same, but in others (i.e. growing pigs) they might be different. Most management information systems will contain this information.",
        }
    },{
        en: {
            question: "Tab1: What if family member (future successor) works and receives as reward part of the ownership of the farm? (Or an employee gets free room and board etc.?)",
            answer: "Crucial is the number of working hours of the family workers (or employees reimbursed with room and board etc.). In order to calculate full production costs, we attribute a value to these working hours of family labour based on the salary cost of employees.",
        }
    }
]