import React from "react";
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import QuestionTemplate from "./Template";
import {translations} from "../../../../dictionary/translations";
import QuestionTemplateContainer from "../../../../containers/questionnaire/form/question/Template";
import Input from "@material-ui/core/Input";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import {style} from "../../../../styles/style.js";
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import AnswerTemplate from '../AnswerTemplate';
import AnswerTemplateContainer from '../../../../containers/questionnaire/form/AnswerTemplate';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import FormHelperText from '@material-ui/core/FormHelperText';
import { green, red } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import Popover from "@material-ui/core/Popover";


const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {main: style.rose_dark},
  }
});

const styles = theme => ({
  label: {
    paddingTop: 12,
    paddingBottom: 12,
    color: `${style.fiolet} !important`,
  },
  helper : {
    color: 'red',
    fontSize: 15,
  },
  popover: {
    pointerEvents: 'none',
    maxWidth: '60vw'
  },
});

const MySwitch = withStyles({
  switchBase: {
    color: red[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

class MultiChoicePlusQuestion extends React.Component {

  componentDidMount(){
    const {getAnswers, question, modeAdmin, answerLastPen, setAnswers, persistAnswersOnTimeout} = this.props;
    const {inputValue} = this.state;
    if (modeAdmin===true) getAnswers(question.id);
    if (modeAdmin!==true){
      if (question.answers[0] && inputValue.length===0){
          question.answers.sort((a,b)=> {
            return a.answerListId - b.answerListId;
          })
          const valuesFromAnswers = [];
          const valuesFromAnswersList = [];
          question.answerList.map((el,key) => {
            const founded = question.answers.filter(elem => elem.answerListId===el.id );
            if (founded[0]){
              valuesFromAnswers.push(founded[0].answer ? founded[0].answer.replace('.',',') : founded[0].answer);
              valuesFromAnswersList.push(founded[0].answerListId);
            }
            else{
              valuesFromAnswers.push('');
              valuesFromAnswersList.push(null);
            }
          })
          const valuesOfTexts = Array(valuesFromAnswers.length).fill('');
          const valuesOfErrors = Array(valuesFromAnswers.length).fill(false);
          this.setState({inputValue: valuesFromAnswers, inputValueList: valuesFromAnswersList, errorText: valuesOfTexts, errorStatus: valuesOfErrors});
      }
      else if (answerLastPen[0].answerListId !== null){
        answerLastPen.sort((a,b)=> {
          return a.answerListId - b.answerListId;
        })
        const inputValueListTemp = answerLastPen.map(({answerListId}) => answerListId);
        const inputValueTemp = answerLastPen.map(({answer}) => answer);
        this.setState({ inputValueList : inputValueListTemp, inputValue: inputValueTemp});
        const answers = question.answers;
        const newAnswers = JSON.parse(JSON.stringify(answers));
        inputValueListTemp.forEach((el, key) => {
          newAnswers.push({answerListId: el, answer: inputValueTemp[key]});
        })
        setAnswers(newAnswers);
        persistAnswersOnTimeout();
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {getAnswers, question, answersAdmin, answerLastPen, setAnswers, persistAnswersOnTimeout} = this.props;
    const {inputValue, inputValueList} = this.state;
    if (this.state.add === false && prevState.add === true) {
      getAnswers(question.id)
    }
    else if (this.state.edit === false && prevState.edit === true) {
      getAnswers(question.id)
    }
    if (answersAdmin !== prevProps.answersAdmin){
      let act = [];
          if (answersAdmin){
            if (answersAdmin.length!==0){
                  answersAdmin.map((item, key) => (
                  act.push(item.isActive)
                ))
            }
          }
          this.setState({activation : act});
    }
    if (JSON.stringify(answerLastPen) !== JSON.stringify(prevProps.answerLastPen) && inputValueList.length===0){
        answerLastPen.sort((a,b)=> {
          return a.answerListId - b.answerListId;
        })
        const inputValueListTemp = answerLastPen.map(({answerListId}) => answerListId);
        const inputValueTemp = answerLastPen.map(({answer}) => answer);
        this.setState({ inputValueList : inputValueListTemp, inputValue: inputValueTemp});
        const answers = question.answers;
        const newAnswers = JSON.parse(JSON.stringify(answers));
        inputValueListTemp.forEach((el, key) => {
          newAnswers.push({answerListId: el, answer: inputValueTemp[key]});
        })
        setAnswers(newAnswers);
        persistAnswersOnTimeout();
    }
  }

  state = {
    activation: [],
    add: false,
    edit: false,
    selectedAnswer: null,
    errorStatus: [],
    errorText: [],
    inputValue: [],
    inputValueList: [],
    anchorEl : null,
  }

  removeById = (array, id) => {
    const item = this.findAnswer(array, id);
    const index = array.indexOf(item);
    if(index !== -1){
      array.splice(index, 1);
    }
  }

  handleChangeCheckBox = async (event, key) => {
    const { question, setAnswers, persistAnswersOnTimeout, locale } = this.props;
    const {inputValue, inputValueList, errorStatus, errorText } = this.state;
    const newInputValueList = [...inputValueList];
    const newInputValue = [...inputValue];
    newInputValue[key] = '';
    const id = Number.parseInt(event.target.value);
    this.setState({inputValue: newInputValue});
    question.answers.sort((a,b)=> {
      return a.answerListId - b.answerListId;
    })
    const newAnswers = JSON.parse(JSON.stringify(question.answers));
    const newAnswersWithoutDate = newAnswers.map((el) => {
      el.date='';
      return el
    });
    if (event.target.checked === true) {
      newAnswersWithoutDate.push({
        answerListId: id
      });
      newInputValueList[key] = id;
      const newErrorStatus = [...errorStatus];
      const newErrorText = [...errorText];
      newErrorStatus[key] = true;
      newErrorText[key] = translations.FIELD_IS_REQUIRED[locale.localeName];
      this.setState({ errorStatus: newErrorStatus, errorText: newErrorText, inputValueList : newInputValueList})

    } else {
      this.removeById(newAnswersWithoutDate, id);
      newInputValueList[key] = null;
      this.setState({inputValueList : newInputValueList});
      await setAnswers(newAnswersWithoutDate);
      await persistAnswersOnTimeout();
    }
    /*
    await setAnswers(newAnswersWithoutDate);
    await persistAnswersOnTimeout();
    */
  }

  handleChangeInput = async (event, key) => {
    const { setAnswers, persistAnswersOnTimeout, question, questions, locale } = this.props;
    const {inputValue, errorStatus, errorText, inputValueList} = this.state;
    question.answers.sort((a,b)=> {
      return a.answerListId - b.answerListId;
    })
    const val = event.target.value;
    const answerListId = Number.parseInt(event.currentTarget.attributes.answerlistid.value);
    const theAnswerList = question.answerList.filter(el => el.id===answerListId);
    const min = theAnswerList[0].min;
    const max = theAnswerList[0].max;
    const maxQuestionId = theAnswerList[0].maxAnswerQuestionId;
    let maxFromAnotherQuestion = null;
    if (maxQuestionId){
      let maxFromAnotherQuestion_tmp = questions.filter((el) => el.questionId === maxQuestionId)[0].answers[0];
      maxFromAnotherQuestion = maxFromAnotherQuestion_tmp ? maxFromAnotherQuestion_tmp.answer : null;
    }

    const newAnswers = JSON.parse(JSON.stringify(question.answers));
    const newAnswersWithoutDate = newAnswers.map((el) => {
      el.date='';
      return el
    });

    const newInputValue = [...inputValue];
    const newErrorStatus = [...errorStatus];
    const newErrorText = [...errorText];
    const newInputValueList = [...inputValueList];
    newInputValue[key] = val;
    const regex = new RegExp("^(\\d+(\\,)?\\d*)?$");
    if (regex.test(val) && val) {
          this.setState({inputValue: newInputValue }, async () => {
            const answer = this.findAnswer(newAnswersWithoutDate, answerListId);
            if (answer){
              answer.answer = val.replace(',','.');
            }
            else{
              newAnswersWithoutDate.push({
                date: '',
                answer: val,
                answerListId: inputValueList[key]
              })
            }
            if (min!==null && max!==null){
              if (val < min || val > max){
                /*
                answer.answer = '';
                await setAnswers(newAnswersWithoutDate);
                await persistAnswersOnTimeout();
                */
                newErrorStatus[key] = true;
                newErrorText[key] = translations.VALUE_BETWEEN[locale.localeName]+`${min} - ${max}`;
                this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
              }
              else{
                await setAnswers(newAnswersWithoutDate);
                await persistAnswersOnTimeout();
                newErrorStatus[key] = false;
                newErrorText[key] = '';
                this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
              }
            }
            else if (min!==null && maxQuestionId!==null){
              if (val < min || val > parseFloat(maxFromAnotherQuestion)){
                  /*
                  answer.answer = '';
                  await setAnswers(newAnswersWithoutDate);
                  await persistAnswersOnTimeout();
                  */
                  newErrorStatus[key] = true;
                  if (maxFromAnotherQuestion){
                    newErrorText[key] = translations.VALUE_BETWEEN[locale.localeName]+`${min} - ${maxFromAnotherQuestion}`;
                    this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
                  }
                  else{
                    newErrorText[key] = translations.VALUE_GREATER_THEN[locale.localeName]+`${min}`;
                    this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
                  }
              }
              else{
                await setAnswers(newAnswersWithoutDate);
                await persistAnswersOnTimeout();
                newErrorStatus[key] = false;
                newErrorText[key] = '';
                this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
              }
            }
            else if (min!==null){
              if (val < min){
                /*
                answer.answer = '';
                await setAnswers(newAnswersWithoutDate);
                await persistAnswersOnTimeout();
                */
                newErrorStatus[key] = true;
                newErrorText[key] = translations.VALUE_GREATER_THEN[locale.localeName]+`${min}`;
                this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
              }
              else{
                await setAnswers(newAnswersWithoutDate);
                await persistAnswersOnTimeout();
                newErrorStatus[key] = false;
                newErrorText[key] = '';
                this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
              }
            }
            else if (max!==null){
              if (val > max){
                /*
                answer.answer = '';
                await setAnswers(newAnswersWithoutDate);
                await persistAnswersOnTimeout();
                */
                newErrorStatus[key] = true;
                newErrorText[key] = translations.VALUE_LESS_THEN[locale.localeName]+`${max}`;
                this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
              }
              else{
                await setAnswers(newAnswersWithoutDate);
                await persistAnswersOnTimeout();
                newErrorStatus[key] = false;
                newErrorText[key] = '';
                this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
              }
            }
            else if (maxQuestionId!==null){
              if (val > parseFloat(maxFromAnotherQuestion) ){
                if (maxFromAnotherQuestion){
                  /*
                  answer.answer = '';
                  await setAnswers(newAnswersWithoutDate);
                  await persistAnswersOnTimeout();
                  */
                  newErrorStatus[key] = true;
                  newErrorText[key] = translations.VALUE_LESS_THEN[locale.localeName]+`${maxFromAnotherQuestion}`;
                  this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
                }
                else{
                  await setAnswers(newAnswersWithoutDate);
                  await persistAnswersOnTimeout();
                  newErrorStatus[key] = false;
                  newErrorText[key] = '';
                  this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
                }
              }
              else{
                await setAnswers(newAnswersWithoutDate);
                await persistAnswersOnTimeout();
                newErrorStatus[key] = false;
                newErrorText[key] = '';
                this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
              }
            }
            else{
              await setAnswers(newAnswersWithoutDate);
              await persistAnswersOnTimeout();
              newErrorStatus[key] = false;
              newErrorText[key] = '';
              this.setState({ errorStatus: newErrorStatus, errorText: newErrorText})
            }
          });
      }
      else if (val){
        //const newVal = this.cutLastLetter(val.toString());
        const regexWithComma = new RegExp("^(\\d+(\\.)?\\d*)?$");
        /*
        const answer = this.findAnswer(newAnswersWithoutDate, answerListId);
        answer.answer = '';
        await setAnswers(newAnswersWithoutDate);
        await persistAnswersOnTimeout();
        */
        if (regexWithComma.test(val)){
          newErrorStatus[key] = true;
          newErrorText[key] = translations.ONLY_COMMA[locale.localeName];
          this.setState({inputValue: newInputValue, errorStatus: newErrorStatus, errorText: newErrorText})
        }
        else{
          newErrorStatus[key] = true;
          newErrorText[key] = translations.VALUE_SHOULD_BE_NUMBER[locale.localeName];
          this.setState({inputValue: newInputValue, errorStatus: newErrorStatus, errorText: newErrorText})
        }
      }
      else{
        this.removeById(newAnswersWithoutDate, answerListId);
        await setAnswers(newAnswersWithoutDate);
        await persistAnswersOnTimeout();
        newInputValueList[key] = null;
        newErrorStatus[key] = false;
        newErrorText[key] = '';
        this.setState({inputValue: newInputValue, errorStatus: newErrorStatus, errorText: newErrorText, inputValueList : newInputValueList})
      }
  }

  isChecked = id => {
    /*
    const { question } = this.props;
    const answers = question.answers;
    const answer = this.findAnswer(answers, id);
    return answer !== undefined ? true : false;
    */
    const {inputValueList} = this.state;
    const answer = inputValueList.find(item => {
      if (item === id) {
        return true;
      } else {
        return false;
      }
    })
    if (answer !== undefined) {
      return true
    } else {
      return false;
    }
    
  }

  findValue = id => {
    const {inputValueList, inputValue} = this.state;
    let index = null;
    const answer = inputValueList.find((item, key) => {
      if (item === id) {
        index=key;
        return true;
      } else {
        return false;
      }
    })
    if (answer) {
      return inputValue[index]
    } else {
      return '';
    }

  }

  findAnswer = (answers, id) => {
    if (answers){
        return answers.find(item => {
          return item.answerListId === id ? true : false;
        });
    }
  }

  isHidden = (answers, id) => {
    /*
    const answer = this.findAnswer(answers, id);
    if (answer === undefined) {
      return {visibility: 'hidden'};
    } else {
      return {};
    }
    */
   const {inputValueList} = this.state;
    const answer = inputValueList.find(item => {
      if (item === id) {
        return true;
      } else {
        return false;
      }
    })
    if (!answer) {
      return {visibility: 'hidden'};
    } else {
      return {};
    }
    
  }

  choiceList = () => {
    const { question, locale, modeAdmin, answersAdmin, classes} = this.props;
    const { errorStatus, errorText, inputValueList, activation } = this.state;
    let listOfAnswers = null;
    if (modeAdmin!==true){
      listOfAnswers = question['answerList'];
    }
    else{
      if (answersAdmin) {
        const answersAdminSorted = answersAdmin.sort(function(a, b) {
          return a.orderKey - b.orderKey;
        });
        listOfAnswers = answersAdminSorted;
      }
    }
    if (listOfAnswers){
            return listOfAnswers.map((answer, key) => (
              <div style={modeAdmin !== true ? {display: 'flex', alignItems: 'flex-end'} : {display: 'flex', justifyContent: 'space-between'}} key={answer.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={modeAdmin !== true ? this.isChecked(answer.id) : null}
                      onChange={modeAdmin !== true ? event => this.handleChangeCheckBox(event, key) : null}
                      value={answer.id.toString()}
                    />
                  }
                  key={answer.id}
                  label={modeAdmin !== true ? answer.text : answer.text.translations[0].text}
                  disabled={modeAdmin!==true ? this.isChecked(answer.id) ? false : answer.isActive ? false : true : false}
                />
                <FormControl required style={modeAdmin !== true ? this.isHidden(question.answers, answer.id) : {}}>
                  <Input
                    
                    name="value"
                    value={modeAdmin!==true ? this.findValue(answer.id) : ''}
                    onChange={modeAdmin!==true ? event => this.handleChangeInput(event, key) : null} 
                    placeholder={translations.WRITE_ANSWER[locale.localeName]}
                    //onBlur={modeAdmin!==true ? event => this.handleChangeInput(event, key) : null}
                    inputProps={{answerlistid: answer.id}}
                  />
                  {errorStatus[key] && <FormHelperText className={classes.helper}>{errorText[key]}</FormHelperText>}
                </FormControl>
                {modeAdmin === true ?
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                        <IconButton aria-label="edit"  onClick={() => this.setState({edit: true, selectedAnswer: answer.id})}>
                            <EditOutlinedIcon fontSize='small'/>
                        </IconButton>
                        <FormGroup >
                            <MySwitch size="small"  checked={activation[key] ? activation[key] : false} onChange={() => this.setActive(answer.id, !activation[key], key)} />
                        </FormGroup>
                    </div>
                : null}
              </div>
            ))
      }
      else{
          return(
            null
          )
        }
  }

  setActive = (id, active, key) => {
    const {activateAnswer} = this.props;
    let act = [...this.state.activation];
    act[key] = active;
    activateAnswer(id, active);
    this.setState({activation : act});
  }

  closeEditOrAddWindow = () => {
    this.setState({
      add : false,
      edit: false
    })
  }

  handlePopoverOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  render() {
    const { locale, questionNumber, question, answersPersistStatus, isSideListOpen, isAnswerReadOnly, isQuestionReadOnly, modeAdmin, activateQuestion, classes } = this.props;
    const { add, edit, selectedAnswer, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <QuestionTemplateContainer Layout={QuestionTemplate} modeAdmin={modeAdmin} isSideListOpen={isSideListOpen} activateQuestion={activateQuestion} questionNumber={questionNumber} question={question} answersPersistStatus={answersPersistStatus} isQuestionReadOnly={isQuestionReadOnly} isAnswerReadOnly={isAnswerReadOnly}>
        <MuiThemeProvider theme={theme}>
        <FormControl component="fieldset">
          <FormLabel filled={false} focused={false}>{translations.CHOOSE_ANSWER[locale.localeName]}:</FormLabel>
          <FormGroup>
            {this.choiceList()}
          </FormGroup>
        </FormControl>
        <Popover
                className={classes.popover}
                open={open}
                onClose={this.handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div style={{margin: 15}}>
                    {translations.ADD_ANSWER[locale.localeName]}
                </div>
        </Popover>
        {modeAdmin === true ? 
                  <Fab size="small" color="secondary" aria-label="add" onClick={() => this.setState({add: true})} onMouseEnter={this.handlePopoverOpen} onMouseLeave={this.handlePopoverClose}>
                          <AddIcon size='small'/>
                  </Fab>
                :
                  null
            }
            {edit === true ?
                    <AnswerTemplateContainer isMultiple={true} withInput={true} Layout={AnswerTemplate}  questionId={question.id} selectedAnswer={selectedAnswer} mode='edit' locale={locale} close={this.closeEditOrAddWindow} />
            :null
            }
            {add === true ?
                    <AnswerTemplateContainer isMultiple={true} withInput={true} Layout={AnswerTemplate}  questionId={question.id} mode='add' locale={locale} close={this.closeEditOrAddWindow} />
            :null
            }
        </MuiThemeProvider>
      </QuestionTemplateContainer>
    )
  }
}

export default withStyles(styles)(MultiChoicePlusQuestion);
