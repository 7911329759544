import React from 'react';
import Loading from "./Loading";
import Error from "./Error";
import {translations} from "../dictionary/translations";
import {ERROR_MODE} from "../util";
import ErrorContainer from "../containers/Error";

export const DictionaryContext = React.createContext();

class Dictionary extends React.Component {

  getDictionaries = () => {
    const {getLocaleList, getUserRoleList} = this.props;
    getLocaleList();
    getUserRoleList();
  }

  resetDictionaries = () => {
    const {resetLocaleList, resetUserRoleList} = this.props;
    resetLocaleList();
    resetUserRoleList();
    window.location.reload();
  }

  componentDidMount() {
    this.getDictionaries();
  }

  render() {
    const {children, locale, loading, success, error, localeList, userRoleList} = this.props;

    if (error) {
      return (<ErrorContainer
        Layout={Error}
        content={translations.ERROR_WHILE_DOWNLOADING_DATA}
        mode={ERROR_MODE.REFRESH}
        callback={this.resetDictionaries}/>)
    }

    if (loading) {
      return (
        <Loading/>
      )
    }

    if (success) {

      const dictionary = {
        localeList: localeList,
        userRoleList: userRoleList
      };

      return (
        <DictionaryContext.Provider value={{dictionary: dictionary, locale: locale}}>
          {children}
        </DictionaryContext.Provider>
      )

    }

    return (
      <div/>
    )
  }
};

export default Dictionary;
