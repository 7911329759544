import user from "./user";
import admin from "./admin";
import dictionary from "./dictionary";
import farm from "./farm";
import questionnaire from "./questionnaire";
import mainNavigation from "./mainNavigation";

import {combineReducers} from "redux";

const rehydrated = (state = false, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      return true;
    default:
      return state;
  }
};

const result = {
  rehydrated,
  dictionary : combineReducers(dictionary),
  farm : combineReducers(farm),
  user : combineReducers(user),
  questionnaire : combineReducers(questionnaire),
  admin : combineReducers(admin),
  mainNavigation : combineReducers(mainNavigation),
}

export default result;
