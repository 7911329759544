import React from "react";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const styles = {

};

const QuestionBox = (props) => {
  const {
    title,
    content,
    leftButtonCallback,
    rightButtonCallback,
    leftButtonText,
    rightButtonText,
    middleButtonCallback,
    middleButtonText,
    } = props;

  return (
    <Dialog open={true}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
        <Button variant="outlined" onClick={() => leftButtonCallback()}>
          {leftButtonText}
        </Button>
        {middleButtonText && middleButtonCallback ? 
            <Button variant="outlined" onClick={() => middleButtonCallback()}>
              {middleButtonText}
            </Button>
            : null
        }
        <Button variant="outlined" onClick={() => {rightButtonCallback()}}>
          {rightButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(QuestionBox);
