import React from 'react';
import {connect} from "react-redux";
import actions from '../../actions';

export class UserContainer extends React.Component {

  render() {

    const {
      Layout,
      classes,
      locale,
      user,
      resetUser,
      resetLocale,
    } = this.props;

    return (
      <Layout
        classes={classes}
        locale={locale}
        user={user}
        resetUser={resetUser}
        resetLocale={resetLocale}
      />
    )
  }
}

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  loading: state.user.login.loading,
  error: state.user.login.error,
  success: state.user.login.success,
  user: state.user.login.user
});

const mapDispatchToProps = {
  resetUser: actions.user.login.resetState,
  resetLocale: actions.user.locale.resetState
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
