import React from 'react';
import {withRouter} from "react-router-dom";
import UserContainer from "../../containers/user/User";
import NothingTemplate from "../NothingTemplate";
import UserPanel from "./Panel";

export class UserTemplate extends React.Component {

  render() {
    const { children, pageTitle } = this.props;

    return (
      <NothingTemplate pageTitle={pageTitle}>
        <div>
          <div>
            <div />
            <UserContainer Layout={UserPanel}/>
          </div>
          <div>
            {children}
          </div>
        </div>
      </NothingTemplate>
    )
  }
};

export default withRouter(UserTemplate);
