import React from "react";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import {translations} from "../../../../dictionary/translations";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/Done";
import InfoIcon from "@material-ui/icons/Info";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import {SAVE_STATUS} from "../../../../util";
import {style} from "../../../../styles/style.js";
import Popover from "@material-ui/core/Popover";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import QuestionTemplateAdmin from '../QuestionTemplate';
import QuestionTemplateContainerAdmin from '../../../../containers/questionnaire/form/QuestionTemplate';
import { green, red } from '@material-ui/core/colors';

const styles = {
  popover: {
    pointerEvents: 'none',
    maxWidth: '60vw'
  },
  card: {
    border: `3px solid ${style.gray}`,
    flexGrow: 1,
    boxSizing: 'border-box'
  },
  disabledQuestion: {
    '& > *': {
      backgroundColor: `${style.light_gray} !important`,
      pointerEvents: 'none !important',
      userSelect: 'none !important'
    }
  },
  disabledAnswer: {
    '& > *': {
      pointerEvents: 'none !important',
      userSelect: 'none !important'
    }
  },
  cardHeader: {
      fontSize: 20,
      fontWeight: 450,
      paddingBottom: 0,
      '@media only screen and (max-width: 768px)' : {
        fontSize: 18,
      },
  },
  saveStatus: {
    fontSize: 14,
    fontStyle: 'bold',
    '@media only screen and (max-width: 768px)' : {
      fontSize: 12,
    },
  },
  group: {
    lineHeight: 2.5,
    textTransform: 'uppercase',
    marginBottom: 10,
  }
};

const MySwitch = withStyles({
  switchBase: {
    color: red[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);


class QuestionTemplate extends React.Component {

  componentDidMount(){
    const { question } = this.props;
    this.setState({active: question.isActive});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {setQuestionListId} = this.props;
    const {selectedQuestionListId, isGoBack} = this.state;
    if (this.state.editQuestion === false && prevState.editQuestion === true) {
      if (!isGoBack) {
        async function refreshPage() {
          await setQuestionListId(null);
        }
        refreshPage().then(() => setQuestionListId(selectedQuestionListId));
      }
    }
  }

  showDate = (UTCdate, locale) => {
    const newUTCdate = UTCdate.replace(/-/g, '/');
    const generalDate = new Date(newUTCdate);
    const newDate = new Date(generalDate.getTime() - generalDate.getTimezoneOffset()*60*1000);
    const year = newDate.getFullYear();
    const month = newDate.getUTCMonth();
    const monthAsString = (month + 1).toString().length < 2 ? '0'+(month + 1).toString() : (month + 1);
    const date = newDate.getDate();
    const dateAsString = (date).toString().length < 2 ? '0'+(date).toString() : date;
    const hours = newDate.getHours();
    const hoursAsString = (hours).toString().length < 2 ? '0'+(hours).toString() : hours;
    const minutes = newDate.getMinutes();
    const minutesAsString = (minutes).toString().length < 2 ? '0'+(minutes).toString() : minutes;
    const selectedDate = `${year}-${monthAsString}-${dateAsString}  ${hoursAsString}:${minutesAsString}`;
    return <span>{translations.DATE_OF_SAVING[locale.localeName]} {selectedDate}</span>
  }

  getIcon = (answersPersistStatus) => {
    const { locale, classes } = this.props;
    if (answersPersistStatus){
    switch (answersPersistStatus.status) {
        case SAVE_STATUS.LOADING:
          return <CircularProgress size={30} style={{color: 'black'}} />

        case SAVE_STATUS.ERROR:
          return <ErrorOutline style={{color: 'red', fontSize: 30}}/>

        case SAVE_STATUS.SUCCESS:
          return <div style={{display: 'flex', alignItems: 'center'}}>
            <div className={classes.saveStatus}>
              {this.showDate(answersPersistStatus.date, locale)}
            </div>
            <div>
              <DoneIcon style={{color: 'green', fontSize: 30}} />
            </div>
          </div>

        case SAVE_STATUS.EMPTY:
          return <div/>

      default: return null;
    }
  }
  }

  state = {
    anchorEl : null,
    editQuestion: false,
    active: false,
    selectedQuestionListId: null,
    isGoBack: false,
  }

  handlePopoverOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  setActive = () => {
    const {activateQuestion, question, questionListId} = this.props;
    const {active} = this.state;
    activateQuestion(questionListId, question.id, !active);
    this.setState({active : !active });
  }

  closeEditWindow = (isGoBack, selectedQuestionListId) => {
    this.setState({
      editQuestion: false,
      selectedQuestionListId: selectedQuestionListId,
      isGoBack: isGoBack,
    })
  }

  generateTitle = () => {
    const {questionNumber, locale, formListData, question} = this.props;
    let textForForms="";
    if (question.recommendedList ? question.recommendedList.length !== 0 ? true : false : false){
      textForForms = '( ';
      question.recommendedList.forEach(el => {
          const form = formListData.filter(({formDicId}) => formDicId===el)[0].formName;
          textForForms += ` ${form},`;
      });
      textForForms = textForForms.substr(0, textForForms.length-1);
      textForForms += ' )'
    }
    const title = `${translations.QUESTION_NUMBER[locale.localeName]} ${questionNumber} ${question.recommended === true ? ` *` : ""}`;
    return [title, textForForms]
  }


  render() {
    const { classes, children, locale, isSideListOpen, question, answersPersistStatus, isQuestionReadOnly, isAnswerReadOnly, modeAdmin, questionListId} = this.props;
    const { anchorEl, active, editQuestion } = this.state;
    const open = Boolean(anchorEl);

    const title = this.generateTitle();

    return (
      <div 
        className={isQuestionReadOnly ? classes.disabledQuestion : isAnswerReadOnly ? classes.disabledAnswer : null} 
        style={isSideListOpen ? {display: 'flex', height: '100%', width: 'calc(100% - 350px)'} : {display: 'flex', height: '100%'}}
      >
        <Card className={classes.card}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end'}}>
              <CardHeader  disableTypography={true} title={title[0]} className={classes.cardHeader} />
              <div style={{position: 'relative', top: -8}}>{title[1]}</div>
              { (modeAdmin!==true ? question.questionTip : question.questionTip ? question.questionTip.id : null) ?
                      <InfoIcon style={{cursor: 'pointer', marginTop: 12, color: isQuestionReadOnly ? 'gray' : 'DodgerBlue'}}
                        onClick={this.handlePopoverOpen}
                        onMouseLeave={this.handlePopoverClose} /> : null
              }
              {modeAdmin === true ?
               <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                  <IconButton aria-label="edit" style={{flexGrow:1}} onClick={() => this.setState({editQuestion: true})}>
                        <EditOutlinedIcon fontSize='small'/>
                  </IconButton>
                  <FormGroup >
                        <MySwitch size="small" checked={active} onChange={() => this.setActive()} />
                  </FormGroup>
              </div> : null}
            </div>
            <div style={{paddingRight: 16}}>
              {this.getIcon(answersPersistStatus)}
            </div>
          </div>
          <CardContent style={{height: '100%'}}>
            {modeAdmin!==true ? question.questionGroupText ?
              <span style={{lineHeight: 2.5}}>
                      <strong>
                        {question.questionGroupText}<br/>
                      </strong>
              </span>
            : null :
            question.questionGroup ? question.questionGroup.id ?
              <span style={{lineHeight: 2.5}}>
                <strong>
                      {question.questionGroup.id ? question.questionGroup.text.translations[0].text : null}<br/>
                </strong>
              </span>
            : null : null}
            <span>{modeAdmin!==true ?  question.questionText : question.text ? question.text.id ? question.text.translations[0].text : null : null}</span>
            <div style={{height: 20}}/>
            {children}
          </CardContent>
        </Card>
            <Popover
                className={classes.popover}
                open={open}
                onClose={this.handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div style={{margin: 15}}>
                  {modeAdmin!==true ? question.questionTip : question.questionTip ? question.questionTip.id ? question.questionTip.text.translations[0].text : null : null}
                </div>
            </Popover>
            {editQuestion === true ?
                    <QuestionTemplateContainerAdmin Layout={QuestionTemplateAdmin}  questionListId={questionListId} questionId={question.id} mode='edit' locale={locale} close={this.closeEditWindow} />
            :null
            }
      </div>
    )
  }


}

export default withStyles(styles)(QuestionTemplate);
