import {URL} from '../../config';
import {FARM_EDIT_BEGIN, FARM_EDIT_FAILURE, FARM_EDIT_RESET, FARM_EDIT_SUCCESS} from "../../reducers/farm/edit";

function editFarm(body, id) {
  return dispatch => {
    dispatch(setBegin());
    return editFarmStart(body, id)
      .then(() => {
        dispatch(setSuccess());
      })
      .catch(() => {
        dispatch(setFailure())
      });
  };
}

const setBegin = () => ({
  type: FARM_EDIT_BEGIN
});

function editFarmStart(body, id) {
  return new Promise((resolve, reject) => {
    fetch(URL + `/secured/farm/` + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        "Authorization" : "Bearer " + sessionStorage.getItem('token'),
      },
      body: body
    }).then(response => response.json())
      .then(json => {
        if (json.success === true) {
          resolve()
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      })
  });
}

const setSuccess = () => ({
  type: FARM_EDIT_SUCCESS
});

const setFailure = () => ({
  type: FARM_EDIT_FAILURE
});

const resetState = () => ({
  type: FARM_EDIT_RESET
});

const index = {
  editFarm,
  resetState
}

export default index;
