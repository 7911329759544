import {SUPPORTED_LOCALE} from "../../util";

const getClientCountry = () => {
  let clientCountry = (navigator.language || window.navigator.language).substr(0, 2);
  if (clientCountry && (typeof clientCountry === 'string' || clientCountry instanceof String) && SUPPORTED_LOCALE.indexOf(clientCountry) !== -1) {
    return clientCountry;
  } else {
    return 'en';
  }
}

const initialState = {
  locale: {
    id: null,
    languageName: null,
    localeName: getClientCountry()
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOCALE': {
      return {
        ...state,
        locale: action.locale
      };
    }
    case 'RESET_LOCALE': {
      return initialState;
    }
    default:
      return state;
  }
}
