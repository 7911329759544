import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {translations} from '../../dictionary/translations';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ErrorContainer from "../../containers/Error";
import {withRouter} from "react-router-dom";
import Loading from "../Loading";
import Error from "../Error";
import {ERROR_MODE} from "../../util";
import LogoImage from '../../img/logo.jpg'
import {style} from "../../styles/style.js";
import Image from 'material-ui-image'
import FormHelperText from '@material-ui/core/FormHelperText';
import Avatar from "@material-ui/core/Avatar";
import Reaptcha from 'reaptcha';


const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    width: 400,
    height: 'auto',
    left: 'calc(50vw - 200px)',
    top: 'calc(50vh - 200px)',
    border: `3px solid ${style.gray}`,
    borderRadius: 20,
    paddingBottom: 20,
    '@media only screen and (max-width: 768px)' : {
      width: '70vw',
      left: '15vw',
    },
  },
  logo : {
    position: 'absolute',
    width: 120,
    height:120,
    left: 'calc(50vw - 60px)',
    top: 'calc(50vh - 260px)',
    zIndex: 1,
    '-webkit-box-shadow': '0px 0px 22px 0px rgba(79,74,79,1)',
    '-moz-box-shadow': '0px 0px 22px 0px rgba(79,74,79,1)',
    'box-shadow' : '0px 0px 22px 0px rgba(79,74,79,1)',
    '@media only screen and (max-width: 768px)' : {
      width: '22vw',
      left: '39vw',
      height: '22vw',
      top: 'calc(50vh - 200px - 11vw)',
    },
  },
  form: {
    width: '90%',
    marginTop: 55,
  },
  label: {
      color: `${style.violet} !important`,
  },
  input : {
    color: `${style.violet}`,
    '&:before' : {
      borderBottom: `2px solid ${style.rose_light} !important`,
    },
    '&:after' : {
      borderBottom: `2px solid ${style.rose_dark} !important`,
    },
    
  },
  submitButton : {
      transition: 'background-color ease 0.5s',
      outline: 'none',
      color: 'white',
      backgroundColor: style.rose_dark,
      '&:hover' : {
        backgroundColor: style.rose_dark_hover,
      },
  },
  backButton : {
    transition: 'background-color ease 0.5s',
    backgroundColor: style.gray,
    marginRight: '10%',
    color: 'white',
    outline: 'none',
    '&:hover' : {
      backgroundColor: style.gray_hover,
    },
  },
  link : {
      marginTop: 20,
      width: '90%',
  },
  otherButton : {
      transition: 'background-color ease 0.5s',
      outline: 'none',
      backgroundColor: style.rose_light,
      color: style.violet,
      '&:hover' : {
        backgroundColor: style.rose_light_hover,
      },
    },
  select : {
    '&:before' : {
      borderBottom: `2px solid ${style.rose_light} !important`,
    },
    '&:after' : {
      borderBottom: `2px solid ${style.rose_dark}`,
    }
  },
  helper : {
    color: 'red',
  }
};

export class UserRegistration extends React.Component {

  state = {
    smallLetterValidator: /[a-z]/,
    localeDicId : '',
    roleDicId : '',
    email : '',
    password : '',
    newPasswordRepeat: '',
    localeDicIdHasError: false,
    roleDicIdHasError: false,
    emailRequiredError: false,
    emailSmallLettersError: false,
    passwordRequiredError: false,
    passwordRepeatRequiredError: false,
    passwordRepeatCompatibilityError: false,
    emailValidationError: false,
    passwordValidationError: false,
    firstRegistrationError: true,
    isVerified: false,
    isVerifiedRequiredError: false,
  }


  handleChange = event => {
    const {emailValidator, passwordValidator} = this.props;

    this.setState({ [event.target.name]: event.target.value}, () => {
      const { localeDicId, roleDicId, email, password, firstRegistrationError, newPasswordRepeat, isVerified} = this.state;
      if (localeDicId) {
        this.setState({ localeDicIdHasError: false });
      }
      if (roleDicId) {
        this.setState({ roleDicIdHasError: false });
      }
      if (password) {
        this.setState({ passwordRequiredError: false });
        if (password.match(passwordValidator) != null){
          this.setState({ passwordValidationError: false });
        }
        else{
          this.setState({ passwordValidationError: true });
        }
      }
      else if (firstRegistrationError==false) {
        this.setState({ passwordRequiredError: true, passwordValidationError: false });
      }
      else{
        this.setState({passwordValidationError: false});
      }
      if (newPasswordRepeat) {
        this.setState({ passwordRepeatRequiredError: false });
        if (newPasswordRepeat === password){
          this.setState({ passwordRepeatCompatibilityError: false });
        }
        else{
          this.setState({ passwordRepeatCompatibilityError: true });
        }
      }
      else if (firstRegistrationError==false) {
        this.setState({ passwordRepeatRequiredError: true, passwordRepeatCompatibilityError: false });
      }
      else{
        this.setState({ passwordRepeatCompatibilityError: false });
      }
      if (email) {
          this.setState({ emailRequiredError: false });
          if (email.match(emailValidator) != null){
            this.setState({ emailValidationError: false });
              const onlyLetters = email.split('@').join('').split('.').join('');
              if (onlyLetters === onlyLetters.toLowerCase()){
                  this.setState({ emailSmallLettersError: false });
              }
              else{
                this.setState({ emailSmallLettersError: true });
              }
          }
          else{
            this.setState({ emailValidationError: true, emailSmallLettersError: false });
          }
      }
      else if (firstRegistrationError==false){
        this.setState({ emailRequiredError: true, emailValidationError: false, emailSmallLettersError: false});
      }
      else{
        this.setState({emailValidationError: false});
      }

      if (localeDicId && roleDicId && email && password && newPasswordRepeat) this.setState({firstRegistrationError: false});
    });
    
    
  };

  findLocaleName = (localeDicId) => {
    const foundEl = this.props.dictionary.localeList.find(el => {
      return (el.id === localeDicId)
    });
    return foundEl.localeName;
  }

  getTranslatedUserRoleName = (userRole, locale) => {
    const foundEl = userRole.translations.find(el => {
      const localeName = this.findLocaleName(el.localeDicId);
      return (localeName === locale.localeName)
    });
    return foundEl.text;
  };

  showLocaleList = (dictionary) => {
    if (dictionary && dictionary.localeList && dictionary.localeList.length > 0) {
      return (dictionary.localeList.map(locale => (
        <MenuItem key={locale.id} value={locale.id}>
          <ListItemIcon>
              <Avatar src={"./images/flags/" + locale.localeName + ".jpg"} style={{width: 20, height: 20}}/>
          </ListItemIcon>
          {locale.localeName}
        </MenuItem>
      )))
    } else {
      return (<MenuItem/>)
    }
  }

  showUserRoleList = (dictionary) => {
    const {locale} = this.props;
    if (dictionary && dictionary.localeList && dictionary.localeList.length > 0 && locale) {
      return (dictionary.userRoleList.map(userRole => (
        <MenuItem key={userRole.id} value={userRole.id}>{this.getTranslatedUserRoleName(userRole, locale)}</MenuItem>
      )))
    } else {
      return (<MenuItem/>)
    }
  }

  sendRegistrationRequest = (event) => {
    const { localeDicId, roleDicId, email, password, newPasswordRepeat, isVerified} = this.state;
    const {emailValidator, passwordValidator, locale} = this.props;
    event.preventDefault();
    if (!localeDicId) {
      this.setState({ localeDicIdHasError: true });
    }
    if (!roleDicId) {
      this.setState({ roleDicIdHasError: true });
    }
    if (!newPasswordRepeat) {
      this.setState({ passwordRepeatRequiredError: true });
    }
    if (!email) {
      this.setState({ emailRequiredError: true });
    }
    if (!password) {
      this.setState({ passwordRequiredError: true });
    }
    else if (!password.match(passwordValidator) == null){
      this.setState({ passwordValidationError: true });
    }
    this.setState({ firstRegistrationError: false });
    if(!isVerified){
      this.setState({isVerifiedRequiredError: true});
    }

    if((!this.state.localeDicIdHasError)&&
       (!this.state.roleDicIdHasError)&&
       (!this.state.emailRequiredError)&&
       (!this.state.passwordRequiredError)&&
       (!this.state.passwordRepeatRequiredError)&&
       (!this.state.firstRegistrationError)&&
       (!this.state.emailValidationError)&&
       (!this.state.emailSmallLettersError)&&
       (!this.state.passwordValidationError)&&
       (!this.state.passwordRepeatCompatibilityError)&&
       (isVerified) ){
          const { registerUser } = this.props;
          const form = new FormData();
          form.append('email', email);
          form.append('localeDicId', localeDicId);
          form.append('roleDicId', roleDicId);
          form.append('password', password);
          registerUser(form, locale.localeName);    
    }
    
  }

  handleSuccess = () => {
    const { resetUserRegistration, login, history, userData, locale} = this.props;
    const {email, password} = this.state;
    const {isSuperUser} = userData;
    resetUserRegistration();
    if (isSuperUser===false){
      history.push('');
    }
    else{
      const form = new FormData();
        form.append('email', email);
        form.append('password', password);
        login(form, locale.localeName);
    }
  }

  
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {history, loginStatus} = this.props;
    if (loginStatus.success === true) {
      history.push('administration')
    }
  }

  onVerify = recaptchaResponse => {
    this.setState({
      isVerified: true,
      isVerifiedRequiredError: false
    });
  };
  

  render() {
    const { classes, history, dictionary, locale, error, loading, success, resetUserRegistration, loginStatus, resetLogin, errorType, userData } = this.props;

    if (error === true) {
      return (
        <ErrorContainer content={errorType} Layout={Error} callback={resetUserRegistration}/>
      )
    }
    if (loginStatus.error === true) {
      return (
        <ErrorContainer Layout={Error} mode={ERROR_MODE.GO_HOME_PAGE} content={translations.ERROR_WHILE_LOGIN} callback={resetLogin}/>
      )
    }
    if (loading === true || loginStatus.loading === true) {
      return (
        <Loading/>
      )
    }
    if (success === true) {
      if (userData.isSuperUser){
        return (<ErrorContainer Layout={Error}
                 content={translations.SUPERUSER_REGISTERED_SUCCESSFULLY}
                 callback={this.handleSuccess}/>)
      }
      else{
        return (<ErrorContainer Layout={Error}
                content={translations.USER_REGISTERED_SUCCESSFULLY}
                callback={this.handleSuccess}/>)
      }
    }
    return (
      <div>
        <Paper className ={classes.logo}>
            <Image disableTransition={true} src={LogoImage} className={classes.logoImage} alt='logo SusPigSys' />
        </Paper>
        <Paper className={classes.paper}>
        <form className={classes.form} >
            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="email">{translations.E_MAIL_ADDRESS[locale.localeName]}</InputLabel>
              <Input className={classes.input} id="email" name="email"  onChange={this.handleChange} />
              {this.state.emailRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
              {this.state.emailValidationError && <FormHelperText className={classes.helper}>{translations.INVALID_EMAIL[locale.localeName]}</FormHelperText>}
              {this.state.emailSmallLettersError && <FormHelperText className={classes.helper}>{translations.EMAIL_SMALL_LETTERS[locale.localeName]}</FormHelperText>}
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="password">{translations.PASSWORD[locale.localeName]}</InputLabel>
              <Input className={classes.input} id="password" name="password" type="password" onChange={this.handleChange}/>
              {this.state.passwordRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
              {this.state.passwordValidationError && <FormHelperText className={classes.helper}>{translations.INVALID_PASSWORD_STRUCTURE[locale.localeName]}</FormHelperText>}
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="newPasswordRepeat">{translations.NEW_PASSWORD_REPEAT[locale.localeName]}</InputLabel>
              <Input className={classes.input} type="password" id="newPasswordRepeat" name="newPasswordRepeat"  onChange={this.handleChange}/>
              {this.state.passwordRepeatRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
              {this.state.passwordRepeatCompatibilityError && <FormHelperText className={classes.helper}>{translations.THE_PASSWORDS_MUST_BE_IDENTICAL[locale.localeName]}</FormHelperText>}
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel  className={classes.label} htmlFor="locale">{translations.CHOOSE_LANGUAGE[locale.localeName]}</InputLabel>
              <Select
                className={classes.select}
                value={this.state.localeDicId}
                onChange={this.handleChange}
                inputProps={{
                  name: 'localeDicId',
                  id: 'locale',
                }}
              >
                {this.showLocaleList(dictionary)}
              </Select>
              {this.state.localeDicIdHasError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel className={classes.label} htmlFor="user-role">{translations.CHOOSE_USER_TYPE[locale.localeName]}</InputLabel>
              <Select
                className={classes.select}
                value={this.state.roleDicId}
                onChange={this.handleChange}
                inputProps={{
                  name: 'roleDicId',
                  id: 'user-role',
                }}
              >
                {this.showUserRoleList(dictionary)}
              </Select>
              {this.state.roleDicIdHasError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
            </FormControl>
            <FormControl>
              {locale.localeName === 'pl' ?
                <Reaptcha sitekey="6Lfaxs0UAAAAACDOpJft81uB60N7V4Ack9TKfotv" onVerify={this.onVerify} hl='pl' />  
                  : null
              }
              {locale.localeName === 'en' ?
                <Reaptcha sitekey="6Lfaxs0UAAAAACDOpJft81uB60N7V4Ack9TKfotv" onVerify={this.onVerify} hl='en' />  
                  : null
              }
              {locale.localeName === 'de' ?
                <Reaptcha sitekey="6Lfaxs0UAAAAACDOpJft81uB60N7V4Ack9TKfotv" onVerify={this.onVerify} hl='de' />  
                  : null
              }
              {locale.localeName === 'fi' ?
                <Reaptcha sitekey="6Lfaxs0UAAAAACDOpJft81uB60N7V4Ack9TKfotv" onVerify={this.onVerify} hl='fi' />  
                  : null
              }
              {locale.localeName === 'it' ?
                <Reaptcha sitekey="6Lfaxs0UAAAAACDOpJft81uB60N7V4Ack9TKfotv" onVerify={this.onVerify} hl='it' />  
                  : null
              }
              {locale.localeName === 'nl' ?
                <Reaptcha sitekey="6Lfaxs0UAAAAACDOpJft81uB60N7V4Ack9TKfotv" onVerify={this.onVerify} hl='nl' />  
                  : null
              }
              {this.state.isVerifiedRequiredError && <FormHelperText className={classes.helper}>{translations.FIELD_IS_REQUIRED[locale.localeName]}</FormHelperText>}
            </FormControl>

            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20, outline: 'none'}}>
              <Button
                fullWidth
                size="large"
                className={classes.backButton}
                onClick={() => {history.goBack()}}
              >
                {translations.GO_BACK[locale.localeName]}
              </Button>
              <Button
                type='submit'
                fullWidth
                size="large"
                className={classes.submitButton}
                onClick={(event) => {this.sendRegistrationRequest(event)}}
              >
                {translations.REGISTRATE[locale.localeName]}
              </Button>
            </div>
            </form>
        </Paper>

      </div>
    )
  }
}

export default withRouter(withStyles(styles)(UserRegistration));
