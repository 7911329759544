import React from 'react';
import Chart from 'chart.js';

class VerticalChart extends React.Component {

  chartRef = React.createRef();

  createChart = (data, title, choosenChildren, dataAverage, formDicId) => {
    const labels = [];
    const values = [];
    data.forEach(el => {
        labels.push(el.name);
        // values.push(formDicId === 2 ? el.scal_value : formDicId === 5 ? el.value : el.value*100);
        values.push(el.value ? el.value.toFixed(2) : 0)
    });

    const maxValue = Math.max(...values);

    const dataForChart = {
        labels: labels,
        datasets: [{
            data: values,
            borderColor: 'black',
            borderWidth: '2',   
            backgroundColor:'rgba(255, 99, 132, 0.2)',
            barThickness: 20,
        },
        {
            data: dataAverage,
            borderColor: 'black',
            borderWidth: '2',   
            backgroundColor:'rgba(0, 0, 255, 0.2)',
            barThickness: 20,
          }],
    };



    this.myChart = new Chart (this.chartRef.current, {
        type: 'bar',
        data: dataForChart,
        options: {
          scales: {
              xAxes: [{
                  display: true,
                  barPercentage: 1,
                  barThickness: 'flex',
                  //maxBarThickness: 8,
                  //minBarLength: 2,
                  gridLines: {
                      offsetGridLines: false
                  }
              }],
              yAxes: [{
                  display: true,
                  ticks: {
                      beginAtZero: true,
                      // steps: 0.1,
                      max: maxValue*1.5
                  }
              }]
          },
          title: {
              display: true,
              text: `${title} - ${choosenChildren}`,
              fontSize: 20,
          },
          legend : {
              display: false,
          },
          ticks: {
              max: 100,
          },
      }  
    });
  }

  componentDidMount() {
    const { data, title,choosenChildren, dataAverage, formDicId} = this.props;
    this.createChart(data, title, choosenChildren, dataAverage, formDicId);
  }

  componentDidUpdate(prevProps) {
    const { data, title, choosenChildren, dataAverage, formDicId} = this.props;
    if (choosenChildren !== prevProps.choosenChildren || dataAverage.length !== prevProps.dataAverage){
        const labels = [];
        const values = [];
        data.forEach(el => {
            labels.push(el.name);
            // values.push(formDicId === 2 ? el.scal_value : formDicId === 5 ? el.value : el.value*100);
            values.push(el.value ? el.value.toFixed(2) : 0);
        });

        const maxValue = Math.max(...values);
        this.myChart.options.scales.yAxes[0].ticks.max = maxValue*1.5;

        this.myChart.data.labels = labels;
        this.myChart.options.title.text = `${title} - ${choosenChildren}`;
        this.myChart.data.datasets[0].data = values;
        this.myChart.data.datasets[1].data = dataAverage;
        this.myChart.update();
    }
  }

  render() {
    return <canvas ref={this.chartRef}/>
  }

}

export default VerticalChart;
