import React from 'react';
import {connect} from "react-redux";
import actions from '../actions';

export class DictionaryContainer extends React.Component {

  render() {

    const {
      Layout,
      children,
      locale,
      loading,
      success,
      error,
      localeList,
      userRoleList,
      getLocaleList,
      getUserRoleList,
      resetLocaleList,
      resetUserRoleList,
    } = this.props;

    return (
      <Layout
        children={children}
        locale={locale}
        loading={loading}
        success={success}
        error={error}
        localeList={localeList}
        userRoleList={userRoleList}
        getLocaleList={getLocaleList}
        getUserRoleList={getUserRoleList}
        resetLocaleList={resetLocaleList}
        resetUserRoleList={resetUserRoleList}
      />
    )
  }
}

const mapDispatchToProps = {
  getLocaleList: actions.dictionary.localeList.getLocaleList,
  getUserRoleList: actions.dictionary.userRoleList.getUserRoleList,
  resetLocaleList: actions.dictionary.localeList.getLocaleList,
  resetUserRoleList: actions.dictionary.userRoleList.resetState,
  setLocale: actions.user.locale.setLocale,
};

const mapStateToProps = state => ({
  locale: state.user.locale.locale,
  loading: state.dictionary.localeList.loading || state.dictionary.userRoleList.loading,
  success: state.dictionary.localeList.success && state.dictionary.userRoleList.success,
  error: state.dictionary.localeList.error || state.dictionary.userRoleList.error,
  localeList: state.dictionary.localeList.items,
  userRoleList: state.dictionary.userRoleList.items,
});

export default connect(mapStateToProps, mapDispatchToProps)(DictionaryContainer);
