import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {withStyles} from "@material-ui/core";
import {translations} from "../../dictionary/translations";
import {style} from "../../styles/style.js";
import {USER_ROLE_DIC_ID} from "../../util";

const styles = {
  appBar: {
    width: 'auto',
    position: 'relative',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: 'transparent',
  },
  tabs : {
    width: 'calc(100% + 30px)',
    '@media only screen and (max-width: 768px)' : {
        width: 'calc(100% + 10px)',
    },
  },
  tab : {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottom: 'none',
    backgroundColor: '#cccccc',
    opacity: 1,
    paddingLeft: '3vw',
    paddingRight: '3vw',
    '@media only screen and (max-width: 768px)' : {
        paddingLeft: '1vw',
        paddingRight: '1vw',
        fontSize: 11,
    },
  },
  tab1 : {
    '-webkit-box-shadow': '10px 0px 5px -5px rgba(0,0,0,0.75)',
    '-moz-box-shadow': '10px 0px 5px -5px rgba(0,0,0,0.75)',
    'box-shadow': '10px 0px 5px -5px rgba(0,0,0,0.75)',
  },
  tab2 : {
    position: 'relative',
    left: -30,
    '-webkit-box-shadow': '-10px 0px 5px -5px rgba(0,0,0,0.75)',
    '-moz-box-shadow': '-10px 0px 5px -5px rgba(0,0,0,0.75)',
    'box-shadow': '-10px 0px 5px -5px rgba(0,0,0,0.75)',
    '@media only screen and (max-width: 768px)' : {
          left: -10,
    },
  },
  tab3 : {
    position: 'relative',
    left: -60,
    '-webkit-box-shadow': '-10px 0px 5px -5px rgba(0,0,0,0.75)',
    '-moz-box-shadow': '-10px 0px 5px -5px rgba(0,0,0,0.75)',
    'box-shadow': '-10px 0px 5px -5px rgba(0,0,0,0.75)',
    '@media only screen and (max-width: 768px)' : {
          left: -20,
    },
  },
  active: {
    zIndex: 1,
    backgroundColor: 'white',
  },
  indicator : {
    backgroundColor: 'white',
 }
};

class MainNavigationNavBar extends React.Component {

  componentDidMount() {
    const {setNavBarValue, modeAdmin} = this.props;
    if (modeAdmin === true ){
      setNavBarValue(-1);
    }
    else{
      setNavBarValue(0);
    }
  }

  handleChange = (event, value) => {
    const {setNavBarValue} = this.props;
    setNavBarValue(value);
  };


  render() {
    const { classes, locale, navBarValue, user, modeAdmin } = this.props;
    const tab1Class = [classes.tab, classes.tab1];
    const tab2Class = [classes.tab, classes.tab2];
    const tab3Class = [classes.tab, classes.tab3];
    if (navBarValue === 0 || navBarValue === -1) tab1Class.push(classes.active);
    else if (navBarValue === 1) tab2Class.push(classes.active);
    else if (navBarValue === 2) tab3Class.push(classes.active);

    return (
      <AppBar className={classes.appBar} color="default">
        <Tabs
          className={classes.tabs}
          classes={{indicator : classes.indicator}}
          value={navBarValue}
          variant = 'fullWidth'
          onChange={this.handleChange}

        >
          {modeAdmin === true ? <Tab value={-1} label={translations.USER_LIST[locale.localeName]} className={tab1Class.join(' ')}/> : null}
          {modeAdmin !== true ? <Tab value={0} label={translations.QUESTIONNAIRE_LIST[locale.localeName]} className={tab1Class.join(' ')}/> : null}
          {modeAdmin !== true ? <Tab value={1} label={translations.FARM_LIST[locale.localeName]}  className={tab2Class.join(' ')}/> : null}
          {modeAdmin !== true ? user.roleDicId === USER_ROLE_DIC_ID.EKSPERT_NAUKOWY ? <Tab value={2} label={translations.DATA_EXPORT[locale.localeName]}  className={tab3Class.join(' ')}/> : null : null}
        </Tabs>
      </AppBar>
    )
  }
}

export default withStyles(styles)(MainNavigationNavBar);
