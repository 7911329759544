import form from "./form";
import list from "./list";
import subFormList from "./subFormList";
import questionList from "./questionList";
import question from "./question";
import questionsAdmin from "./questionsAdmin";
import optionalQuestionList from "./optionalQuestionList";
import answer from "./answer";

const index = {
  form,
  list,
  subFormList,
  questionList,
  optionalQuestionList,
  question,
  questionsAdmin,
  answer
}

export default index;
