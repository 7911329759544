import React from 'react';
import {connect} from "react-redux";
import actions from "../../../../actions";

export class FormBarContainer extends React.Component {

  render() {

    const {
      Layout,
      formId,
      setNextFormId,
      formListData,
      modeAdmin,
      isAnswerReadOnly,
      user,
      isSideListOpen
    } = this.props;

    return (
      <Layout
        formId={formId}
        setNextFormId={setNextFormId}
        formListData={formListData}
        modeAdmin={modeAdmin}
        isAnswerReadOnly={isAnswerReadOnly}
        user={user}
        isSideListOpen={isSideListOpen}
      />
    )
  }
}

const mapStateToProps = state => ({
  formListData: state.questionnaire.form.list.data,
  formId : state.questionnaire.form.form.formId,
  isAnswerReadOnly : state.questionnaire.form.form.isAnswerReadOnly,
  user: state.user.login.user,
});

const mapDispatchToProps = {
  setNextFormId : actions.questionnaire.form.list.setNextFormId,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormBarContainer);
