import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {translations} from "../../../dictionary/translations";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    left: '10%',
    width: '80%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    paddingBottom: 20,
    boxShadow: 'none'
  },
  table: {
    minWidth: 650,
  },
}));


export default function SimpleTable({localeName, data, dataAverage}) {
  const classes = useStyles();
  const isAverageVisible = dataAverage.length !== 0;
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{translations.NAME[localeName]}</TableCell>
            <TableCell align="right">{translations.VALUE[localeName]}</TableCell>
            <TableCell align="right">{translations.SCAL_VALUE[localeName]}</TableCell>
            {isAverageVisible ? <TableCell align="right">{translations.AVERAGE_VALUE[localeName]}</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.value ? row.value.toFixed(2) : ''}</TableCell>
              <TableCell align="right">{row.scal_value ? row.scal_value.toFixed(2) : ''}</TableCell>
              {isAverageVisible ? <TableCell align="right">{dataAverage[index]}</TableCell> : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}