import React from 'react';
import UserTemplate from "./Template";

const title = {
  marginBottom: '3%',
  fontFamily: 'Rubik',
  textAlign: 'center'
};

export class UserFormTemplate extends React.Component {

  render() {
    const {children, pageTitle, user} = this.props;

    return (
      <UserTemplate pageTitle={pageTitle} user={user}>
        <div>
          <h1 style={title}>
            {pageTitle}
          </h1>

          <div className="formStyle">
            {children}
          </div>
        </div>
      </UserTemplate>
    )

  };
}

export default UserFormTemplate;
