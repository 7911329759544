import {BEGIN_STATUS, FAILURE_STATUS, INITIAL_STATUS, SUCCESS_STATUS} from "../../../util";

export const GET_SUBFORM_LIST_BEGIN = 'GET_SUBFORM_LIST_BEGIN';
export const GET_SUBFORM_LIST_SUCCESS = 'GET_SUBFORM_LIST_SUCCESS';
export const GET_SUBFORM_LIST_FAILURE = 'GET_SUBFORM_LIST_FAILURE';
export const GET_SUBFORM_LIST_RESET = 'GET_SUBFORM_LIST_RESET';
export const SET_FILLING_UP_STATUS = 'SET_FILLING_UP_STATUS';
export const SET_NEXT_SUBFORM_ID = 'SET_NEXT_SUBFORM_ID';
export const PREVIOUS_SUBFORM_LIST = 'PREVIOUS_SUBFORM_LIST';
export const ACTIVATE_SUBFORM_BEGIN = 'ACTIVATE_SUBFORM_BEGIN';
export const ACTIVATE_SUBFORM_SUCCESS = 'ACTIVATE_SUBFORM_SUCCESS';
export const ACTIVATE_SUBFORM_FAILURE = 'ACTIVATE_SUBFORM_FAILURE';
export const SWAP_SUBFORM_BEGIN = 'SWAP_SUBFORM_BEGIN';
export const SWAP_SUBFORM_SUCCESS = 'SWAP_SUBFORM_SUCCESS';
export const SWAP_SUBFORM_FAILURE = 'SWAP_SUBFORM_FAILURE';

export const initialState = {
  getStatus: INITIAL_STATUS,
  activateStatus: INITIAL_STATUS,
  swapStatus: INITIAL_STATUS,
  data : null,
  fillingUpStatus : [],
  nextSubFormId : null,
  prevSubformList: null,
};



export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBFORM_LIST_BEGIN: {
      return {
        ...state,
        getStatus: BEGIN_STATUS,
        data: null,
      };
    }
    case GET_SUBFORM_LIST_SUCCESS: {
      return {
        ...state,
        getStatus: SUCCESS_STATUS,
        data: action.data
      };
    }
    case GET_SUBFORM_LIST_FAILURE: {
      return {
        ...state,
        getStatus: FAILURE_STATUS,
        data: null
      };
    }


    case ACTIVATE_SUBFORM_BEGIN: {
      return {
        ...state,
        activateStatus: BEGIN_STATUS
      };
    }
    case ACTIVATE_SUBFORM_SUCCESS: {
      return {
        ...state,
        activateStatus: SUCCESS_STATUS
      };
    }
    case ACTIVATE_SUBFORM_FAILURE: {
      return {
        ...state,
        activateStatus: FAILURE_STATUS
      };
    }

    case SWAP_SUBFORM_BEGIN: {
      return {
        ...state,
        swapStatus: BEGIN_STATUS
      };
    }
    case SWAP_SUBFORM_SUCCESS: {
      return {
        ...state,
        swapStatus: SUCCESS_STATUS
      };
    }
    case SWAP_SUBFORM_FAILURE: {
      return {
        ...state,
        swapStatus: FAILURE_STATUS
      };
    }


    case SET_FILLING_UP_STATUS: {
      return {
        ...state,
        fillingUpStatus : action.fillingUpStatus
      };
    }
    case SET_NEXT_SUBFORM_ID: {
      return {
        ...state,
        nextSubFormId : action.nextSubFormId
      };
    }
    case PREVIOUS_SUBFORM_LIST: {
      return {
        ...state,
        prevSubformList : action.prevSubformList
      };
    }
    case GET_SUBFORM_LIST_RESET: {
      return initialState;
    }
    default:
      return state;
  }
}
